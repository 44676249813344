import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddFiatAddressFormData } from '@global-components/Forms/AddFiatAddressForm';
import { EmailVerification } from '@global-components/Forms/Verification/EmailVerification';
import { MobileVerification } from '@global-components/Forms/Verification/MobileVerification';
import Wizard from '@global-components/Wizard';

import { Asset } from '@shared/api';
import { withdrawalService } from '@shared/services';
import { UIStore, UserStore } from '@shared/store';

// Need to lazy load it because of the order the global service it relies on is instantiated
const AddFiatAddressForm = React.lazy(() => import('@global-components/Forms/AddFiatAddressForm'));
const MIN_CONTAINER_HEIGHT = '600px';

enum TransferAddressFiatModalStep {
  Form = 0,
  PhoneVerification = 1,
  EmailVerification = 2,
}

type Props = {
  asset: Asset;
  onClose: () => void;
  refetchAddresses?: () => void;
};

export const TransferAddressFiatModal: React.FC<Props> = ({ asset, onClose, refetchAddresses }) => {
  const [stepIndex, setStepIndex] = useState<TransferAddressFiatModalStep>(TransferAddressFiatModalStep.Form);

  const [addressFormData, setAddressFormData] = useState<AddFiatAddressFormData>({
    accountHolderName: '',
    accountNumber: '',
    accountName: '',
    bsb: '',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [pin, setPin] = useState<string>('');

  const { addToastMessage } = UIStore.useUIStore;
  const { isNZ } = UserStore.useUserStore;
  const { t } = useTranslation('common');

  const addAccount = async () => {
    try {
      if (addressFormData) {
        const { accountHolderName, accountName, accountNumber, bsb } = addressFormData;

        const response = await withdrawalService.addWithdrawAddress(
          asset.code,
          accountName,
          accountNumber,
          accountHolderName,
          bsb,
          token,
          pin || undefined,
        );

        if (refetchAddresses) {
          refetchAddresses();
        }

        setToken(response.token);

        return {
          mfaType: response.mfaType,
        };
      }
      throw new Error(t('addAddress.genericError'));
    } catch (error: any) {
      addToastMessage({ severity: 'error', message: error?.message || t('addAddress.genericError') });
      return {
        mfaType: 'error',
      };
    }
  };

  const onAddAccountSubmit = async () => {
    if (addressFormData) {
      setLoading(true);
      try {
        const { mfaType } = await addAccount();
        setLoading(false);

        switch (mfaType) {
          case 'sms': {
            setStepIndex(TransferAddressFiatModalStep.PhoneVerification);
            break;
          }
          case 'email': {
            setStepIndex(TransferAddressFiatModalStep.EmailVerification);
            break;
          }
          case 'error': {
            break;
          }
          default: {
            onClose();
            addToastMessage({ severity: 'success', message: t('addAddress.success') });
            break;
          }
        }
      } catch (error: any) {
        addToastMessage({ severity: 'error', message: error?.message || t('addAddress.genericError') });
      }
    }
  };

  const returnToForm = async (reSubmit?: boolean) => {
    setStepIndex(TransferAddressFiatModalStep.Form);
    if (reSubmit) {
      await onAddAccountSubmit();
    }
  };

  const isNotFilled =
    !addressFormData.accountHolderName ||
    !addressFormData.accountNumber ||
    !addressFormData.accountName ||
    (!isNZ() && !addressFormData.bsb); // there is no bsb for NZ accounts

  return (
    <Wizard.Container
      maintainStateOnIndexes={[TransferAddressFiatModalStep.Form]}
      stepIndex={stepIndex}
      onClose={onClose}
      containerSx={{ minHeight: MIN_CONTAINER_HEIGHT }}
      open
    >
      {[
        // Form
        <Wizard.Step
          key={TransferAddressFiatModalStep.Form}
          title={t('addAddress.title')}
          onAction={onAddAccountSubmit}
          actionLoading={loading}
          stepLoading={loading}
          locked={isNotFilled || !isValid}
          onClose={onClose}
        >
          <Suspense fallback=''>
            <AddFiatAddressForm
              setFormData={setAddressFormData}
              formData={addressFormData}
              setFormValid={setIsValid}
              asset={asset}
            />
          </Suspense>
        </Wizard.Step>,

        // Mobile verification
        <Wizard.Step
          key={TransferAddressFiatModalStep.PhoneVerification}
          title={t('verification.mobile.title')}
          onClose={onClose}
          hideActions
        >
          <MobileVerification
            onSubmit={() => returnToForm(true)}
            onCancel={onClose}
            setCode={setPin}
            code={pin}
            onResendCode={async () => {
              setToken('');
              setPin('');
              await onAddAccountSubmit();
            }}
          />
        </Wizard.Step>,

        // Email verification
        <Wizard.Step
          key={TransferAddressFiatModalStep.EmailVerification}
          actionName={t('verification.email.action')}
          title={t('verification.email.title')}
          onAction={onClose}
          onClose={onClose}
        >
          <EmailVerification />
        </Wizard.Step>,
      ]}
    </Wizard.Container>
  );
};
