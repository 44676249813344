import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';
import { ToastManagerContext } from '@swyftx/aviary/molecules/ToastManager/ToastManager.context';

import { api } from '@shared/api';
import { RecurringOrderSource } from '@shared/enums';

import * as Sentry from '@sentry/react';

import { useAutoInvestCache } from '../../hooks';

type Props = {
  name: string;
  templateUuid: string;
  orderType: RecurringOrderSource;
  position?: 'center' | 'bottom';
  onOpen?: () => void;
};

const DeleteAutoInvestModal: React.FC<PropsWithChildren<Props>> = ({
  templateUuid,
  name,
  orderType,
  position = 'center',
  onOpen,
  children,
}) => {
  const [open, setOpen] = useState<boolean>();
  const { invalidateCache } = useAutoInvestCache();
  const { addToast } = useContext(ToastManagerContext);
  const [removing, setRemoving] = useState<boolean>(false);

  const handleDeleteOrder = useCallback(async () => {
    try {
      setRemoving(true);
      await api.endpoints.deleteRecurringOrderTemplate({ params: { templateUuid, name } });
      invalidateCache();
      setOpen(false);
      addToast({ title: 'Order successfully deleted', type: 'success' });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setRemoving(false);
    }
  }, [addToast, invalidateCache, name, templateUuid]);

  useEffect(() => {
    if (open && onOpen) onOpen();
  }, [open, onOpen]);

  return (
    <Modal
      triggerElement={children}
      open={open}
      onOpenChange={setOpen}
      position={position}
      className='sm:!min-w-[500px] sm:!max-w-[500px]'
    >
      <FlexLayout direction='column'>
        <FlexLayout alignItems='center' justifyContent='space-between' className='p-24'>
          <Heading size='h4'>Delete order</Heading>
          <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={() => setOpen(false)} />
        </FlexLayout>
        <FlexLayout direction='column' spacing={24} className='px-24'>
          <Body color='secondary'>Are you sure you want to delete this order? This action cannot be undone.</Body>
          {orderType !== 'ACCOUNT' && (
            <Notification
              severity='warning'
              title='Additional action required'
              content='Remember to also delete any recurring bank transfers set up with your bank in relation to this order. Future transfers received after order deletion will be credited to your account.'
            />
          )}
        </FlexLayout>
        <FlexLayout alignItems='center' justifyContent='end' className='p-24' spacing={12}>
          <Button variant='outlined' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant='filled' color='destructive' onClick={handleDeleteOrder} loading={removing}>
            Delete order
          </Button>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { DeleteAutoInvestModal };
