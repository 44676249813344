import React from 'react';

import { EventCallbackInterface } from 'victory';

import { DonutChartItem } from './DonutChart.types';
import { useDonutChartLegend } from './hooks';
import { FlexLayout } from '../../Layout/Flex';
import { Body, Numeric } from '../../Typography';

type Props = {
  chartId: string;
  data: DonutChartItem[];
  showPercentages?: boolean;
  onUpdateExternalMutations: React.Dispatch<
    React.SetStateAction<EventCallbackInterface<string | string[], string | number | (string | number)[]>[] | undefined>
  >;
};

const DonutChartLegend: React.FC<Props> = ({ chartId, data, showPercentages = true, onUpdateExternalMutations }) => {
  const { onMouseEnter, onMouseLeave } = useDonutChartLegend({ chartId, onUpdateExternalMutations });

  return (
    <FlexLayout className='w-full flex-wrap justify-center @md:w-full @md:justify-start' alignItems='start' spacing={0}>
      {data.map((d, index) => (
        <FlexLayout
          key={d.key}
          className='cursor-pointer rounded-[12px] px-8 py-4 hover:bg-color-background-surface-hover'
          alignItems='center'
          spacing={0}
          justifyContent='start'
          onMouseEnter={() => onMouseEnter(index.toString())}
          onMouseLeave={onMouseLeave}
        >
          <FlexLayout alignItems='center' spacing={8}>
            <div className='h-12 w-12 rounded-[4px]' style={{ backgroundColor: d.color }} />
            <Body size='xsmall'>{d.key}</Body>
          </FlexLayout>
          {d.percentage && showPercentages && <Numeric size='xsmall'>{d.percentage}</Numeric>}
        </FlexLayout>
      ))}
    </FlexLayout>
  );
};

export { DonutChartLegend };
