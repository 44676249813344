import { MFAEnrolment, MFARequired, OTPRequired, SuperheroLoginResponse, UserProfile } from '@shared/api';

export enum AuthStep {
  SignIn = 'SignIn',
  TwoFactorLogin = 'TwoFactorLogin',
  Success = 'Success',
  NewLoginDetected = 'NewLoginDetected',
  Error = 'Error',
}

export type FederatedAuthType = 'Learn' | 'Superhero';

type AuthStateType = {
  email: string;
  password: string;
  token: string;
  rememberMe: boolean;
  error: string;
  submitting: boolean;
  mfaData?: MFARequired | MFAEnrolment;
  otpData?: OTPRequired;
  authStep: AuthStep;
};

type AuthPostMessage = {
  type: 'success' | 'error';
  token?: string;
  user?: {
    firstName?: string;
  };
};

type AuthActionType = {
  setEmail: (email: string) => void;
  setPassword: (email: string) => void;
  setToken: (token: string) => void;
  setRememberMe: (rememberMe: boolean) => void;
  setError: (error: string) => void;
  setSubmitting: (submitting: boolean) => void;
  setMfaData: (mfaData?: MFARequired | MFAEnrolment) => void;
  setOtpData: (otpData?: OTPRequired) => void;

  setAuthStep: (authStep: AuthStep) => void;
  login: (type: FederatedAuthType) => void;
  mfaLogin: (
    type: FederatedAuthType,
    code: string,
    challenge_type?: string,
    oob_code?: string,
    handleLogin?: boolean,
  ) => any;
  otpLogin: (type: FederatedAuthType, code: string) => void;
  onLoggedIn: (type: FederatedAuthType, res: UserProfile | SuperheroLoginResponse) => any;
  getFederatedAuthType: (searchParams: URLSearchParams) => FederatedAuthType;
  getRememberMe: () => void;
  postMessage: (message: AuthPostMessage) => void;
  cleanup: () => void;
};

interface AuthStoreSchema extends AuthStateType, AuthActionType {}

const initialValues: AuthStoreSchema = {
  email: '',
  password: '',
  token: '',
  rememberMe: false,
  error: '',
  submitting: false,
  mfaData: undefined,
  authStep: AuthStep.SignIn,
  setEmail: () => {},
  setPassword: () => {},
  setToken: () => {},
  setRememberMe: () => {},
  setMfaData: () => {},
  setOtpData: () => {},
  setAuthStep: () => {},
  setError: () => {},
  setSubmitting: () => {},
  login: () => {},
  mfaLogin: () => {},
  otpLogin: () => {},
  onLoggedIn: () => {},
  getFederatedAuthType: () => 'Learn',
  getRememberMe: () => {},
  postMessage: () => {},
  cleanup: () => {},
};

export { initialValues };
export type { AuthStoreSchema, AuthPostMessage };
