import React, { useEffect, useState } from 'react';

import Select from '@mui/material/Select';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { AvatarProps } from '@swyftx/aviary/atoms/Avatar/Avatar.styles';
import { AddFilled, DollarFilled } from '@swyftx/aviary/icons/filled';
import { Chip, MenuItem, Skeleton, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';
import { assetService, WithdrawalAddress, withdrawalService } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { SingleWalletManageAccounts } from '@routes/Wallet/subroutes/SingleWallet/components/SingleWalletManageAccounts';
import WalletService from '@services/WalletService/Wallet.service';

type Props = {
  asset: Asset;
  title: string;
  value?: string;
  onChange: (val: WithdrawalAddress) => void;
  onAddAddress?: () => void;
  forceAddressFetch?: any;
  loading?: boolean;
};

const generateKey = () => Date.now().toString(36) + Math.random().toString(36).substring(2);

const WithdrawAddressDropdown: React.FC<Props> = ({
  asset,
  value,
  onChange,
  onAddAddress,
  title,
  forceAddressFetch,
  loading = false,
}) => {
  const [addresses, setAddresses] = useState<Array<WithdrawalAddress>>([]);
  const isFiat = asset && assetService.isAssetFiat(asset.id);

  // Used to determine whether to trigger an address fetch on the dropdown
  const [addressStateKey, setAddressStateKey] = useState<string>(generateKey());

  useEffect(() => {
    const getAddresses = async () => {
      const withdrawAddresses = await WalletService.getWithdrawAddresses(asset.code);
      setAddresses(withdrawAddresses);
    };

    if (asset) getAddresses();
  }, [asset, forceAddressFetch, addressStateKey]);

  const onSelect = (id: string) => {
    if (id === '-1') {
      if (onAddAddress) onAddAddress();
      return;
    }
    const address = addresses?.find((a) => a.id === Number(id));

    if (address) onChange(address);
  };

  const getIcon = (color: AvatarProps['color'], imageClass?: string) => (
    <Avatar size='lg' color={color}>
      <DollarFilled className={cn('h-18 w-18', imageClass)} />
    </Avatar>
  );

  const renderRow = (address: WithdrawalAddress, networkActive?: boolean) => (
    <Stack direction='row' spacing={2} alignItems='center' width='100%' justifyContent='space-between'>
      <Stack direction='row' spacing={2} alignItems='center' paddingY={0.5}>
        {getIcon('success', 'text-color-text-inverse')}
        <Stack direction='column'>
          <Typography PII fontSize={16} fontWeight={500} color={address.verified ? 'text.primary' : 'text.disabled'}>
            {address.label}
          </Typography>

          <Stack PII direction='row' spacing={1}>
            <Typography fontSize={14} fontWeight={400} color='text.secondary'>
              {address.address_details.address}
            </Typography>
          </Stack>

          {address.metadata?.name && address.metadata?.data && (
            <Typography fontSize={14} fontWeight={400} color='text.secondary'>
              {address.metadata.name}: {address.metadata.data}
            </Typography>
          )}

          {address.address_details.network && (
            <Typography fontSize={14} fontWeight={400} color='text.secondary'>
              {`${address.address_details.network} Network`}
            </Typography>
          )}
        </Stack>
      </Stack>
      {!address.verified && <Chip label='Confirm email' size='small' color='primary' />}
      {address.verified === true && networkActive === false && (
        <Chip label='Network disabled' size='small' color='error' />
      )}
    </Stack>
  );

  if (loading) {
    return (
      <Stack spacing={1}>
        <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom={0.5}>
          <Skeleton variant='rounded' width='218px' height='21px' />

          <Skeleton variant='rounded' width='145px' height='36px' />
        </Stack>
        <Skeleton variant='rounded' width='100%' height='57px' />
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom={0.5}>
        <Typography fontSize={14} fontWeight={500} color='text.primary'>
          {title}
        </Typography>

        <SingleWalletManageAccounts
          asset={asset}
          isFiat={isFiat}
          createAccountHandler={() => onSelect('-1')}
          fullWidth={false}
          onClose={() => setAddressStateKey(generateKey())}
        />
      </Stack>
      <Select
        labelId='withdraw-funds-address-dropdown'
        id='demo-customized-select'
        sx={{
          width: '100%',
          marginBottom: 2,
        }}
        MenuProps={{
          MenuListProps: {
            sx: { paddingY: 0 },
          },
        }}
        value={value}
        onChange={(e) => onSelect(e.target.value)}
        displayEmpty
        renderValue={(selected) => {
          const selectedId = Number(selected);
          const filteredAddress = addresses && addresses.find((address) => address.id === selectedId);

          if (!selected || !filteredAddress) {
            return (
              <Stack direction='row' spacing={2} alignItems='center'>
                {getIcon('secondary')}
                <Typography fontSize={16} fontWeight={500} color='text.primary'>
                  Select an account
                </Typography>
              </Stack>
            );
          }

          return renderRow(filteredAddress);
        }}
      >
        {addresses?.map((address) => {
          const networkActive = withdrawalService.canNetworkWithdraw(address, asset);
          return (
            <MenuItem
              value={address.id}
              key={address.id}
              disabled={!address.verified || !networkActive}
              sx={{ '&.Mui-disabled': { opacity: 1 } }}
            >
              {renderRow(address, networkActive)}
            </MenuItem>
          );
        })}
        <MenuItem value='-1'>
          <Stack direction='row' spacing={2} height='100%' alignItems='center' paddingY={1}>
            <Avatar size='lg' color='primary'>
              <AddFilled className='text-color-text-inverse' />
            </Avatar>

            <Typography fontSize={16} fontWeight={500} color='primary'>
              Add a new account
            </Typography>
          </Stack>
        </MenuItem>
      </Select>
    </Stack>
  );
};

export { WithdrawAddressDropdown };
