import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Calendar } from '@swyftx/aviary/icons/outlined';

type Props = {
  leadingIcon?: React.ReactElement;
  onClick: () => void;
};

const CalendarPickerTriggerButton: React.FC<Props> = ({ leadingIcon = <Calendar />, onClick }) => (
  <Button layout='icon' leadingIcon={leadingIcon} size='sm' variant='ghost' onClick={onClick} />
);

export { CalendarPickerTriggerButton };
