import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Input, TextFieldProps } from '@swyftx/react-web-design-system';

import NameValidator from '@hooks/validator/types/name.validator';
import { useValidation } from '@hooks/validator/useValidation';

type NameInputFormProps = {
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  setValid?: (valid: boolean) => void;
  success?: boolean;
  type: 'FirstName' | 'MiddleName' | 'LastName' | 'ApiKey' | 'AccountName' | 'AccountHolderName';
  showLabel?: boolean;
  liveValidate?: boolean;
  allowNumbers?: boolean;
} & TextFieldProps;

const NameInput: React.FC<NameInputFormProps> = ({
  id,
  type,
  attachForm,
  setValid,
  onChange,
  value,
  showLabel = true,
  liveValidate = false,
  allowNumbers = true,
  ...props
}) => {
  const { t } = useTranslation('common');
  const inputTypeTranslationKey = `labels.${type}`;

  const validatorProps = useValidation(
    [new NameValidator(t('errors.input.invalid', { label: t(inputTypeTranslationKey as any) }))],
    liveValidate,
    onChange,
    value,
  );

  const onInputValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const containsNumber = (inputValue: string): boolean => /[0-9]/.test(inputValue);

    if (containsNumber(event.target.value) && !allowNumbers) {
      event.preventDefault();
    } else if (onChange) {
      validatorProps.onChange(event);
    }
  };

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  useEffect(() => {
    if (attachForm)
      attachForm(id!, () => {
        const { required } = props;
        // if we aren't required, bypass all validation and just return true
        if (required !== undefined && required === false) return new Promise((resolve) => resolve(true));

        return validatorProps.validate();
      });
  }, [attachForm, id, validatorProps.validate]);

  return (
    <Input
      id={id}
      PII
      label={showLabel && t(inputTypeTranslationKey as any)}
      placeholder={t(inputTypeTranslationKey as any)}
      InputProps={{ sx: { height: '48px', fontSize: '14px' }, onChange: (event) => onInputValueChange(event) }}
      ContainerProps={{
        sx: { marginBottom: validatorProps.error ? { xs: 1, sm: 0 } : { xs: 3, sm: 2 } },
      }}
      sx={{
        width: '100%',
        '.MuiFormHelperText-root': {
          marginTop: '4px',
          lineHeight: '1',
        },
      }}
      type='text'
      {...props}
      {...validatorProps}
    />
  );
};

export { NameInput };
