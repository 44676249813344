import { DarkModeEnum } from '@shared/enums';
import { StorageKey } from '@shared/storage';
import { runWithTransitionsDisabled } from '@shared/utils/lib/stylesheet';

import { action, observable } from 'mobx';

import { ThemeStateType, ThemeStoreSchema } from './@types/themeTypes';

const initialValues: ThemeStateType = {
  theme: 'default',
  darkMode: DarkModeEnum.LIGHT,
  systemTheme: DarkModeEnum.LIGHT,
};

const store: ThemeStoreSchema = observable({
  /* observables */
  ...initialValues,

  setDarkMode: action((darkMode: DarkModeEnum = DarkModeEnum.LIGHT) => {
    runWithTransitionsDisabled(() => {
      // AUTO check to fix backwards compatibility just in case they have auto in their localstorage
      store.darkMode = darkMode !== DarkModeEnum.AUTO ? darkMode : initialValues.darkMode;
      localStorage.setItem(StorageKey.DARK_MODE, store.darkMode);
      const htmlElement = document.documentElement;
      htmlElement.classList.remove('dark', 'light', 'midnight');
      htmlElement.classList.add(darkMode);
    });
  }),

  // This is mainly for web to keep track of the system theme
  setSystemTheme: action((systemTheme: DarkModeEnum = DarkModeEnum.LIGHT) => {
    store.systemTheme = systemTheme || initialValues.systemTheme;
    localStorage.setItem(StorageKey.SYSTEM_THEME, store.systemTheme);
    const htmlElement = document.documentElement;
    htmlElement.classList.remove('dark', 'light');
    document.documentElement.classList.add(systemTheme === DarkModeEnum.DARK ? 'dark' : 'light');
  }),
});

export { store as useThemeStore };
