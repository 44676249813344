import {
  api,
  LoginParams,
  MFAEnrolment,
  MfaParams,
  MFARequired,
  ReCaptchaHeaders,
  SuperheroLoginResponse,
} from '@shared/api';
import env from '@shared/config';
import { ReCaptchaEnum } from '@shared/enums';
import { SwyftxError, isReCaptchaError } from '@shared/error-handler';
import logger from '@shared/logger';
import { UserStore } from '@shared/store';

const LOG_TAG = 'SuperheroService';

const Login = async (
  email: string,
  password: string,
): Promise<SuperheroLoginResponse | MFARequired | MFAEnrolment | undefined> => {
  const { recaptchaToken, setRecaptchaToken, setRefreshRecaptcha } = UserStore.useUserStore;

  const data: LoginParams = {
    login: {
      email,
      password,
    },
  };

  const headers: ReCaptchaHeaders = {
    captcha: env.DISABLE_CAPTCHA ? '' : `${ReCaptchaEnum.CHECK}.${recaptchaToken}`,
  };

  try {
    const res = await api.endpoints.superheroLogin({ data, headers });

    if (res === undefined) {
      return undefined;
    }

    return res.data;
  } catch (e: any) {
    const error = e as SwyftxError;

    // Check if there is a reCAPTCHA error
    if (isReCaptchaError(error)) {
      setRefreshRecaptcha(true);
      setRecaptchaToken('');
    }

    logger.error(LOG_TAG, error.errorMessage);
    throw error;
  }
};

const MFALogin = async (
  code: string,
  token: string,
  challenge_type: string,
  oob_code?: string,
): Promise<SuperheroLoginResponse | undefined> => {
  const data: MfaParams = {
    mfa: {
      token,
      challenge_type,
      otp: challenge_type === 'otp' ? code : undefined,
      binding_code: undefined,
      recovery_code: challenge_type === 'rec' ? code : undefined,
      oob_code,
    },
  };

  try {
    const res = await api.endpoints.superheroMfa({ data });
    if (res === undefined) {
      throw new Error();
    }

    return res.data;
  } catch (e) {
    const err = e as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const SuperheroService = {
  Login,
  MFALogin,
};

export default SuperheroService;
