import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { Asset } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';

import { QuickFilterUsedValueType, TileName } from 'src/context/Avo/generated-avo';

const useAutoInvestAnalytics = () => {
  const { pathname: screen } = useLocation();
  const avo = useAvo();
  const isXs = useTailwindBreakpoint('xs');

  const layout = useMemo(() => (isXs ? 'mobile' : 'desktop'), [isXs]);

  const gettingStartedTileOpened = useCallback(() => {
    avo.gettingStartedTileOpened({ screen, tileName: TileName.AUTO_INVEST });
  }, [avo, screen]);

  const createAutoInvestClicked = useCallback(
    (source: string) => {
      avo.recurringOrderOpened({ source, screen, layout });
    },
    [avo, layout, screen],
  );

  const autoInvestAssetClicked = useCallback(
    (
      asset: Asset,
      searchValue: string,
      quickFilterUsed: QuickFilterUsedValueType,
      modalName?: string,
      featureName?: string,
    ) => {
      avo.assetSelected({
        assetCode: asset.code,
        screen,
        searchValue,
        quickFilterUsed,
        modalName,
        featureName,
      });
    },
    [avo, screen],
  );

  const autoInvestQuickAmountSelected = useCallback(
    (amount: number, presetAmount?: string) => {
      avo.recurringOrderCreationAmountSelected({
        screen,
        presetAmount,
        amount,
      });
    },
    [avo, screen],
  );

  const followUpActionPromptClicked = useCallback(
    (buttonName: string, modalName?: string, featureName?: string) => {
      avo.followUpActionPromptClicked({
        screen,
        buttonName,
        modalName,
        featureName,
      });
    },
    [avo, screen],
  );

  const learnArticleOpened = useCallback(
    (articleTitle: string, articleLink: string) => {
      avo.learnArticleOpened({
        screen,
        articleLink,
        articleTitle,
        layout,
      });
    },
    [avo, layout, screen],
  );

  const autoInvestFlowExited = useCallback(
    (stepId: string, modalName?: string, featureName?: string) => {
      avo.uxFlowExited({
        screen,
        stepId,
        modalName,
        featureName,
      });
    },
    [avo, screen],
  );

  return {
    gettingStartedTileOpened,
    createAutoInvestClicked,
    autoInvestAssetClicked,
    autoInvestQuickAmountSelected,
    followUpActionPromptClicked,
    learnArticleOpened,
    autoInvestFlowExited,
  };
};

export { useAutoInvestAnalytics };
