import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UserStore } from '@shared/store';

import { useAvo } from '@hooks/Avo/useAvo';

import { TableNameValueType, TableSortDirectionValueType } from 'src/context/Avo/generated-avo';

const useTradePageAnalytics = () => {
  const { pathname } = useLocation();
  const avo = useAvo();
  const { balances } = UserStore.useUserStore;

  const fetchAssetParams = useCallback(
    (asset: Asset) => {
      const balance = balances[asset.id];
      const availableBalance = balance ? balance.availableBalance : '0';
      const assetType = asset.assetType === AssetType.Crypto ? 'CRYPTO' : 'FIAT';

      return {
        assetType: assetType as 'FIAT' | 'CRYPTO',
        ownsAsset: Big(availableBalance).gt(0),
        assetBalance: availableBalance,
      };
    },
    [balances],
  );

  const changedAssetViewed = useCallback(
    (props: { asset: Asset }) => {
      const { asset } = props;
      const { ownsAsset, assetBalance, assetType } = fetchAssetParams(asset);
      avo.changedAssetViewed({
        screen: pathname,
        assetId: asset.id,
        assetCode: asset.code,
        assetType,
        ownsAsset,
        assetBalance,
      });
    },
    [avo, fetchAssetParams, pathname],
  );

  const changedFilter = useCallback(
    (props: { tableName: TableNameValueType; filterName: string; filterEnabled: boolean }) => {
      const { tableName, filterName, filterEnabled } = props;
      avo.changedTableFilter({
        screen: pathname,
        tableName,
        filterName,
        filterEnabled,
      });
    },
    [avo, pathname],
  );

  const changedTableSort = useCallback(
    (props: {
      tableName: TableNameValueType;
      tableHeadingSortLabel: string;
      tableSortDirection: TableSortDirectionValueType;
    }) => {
      const { tableSortDirection, tableName, tableHeadingSortLabel } = props;
      avo.changedTableSort({
        screen: pathname,
        tableName,
        tableHeadingSortLabel,
        tableSortDirection,
      });
    },
    [avo, pathname],
  );

  const clickedButton = useCallback(
    (props: { buttonName: string; buttonState: string; parentComponent?: string; tableName?: TableNameValueType }) => {
      const { buttonName, buttonState, parentComponent, tableName } = props;
      avo.clickedButton({
        screen: pathname,
        buttonName,
        buttonState,
        parentComponent,
        tableName,
      });
    },
    [avo, pathname],
  );

  const clickedLearnMoreOnAssetInfoModal = useCallback(
    (props: { asset: Asset }) => {
      const { asset } = props;
      const { ownsAsset, assetBalance } = fetchAssetParams(asset);
      avo.clickedLearnMoreOnAssetInfoModal({
        screen: pathname,
        assetId: asset.id,
        assetName: asset.name,
        assetCode: asset.code,
        ownsAsset,
        assetBalance,
      });
    },
    [avo, fetchAssetParams, pathname],
  );

  const clickedOnTableRow = useCallback(
    (props: {
      tableName: TableNameValueType;
      tableHeadingSortLabel: string;
      tableSortDirection: TableSortDirectionValueType;
    }) => {
      const { tableName, tableHeadingSortLabel, tableSortDirection } = props;
      avo.clickedOnTableRow({
        screen: pathname,
        tableName,
        tableHeadingSortLabel,
        tableSortDirection,
      });
    },
    [avo, pathname],
  );

  const openedAssetListDropdown = useCallback(
    (asset: Asset) => {
      const { ownsAsset, assetBalance } = fetchAssetParams(asset);
      avo.openedAssetListDropdown({
        assetId: asset.id,
        assetCode: asset.code,
        assetName: asset.name,
        ownsAsset,
        assetBalance,
        screen: pathname,
      });
    },
    [avo, fetchAssetParams, pathname],
  );

  const openedAssetInfoModal = useCallback(
    (props: { asset: Asset }) => {
      const { asset } = props;
      const { ownsAsset, assetBalance } = fetchAssetParams(asset);
      avo.openedAssetInfoModal({
        screen: pathname,
        assetId: asset.id,
        assetCode: asset.code,
        ownsAsset,
        assetBalance,
      });
    },
    [avo, fetchAssetParams, pathname],
  );

  const searchedInAssetDropdown = useCallback(() => {
    avo.searchedInAssetDropdown({
      screen: pathname,
    });
  }, [avo, pathname]);

  const viewedUniversalTradePage = useCallback(() => {
    avo.viewedUniversalTradePage({
      screen: pathname,
      customDestinationPageName_: 'Universal Trade',
    });
  }, [avo, pathname]);

  return {
    changedAssetViewed,
    changedFilter,
    changedTableSort,
    clickedButton,
    clickedLearnMoreOnAssetInfoModal,
    clickedOnTableRow,
    openedAssetInfoModal,
    openedAssetListDropdown,
    searchedInAssetDropdown,
    viewedUniversalTradePage,
  };
};

export { useTradePageAnalytics };
