import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Filter } from '@swyftx/aviary/icons/outlined';
import { Button, Card, Grid } from '@swyftx/react-web-design-system';

import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';
import { useTriggerOrderAnnouncement } from '@global-components/Modals/EditTriggerOrdersAnnouncementModal';

import { AppStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { observer } from 'mobx-react-lite';
import { SizeMe } from 'react-sizeme';

import { OrdersTable, TransactionsFilters, TransactionsTabs } from './components';
import { ORDERS_TABLE_TAB_HEIGHT } from './components/OrdersTable/OrdersTable.settings';
import { routeToTypeMap, TransactionsRouteType } from './types/Transactions.types';

type OrderParams = {
  type: TransactionsRouteType;
};

const Transactions: React.FC = observer(() => {
  const { isDemo } = AppStore.useAppStore;

  // Users should be redirected to the orders page anyway, but default it here for type safety
  const type = useParams<OrderParams>()?.type ?? TransactionsRouteType.Orders;

  const { fetchOrders, updateFilters, setShowFilters, showFilters } = useContext(TransactionsContext);

  const { bx, isMobile } = useContentBreakpoint();

  useTriggerOrderAnnouncement();

  useEffect(() => {
    fetchOrders(undefined, true);
  }, []);

  useEffect(() => {
    updateFilters({ types: routeToTypeMap[type] });
  }, [type, updateFilters, isDemo]);

  return (
    <div className='h-full p-16 @container sm:p-24 sm:pr-0'>
      <SizeMe monitorHeight>
        {({ size }) => (
          <Card
            id='transactions.card'
            variant='outlined'
            sx={{
              height: bx({ xs: 'calc(100% - .5rem)', md: '100%' }),
            }}
            contentSx={{ padding: '0 !important', paddingBottom: '0 !important', height: '100%' }}
          >
            <Grid container direction='column' justifyContent='flex-start' height='100%' flexWrap='nowrap'>
              <Grid container direction='row' alignItems='center' className='w-full'>
                <LayoutGridItem xs={11} sm={12} padding={1} height={ORDERS_TABLE_TAB_HEIGHT} className='w-full'>
                  <TransactionsTabs id='orders.tabs' type={type} />
                </LayoutGridItem>
                {isMobile && (
                  <LayoutGridItem
                    id='transactionsFilters.toggle.container'
                    alignItems='center'
                    textAlign='right'
                    paddingRight={2}
                    width='60px'
                    xs={1}
                  >
                    <Button
                      id='transactionsFilters.toggle.buttons.toggleFilter'
                      sx={{ color: showFilters ? 'primary' : 'text.secondary' }}
                      startIcon={<Filter className='h-30 w-30' />}
                      onClick={() => setShowFilters(!showFilters)}
                      variant='text'
                    />
                  </LayoutGridItem>
                )}
              </Grid>
              {(!isMobile || showFilters) && <TransactionsFilters type={type} />}
              <OrdersTable width={size.width || 0} height={size.height || 0} showFilters={showFilters} type={type} />
            </Grid>
          </Card>
        )}
      </SizeMe>
    </div>
  );
});

Transactions.displayName = 'Transactions';

export default Transactions;
