import React from 'react';

import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { IconButton } from '@swyftx/react-web-design-system';

import { UniversalTradeStore } from '@shared/store';
import { TradeState } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { observer } from 'mobx-react-lite';

const ICON_SIZE = '20px';

type Props = {
  color?: string;
};

export const CloseUniversalTrade: React.FC<Props> = observer(({ color }) => {
  const { setShowGlobalTrade, setTradeState } = UniversalTradeStore;

  const closeUniversalTrade = () => {
    setShowGlobalTrade(false);
    setTradeState(TradeState.PlaceOrder);
  };

  return (
    <IconButton
      sx={{
        width: '32px',
        height: '32px',
        color: color || 'text.primary',
        position: 'absolute',
        right: 8,
      }}
      size='small'
      onClick={closeUniversalTrade}
    >
      {/* Need to fix design system  */}
      <KeyboardDoubleArrowLeft sx={{ transform: 'rotate(180deg)', width: ICON_SIZE, height: ICON_SIZE }} />
    </IconButton>
  );
});
