import { useMemo } from 'react';

import { TransferEnum } from '@global-components/Modals/TransferModal/TransferModal.types';

import { assetService } from '@shared/services';

import { useOwnedAssets } from '@hooks/Assets/useOwnedAssets';
import { useSort } from '@hooks/useSort';
import { AssetSort } from '@hooks/useSort/useSort';

import { AssetFilter, useAssets } from './useAssets';
import { canSendWithdraw, fetchAssets } from './useAssets.utils';

/**
 * Returns assets that can be deposited or withdrawn depending on transferType
 *
 * @param transferType (deposit or withdraw) will filter assets based on this selection
 * @returns assets[] (can be adapted on to return more if needed)
 */
export const useTransferAssets = (transferType: TransferEnum) => {
  const { sortAssets } = useSort();
  const ownedAssets = useOwnedAssets();
  const ownedAssetIds = ownedAssets.map((a) => a.id);
  const allAssets = useAssets({ filter: AssetFilter.All });
  const fiatAssetIds = allAssets.filter((a) => assetService.isAssetFiat(a.id)).map((a) => a.id);
  const cryptoAssetIds = allAssets.filter((a) => !assetService.isAssetFiat(a.id)).map((a) => a.id);

  const sortedAssets = useMemo(() => {
    const ownedFiatAssets = sortAssets(
      fiatAssetIds.filter((a) => ownedAssetIds.includes(a)),
      true,
      AssetSort.Name,
    );

    const ownedCryptoAssets = sortAssets(
      cryptoAssetIds.filter((a) => ownedAssetIds.includes(a)),
      false,
      AssetSort.Balance,
    );

    const notOwnedFiatAssets = sortAssets(
      fiatAssetIds.filter((a) => !ownedFiatAssets.includes(a)),
      true,
      AssetSort.Name,
    );

    const notOwnedCryptoAssets = sortAssets(
      cryptoAssetIds.filter((a) => !ownedCryptoAssets.includes(a)),
      true,
      AssetSort.Rank,
    );

    const mergedAssets = ownedFiatAssets
      .concat(ownedCryptoAssets)
      .concat(notOwnedFiatAssets)
      .concat(notOwnedCryptoAssets);

    return fetchAssets(mergedAssets);
  }, [fiatAssetIds, cryptoAssetIds, ownedAssetIds]);

  const assets = useMemo(
    () =>
      transferType === TransferEnum.DepositReceive
        ? sortedAssets.filter((a) => a.deposit_enabled)
        : sortedAssets.filter((a) => canSendWithdraw(a)),
    [sortedAssets, transferType],
  );

  return { assets };
};
