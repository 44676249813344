import { NavigationURLs } from 'src/lib/navigation/types';

const FULL_PAGE_ROUTES = [
  NavigationURLs.Auth,
  NavigationURLs.Login,
  NavigationURLs.Maintenance,
  NavigationURLs.Message,
  NavigationURLs.Onboarding,
  NavigationURLs.EntityOnboarding,
  NavigationURLs.PasswordReset,
  NavigationURLs.Register,
  NavigationURLs.SourceOfWealth,
];

const NO_GLOBAL_ACTIONS_ROUTES = [
  NavigationURLs.Auth,
  NavigationURLs.Login,
  NavigationURLs.Maintenance,
  NavigationURLs.Message,
  NavigationURLs.Onboarding,
  NavigationURLs.EntityOnboarding,
  NavigationURLs.PasswordReset,
  NavigationURLs.Register,
  NavigationURLs.SourceOfWealth,
  NavigationURLs.QuickBuy,
  NavigationURLs.AutoInvest,
  NavigationURLs.AutoInvestCreate,
  NavigationURLs.SwyftxPro,
];

const HIDE_MARKET_TICKER = FULL_PAGE_ROUTES;

const HIDE_RESTRICTED_MODAL = [...FULL_PAGE_ROUTES, NavigationURLs.ProfileVerification];

export { NO_GLOBAL_ACTIONS_ROUTES, HIDE_MARKET_TICKER, HIDE_RESTRICTED_MODAL, FULL_PAGE_ROUTES };
