import React from 'react';

import { Box } from '@mui/material';

import { Tooltip, Typography } from '@swyftx/react-web-design-system';

import { assetService } from '@shared/services';

type Props = {
  assetList: string[];
};

// icon used to display additional number of assets when we have a fixed length of asset icons we want to show
const AssetPlusMoreIcon: React.FC<Props> = ({ assetList }) => (
  <Tooltip
    placement='bottom-end'
    title={assetList.map((asset) => {
      const assetDetails = assetService?.getAssetByCode(asset);
      return (
        <Typography
          variant='subtitle2'
          key={asset}
          sx={{
            color: '#fff',
          }}
        >
          {assetDetails?.name ?? asset}
        </Typography>
      );
    })}
  >
    <Box
      width={20}
      height={20}
      alignItems='center'
      sx={{
        overflow: 'hidden',
        borderRadius: '100%',
        display: 'flex',
        background: '#0072ed14',
        margin: 0.5,
        '&:hover': {
          cursor: 'default',
        },
      }}
    >
      <Typography variant='body2' color='#0072ed' fontWeight={500} fontSize={assetList.length > 9 ? 11 : 14}>
        +{assetList.length}
      </Typography>
    </Box>
  </Tooltip>
);

export { AssetPlusMoreIcon };
export default AssetPlusMoreIcon;
