import { useCallback, useContext } from 'react';

import { Themes } from '@swyftx/react-web-design-system';

import { DarkModeEnum } from '@shared/enums';
import storage, { StorageKey } from '@shared/storage';
import { AppStore, ThemeStore, UIStore, UniversalTradeStore, UserStore } from '@shared/store';
import { isNullish } from '@shared/utils';

import { WalletContext } from '@Wallet/Wallet.context';

export const useDataPersistence = (): Record<string, any> => {
  const { setHideZeroBalances } = useContext(WalletContext);
  const themeStore = ThemeStore.useThemeStore;
  const appStore = AppStore.useAppStore;
  const userStore = UserStore.useUserStore;
  const uiStore = UIStore.useUIStore;
  const { setShowGlobalTrade } = UniversalTradeStore;

  const dataStorageEffect = useCallback(async () => {
    const data = await storage.multiGet([
      StorageKey.LANGUAGE,
      StorageKey.RECEIVE_LANDING_READ,
      StorageKey.WITHDRAWAL_LANDING_READ,
      StorageKey.SEND_LANDING_READ,
      StorageKey.SEND_SCAM_LANDING_READ,
      StorageKey.DEPOSIT_LANDING_READ,
      StorageKey.PRICE_ALERT_NOTICE_READ,
      StorageKey.CHARTS,
      StorageKey.SYSTEM_THEME,
      StorageKey.CONTENT_CARDS,
      StorageKey.SHOW_MARKET_TICKER,
      StorageKey.HIDE_ZERO_BALANCES,
      StorageKey.FETCH_ASSETS_FROM_ASSET_SERVICE,
    ]);
    if (data) {
      let darkMode: DarkModeEnum | null = localStorage.getItem(StorageKey.DARK_MODE) as DarkModeEnum;

      if (darkMode != null) {
        // ensure the theme exists or this will result in a fatal error
        if (Themes[darkMode] == null) {
          darkMode = DarkModeEnum.LIGHT;
        }

        themeStore.setDarkMode(darkMode);
      }

      const showUniversalTrade: string | null = localStorage.getItem(StorageKey.SHOW_UNIVERSAL_TRADE_PANEL);
      if (showUniversalTrade != null) {
        setShowGlobalTrade(showUniversalTrade === 'true');
      }

      if (!isNullish(data[StorageKey.LANGUAGE])) {
        appStore.setLanguage(data[StorageKey.LANGUAGE]);
      }

      if (!isNullish(data[StorageKey.RECEIVE_LANDING_READ])) {
        appStore.setReceiveLandingRead(data[StorageKey.RECEIVE_LANDING_READ]);
      }

      if (!isNullish(data[StorageKey.WITHDRAWAL_LANDING_READ])) {
        appStore.setWithdrawalLandingRead(data[StorageKey.WITHDRAWAL_LANDING_READ]);
      }

      if (!isNullish(data[StorageKey.SEND_LANDING_READ])) {
        appStore.setSendLandingRead(data[StorageKey.SEND_LANDING_READ]);
      }

      if (!isNullish(data[StorageKey.SEND_SCAM_LANDING_READ])) {
        appStore.setSendScamLandingRead(data[StorageKey.SEND_SCAM_LANDING_READ]);
      }

      if (!isNullish(data[StorageKey.DEPOSIT_LANDING_READ])) {
        appStore.setDepositLandingRead(data[StorageKey.DEPOSIT_LANDING_READ]);
      }

      if (!isNullish(data[StorageKey.MULTI_TRADE_LIMITATION_READ])) {
        appStore.setMultiTradeLimitationRead(data[StorageKey.MULTI_TRADE_LIMITATION_READ]);
      }

      if (!isNullish(data[StorageKey.PRICE_ALERT_NOTICE_READ])) {
        appStore.setPriceAlertNoticeRead(data[StorageKey.PRICE_ALERT_NOTICE_READ]);
      }

      if (!isNullish(data[StorageKey.CHARTS])) {
        userStore.setChartPreferences(data[StorageKey.CHARTS]);
      }

      if (!isNullish(data[StorageKey.HIDE_ZERO_BALANCES])) {
        setHideZeroBalances(data[StorageKey.HIDE_ZERO_BALANCES]);
      }

      if (!isNullish(data[StorageKey.SHOW_MARKET_TICKER])) {
        uiStore.setShowMarketTicker(data[StorageKey.SHOW_MARKET_TICKER]);
      }
    }
  }, [setHideZeroBalances, setShowGlobalTrade]);

  return {
    dataStorageEffect,
  };
};
