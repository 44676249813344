import {
  AssetServiceAsset,
  AssetServiceAssetType,
  CoinGeckoPriceChange,
  CoinGeckoSupply,
  CoinGeckoUrls,
  CryptoNetwork,
  EnabledStatuses,
} from '@shared/api/@types/assetService';
import { Asset, AssetPriceChange, AssetType, AssetVolume, Network, Urls } from '@shared/api/@types/markets';

import { AssetColors } from '@utils/assets';

const na = 'N/A';
const infinity = '∞';

const transformAssetType = (assetType: AssetServiceAssetType): AssetType =>
  assetType === AssetServiceAssetType.Fiat ? AssetType.Fiat : AssetType.Crypto;

const transformUrls = (urls?: CoinGeckoUrls): Urls => ({
  explorer: urls?.explorer ?? '',
  reddit: urls?.reddit ?? '',
  twitter: urls?.twitter ?? '',
  website: urls?.website ?? '',
});

const transformPriceChange = (priceChange?: CoinGeckoPriceChange): AssetPriceChange => ({
  week: priceChange?.week ?? 0,
  month: priceChange?.month ?? 0,
});

const sanitizeSupply = (supply?: CoinGeckoSupply) => {
  const circulatingSupply = supply?.circulating ?? na;
  const totalSupply = supply?.total ?? circulatingSupply;
  const maxSupply = (supply ? supply?.max : na) ?? infinity;

  return {
    circulatingSupply,
    totalSupply,
    maxSupply,
  };
};

// TODO move this to asset service V2 rather than being hardcoded. This was pulled from the Swyftx Monorepo
const transformCustomWarningText = (asset: AssetServiceAsset): string | undefined => {
  switch (asset.code) {
    case 'LTC':
      return '<b>Minimum deposit amount: 0.001 LTC.</b> Any deposits less than or equal to the minimum amount will not be credited or refunded.';
    case 'NANO':
      return '<b>Minimum deposit amount: 0.01 NANO.</b> Any deposits less than or equal to the minimum amount will not be credited or refunded.';
    case 'DOT':
      return '<b>Minimum deposit amount: 1.2 DOT.</b> Any deposits less than this amount will not be credited or refunded.';
    case 'XNO':
      return '<b>Minimum deposit amount: 0.001 XNO.</b> Any deposits less than or equal to the minimum amount will not be credited or refunded.';
    case 'IOTX':
      return '<b>Minimum deposit amount: 0.1 IOTX.</b> Any deposits less than or equal to the minimum amount will not be credited or refunded.';
    default:
      return undefined;
  }
};

const transformNetworks = (networks?: CryptoNetwork[]): Network[] => {
  if (!networks) return [];

  return networks.map((network) => {
    let withdrawDisabled: boolean;
    let withdrawDisableForce: boolean;
    let depositDisabled: boolean;
    let depositDisableForce: boolean;

    withdrawDisabled = network.withdrawStatus !== EnabledStatuses.Enabled;
    withdrawDisableForce = network.withdrawStatus === EnabledStatuses.PermanentlyDisabled;
    depositDisabled = network.depositStatus !== EnabledStatuses.Enabled;
    depositDisableForce = network.depositStatus === EnabledStatuses.PermanentlyDisabled;

    return {
      id: network.id,
      networkName: network.network,
      networkNameFull: network.networkName,
      memo: network.memoEnabled,
      destinationTag: Number(network.destinationTagEnabled),
      message: network.messageEnabled,
      paymentId: network.paymentIdEnabled,
      addressFormats: network.addressFormats ?? '',
      withdrawFee: Number(network.withdrawFee),
      withdrawMin: Number(network.withdrawMin),
      minWithdrawalIncrementE: network.minWithdrawalIncrementE,
      metadataTag: 'memo',
      withdrawDisabled,
      withdrawDisableForce,
      depositDisabled,
      depositDisableForce,
    };
  });
};

export const mapAssetServiceAssetToLegacyAsset = (asset: AssetServiceAsset, volume: AssetVolume): Asset => {
  const { circulatingSupply, totalSupply, maxSupply } = sanitizeSupply(asset.metadata?.data.supply);

  const legacyAsset: Asset = {
    id: asset.id,
    code: asset.code,
    name: asset.name,
    // eslint-disable-next-line no-restricted-syntax
    color: asset.colour || AssetColors[asset.code],
    assetType: transformAssetType(asset.type),
    deposit_enabled: Number(asset.depositEnabled),
    withdraw_enabled: Number(asset.withdrawEnabled),
    tradable: Number(asset.tradable),
    price_scale: asset.priceScale,
    isBaseAsset: Number(asset.baseAsset),
    delisting: Number(asset.delisting),
    buyDisabled: Number(asset.buyDisabled),
    sellEnabled: Number(true),
    min_deposit: Number(asset.minDeposit),
    min_withdrawal: Number(asset.minWithdrawal),
    minimum_order_increment: asset.minimumOrderIncrement,
    minWithdrawalIncrementE: asset.minWithdrawalIncrementE,
    active: true,
    category: asset.metadata?.data.category ?? '',
    categories: asset.metadata?.data.categories ?? [],
    categoryIds: Array.from(new Set(asset.categoryIds)),
    rank: asset.metadata?.data.rank ?? Infinity,
    displayRank: asset.metadata?.data.rank?.toString() ?? na,
    rankSuffix: asset.metadata?.data.rankSuffix,
    totalSupply,
    urls: transformUrls(asset.metadata?.data.urls),
    volume,
    maxSupply,
    circulatingSupply,
    priceChange: transformPriceChange(asset.metadata?.data.priceChange),
    description: asset.description,
    primary: asset.primary,
    secondary: asset.secondary,
    minimum_order: Number(asset.minimumOrder),
    subAssetOf: undefined,
    contract: asset.contract || '',
    assetDepositScale: asset.assetDepositScale,
    hasDetail: true,
    spread: undefined,
    customWarningText: transformCustomWarningText(asset),
    liquidityFlag: undefined,
    mineable: true,
    networks: transformNetworks(asset.cryptoNetworks),
  };

  return legacyAsset;
};
