import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, Input, Notification, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset, Network } from '@shared/api';
import { CryptoEnum, NetworkEnum } from '@shared/enums';
import { walletAddressService } from '@shared/services';
import { UIStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

import { useDebounce } from 'react-use';

import { useCryptoAddressDetails } from '../hooks/useCryptoAddressDetails';
import { useInvalidAddressMessage } from '../hooks/useInvalidAddressMessage';

export type AddCryptoAddressFormData = {
  label: string;
  address: string;
  selectedCryptoNetworkId: number;
  metaDataPayload?: Record<string, string>;
};

type Props = {
  setCanContinue?: (checked: boolean) => void;
  canContinue?: boolean;
  asset: Asset;
  setFormData: (formData: AddCryptoAddressFormData) => void;
};

const labelTypographyProps = {
  fontSize: pxToRem(14),
  fontWeight: 500,
};

const Required = () => (
  <Typography color='error.main' {...labelTypographyProps} display='inline'>
    *
  </Typography>
);

const VALIDATION_DEBOUNCE = 300;

// Possible decouple/abstract to global scope as its used in global transfer
export const SendCryptoAddAddress: React.FC<Props> = ({ setFormData, setCanContinue, asset }) => {
  const [selectedNetwork, setSelectedNetwork] = useState<Network>();
  const [nickName, setNickName] = useState('');
  const [address, setAddress] = useState('');
  const [memo, setMemo] = useState('');
  const [debouncedMemo, setDebouncedMemo] = useState('');
  const [validMemo, setValidMemo] = useState<boolean>();
  const [debouncedAddress, setDebouncedAddress] = useState<string>('');
  const { errorMessage, notification } = useInvalidAddressMessage(address, asset);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const { t } = useTranslation('wallet');

  const { addToastMessage } = UIStore.useUIStore;

  useDebounce(
    () => {
      if (address.length) {
        setDebouncedAddress(address);
      }
    },
    VALIDATION_DEBOUNCE,
    [address],
  );

  useDebounce(
    () => {
      if (memo.length) {
        setDebouncedMemo(memo);
      }
    },
    VALIDATION_DEBOUNCE,
    [memo],
  );

  const updateAddressFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      setAddress(text.trim());
      addToastMessage({ message: t('withdrawCrypto.addAddress.updateDateClipboardToast.address'), severity: 'info' });
    });
  };

  const updateMemoFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      setMemo(text.trim());
      addToastMessage({ message: t('withdrawCrypto.addAddress.updateDateClipboardToast.memo'), severity: 'info' });
    });
  };

  const getNetworkName = (network: Network) => {
    switch (network.id) {
      case NetworkEnum.BTC:
      case NetworkEnum.TRX:
      case NetworkEnum.ETH:
      case NetworkEnum.NEO:
      case NetworkEnum.BNB:
        return `${network.networkName} (${network.networkNameFull})`; // Show both network code and name
      case NetworkEnum.BSC:
        return 'BSC (BEP20)'; // BSC is a unique case where the Full Network Name doesnt work when formatted the same way as the other networks
      default:
        return network.networkName; // Show only network code
    }
  };

  const { cryptoAddressMetadata, cryptoNetworks, validCryptoAddress } = useCryptoAddressDetails(
    asset,
    debouncedAddress,
  );

  const filteredCryptoNetworks = useMemo(() => cryptoNetworks.filter((c) => !c.withdrawDisableForce), [cryptoNetworks]);

  const singleNetworkFound = filteredCryptoNetworks?.length === 1;
  const multipleNetworksFound = filteredCryptoNetworks?.length > 1;
  const disabledSelectedNetwork =
    selectedNetwork && (selectedNetwork.withdrawDisabled || selectedNetwork.withdrawDisableForce);
  const showEthWarning =
    !selectedNetwork?.withdrawDisabled &&
    !selectedNetwork?.withdrawDisableForce &&
    asset.id === CryptoEnum.BTC &&
    selectedNetwork?.id === NetworkEnum.ETH;
  const isMemoRequired = cryptoAddressMetadata?.required;

  useEffect(() => {
    if (debouncedMemo?.length) {
      setValidMemo(walletAddressService.isValidCryptoMemo(debouncedMemo, selectedNetwork?.networkName));
    }
  }, [debouncedMemo, selectedNetwork]);

  useEffect(() => {
    if (
      (isMemoRequired ? memo.length && validMemo : true) &&
      validCryptoAddress &&
      nickName.length &&
      selectedNetwork &&
      address.length
    ) {
      setFormData({
        address,
        label: nickName,
        selectedCryptoNetworkId: selectedNetwork.id,
        metaDataPayload: cryptoAddressMetadata ? { [cryptoAddressMetadata.key]: memo } : undefined,
      });
      if (setCanContinue && disabledSelectedNetwork) {
        setCanContinue(false);
      } else if (setCanContinue) {
        setCanContinue(true);
      }
    }
  }, [nickName, address, selectedNetwork, isMemoRequired, validCryptoAddress, validMemo, cryptoAddressMetadata, memo]);

  useEffect(() => {
    // Do not set default crypto network when multiple networks are available
    setSelectedNetwork(singleNetworkFound ? filteredCryptoNetworks[0] : undefined);
  }, [filteredCryptoNetworks, singleNetworkFound]);

  const memoTitle = useMemo(() => {
    if (selectedNetwork?.destinationTag) return 'Destination Tag';

    return 'Memo';
  }, [selectedNetwork]);

  return (
    <Stack spacing={2}>
      <Typography color='text.secondary' fontSize={pxToRem(16)}>
        {t('withdrawCrypto.addAddress.memoNote')}
      </Typography>
      {notification}
      <Stack spacing={1}>
        <Typography {...labelTypographyProps}>
          {t('withdrawCrypto.addAddress.addressNickname')}
          <Required />
        </Typography>
        <Input
          PII
          onChange={(e) => setNickName(e.target.value)}
          placeholder={t('withdrawCrypto.addAddress.addressNicknamePlaceholder')}
          value={nickName}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography {...labelTypographyProps}>
          {validCryptoAddress === false
            ? t('withdrawCrypto.addAddress.cryptoAddressStatus.invalid')
            : t('withdrawCrypto.addAddress.cryptoAddressStatus.valid')}
          <Required />
        </Typography>
        <Input
          PII
          onChange={(e) => setAddress(e.target.value.trim())}
          onBlur={() => setAddress(address.trim())}
          error={validCryptoAddress === false}
          placeholder='Enter address'
          value={address}
        />
        {!validCryptoAddress && errorMessage && (
          <Typography fontSize={12} fontWeight={500} color='error'>
            {errorMessage}
          </Typography>
        )}
      </Stack>
      {hasClipboardPermission === 'granted' && (
        <Button variant='text' onClick={updateAddressFromClipboard}>
          <Stack direction='row' spacing={1}>
            <Paste className='h-20 w-20 text-color-text-primary' />
            <Typography {...labelTypographyProps} color='primary'>
              {t('withdrawCrypto.paste')}
            </Typography>
          </Stack>
        </Button>
      )}
      {singleNetworkFound && (
        <Notification
          title={t('withdrawCrypto.addAddress.singleNetworkFound.title', {
            networkName: filteredCryptoNetworks[0].networkName,
            networkNameFull: filteredCryptoNetworks[0].networkNameFull,
          })}
          severity='info'
          variant='standard'
        >
          {t('withdrawCrypto.addAddress.singleNetworkFound.notification')}
        </Notification>
      )}
      {showEthWarning && (
        <Notification severity='warning' title={t('withdrawCrypto.addAddress.showEthWarning.title')}>
          {t('withdrawCrypto.addAddress.showEthWarning.notification')}
        </Notification>
      )}
      {filteredCryptoNetworks.length > 0 && asset.code === 'KDA' && (
        <Notification
          severity='warning'
          title='Kadena (KDA) has limited functionality'
          alertTitleProps={{ color: 'warning.main' }}
          sx={{ backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'grey.50' : 'grey.900') }}
        >
          You must use Chain 2 when withdrawing KDA from Swyftx.
        </Notification>
      )}
      {multipleNetworksFound && (
        <Stack spacing={2}>
          <Notification
            title={t('withdrawCrypto.addAddress.multipleNetworksFound.title')}
            severity='info'
            variant='standard'
          >
            {t('withdrawCrypto.addAddress.multipleNetworksFound.notification')}
          </Notification>
          <Stack spacing={1}>
            <Typography {...labelTypographyProps}>
              {t('withdrawCrypto.addAddress.multipleNetworksFound.selectNetworkTitle')}
              <Required />
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              {filteredCryptoNetworks.map((network) => {
                const selected = selectedNetwork?.id === network.id;
                return (
                  <Button
                    id={network.id.toString()}
                    key={network.id.toString()}
                    variant='text'
                    size='large'
                    sx={{
                      '&.MuiLoadingButton-root': {
                        color: selected ? 'text.primary' : 'text.secondary',
                        backgroundColor: selected ? 'action.hover' : '',
                        fontWeight: selected ? '500' : '400',
                      },
                    }}
                    onClick={() => setSelectedNetwork(network)}
                  >
                    {getNetworkName(network)}
                  </Button>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      )}
      {selectedNetwork && disabledSelectedNetwork && (
        <Notification
          title={t('withdrawCrypto.addAddress.disabledSelectedNetwork.title', {
            networkName: selectedNetwork.networkName,
            networkNameFull: selectedNetwork.networkNameFull,
            assetName: asset.code,
          })}
          variant='standard'
          severity='error'
        >
          {t('withdrawCrypto.addAddress.disabledSelectedNetwork.notification')}
        </Notification>
      )}
      {cryptoAddressMetadata && (
        <>
          <Stack spacing={1}>
            <Typography {...labelTypographyProps}>
              {validMemo === false
                ? t('withdrawCrypto.addAddress.cryptoAddressMetadata.memoValidation.invalid', { memoTitle })
                : t('withdrawCrypto.addAddress.cryptoAddressMetadata.memoValidation.valid', { memoTitle })}
              {isMemoRequired && <Required />}
            </Typography>
            <Input
              value={memo}
              onChange={(e) => setMemo(e.target.value.trim())}
              onBlur={() => setMemo(memo.trim())}
              placeholder={`Enter ${memoTitle.toLowerCase()}`}
            />
          </Stack>
          {hasClipboardPermission === 'granted' && (
            <Button variant='text' onClick={updateMemoFromClipboard}>
              <Stack direction='row' spacing={1}>
                <Paste className='h-20 w-20 text-color-text-primary' />
                <Typography {...labelTypographyProps} color='primary'>
                  {t('withdrawCrypto.paste')}
                </Typography>
              </Stack>
            </Button>
          )}
        </>
      )}
    </Stack>
  );
};
