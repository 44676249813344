import React, { useCallback, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

const IMG_SIZE = '64px';

export const EntityAccountDepositFundsBanner: React.FC = () => {
  const { theme } = useTailwindTheme();
  const { entityColorIndex } = UserStore.useUserStore;
  const { openModal } = useModal();
  const countryAsset = useCountryAsset();

  const [open, setOpen] = useState<boolean>(true);

  const handleDismiss = useCallback(() => {
    localStorage.setItem(StorageKey.DISMISS_ENTITY_DEPOSIT_FUNDS_BANNER, 'true');
    setOpen(false);
  }, []);

  const onOpenDepositModal = useCallback(() => {
    openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
  }, [countryAsset, openModal]);

  if (!open) return null;

  return (
    <FlexLayout className='w-full'>
      <Card className='w-full p-24'>
        <FlexLayout direction='row' className='items-center justify-between'>
          <FlexLayout alignItems='center' justifyContent='space-between' spacing={16}>
            <img
              src={`/assets/images_new/entity${(entityColorIndex ?? 0) + 1}/${theme}/deposit.png`}
              alt='deposit'
              width={IMG_SIZE}
              height={IMG_SIZE}
            />

            <FlexLayout direction='column' className='w-full' spacing={4}>
              <Heading size='h6'>Ready to start investing?</Heading>
              <Body color='secondary' size='small'>
                Deposit funds now and start your investing journey.
              </Body>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='row' spacing={8}>
            <Button variant='outlined' onClick={handleDismiss}>
              Dismiss
            </Button>
            <Button onClick={onOpenDepositModal}>Deposit</Button>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
};
