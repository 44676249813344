import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { assetService } from '@shared/services';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useOrder } from '@hooks/Orders';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableExchangeRate: React.FC<OrdersTableColumnProps> = ({ item, mobile, width }) => {
  const primaryAsset = useMemo(() => assetService.getAsset(item.primaryAsset), [item.primaryAsset]);
  const secondaryAsset = useMemo(() => assetService.getAsset(item.secondaryAsset), [item.secondaryAsset]);
  const primaryAssetCode = primaryAsset?.code || '';
  const secondaryAssetCode = secondaryAsset?.code || '';
  const { t } = useTranslation('orders');
  const { getTriggerPrice } = useOrder(item);
  const { bx } = useContentBreakpoint();

  return (
    <Stack
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      flex={2}
      key={`${item.uuid}_exchange_rate`}
      justifyContent={mobile ? 'space-between' : 'flex-end'}
      alignItems='center'
      paddingRight={mobile ? 1 : 0}
      direction='row'
      spacing={0.4}
    >
      {mobile && (
        <Typography color='text.secondary' fontWeight={400} fontSize={14}>
          {t('ordersTable.headers.price')}
        </Typography>
      )}
      <Stack
        direction={bx({ xs: 'column', md: 'row' })}
        justifyContent='flex-end'
        alignItems={bx({ xs: 'end', md: 'center' })}
        spacing={1}
      >
        <FormattedText
          value={getTriggerPrice()}
          currency={primaryAsset}
          typographyProps={{ color: 'primary' }}
          secondaryText={{
            typographyProps: { color: 'secondary' },
            value: ` ${primaryAssetCode}/${secondaryAssetCode}`,
          }}
          formatOpts={{ appendCode: false, hideCode: true }}
        />
      </Stack>
    </Stack>
  );
};

export { OrdersTableExchangeRate };
