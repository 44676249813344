import React, { useContext } from 'react';

import { Divider, Stack } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { assetService, TransactionHistoryItem } from '@shared/services';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import {
  OrdersTableAddress,
  OrdersTableAmount,
  OrdersTableAsset,
  OrdersTableDate,
  OrdersTableExchangeRate,
  OrdersTableFee,
  OrdersTableTotal,
} from './columns';

type Props = {
  item: TransactionHistoryItem;
  type?: TransactionsRouteType;
};

const OrdersTableItemMobile: React.FC<Props> = ({ item, type }) => {
  const { forceUpdateOrders } = useContext(TransactionsContext);
  const { openModal } = useModal();

  return (
    <Stack
      id='ordersTable.mobile.item'
      direction='column'
      width='100%'
      paddingY={0.5}
      spacing={1}
      onClick={(e) => {
        e.stopPropagation();
        if (item.orderType) {
          openModal(Modals.OrderDetails, {
            orderId: item.uuid,
            transaction: item,
            onEditSuccess: forceUpdateOrders,
          });
        } else {
          const asset = assetService.getAsset(item.secondaryAsset);
          if (asset) openModal(Modals.TransactionDetails, { transaction: item, asset });
        }
      }}
    >
      <OrdersTableDate item={item} mobile />
      <OrdersTableAsset item={item} mobile />
      {!item.orderType && <OrdersTableAddress item={item} type={type} mobile />}
      <OrdersTableAmount item={item} mobile />
      <OrdersTableExchangeRate item={item} mobile />
      <OrdersTableFee item={item} mobile />
      <OrdersTableTotal item={item} mobile />
      <Divider orientation='horizontal' variant='fullWidth' />
    </Stack>
  );
};

export { OrdersTableItemMobile };
