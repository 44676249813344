import { useCallback, useEffect, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { EnhancedTableData, EnhancedTableHeaderData, EnhancedTableSort } from '@swyftx/aviary/molecules/EnhancedTable';
import { formatValueToCurrencyShorthand } from '@swyftx/aviary/utils';
import { CurrencyType } from '@swyftx/aviary/utils/currency/currency.types';

import { BuySellButtons } from '@global-components/BuySellButtons';

import { Asset, AssetType } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { useCategory } from 'src/lib/categories/hooks';
import { AssetName } from 'src/lib/markets/components/AssetsTable/components';

import { AssetListTableData, AssetListTabTypes } from '../AssetList.types';
import { AssetCategories } from '../components/AssetCategories';
import { RankAsset24Vol } from '../components/RankAsset24Vol';

type AssetListHeaderData = { [key in keyof AssetListTableData]: EnhancedTableHeaderData };

export const useAssetListTable = (assets: Asset[], search: string, selectedTab: AssetListTabTypes) => {
  const { getRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();
  const { getAssetCategories } = useCategory();

  const getKeyAndDirection = useCallback((tab: AssetListTabTypes) => {
    switch (tab) {
      case AssetListTabTypes.GAINERS:
        return { key: 'dailyChange', direction: 'DESC' };
      case AssetListTabTypes.LOSERS:
        return { key: 'dailyChange', direction: 'ASC' };
      case AssetListTabTypes.NEW:
        return { key: 'rankAssetVol', direction: 'DESC' };
      case AssetListTabTypes.OWNED:
      case AssetListTabTypes.ALL:
      case AssetListTabTypes.FAVOURITES:
        return { key: 'rank', direction: 'DESC' };
      default:
        tab satisfies never;
        return { key: 'rank', direction: 'DESC' };
    }
  }, []);

  const [sort, setSort] = useState<EnhancedTableSort<AssetListTableData>>({
    sortKey: 'rank',
    sortDirection: 'DESC',
  });

  useEffect(() => {
    const { key, direction } = getKeyAndDirection(selectedTab);
    setSort({ sortKey: key as keyof AssetListTableData, sortDirection: direction as 'ASC' | 'DESC' });
  }, [selectedTab]);

  const headers: AssetListHeaderData = {
    rank: {
      title: 'Rank',
      alignment: 'start',
      sortable: true,
      className: 'min-w-[4rem] max-w-[4rem] truncate hidden @sm:table-cell',
    },
    asset: {
      title: 'Asset',
      alignment: 'start',
      sortable: true,
      className: 'min-w-[12rem] max-w-[12rem] truncate hidden @sm:table-cell',
    },
    categories: {
      title: 'Categories',
      alignment: 'start',
      sortable: false,
      className: 'min-w-[12rem] max-w-[12rem] truncate hidden @sm:table-cell',
    },
    buyPrice: {
      title: 'Buy Price',
      alignment: 'start',
      sortable: true,
      className: 'truncate min-w-[6rem] max-w-[6rem] hidden @sm:table-cell',
      tooltip: 'The current buy price of the asset',
    },
    dailyChange: {
      title: '24H Change',
      alignment: 'end',
      sortable: true,
      className: 'min-w-[2.5rem] min-w-[2.5rem] truncate hidden @sm:table-cell',
    },
    weeklyChange: {
      title: '7D Change',
      alignment: 'end',
      sortable: true,
      className: 'min-w-[2.5rem] min-w-[2.5rem] truncate hidden @md:table-cell',
    },
    monthlyChange: {
      title: '30D Change',
      alignment: 'end',
      sortable: true,
      className: 'min-w-[2.5rem] min-w-[2.5rem] truncate hidden @lg:table-cell',
    },
    marketCap: {
      title: 'Market Cap',
      alignment: 'end',
      sortable: true,
      className: 'hidden @lg:table-cell',
      tooltip: 'Total market capitalisation of the asset (Circulating supply * Price)',
    },
    dailyVolume: {
      title: '24H Volume',
      alignment: 'end',
      sortable: true,
      className: 'hidden @md:table-cell',
    },
    actions: {
      title: 'Actions',
      alignment: 'end',
      sortable: false,
      className: 'min-w-[11rem] max-w-[11rem] hidden @sm:table-cell',
    },
    // Mobile view
    rankAssetVol: {
      title: 'Rank/Asset/24h vol',
      alignment: 'start',
      sortable: true,
      className: 'truncate table-cell @sm:hidden max-w-[8rem]',
    },
    buyPrice24h: {
      title: 'Buy Price / 24h%',
      alignment: 'end',
      sortable: true,
      className: 'truncate table-cell @sm:hidden ',
    },
  };

  const assetSearch = useCallback(
    (asset: Asset) => {
      const searchLower = search.toLowerCase();
      const nameLower = asset.name.toLowerCase();
      const codeLower = asset.code.toLowerCase();
      return nameLower.includes(searchLower) || codeLower.includes(searchLower);
    },
    [search],
  );

  const hasCategories = useCallback((asset: Asset) => !!getAssetCategories(asset).length, [getAssetCategories]);

  const data: EnhancedTableData<AssetListTableData>[] = useMemo(
    () =>
      assets.filter(assetSearch).map((asset) => {
        const rate = getRate(asset);
        const marketCap = Big(asset.circulatingSupply).times(rate.midPrice).toString();
        const dailyVolume = Big(asset.volume[baseAsset?.id || FiatIdEnum.AUD].day).toString();
        const rank = asset.rank !== Infinity && asset.rank !== null ? `#${asset.rank}` : '-';
        return {
          rank: {
            value: rank,
            element: <Numeric>{rank}</Numeric>,
          },
          rankAssetVol: {
            value: asset.id,
            element: <RankAsset24Vol asset={asset} dailyVolume={dailyVolume} />,
          },
          buyPrice24h: {
            value: rate.askPrice,
            element: (
              <FlexLayout direction='column' className='items-end'>
                <Numeric>
                  {formatCurrency(rate.askPrice, baseAsset)}{' '}
                  {baseAsset?.assetType === AssetType.Crypto ? baseAsset.code : undefined}
                </Numeric>
                <div>
                  <NumericDataItem size='small' data={`${rate.dailyPriceChange}%`}>
                    {Big(rate.dailyPriceChange).gt(0) ? '+' : ''}
                    {Number(rate.dailyPriceChange).toFixed(2)}%
                  </NumericDataItem>
                </div>
              </FlexLayout>
            ),
          },
          asset: {
            value: asset.name,
            element: <AssetName asset={asset} variant='assetList' />,
          },
          categories: {
            value: asset.categories,
            element: <AssetCategories asset={asset} />,
            blockClick: hasCategories(asset),
          },
          buyPrice: {
            value: rate.askPrice,
            element: (
              <Numeric>
                {formatCurrency(rate.askPrice, baseAsset)}{' '}
                {baseAsset?.assetType === AssetType.Crypto ? baseAsset.code : undefined}
              </Numeric>
            ),
          },
          dailyChange: {
            value: rate.dailyPriceChange,
            element: (
              <FlexLayout className='justify-end'>
                <NumericDataItem size='medium' data={`${rate.dailyPriceChange}%`}>
                  {Big(rate.dailyPriceChange).gt(0) ? '+' : ''}
                  {Number(rate.dailyPriceChange).toFixed(2)}%
                </NumericDataItem>
              </FlexLayout>
            ),
          },
          weeklyChange: {
            value: asset.priceChange.week,
            element: (
              <FlexLayout className='justify-end'>
                <NumericDataItem size='medium' data={`${asset.priceChange.week}%`}>
                  {Big(asset.priceChange.week).gt(0) ? '+' : ''}
                  {Number(asset.priceChange.week).toFixed(2)}%
                </NumericDataItem>
              </FlexLayout>
            ),
          },
          monthlyChange: {
            value: asset.priceChange.month,
            element: (
              <FlexLayout className='justify-end'>
                <NumericDataItem size='medium' data={`${asset.priceChange.month}%`}>
                  {Big(asset.priceChange.month).gt(0) ? '+' : ''}
                  {Number(asset.priceChange.month).toFixed(2)}%
                </NumericDataItem>
              </FlexLayout>
            ),
          },
          marketCap: {
            value: marketCap,
            element: (
              <FlexLayout className='justify-end'>
                <Numeric className='truncate'>{formatValueToCurrencyShorthand(marketCap)}</Numeric>
              </FlexLayout>
            ),
          },
          dailyVolume: {
            value: dailyVolume,
            element: (
              <FlexLayout className='justify-end'>
                <Numeric className='truncate'>
                  {formatValueToCurrencyShorthand(
                    dailyVolume,
                    baseAsset?.assetType === AssetType.Fiat ? CurrencyType.Fiat : CurrencyType.CryptoCurrency,
                    baseAsset?.assetType === AssetType.Crypto ? baseAsset.code : undefined,
                  )}
                </Numeric>
              </FlexLayout>
            ),
          },
          actions: {
            value: asset,
            element: <BuySellButtons infoButton asset={asset} size='sm' variant='aviary-subtle' />,
            blockClick: true,
          },
        };
      }),
    [assetSearch, assets, baseAsset, getRate],
  );

  const sortItems = (
    a: EnhancedTableData<AssetListTableData>,
    b: EnhancedTableData<AssetListTableData>,
    sortingBy: EnhancedTableSort<AssetListTableData>,
  ) => {
    switch (sortingBy.sortKey) {
      case 'asset':
        const aVal = a[sortingBy.sortKey].value as string;
        const bVal = b[sortingBy.sortKey].value as string;
        if (sortingBy.sortDirection === 'ASC') return bVal.localeCompare(aVal);
        return aVal.localeCompare(bVal);
      case 'dailyChange':
      case 'weeklyChange':
      case 'monthlyChange':
      case 'marketCap':
      case 'dailyVolume':
      case 'buyPrice':
      case 'rankAssetVol':
      case 'buyPrice24h':
        const aValBig = Big(a[sortingBy.sortKey].value as string);
        const bValBig = Big(b[sortingBy.sortKey].value as string);
        if (sortingBy.sortDirection === 'ASC') return bValBig.lt(aValBig) ? 1 : -1;
        return bValBig.lt(aValBig) ? -1 : 1;
      case 'rank':
        const aRank = a[sortingBy.sortKey].value as string;
        const bRank = b[sortingBy.sortKey].value as string;
        if (aRank === '-' || bRank === '-') {
          if (sortingBy.sortDirection === 'ASC') return aRank.localeCompare(bRank);
          return bRank.localeCompare(aRank);
        }
        const aRankBig = Big(aRank.replace('#', ''));
        const bRankBig = Big(bRank.replace('#', ''));
        if (sortingBy.sortDirection === 'ASC') return bRankBig.lt(aRankBig) ? -1 : 1;
        return bRankBig.lt(aRankBig) ? 1 : -1;
      case 'actions':
      case 'categories':
        return 1;
      default:
        sortingBy.sortKey satisfies never;
    }
    return 1;
  };

  const filteredData = useMemo(() => data.sort((a, b) => sortItems(a, b, sort)), [data, sort]);

  const onSort = useCallback(
    (newSort?: EnhancedTableSort<AssetListTableData>) => {
      const tableData: EnhancedTableData<AssetListTableData>[] = Object.assign([], filteredData);
      if (!newSort) return tableData;
      setSort(newSort);
      return tableData.sort((a, b) => sortItems(a, b, newSort));
    },
    [filteredData],
  );

  return { headers, data, sort, onSort };
};
