import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { assetService } from '@shared/services';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableAsset: React.FC<OrdersTableColumnProps> = ({ item, mobile, width }) => {
  const secondaryAsset = assetService.getAsset(item.secondaryAsset);
  const secondaryAssetCode = secondaryAsset?.code || '';
  const { t } = useTranslation('orders');

  return (
    <Stack
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      flex={2}
      spacing={1}
      paddingRight={mobile ? 1 : 0}
      direction='row'
      justifyContent={mobile ? 'space-between' : 'flex-start'}
      alignItems='center'
    >
      {mobile && (
        <Typography fontSize={14} color='text.secondary' fontWeight={400}>
          {t('ordersTable.headers.asset')}
        </Typography>
      )}
      <Stack direction='row' justifyContent={mobile ? 'flex-end' : 'flex-start'} alignItems='center' spacing={1}>
        <AssetIcon asset={secondaryAsset} size={mobile ? 16 : 20} />
        <Typography fontSize={16} number>
          {secondaryAssetCode}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { OrdersTableAsset };
