import React from 'react';

import { Theme } from '@mui/material';

import { Input, pxToRem, Typography } from '@swyftx/react-web-design-system';

import { Asset, AssetType } from '@shared/api';

import { useTheme } from '@hooks/useTheme';

type Props = {
  onChange: (value: string) => void;
  asset: Asset;
  value: string;
  error?: boolean;
  forceSuffix?: string;
};

const getDefaultTextFieldStyles = (theme: Theme) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& input': {
    paddingY: 1.5,
    paddingX: 1,
    fontWeight: 500,
    fontSize: pxToRem(18),
    className: 'font-mono',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[700],
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  fieldset: {
    borderColor: 'divider',
  },
});

export const PriceInput: React.FC<Props> = ({ asset, value, forceSuffix, onChange, error }) => {
  const prefix = asset.assetType === AssetType.Fiat ? '$' : '';
  const { theme } = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Input
      value={value}
      error={error}
      onChange={handleChange}
      type='number'
      variant='outlined'
      prefix={prefix}
      sx={getDefaultTextFieldStyles(theme)}
      inputProps={{
        className: 'font-mono',
        startAdornment: prefix ? (
          <Typography fontSize={pxToRem(18)} number>
            {prefix}
          </Typography>
        ) : null,
      }}
      InputProps={{
        endAdornment: (
          <Typography fontSize={pxToRem(18)} number color='text.secondary'>
            {forceSuffix || asset.code}
          </Typography>
        ),
      }}
    />
  );
};
