import React, { useEffect } from 'react';

import { Asset, AssetType } from '@shared/api';

import { TransferAddressCryptoModal } from './TransferAddressCryptoModal';
import { TransferAddressFiatModal } from './TransferAddressFiatModal/TransferAddressFiatModal';

type Props = {
  asset: Asset | null;
  show: boolean;
  onClose: () => void;
  refetchAddresses?: () => void;
};

const ADDRESS_REFETCH_INTERVAL = 10000; // ms

export const TransferAddressModal: React.FC<Props> = ({ show, onClose, asset, refetchAddresses }) => {
  const isCrypto = asset && asset.assetType === AssetType.Crypto;
  const isFiat = asset && asset.assetType === AssetType.Fiat;

  // Periodically update the addresses so the user doesn't have to re-open the modal upon email verification
  useEffect(() => {
    const addressRefresh = refetchAddresses
      ? window.setInterval(() => refetchAddresses(), ADDRESS_REFETCH_INTERVAL)
      : null;

    return () => {
      if (addressRefresh) {
        window.clearInterval(addressRefresh);
      }
    };
  }, [refetchAddresses]);

  if (!asset || !show) {
    return null;
  }

  if (isCrypto) {
    return <TransferAddressCryptoModal asset={asset} onClose={onClose} refetchAddresses={refetchAddresses} />;
  }

  if (isFiat) {
    return <TransferAddressFiatModal asset={asset} onClose={onClose} refetchAddresses={refetchAddresses} />;
  }

  return null;
};
