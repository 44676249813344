import React, { PropsWithChildren, useMemo } from 'react';

import { Card, CardContent, CardHeader, CardTitle } from '@swyftx/aviary/atoms/Card';
import { DonutChart } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart';
import { DonutChartItem } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChart.types';
import { DonutChartLegend } from '@swyftx/aviary/atoms/Charts/DonutChart/DonutChartLegend';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric, Utility } from '@swyftx/aviary/atoms/Typography';
import { ChartSplit } from '@swyftx/aviary/icons/outlined';

import * as Sentry from '@sentry/react';
import { EventCallbackInterface } from 'victory-core';

import { PortfolioBreakdownItem } from './PortfolioBreakdownCard.types';
import { PortfolioBreakdownChartTooltip } from './PortfolioBreakdownChartTooltip';

type Props = {
  loading?: boolean;
  title?: string;
  portfolioValue: string;
  actions?: React.ReactElement;
  data: PortfolioBreakdownItem[];
  showLegend?: boolean;
  showLegendPercentages?: boolean;
  className?: string;
  size?: number;
  externalMutations: EventCallbackInterface<string | string[], string | number | (string | number)[]>[] | undefined;
  setExternalMutations: React.Dispatch<
    React.SetStateAction<EventCallbackInterface<string | string[], string | number | (string | number)[]>[] | undefined>
  >;
};

const PortfolioBreakdownCard: React.FC<PropsWithChildren<Props>> = ({
  loading = false,
  portfolioValue,
  title,
  actions,
  showLegend = true,
  showLegendPercentages = true,
  data,
  size = 304,
  externalMutations,
  setExternalMutations,
  className,
  children,
}) => {
  const mappedData = useMemo(
    () =>
      data?.map(
        (d): DonutChartItem => ({
          y: d.countryValue,
          color: d.color,
          icon: d.icon,
          key: d.assetCode,
          title: `${d.balance} ${d.assetCode}`,
          subTitle: d.value,
          percentage: d.portfolioPercentage,
          otherData: d.otherData?.map((o) => ({
            color: o.asset?.color ?? '',
            title: o.asset?.code ?? 'Other Assets',
            subTitle: o.value,
          })),
        }),
      ),
    [data],
  );

  return (
    <Card className={`@container ${className}`}>
      {title && (
        <CardHeader>
          <FlexLayout alignItems='center' justifyContent='space-between'>
            <FlexLayout alignItems='center' spacing={8}>
              <Icon icon={<ChartSplit />} color='info' />
              <CardTitle>{title}</CardTitle>
            </FlexLayout>
            {actions}
          </FlexLayout>
        </CardHeader>
      )}
      <CardContent className='!p-0'>
        <FlexLayout direction='column' spacing={8} className='block @md:hidden'>
          <Utility>Portfolio Value</Utility>
          <Numeric size='xxlarge' weight='emphasis' loading={loading}>
            {portfolioValue}
          </Numeric>
        </FlexLayout>
        <FlexLayout
          alignItems='center'
          direction={!mappedData.length ? 'column' : 'row'}
          justifyContent={!mappedData.length ? 'space-between' : 'center'}
          className={`${!children ? 'h-full' : ''} w-full`}
        >
          <DonutChart
            id='asset-breakdown'
            loading={loading}
            innerRadius={size / 2}
            height={size}
            tooltipElement={<PortfolioBreakdownChartTooltip chartId='asset-breakdown' />}
            legend={
              showLegend ? (
                <FlexLayout direction='column' justifyContent='space-between' spacing={24}>
                  <FlexLayout direction='column' spacing={8} className='hidden @md:block'>
                    <Utility>Portfolio Value</Utility>
                    <Numeric size='xxlarge' weight='emphasis' loading={loading}>
                      {portfolioValue}
                    </Numeric>
                  </FlexLayout>
                  <DonutChartLegend
                    chartId='asset-breakdown'
                    onUpdateExternalMutations={setExternalMutations}
                    data={mappedData}
                    showPercentages={showLegendPercentages}
                  />
                </FlexLayout>
              ) : undefined
            }
            data={mappedData}
            noDataElement={
              <Body size='small' weight='emphasis' className='mb-[16px] w-full text-center'>
                You don&apos;t own any assets
              </Body>
            }
            externalMutations={externalMutations}
            setExternalMutations={setExternalMutations}
          />
        </FlexLayout>
        {children}
      </CardContent>
    </Card>
  );
};

export const ProfiledPortfolioBreakdownCard = Sentry.withProfiler(PortfolioBreakdownCard);

export { ProfiledPortfolioBreakdownCard as PortfolioBreakdownCard };
