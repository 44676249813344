import { useContext, useEffect, useMemo } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { TourGuideContext, TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { useFeatureDiscovery } from '../../hooks/useFeatureDiscovery';

const useAutoInvestEntryTour = () => {
  const { startTour } = useContext(TourGuideContext);
  const { showFeatureDiscovery } = useFeatureDiscovery();
  const [viewedTour, setViewedTour] = useLocalStorage<boolean>(StorageKey.AUTO_INVEST_ENTRY_TOUR, false);
  const [viewedDashboardTour] = useLocalStorage<boolean>(StorageKey.DASHBOARD_LAYOUT_TOUR);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const autoInvestEnabled = isFeatureEnabled(AppFeature.AutoInvest);

  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const isSmallScreen = useMemo(() => isXs || isSm, [isSm, isXs]);

  const tourItem = useMemo(
    (): TourGuideData => ({
      name: 'Auto Invest Entry Tour',
      description: 'Shows how to access the new Auto Invest page',
      showBackdrop: true,
      steps: [
        {
          title: 'Recurring orders is now Auto Invest',
          description: 'Configure automated orders that execute using your AUD wallet balances.',
          image: 'auto_invest_thumbnail',
          imageClassName: 'rounded-8',
          spotlightElementId: 'nav_item_auto_invest',
          alignment: isSmallScreen ? 'center' : 'right',
          customSpotlightStyles:
            'border-radius: .5rem; overflow:hidden; background: var(--color-background-surface-primary);',
        },
      ],
      variant: 'spotlight',
      tourKey: StorageKey.AUTO_INVEST_ENTRY_TOUR,
    }),
    [isSmallScreen],
  );

  useEffect(() => {
    if (!viewedTour && !showFeatureDiscovery && !isSmallScreen && autoInvestEnabled && viewedDashboardTour) {
      startTour(tourItem, () => setViewedTour(true));
    }
  }, [
    tourItem,
    setViewedTour,
    showFeatureDiscovery,
    startTour,
    viewedTour,
    isSmallScreen,
    isFeatureEnabled,
    autoInvestEnabled,
    viewedDashboardTour,
  ]);
};

export { useAutoInvestEntryTour };
