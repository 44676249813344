import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { NoResults } from '@global-components/NoResults/NoResults';

import { Big } from '@shared/safe-big';
import { TransactionHistoryItem } from '@shared/services';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import { ORDERS_TABLE_MOBILE_FILTER_HEIGHT, ORDERS_TABLE_TAB_HEIGHT } from './OrdersTable.settings';
import { OrdersTableItemMobile } from './OrdersTableItem.mobile';
import { OrdersTableMobileItemSkeleton } from './OrdersTableMobileItem.skeleton';

type Props = {
  items: TransactionHistoryItem[];
  height: number;
  loading?: boolean;
  showFilters: boolean;
  type?: TransactionsRouteType;
};

const OrdersTableMobile: React.FC<Props> = observer(({ items, height, loading, showFilters, type }) => {
  const { t } = useTranslation('orders');
  const containerRef = useRef<HTMLDivElement>(null);
  const { incrementPage, fetching } = useContext(TransactionsContext);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [items]);

  const onScroll = useCallback(
    (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (Big(scrollHeight).minus(scrollTop).minus(clientHeight).lte(120)) {
        incrementPage();
      }
    },
    [incrementPage],
  );

  return (
    <Box
      height={height - ORDERS_TABLE_TAB_HEIGHT - (showFilters ? ORDERS_TABLE_MOBILE_FILTER_HEIGHT : 0)}
      sx={{ overflowY: 'auto', paddingLeft: 1 }}
      onScroll={onScroll}
      ref={containerRef}
    >
      {items.map((item) => (
        <OrdersTableItemMobile key={item.uuid} item={item} type={type} />
      ))}

      {!items.length && !loading && (
        <NoResults title={t(`empty.${type}.title` as any)} subTitle={t(`empty.${type}.subTitle` as any)} />
      )}
      {fetching && <OrdersTableMobileItemSkeleton num={8} />}
    </Box>
  );
});

export { OrdersTableMobile };
