import React from 'react';

import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { useTrendingCategory } from '../../hooks/useTrendingCategory';
import { CategoryTrendingModal } from '../CategoryTrendingModal';

const AssetDiscoverySplitTestModal: React.FC = () => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { trendingCategory } = useTrendingCategory();

  if (isFeatureEnabled(AppFeature.AssetDiscoveryV4SplitTest, 'trending') && trendingCategory)
    return <CategoryTrendingModal defaultOpen trendingCategory={trendingCategory} />;

  return null;
};

export { AssetDiscoverySplitTestModal };
