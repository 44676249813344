import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Notification, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { useIntercom } from 'react-use-intercom';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  title: string;
  subTitle: string;
  errorType?: 'verification' | 'restricted' | 'limit';
  showButton?: boolean;
};

export const TransferModalErrorContentStandard: React.FC<Props> = ({ subTitle, title }) => (
  <Stack width='100%' height='100%' alignItems='center' justifyContent='center' maxHeight={pxToRem(160)} padding={2}>
    <Stack justifyContent='center' spacing={2}>
      <Typography fontWeight={600} fontSize={pxToRem(18)} textAlign='center'>
        {title}
      </Typography>
      <Typography fontSize={pxToRem(16)} color='text.secondary' textAlign='center'>
        {subTitle}
      </Typography>
    </Stack>
  </Stack>
);

export const TransferModalErrorContentVerification: React.FC<Props> = ({
  subTitle,
  title,
  errorType,
  showButton = true,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'transferModal.buttonLabels' });
  const { navigate } = useNavigateRoute();
  const { show } = useIntercom();

  const handleClick = () => {
    navigate(NavigationURLs.Onboarding);
  };

  return (
    <Stack justifyContent='center' spacing={2}>
      <Notification severity={errorType === 'limit' ? 'error' : 'warning'} title={title}>
        <Typography fontSize={pxToRem(14)} color='text.secondary'>
          {subTitle}
        </Typography>
        {errorType === 'limit' && (
          <Button color='error' sx={{ marginLeft: '-8px' }} onClick={() => show()}>
            {t('contactSupport')}
          </Button>
        )}
      </Notification>
      {showButton && (
        <Button variant='contained' color='primary' onClick={handleClick}>
          {errorType === 'verification' ? t('verifyNow') : t('resolveRestriction')}
        </Button>
      )}
    </Stack>
  );
};
