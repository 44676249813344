import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Button, Loading, Stack, Typography } from '@swyftx/react-web-design-system';

import { UserBalance } from '@shared/api';
import { Asset, AssetType } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UserStore } from '@shared/store';

import { usePortfolio } from '@hooks/usePortfolio';
import { WalletContext } from '@routes/Wallet/Wallet.context';

import { observer } from 'mobx-react-lite';

import { AppHeaderBaseCurrencySelectorBases } from './AppHeaderBaseCurrencySelectorBases';
import { AppHeaderBaseCurrencySelectorMenuItem } from './AppHeaderBaseCurrencySelectorMenuItem';
import { AppHeaderBaseCurrencySelectorMenuOptions } from './AppHeaderBaseCurrencySelectorMenuOptions';
import { AppHeaderBaseCurrencySelectorNoBalance } from './AppHeaderBaseCurrencySelectorNoBalance';
import { AppHeaderBaseCurrencySelectorPortfolio } from './AppHeaderBaseCurrencySelectorPortfolio';
import { AppHeaderBaseCurrencySelectorPurchaseCrypto } from './AppHeaderBaseCurrencySelectorPurchaseCrypto';
import { AppHeaderBaseCurrencySelectorVerify } from './AppHeaderBaseCurrencySelectorVerify';

type Props = {
  loading: boolean;
  loadingStatus: string;
  onSelectCurrency: (asset: Asset) => void;
};

const AppHeaderBaseCurrencySelectorMenu: React.FC<Props> = observer(({ loading, loadingStatus, onSelectCurrency }) => {
  const { balances, isUserVerified } = UserStore.useUserStore;
  const { isDemo } = AppStore.useAppStore;
  const { portfolio } = usePortfolio({ balanceKey: 'all' });
  const { getRate, getMinimumOrderAmount } = RatesStore.useRatesStore;
  const { hideZeroBalances, setHideZeroBalances } = useContext(WalletContext);
  const { t } = useTranslation('common');

  const getValue = (balance: UserBalance) => Big(balance.availableBalance).plus(balance.stakingBalance);

  const balanceValues = useMemo(
    () =>
      Object.values(balances)
        .filter(({ availableBalance, assetId }) =>
          hideZeroBalances ? Big(availableBalance).gte(getMinimumOrderAmount(assetId)) : true,
        )
        .sort((a, b) => {
          const aAssetType = assetService.getAsset(a.assetId)?.assetType || 0;
          const bAssetType = assetService.getAsset(b.assetId)?.assetType || 0;

          if (aAssetType === bAssetType) {
            const aVal = Big(getRate(a.assetId).midPrice).times(Big(a.availableBalance).plus(a.stakingBalance));
            const bVal = Big(getRate(b.assetId).midPrice).times(Big(b.availableBalance).plus(b.stakingBalance));
            return bVal.toNumber() - aVal.toNumber();
          }

          return aAssetType > bAssetType ? 1 : -1;
        }),
    [balances, getMinimumOrderAmount, getRate, hideZeroBalances],
  );

  const hasCryptoBalance = useMemo(() => {
    let hasCrypto = false;
    Object.values(balances).forEach((balance) => {
      const asset = assetService.getAsset(balance.assetId);
      if (asset?.assetType === AssetType.Crypto) hasCrypto = true;
    });

    return hasCrypto;
  }, [balances]);

  return (
    <FlexLayout className='h-full min-w-[400px]' direction='column'>
      {loading && (
        <div className='h-[60vh] w-full overflow-y-auto'>
          <Stack direction='column' alignItems='center' height='100%' width='100%' justifyContent='center' spacing={2}>
            <Loading variant='indeterminate' />
            <Typography fontSize={18} fontWeight={600}>
              {loadingStatus}
            </Typography>
          </Stack>
        </div>
      )}
      {!loadingStatus && (
        <>
          <AppHeaderBaseCurrencySelectorBases onSelectCurrency={onSelectCurrency} key='currency-bases' />
          <AppHeaderBaseCurrencySelectorPortfolio key='currency-portfolio' />
          <Separator className='my-4' />
          {!isUserVerified() && !isDemo && <AppHeaderBaseCurrencySelectorVerify />}
          {(isUserVerified() || isDemo) && (
            <FlexLayout direction='column' className='max-h-[50vh] w-full overflow-y-auto' key='balances'>
              {balanceValues.map((balance: UserBalance) => {
                const value = getValue(balance);
                const asset = assetService.getAsset(balance.assetId);

                if (!asset || value.lte(0)) return null;

                return (
                  <AppHeaderBaseCurrencySelectorMenuItem
                    key={asset.code}
                    value={value}
                    totalValue={portfolio.accountValue}
                    asset={asset}
                  />
                );
              })}
              {hasCryptoBalance && hideZeroBalances && (
                <Stack width='100%' alignItems='center' marginTop={1} spacing={0}>
                  <Typography fontWeight={500}>{t('balances.cantFindAssets')}</Typography>
                  <Button onClick={() => setHideZeroBalances(false)}>{t('balances.showBalances')}</Button>
                </Stack>
              )}
              {!hasCryptoBalance && balanceValues.length === 0 && <AppHeaderBaseCurrencySelectorNoBalance />}
              {!hasCryptoBalance && <AppHeaderBaseCurrencySelectorPurchaseCrypto />}
            </FlexLayout>
          )}
          <Separator className='my-8' key='basecurrencyselectormenuseparator' />
          <AppHeaderBaseCurrencySelectorMenuOptions key='currency-options' />
        </>
      )}
    </FlexLayout>
  );
});

export { AppHeaderBaseCurrencySelectorMenu };
