import React from 'react';

import { cn } from '@shared/utils/lib/ui';

import { calcStepMarkOffset } from './Slider.utils';

interface SliderProps {
  value?: number;
  mark: number;
  onValueChange?: (value: number[]) => void;
}

export const SliderMark: React.FC<SliderProps> = (props) => {
  const { value, mark, onValueChange } = props;

  return (
    <div
      onClick={() => onValueChange && onValueChange([mark])}
      key={mark}
      className={cn(
        'h-8 w-8 rounded-6',
        'border bg-color-background-surface-primary',
        value && value >= mark ? ' border-color-border-accent' : ' border-color-border-main',
        'absolute',
      )}
      style={{ left: calcStepMarkOffset(mark, 100), transform: 'translate(-50%, calc(-50% + 1px))' }}
    />
  );
};
