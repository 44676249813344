import React from 'react';

import { Divider, Stack } from '@swyftx/react-web-design-system';

import { TabItemProps, TransferTabItem } from './TransferTabItem/TransferTabItem';

export type TransferTabsData = Array<Omit<TabItemProps, 'direction' | 'spacing'>>;

type Props = {
  tabs: TransferTabsData;
  direction?: TabItemProps['direction'];
  spacing?: TabItemProps['spacing'];
};

// Could possible abstract this further into global components if it has other use-cases
export const TransferTabs: React.FC<Props> = ({ tabs, direction = 'row', spacing = 1 }) => (
  <Stack direction='row' divider={<Divider orientation='vertical' />}>
    {tabs.map((tabProps) => (
      <TransferTabItem key={tabProps.label} {...tabProps} direction={direction} spacing={spacing} />
    ))}
  </Stack>
);
