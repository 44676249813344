import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
  title: string;
  value: string;
};

const TrendingCryptoNarrativeCategory: React.FC<Props> = ({ className, title, value }) => (
  <FlexLayout spacing={16} alignItems='center'>
    <Body color='secondary' size='xsmall' className='min-w-[100px]'>
      {title}
    </Body>
    <FlexLayout
      spacing={8}
      className={cn(
        'h-24 scale-x-0 animate-[narrativeAnimate_1s_ease-in-out_forwards_200ms] bg-color-background-primary px-8',
        className,
      )}
      justifyContent='end'
      alignItems='center'
    >
      <Body color='inverse' size='small' weight='emphasis'>
        {value}
      </Body>
    </FlexLayout>
  </FlexLayout>
);

export { TrendingCryptoNarrativeCategory };
