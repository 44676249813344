import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { AppHeaderAssetActions } from '@global-components/AppHeader/AppHeaderAssetActions';
import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';
import { BuySellButtons } from '@global-components/BuySellButtons';
import { PriceTicker } from '@global-components/PriceTicker/PriceTicker';

import { Asset, RateSide } from '@shared/api';
import { RatesStore, UniversalTradeStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useAssetSummaryData } from '../AssetSummary.hooks';

type Props = {
  asset: Asset;
  priceSide?: RateSide;
  forceBaseAsset?: Asset;
};

type AssetSummaryTitleParams = {
  asset: string;
};

const AssetSummaryTitle: React.FC<Props> = observer(({ asset, priceSide, forceBaseAsset }) => {
  const { getRate } = RatesStore.useRatesStore;
  const { showGlobalTrade } = UniversalTradeStore;
  const livePrice = asset && getRate(asset)[priceSide || 'midPrice'];
  const { currency, price, changingCurrency } = useAssetSummaryData(livePrice, forceBaseAsset);
  const countryAsset = useCountryAsset();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const isXs = useTailwindBreakpoint('xs');
  const isSmAndPanelOpen = useMemo(() => !isXs && showGlobalTrade, [isXs, showGlobalTrade]);
  const { navigate } = useNavigateRoute();

  const getIcon = () => <AssetIcon asset={asset} size={28} />;
  const { asset: assetParam } = useParams<AssetSummaryTitleParams>();

  return (
    <FlexLayout
      className='w-full flex-col items-start py-8 @sm:flex-row @sm:justify-between'
      justifyContent='space-between'
      spacing={16}
      alignItems='center'
    >
      <FlexLayout alignItems='center' direction='row' className='w-full' spacing={8}>
        <FlexLayout className='w-full flex-col @sm:flex-row' spacing={8} alignItems='center'>
          {getIcon()}
          <FlexLayout alignItems='center' className='flex-col gap-0 @sm:flex-row @md:gap-8'>
            <Body size='large' weight='bold' color='primary'>
              {asset.name}
            </Body>

            <FlexLayout direction='row' alignItems='center' spacing={16}>
              <PriceTicker
                typographyProps={{
                  size: 'large',
                  weight: 'emphasis',
                }}
                disableNextAnimation={changingCurrency}
                formatOptions={{ hideCode: true, hideSymbol: true, appendCode: false }}
                currency={currency}
                price={price || '0'}
              />
              <Numeric color='secondary'>
                {currency?.code || 'N/A'}/{asset.code}
              </Numeric>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      {!isXs && (
        <FlexLayout
          direction='row'
          className={`w-full items-center justify-end ${isSmAndPanelOpen ? '' : 'max-w-[250px]'}`}
          spacing={8}
        >
          <AppHeaderAssetActions assetCode={assetParam ? assetParam.toLowerCase() : ''} />
          <BuySellButtons asset={asset} openTradePanel variant='aviary' />
          {isFeatureEnabled(AppFeature.QuickBuy) && (
            <Button
              className='w-[full]'
              disabled={!asset.tradable}
              onClick={() =>
                navigate(NavigationURLs.QuickBuy, {
                  state: { assetCode: countryAsset?.id !== asset.id ? asset.code : undefined },
                })
              }
            >
              Quick Buy
            </Button>
          )}
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

AssetSummaryTitle.displayName = 'AssetSummaryTitle';

export { AssetSummaryTitle };
