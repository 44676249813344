import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPriceScale } from '@swyftx/currency-util';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { TradeInputBase } from '@global-components/Input/TradeInput/components/TradeInputBase';

import { AssetType } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { RatesStore } from '@shared/store';
import { isValidDecimalString } from '@shared/utils';

import { useUniversalTradeAsset } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

type TradeDetailsTriggerInputProps = {
  tradeDataKey: string;
};

const DEFAULT_PRICE_SCALE = 6;

const TradeOrderDetailsTriggerInput: React.FC<TradeDetailsTriggerInputProps> = observer(({ tradeDataKey }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });
  const { getRate } = RatesStore.useRatesStore;
  const { fromAsset, data, setTradeData, triggerFromAsset, triggerToAsset, error, clientSideError } =
    useUniversalTradeAsset(tradeDataKey);
  const { customTrigger } = data;

  const calculatedPriceScale = useMemo(() => {
    if (!triggerToAsset) return Big(DEFAULT_PRICE_SCALE);

    // The price scale calculated on the asset from rates
    const assetPriceScale = triggerFromAsset?.price_scale || DEFAULT_PRICE_SCALE;
    // The price scale calculated by the new method. This method improves scales for
    // assets like BABYDOGE
    const calcPriceScale = getPriceScale(getRate(triggerToAsset).midPrice);

    // Take whichever of the two scales are the largest to ensure we always show the
    // largest possible decimals
    return Big(Math.max(assetPriceScale, calcPriceScale));
  }, [getRate, triggerFromAsset?.price_scale, triggerToAsset]);

  const calculateTrigger = (val: string) => {
    if (!triggerToAsset) return;
    const parsedValue = val.replace(/,/g, '');

    const scale = Math.max(triggerToAsset?.price_scale, calculatedPriceScale.toNumber());
    const isValid: boolean = isValidDecimalString(parsedValue, scale);

    if (!fromAsset || !isValid) {
      return;
    }

    setTradeData({ customTrigger: parsedValue });
  };

  return (
    <Stack direction='column' alignItems='flex-start' spacing={1} marginBottom={2} marginTop={2}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
        <Typography fontSize={14} fontWeight={500} color='text.primary' lineHeight='normal'>
          {t('labels.priceTrigger')}
        </Typography>
      </Stack>

      <TradeInputBase
        value={customTrigger || ''}
        error={(error || '').length > 0 || (clientSideError || '').length > 0}
        onChange={calculateTrigger}
        showDollarSign={fromAsset?.assetType === AssetType.Fiat}
        suffix={triggerFromAsset && triggerToAsset ? `${triggerFromAsset?.code}/${triggerToAsset?.code}` : ''}
        disabled={!(triggerFromAsset && triggerToAsset)}
        asset={fromAsset}
      />

      {!(triggerFromAsset && triggerToAsset) && (
        <Typography color='text.secondary' fontWeight={400} fontSize={12}>
          Select assets to enter a trigger price
        </Typography>
      )}
    </Stack>
  );
});

TradeOrderDetailsTriggerInput.displayName = 'TradeOrderDetailsTriggerInput';

export { TradeOrderDetailsTriggerInput };
