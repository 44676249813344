import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Information } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { AppStore, UniversalTradeStore, UserStore } from '@shared/store';
import {
  TradeAssetAction,
  TradeSide,
  TradeState,
  TradeType,
  TradeVariant,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { EntityPermissions } from '@hooks/Permissions';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';

import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTradePageAnalytics } from 'src/lib/trade/hooks/TradePage/useTradePageAnalytics';

type BuySellButtonsProps = {
  asset: Asset;
  preClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  postClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, side: 'buy' | 'sell') => void;
  pairWithBaseAsset?: boolean;
  openTradePanel?: boolean;
  hideSell?: boolean;
  hideBuy?: boolean;
  replace?: boolean;
  variant?: 'aviary' | 'aviary-subtle';
  size?: 'sm' | 'md' | 'lg';
  quickBuy?: boolean;
  className?: string;
  infoButton?: boolean;
};

export const BuySellButtons: React.FC<BuySellButtonsProps> = observer(
  ({
    asset,
    preClick,
    postClick,
    pairWithBaseAsset = true,
    replace = true,
    hideSell,
    hideBuy,
    size = 'md',
    variant = 'aviary',
    quickBuy = false,
    infoButton = false,
    className,
  }) => {
    const { isUserVerified, scopeArray } = UserStore.useUserStore;
    const { setTradeAssets, tradeTo, tradeFrom, setTradeType, setShowGlobalTrade, setTradeVariant, setTradeState } =
      UniversalTradeStore;
    const { isDemo } = AppStore.useAppStore;
    const baseAsset = useBaseAsset();
    const { navigate } = useNavigateRoute();
    const { pathname } = useLocation();
    const { isFeatureEnabled } = useIsFeatureEnabled();
    const countryAsset = useCountryAsset();
    const { t } = useTranslation('common', { keyPrefix: 'buySellButtons' });
    // custom permissions cause of complicated tooltip logic
    const hasPermission = scopeArray?.includes(EntityPermissions.ORDERS_CREATE);
    const { getTradeAssetIds, resetTradeAssets } = useUniversalTradeUtilityStore();
    const quickBuyEnabled = isFeatureEnabled(AppFeature.QuickBuy);
    const universalTradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
    const { clickedButton } = useTradePageAnalytics();
    const isXs = useTailwindBreakpoint('xs');

    const openPanel = () => {
      setShowGlobalTrade(true);
    };

    const navigateToUniversalTrade = useCallback(
      (direction: 'buy' | 'sell') => {
        if (pathname?.includes(NavigationURLs.UniversalTrade)) return;
        if (isXs && !universalTradePageEnabled) {
          navigate(NavigationURLs.UniversalTrade);
          return;
        }
        if (universalTradePageEnabled) {
          clickedButton({ buttonName: direction, buttonState: 'enabled' });
          navigate(NavigationURLs.UniversalTradeAsset, { pathParams: { asset: asset.code } });
        }
      },
      [asset.code, clickedButton, isXs, navigate, pathname, universalTradePageEnabled],
    );

    // BUY is disabled if the same asset is already in the tradeTo OR if it's disabled for trade
    // SELL is disabled if the same asset is already in the tradeFrom
    // should still be able to trade with no balance
    // makes it difficult to flip the direction of the trade in the case that the user may want to
    // set up an on-trigger sell

    const tradingTo = tradeTo.includes(asset.id);
    const tradingFrom = tradeFrom.includes(asset.id);

    const handleBuy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation(); // Remove any previous events propagating
      setTradeState(TradeState.PlaceOrder);
      resetTradeAssets();

      navigateToUniversalTrade('buy');

      if (preClick) {
        preClick(e);
      }

      const shouldReplace = tradeTo.length === 1 && UniversalTradeStore.tradeType !== TradeType.Recurring;

      if (!tradingTo) {
        if (shouldReplace && tradeFrom.length === 1) {
          resetTradeAssets();
          setTradeAssets(
            [asset.id],
            TradeSide.To,
            replace || shouldReplace ? TradeAssetAction.Replace : TradeAssetAction.Add,
          );
          if (baseAsset)
            setTradeAssets([baseAsset.id], TradeSide.From, replace ? TradeAssetAction.Replace : TradeAssetAction.Add);
        } else {
          if (tradeFrom.includes(asset.id)) setTradeAssets([asset.id], TradeSide.From, TradeAssetAction.Remove);

          setTradeAssets(
            [asset.id],
            TradeSide.To,
            replace || shouldReplace ? TradeAssetAction.Replace : TradeAssetAction.Add,
          );
        }

        if (
          UniversalTradeStore.tradeType !== TradeType.Recurring &&
          pairWithBaseAsset &&
          baseAsset &&
          (replace || (!replace && !tradeFrom.length))
        ) {
          setTradeAssets([baseAsset.id], TradeSide.From, replace ? TradeAssetAction.Replace : TradeAssetAction.Add);
        }

        if (
          !getTradeAssetIds(TradeSide.To, countryAsset)?.includes(asset.id) &&
          UniversalTradeStore.tradeType === TradeType.Recurring
        ) {
          setTradeType(TradeType.Instantly);
        }

        setTradeVariant(TradeVariant.Buy);
      }

      openPanel();

      if (postClick) {
        postClick(e, 'buy');
      }
    };

    const handleSell = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      navigateToUniversalTrade('sell');

      setTradeState(TradeState.PlaceOrder);
      resetTradeAssets();

      if (preClick) {
        preClick(e);
      }

      const shouldReplace = tradeFrom.length === 1 && UniversalTradeStore.tradeType !== TradeType.Recurring;

      if (!tradingFrom) {
        if (shouldReplace && tradeTo.length === 1) {
          resetTradeAssets();
          setTradeAssets(
            [asset.id],
            TradeSide.From,
            replace || shouldReplace ? TradeAssetAction.Replace : TradeAssetAction.Add,
          );
          if (baseAsset)
            setTradeAssets([baseAsset.id], TradeSide.To, replace ? TradeAssetAction.Replace : TradeAssetAction.Add);
        } else {
          if (tradeTo.includes(asset.id)) setTradeAssets([asset.id], TradeSide.To, TradeAssetAction.Remove);

          setTradeAssets(
            [asset.id],
            TradeSide.From,
            replace || shouldReplace ? TradeAssetAction.Replace : TradeAssetAction.Add,
          );
        }

        if (pairWithBaseAsset && baseAsset && (replace || (!replace && !tradeTo.length))) {
          setTradeAssets([baseAsset.id], TradeSide.To, replace ? TradeAssetAction.Replace : TradeAssetAction.Add);
        }

        if (UniversalTradeStore.tradeType === TradeType.Recurring) {
          setTradeType(TradeType.Instantly);
        }
      }
      openPanel();

      if (postClick) {
        postClick(e, 'sell');
      }
    };

    const getDisabledReason = useCallback(
      (forSell = false) => {
        if (!hasPermission) {
          return t('tooltips.noPermission');
        }

        if (!isUserVerified() && !isDemo) {
          return t('tooltips.unverified');
        }

        if (forSell) {
          if (!asset.sellEnabled) {
            return t('tooltips.disabledSell', { code: asset.code });
          }

          if (tradingFrom) {
            return t('tooltips.alreadyAdded', { code: asset.code });
          }
        } else {
          if (asset.buyDisabled) {
            return t('tooltips.disabledBuy', { code: asset.code });
          }
          if (tradingTo) {
            return t('tooltips.alreadyAdded', { code: asset.code });
          }
        }

        return '';
      },
      [
        hasPermission,
        isUserVerified,
        isDemo,
        t,
        asset.sellEnabled,
        asset.code,
        asset.buyDisabled,
        tradingFrom,
        tradingTo,
      ],
    );

    const buyDisabled: string = getDisabledReason();

    const sellDisabled: string = getDisabledReason(true);

    return (
      <FlexLayout
        direction='row'
        className={cn(
          'w-full min-w-[10rem] items-center justify-end',
          variant === 'aviary-subtle' ? 'flex-row-reverse justify-start ' : '',
          className,
        )}
        spacing={variant === 'aviary-subtle' ? 4 : 8}
      >
        {!hideBuy && (
          <Button
            className={variant !== 'aviary-subtle' ? 'w-full' : ''}
            color={variant === 'aviary-subtle' ? 'primary' : 'success'}
            disabled={buyDisabled.length > 0}
            onClick={handleBuy}
            size={size}
            tooltip={buyDisabled}
            variant='filled'
          >
            {t('buttonLabels.buy')}
          </Button>
        )}
        {!hideSell && (
          <Button
            className={variant !== 'aviary-subtle' ? 'w-full' : ''}
            color='destructive'
            disabled={sellDisabled.length > 0}
            onClick={handleSell}
            size={size}
            tooltip={sellDisabled}
            variant={variant === 'aviary-subtle' ? 'outlined' : 'filled'}
          >
            {t('buttonLabels.sell')}
          </Button>
        )}
        {quickBuyEnabled && quickBuy && (
          <Button
            className='w-full'
            disabled={!asset.tradable}
            onClick={() => navigate(NavigationURLs.QuickBuy, { state: { assetCode: asset.code } })}
            size={size}
          >
            Quick Buy
          </Button>
        )}
        {infoButton && (
          <Button
            className={cn(variant !== 'aviary-subtle' ? 'w-full' : '')}
            onClick={() => navigate(NavigationURLs.SingleAsset, { pathParams: { asset: asset.code } })}
            size={size}
            tooltip='Asset info'
            variant='outlined'
            leadingIcon={variant === 'aviary-subtle' ? <Information /> : undefined}
            layout={variant === 'aviary-subtle' ? 'icon' : 'default'}
          >
            Info
          </Button>
        )}
      </FlexLayout>
    );
  },
);
