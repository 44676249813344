import React, { useContext } from 'react';

import Stack from '@mui/material/Stack';

import { Button, Typography } from '@swyftx/react-web-design-system';

import { AuthContext } from '@routes/Auth/Auth.context';

import { observer } from 'mobx-react-lite';

const Error: React.FC = observer(() => {
  const { error, cleanup } = useContext(AuthContext);

  return (
    <Stack direction='column' sx={{ width: '100%', height: '100%', flex: 1 }} gap='24px' alignItems='center'>
      <Typography fontSize='32px' fontWeight={700}>
        Uh oh!
      </Typography>
      <Stack gap='16px' alignItems='center'>
        <Typography fontSize='14px' fontWeight={400} color='text.secondary'>
          {error}
        </Typography>
        <Typography fontSize='14px' fontWeight={400} color='text.secondary'>
          Continue with the button below to try again.
        </Typography>
      </Stack>
      <Button variant='contained' sx={{ height: '48px' }} onClick={cleanup}>
        Continue
      </Button>
    </Stack>
  );
});

Error.displayName = 'Error';

export { Error };
