import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import { Chip } from '@swyftx/react-web-design-system';

import { AssetIcon } from '@global-components/AssetIcon/AssetIcon';

import { assetService } from '@shared/services';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { observer } from 'mobx-react-lite';

type Props = {
  assetId: number;
  side: TradeSide;
};

const TradeOrderDetailsAssetContainerItem: React.FC<Props> = observer(({ assetId, side }) => {
  const { setTradeAssets } = UniversalTradeStore;
  const asset = useMemo(() => assetService.getAsset(assetId), [assetId]);

  const getAvatar = () => {
    if (!asset) return undefined;

    return (
      <Box>
        <AssetIcon asset={asset} size={20} />
      </Box>
    );
  };

  if (!asset) return null;

  return (
    <Chip
      size='medium'
      label={asset.name}
      sx={{
        '.MuiChip-avatar': {
          color: 'white !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        fontWeight: 600,
        borderRadius: 1,
        height: '36px',
      }}
      onClick={() => setTradeAssets([assetId], side, TradeAssetAction.Remove)}
      onDelete={() => setTradeAssets([assetId], side, TradeAssetAction.Remove)}
      avatar={getAvatar()}
    />
  );
});

TradeOrderDetailsAssetContainerItem.displayName = 'TradeOrderDetailsAssetContainerItem';

export { TradeOrderDetailsAssetContainerItem };
