import { useCallback } from 'react';

import { api } from '@shared/api';

import { useFetchTriggerNotificationsCache } from './useFetchTriggerNotifications/useFetchTriggerNotificationsCache';

const useDismissTriggerNotifications = () => {
  const { invalidateCache } = useFetchTriggerNotificationsCache();

  const dismissNotifications = useCallback(
    async (ids?: number[]) => {
      if (!ids?.length) return;

      await api.endpoints.dismissTriggerNotifications({ data: { triggers: ids } });
      invalidateCache();
    },
    [invalidateCache],
  );

  return {
    dismissNotifications,
  };
};

export { useDismissTriggerNotifications };
