import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Button as MUIButton, Tooltip } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';
import { MAX_TRADE_ASSETS } from '@shared/constants';
import { AppStore, UserStore } from '@shared/store';

import { EntityPermissions } from '@hooks/Permissions';
import { useUniversalTradePanel } from '@hooks/Trade/useUniversalTradePanel';

import { observer } from 'mobx-react-lite';

type Props = {
  assets?: Asset[];
  color?: 'success' | 'primary';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'aviary' | 'aviary-subtle' | 'mui'; // Very much temporary
  customLabel?: string;
  buttonVariant?: 'filled' | 'outlined';
  onClickCallback?: () => void;
};

const BuyButton: React.FC<Props> = observer(
  ({
    assets,
    color = 'success',
    size = 'md',
    customLabel,
    variant = 'mui',
    buttonVariant = 'filled',
    onClickCallback,
  }) => {
    const { multiTradeLimitationRead, isDemo } = AppStore.useAppStore;
    const { isUserVerified, scopeArray } = UserStore.useUserStore;
    const [showTooltip, setShowTooltip] = useState(true);
    const { t } = useTranslation('common', { keyPrefix: 'buySellButtons' });
    const { addAssetsToBuy } = useUniversalTradePanel();
    const { openModal } = useModal();
    const hasPermission = scopeArray?.includes(EntityPermissions.ORDERS_CREATE);

    const handleBuy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      if (!assets) return;

      if (onClickCallback) onClickCallback();

      if (assets.length > MAX_TRADE_ASSETS && !multiTradeLimitationRead) {
        openModal(Modals.MultiTradeLimitation, { assets });
        return;
      }

      addAssetsToBuy(assets, true);
    };

    const disabled = useMemo(() => {
      if (!hasPermission) {
        return t('tooltips.noPermission');
      }

      if (!isUserVerified() && !isDemo) {
        return t('tooltips.unverified');
      }

      return '';
    }, [hasPermission, isUserVerified, isDemo, t]);

    return (
      <Tooltip title={showTooltip ? disabled : ''} onMouseEnter={() => setShowTooltip(true)}>
        <span className='flex w-full justify-end'>
          {variant === 'mui' && (
            <MUIButton
              variant='contained'
              size='medium'
              className='w-full'
              color={color}
              onClick={handleBuy}
              disabled={disabled.length > 0}
            >
              {customLabel || t('buttonLabels.buy')}
            </MUIButton>
          )}
          {['aviary', 'aviary-subtle'].includes(variant) && (
            <Button
              variant={buttonVariant}
              size={size}
              className={variant === 'aviary-subtle' ? '' : 'w-full'}
              color={color}
              onClick={handleBuy}
              disabled={disabled.length > 0}
              bodyProps={{ weight: 'emphasis' }}
            >
              {customLabel || t('buttonLabels.buy')}
            </Button>
          )}
        </span>
      </Tooltip>
    );
  },
);

export { BuyButton };
