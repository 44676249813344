/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InfoOutlined from '@mui/icons-material/InfoOutlined';

import { Button, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { NetworkFeesModal } from '@global-components/Modals/NetworkFeesModal';
import { FormattedText } from '@global-components/Text/FormattedText/FormattedText';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { WithdrawalAddress, withdrawalService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

type Props = {
  withdrawAmount: string;
  selectedAsset: Asset | null;
  withdrawAddress?: WithdrawalAddress;
};

export const TransferWithdrawFees: React.FC<Props> = ({ selectedAsset, withdrawAmount, withdrawAddress }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation('modals', { keyPrefix: 'transferModal.withdraw' });
  const { convertRate } = RatesStore.useRatesStore;
  const baseAsset = useBaseAsset();

  const withdrawalFee =
    withdrawAddress && selectedAsset ? withdrawalService.getMiningFee(withdrawAddress, selectedAsset) : null;
  const totalSendExclFee = withdrawalFee ? Big(withdrawAmount || 0).minus(withdrawalFee) : Big(withdrawAmount);

  if (!(baseAsset && withdrawalFee && Big(totalSendExclFee).gt(0)) || !selectedAsset) {
    return null;
  }

  return (
    <>
      <Stack spacing={1}>
        <Stack direction='row' width='100%' justifyContent='space-between' alignItems='flex-start'>
          <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={0.5}>
            <Typography fontWeight={500} fontSize={pxToRem(14)}>
              {t('fees')}
            </Typography>
            <Button icon size='small' onClick={() => setShowModal(true)}>
              <InfoOutlined color='primary' fontSize='small' />
            </Button>
          </Stack>
          <Stack alignItems='flex-end'>
            <FormattedText
              currency={selectedAsset}
              value={withdrawalFee}
              secondaryText={{
                typographyProps: {
                  color: 'secondary',
                  size: 'large',
                  className: 'ml-8',
                },
                value: selectedAsset.code,
              }}
              formatOpts={{ appendCode: false, hideCode: true }}
              typographyProps={{
                color: 'primary',
                size: 'large',
                weight: 'emphasis',
              }}
            />
            <FormattedText
              prefix='~'
              currency={baseAsset}
              value={convertRate(selectedAsset, baseAsset, withdrawalFee, 'midPrice')}
              secondaryText={{
                typographyProps: {
                  color: 'secondary',
                  size: 'large',
                  className: 'ml-8',
                },
                value: baseAsset.code,
              }}
              formatOpts={{ appendCode: false, hideCode: true }}
              typographyProps={{
                color: 'secondary',
                weight: 'emphasis',
                size: 'large',
              }}
            />
          </Stack>
        </Stack>
        <Stack direction='row' width='100%' justifyContent='space-between' alignItems='flex-start'>
          <Typography fontWeight={500} fontSize={pxToRem(14)}>
            {t('totalExcludingFees')}
          </Typography>
          <Stack alignItems='flex-end'>
            <FormattedText
              currency={selectedAsset}
              value={totalSendExclFee}
              secondaryText={{
                typographyProps: {
                  color: 'secondary',
                  size: 'large',
                  className: 'ml-8',
                },
                value: selectedAsset.code,
              }}
              formatOpts={{ appendCode: false, hideCode: true }}
              typographyProps={{
                color: 'primary',
                size: 'large',
                weight: 'emphasis',
              }}
            />
            <FormattedText
              prefix='~'
              currency={baseAsset}
              value={convertRate(selectedAsset, baseAsset, totalSendExclFee, 'midPrice')}
              secondaryText={{
                typographyProps: {
                  color: 'secondary',
                  size: 'large',
                  className: 'ml-8',
                },
                value: baseAsset.code,
              }}
              formatOpts={{ appendCode: false, hideCode: true }}
              typographyProps={{
                color: 'secondary',
                weight: 'emphasis',
                size: 'large',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      {showModal && <NetworkFeesModal onClose={() => setShowModal(false)} />}
    </>
  );
};
