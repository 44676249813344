import React from 'react';

import TooltipMUI, { TooltipProps } from '@mui/material/Tooltip';

const Tooltip: React.FC<TooltipProps> = ({ id, children, ...props }) => (
  <TooltipMUI id={id} {...props}>
    {children}
  </TooltipMUI>
);

export { Tooltip };
