import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Stack, Typography } from '@swyftx/react-web-design-system';

import {
  depositStatus,
  depositStatusColors,
  orderStatus,
  orderStatusColors,
  orderType,
  TransactionType,
  withdrawStatus,
  withdrawStatusColors,
} from '@shared/api/@types/portfolio';
import { formatDateTime } from '@shared/utils';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { capitalize } from '@utils/formatting';

import { observer } from 'mobx-react-lite';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableDate: React.FC<OrdersTableColumnProps> = observer(({ item, mobile, width }) => {
  const { bx } = useContentBreakpoint();
  const { t } = useTranslation('orders');

  const statusText = useMemo(() => {
    if (item.type === TransactionType.Deposit) {
      return t(depositStatus[item.statusRaw] as any);
    }

    if (item.type === TransactionType.Withdrawal) {
      return t(withdrawStatus[item.statusRaw] as any);
    }

    return t(orderStatus[item.statusRaw] as any);
  }, [item.statusRaw, item.type, t]);

  const statusColor = useMemo(() => {
    if (item.type === TransactionType.Deposit) {
      return depositStatusColors[item.statusRaw];
    }

    if (item.type === TransactionType.Withdrawal) {
      return withdrawStatusColors[item.statusRaw];
    }

    return orderStatusColors[item.statusRaw];
  }, [item.statusRaw, item.type]);

  return (
    <Stack
      flex={2}
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      paddingLeft={mobile ? 0 : 2}
      direction={mobile ? 'row' : 'column'}
      paddingRight={mobile ? 1 : 0}
      alignItems={mobile ? 'center' : 'flex-start'}
      justifyContent={mobile ? 'space-between' : 'flex-start'}
    >
      <Stack direction='row' alignItems='center' justifyContent='flex-start' spacing={mobile ? 1 : 1.5}>
        <Typography fontSize={bx({ xs: 12, sm: 14 })} fontWeight={500}>
          {item.orderType ? t(orderType[item.orderType || 0] as any) : capitalize(item.type)}
        </Typography>
        <Badge variant='dot' color={statusColor} />
        <Typography fontSize={bx({ xs: 12, sm: 14 })} fontWeight={500}>
          {statusText}
        </Typography>
      </Stack>
      <Typography
        fontSize={bx({ xs: 12, sm: 14 })}
        sx={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}
        number
        color='text.secondary'
      >
        {formatDateTime(new Date(item.date))}
      </Typography>
    </Stack>
  );
});

export { OrdersTableDate };
