import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Stack, Typography } from '@swyftx/react-web-design-system';

import { Links } from '@shared/constants';

type Props = {
  onClose: () => void;
};

const NetworkFeesModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation('modals', { keyPrefix: 'networkFees' });

  const openFeesArticle = () => {
    window.open(Links.help.networkFees, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      open
      id='network-fees-modal'
      HeaderProps={{
        title: t('title'),
        dismissible: true,
      }}
      sx={{
        width: '600px',
        '.MuiCardContent-root': {
          height: '100%',
          '> .MuiBox-root': { flexGrow: 1 },
          '> .MuiBox-root ~ .MuiBox-root': { flexGrow: 0 },
        },
      }}
      FooterProps={{
        content: (
          <Stack direction='row' alignItems='center' justifyContent='flex-end' spacing={2}>
            <Button variant='contained' color='primary' size='large' onClick={onClose} disableElevation>
              {t('dismiss')}
            </Button>
          </Stack>
        ),
      }}
      onClose={onClose}
    >
      <Stack direction='column' alignItems='flex-start' justifyContent='center' spacing={2}>
        <Typography fontWeight={600} fontSize={14}>
          {t('contentTitle')}
        </Typography>
        <Typography fontWeight={400} fontSize={14} color='text.secondary'>
          {t('contentDescription')}
        </Typography>
        <Typography fontWeight={400} fontSize={14} color='primary' sx={{ cursor: 'pointer' }} onClick={openFeesArticle}>
          {t('learnMore')}
        </Typography>
      </Stack>
    </Modal>
  );
};

export { NetworkFeesModal };
