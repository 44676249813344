/* eslint-disable no-unused-vars */
import { api } from '@shared/api';
import { Offering, Reward } from '@shared/api/@types/staking';
import { CronJobService } from '@shared/cron';
import { TimesEnum } from '@shared/enums';
import handleError, { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { UserStore } from '@shared/store';

const LOG_TAG = '[STAKING_DETAIL_SERVICe]';

export type StakingItem = Offering & Reward;

export interface StakingOfferings {
  [key: string]: StakingItem[];
}

let stakingOptedIn = false;

class StakingDetailService extends CronJobService {
  async update() {
    try {
      const { isLoggedIn, setStakingInfo } = UserStore.useUserStore;
      if (isLoggedIn) {
        const response = await api.endpoints.getStakingInfo();

        stakingOptedIn = response?.data?.optedIn;

        const stakingDetails: StakingOfferings = {};

        for (const offering of response.data.offerings) {
          if (!stakingDetails[offering.assetId]) stakingDetails[offering.assetId] = [];
          // eslint-disable-next-line no-underscore-dangle
          stakingDetails[offering.assetId].push({ rewards: '', ...offering, offerId: offering._id });
        }

        if (response.data.rewards.length) {
          for (const rewards of response.data.rewards) {
            for (const stakingOffer of stakingDetails[rewards.assetId]) {
              stakingOffer.rewards = rewards.rewards;
              stakingOffer.offerId = rewards.offerId;
            }
          }
        }

        setStakingInfo(stakingDetails);
      }
    } catch (ex) {
      // ignore if known
      if (!(ex as SwyftxError).isSwyftxError) {
        handleError(LOG_TAG, 'Failed to update staking detail', ex);
      }
    }
  }
}

export const stakingDetailService = new StakingDetailService(TimesEnum.MINUTE);

export const isStakingEnabled = () => stakingOptedIn;

export const enableStaking = async () => {
  await api.endpoints.optInStaking();
  stakingDetailService.forceUpdate();
  stakingOptedIn = true;
};

// [dev] When hot reloading remove the cron
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const module: any;
if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    logger.debug(LOG_TAG, 'Hot reload cleanup');
    stakingDetailService.cleanup();
  });
}
