import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Numeric } from '@swyftx/aviary/atoms/Typography';
import { Stack, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { TransactionType } from '@shared/api/@types/portfolio';
import { assetService } from '@shared/services';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useOrder } from '@hooks/Orders';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableTotal: React.FC<OrdersTableColumnProps> = ({ item, mobile, width }) => {
  const primaryAsset = assetService.getAsset(item.primaryAsset);
  const { t } = useTranslation('orders');
  const { getValue } = useOrder(item);
  const { bx } = useContentBreakpoint();

  const primaryIsQuantity = useMemo(() => primaryAsset?.id === item.limitAsset, [primaryAsset, item.limitAsset]);
  const isDepositOrWithdraw = useMemo(
    () => item.type === TransactionType.Deposit || item.type === TransactionType.Withdrawal,
    [item],
  );

  return (
    <Stack
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      flex={2}
      key={`${item.uuid}_amount`}
      justifyContent={mobile ? 'space-between' : 'flex-end'}
      alignItems='center'
      direction='row'
      paddingRight={mobile ? 1 : 0}
      spacing={0.4}
    >
      {mobile && (
        <Typography color='text.secondary' fontWeight={400} fontSize={14}>
          {t('ordersTable.headers.total')}
        </Typography>
      )}
      <Stack
        direction={bx({ xs: 'row', md: 'row' })}
        justifyContent='flex-end'
        alignItems={bx({ xs: 'end', md: 'center' })}
        spacing={1}
      >
        {!isDepositOrWithdraw && primaryIsQuantity && <Typography color='text.secondary'>*</Typography>}
        <FormattedText
          prefix={primaryAsset?.id !== item.tradedAssetId ? '~' : ''}
          value={getValue(item.primaryAsset)}
          currency={primaryAsset}
          typographyProps={{
            color: 'primary',
          }}
          formatOpts={{ appendCode: false, hideCode: true }}
        />
        <Numeric color='secondary'>{primaryAsset?.code}</Numeric>
      </Stack>
    </Stack>
  );
};

export { OrdersTableTotal };
