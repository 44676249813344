import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { NavigationURLs } from 'src/lib/navigation/types';

const useSetTwoFactorAuthenticationTour = (): TourGuideData => {
  const { t } = useTranslation('featureDiscovery', { keyPrefix: 'tours' });

  return useMemo(
    () => ({
      name: t('setTwoFactorAuthentication.name'),
      description: t('setTwoFactorAuthentication.description'),
      startRoute: NavigationURLs.ProfileSecurity,
      steps: [
        {
          title: t('setTwoFactorAuthentication.steps.1.title'),
          description: t('setTwoFactorAuthentication.steps.1.content'),
          spotlightElementId: 'two-factor-authentication-item',
          alignment: 'right',
        },
      ],
    }),
    [t],
  );
};

export { useSetTwoFactorAuthenticationTour };
