/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button, Grid } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { styled } from '@mui/system';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';
import {
  CoinFilled,
  DepositFilled,
  DollarNoteFilled,
  PriceAlertFilled,
  SwapFilled,
  WithdrawFilled,
} from '@swyftx/aviary/icons/filled';
import { pxToRem, Typography } from '@swyftx/react-web-design-system';

import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api/@types/markets';
import { AppStore, UniversalTradeStore, UserStore } from '@shared/store';
import { TradeAssetAction, TradeSide, TradeState } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { EntityPermissions, usePermissions } from '@hooks/Permissions';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useFetchTriggerNotifications } from 'src/lib/dynamic-price-alerts/hooks/useFetchTriggerNotifications/useFetchTriggerNotifications';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { Modals } from '../../../../../global-components/Modals/Modals.enum';

const ICON_SIZE = 20;
const LARGE_HEIGHT = 250;

const iconProps: Partial<SvgIconProps> = {
  width: pxToRem(ICON_SIZE),
  height: pxToRem(ICON_SIZE),
};

const LOG_TAG = 'QUICK_ACTIONS_TILE';

const QuickActionButton = styled(Button)(({ theme }) => ({
  height: '60px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& .MuiTypography-root': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiButton-startIcon .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const QuickActionsTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const { openModal } = useModal();
  const { t } = useTranslation('dashboard', { keyPrefix: 'quickActionsTile' });
  const countryAsset = useCountryAsset();
  const baseAsset = useBaseAsset();
  const { isUserVerified, isEntity, balances } = UserStore.useUserStore;
  const { setTradeAssets, setShowGlobalTrade, setTradeState } = UniversalTradeStore;
  const { isDemo } = AppStore.useAppStore;
  const { pathname } = useLocation();
  const avo = useAvo();
  const ref = useRef<HTMLDivElement>(null);
  const { data } = useFetchTriggerNotifications();
  const { navigate } = useNavigateRoute();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  const { enabled: DepositWithdrawEnabled } = usePermissions({
    permissions: [EntityPermissions.ADDRESS],
  });

  const { enabled: TradeEnabled } = usePermissions({
    permissions: [EntityPermissions.ORDERS_CREATE],
  });

  const isCompact = useMemo(
    () =>
      tile.metadata?.size === 'compact' || (ref?.current?.clientHeight && ref?.current?.clientHeight >= LARGE_HEIGHT),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tile, ref?.current?.clientHeight],
  );

  const showGlobalTrade = useCallback(
    (asset?: Asset, tradeSide: TradeSide = TradeSide.To) => {
      if (!asset) return;

      const reverseSide = tradeSide === TradeSide.To ? TradeSide.From : TradeSide.To;
      setTradeAssets([], reverseSide, TradeAssetAction.Replace);
      setTradeAssets([asset.id], tradeSide, TradeAssetAction.Replace);
      setShowGlobalTrade(true);
    },
    [setTradeAssets, setShowGlobalTrade],
  );

  const quickActions: Array<{
    name: string;
    icon: JSX.Element;
    onClick: () => void;
    disabled: boolean;
    disabledTooltip: string;
  }> = useMemo(
    () => [
      {
        name: 'Buy',
        icon: <CoinFilled {...iconProps} />,
        onClick: () => {
          // Don't auto-select FROM asset if user has no balances
          const hasBaseAsset = Boolean(baseAsset && balances?.[baseAsset.id]);
          if (hasBaseAsset) {
            setTradeState(TradeState.SelectToAsset);
            window.setTimeout(() => {
              showGlobalTrade(baseAsset, TradeSide.From);
            }, 100);
          } else {
            setTradeState(TradeState.PlaceOrder);
            window.setTimeout(() => {
              setShowGlobalTrade(true);
            }, 100);
          }
        },
        disabled: !TradeEnabled && !isUserVerified() && !isDemo,
        disabledTooltip: 'labels.disabledBuy',
      },
      {
        name: 'Sell',
        icon: <DollarNoteFilled {...iconProps} />,
        onClick: () => {
          setTradeState(TradeState.SelectFromAssets);
          window.setTimeout(() => {
            showGlobalTrade(baseAsset, TradeSide.To);
          }, 100);
        },
        disabled: !TradeEnabled && !isUserVerified() && !isDemo,
        disabledTooltip: 'labels.disabledSell',
      },
      {
        name: 'Swap',
        icon: <SwapFilled {...iconProps} />,
        onClick: () => {
          setTradeState(TradeState.PlaceOrder);
          window.setTimeout(() => {
            setShowGlobalTrade(true);
          }, 100);
        },
        disabled: !TradeEnabled && !isUserVerified() && !isDemo,
        disabledTooltip: 'labels.disabledSwap',
      },
      {
        name: 'Withdraw',
        icon: <WithdrawFilled {...iconProps} />,
        onClick: () => openModal(Modals.WithdrawSend, { selectedAsset: countryAsset }),
        disabled: !TradeEnabled && DepositWithdrawEnabled && isEntity() && !isUserVerified(),
        disabledTooltip: 'labels.disabledWithdraw',
      },
      {
        name: 'Deposit',
        icon: <DepositFilled {...iconProps} />,
        onClick: () => {
          avo.depositFundsTapped({
            screen: pathname,
            component: LOG_TAG,
          });
          openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
        },
        disabled: !TradeEnabled && DepositWithdrawEnabled && isEntity() && !isUserVerified(),
        disabledTooltip: 'labels.disabledDeposit',
      },
    ],
    [
      TradeEnabled,
      isUserVerified,
      isDemo,
      DepositWithdrawEnabled,
      isEntity,
      baseAsset,
      balances,
      setTradeState,
      showGlobalTrade,
      setShowGlobalTrade,
      openModal,
      countryAsset,
      avo,
      pathname,
    ],
  );

  return (
    <DashboardBaseTile dragProps={dragProps} tileName={tile.name} title={t('title')} contentSx={{ height: '100%' }}>
      <Grid
        ref={ref}
        padding={2}
        container
        style={{
          display: 'grid',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          gridTemplateColumns: `repeat(auto-fit, minmax(${isCompact ? '33%' : '120px'}, 1fr))`,
        }}
      >
        {quickActions
          .filter((action) => !action.disabled)
          .map((action) => (
            <QuickActionButton
              key={action.name}
              color='primary'
              size='large'
              onClick={action.onClick}
              disabled={action.disabled}
              startIcon={action.icon}
            >
              <Typography color='text.secondary' fontSize={14} paddingLeft={0.5}>
                {action.name}
              </Typography>
            </QuickActionButton>
          ))}
        {isFeatureEnabled(AppFeature.DynamicPriceAlerts) && (
          <QuickActionButton
            key='Price Alerts'
            color='primary'
            size='large'
            onClick={() => navigate(NavigationURLs.PriceAlerts)}
            startIcon={
              <FlexLayout className='relative h-full w-full' alignItems='center' justifyContent='center'>
                {data && data.length > 0 && (
                  <Chip
                    size='sm'
                    color='destructive'
                    className={cn('absolute origin-right px-6 py-0', 'right-0 top-0 -translate-y-1/2 translate-x-1/2')}
                  >
                    <Numeric size='xxsmall' color='inverse' weight='emphasis' className='text-right'>
                      {data?.length}
                    </Numeric>
                  </Chip>
                )}
                <PriceAlertFilled className='h-24 w-24' />
              </FlexLayout>
            }
          >
            <Typography color='text.secondary' fontSize={14} paddingLeft={0.5}>
              Price Alerts
            </Typography>
          </QuickActionButton>
        )}
      </Grid>
    </DashboardBaseTile>
  );
});

export { QuickActionsTile };
