import React, { PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

import * as RadixTooltip from '@radix-ui/react-tooltip';

import { FlexLayout } from '../Layout/Flex';
import { Body } from '../Typography';

type Props = {
  title?: string;
  content?: React.ReactNode;
  alignOffset?: number;
  sideOffset?: number;
  align?: 'start' | 'center' | 'end';
  side?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
};

const Tooltip: React.FC<PropsWithChildren<Props>> = ({
  title,
  content,
  alignOffset = 4,
  sideOffset = 4,
  align = 'center',
  side = 'top',
  children,
  className,
}) => {
  if (!title) return <>{children}</>;

  return (
    <RadixTooltip.Provider delayDuration={0} disableHoverableContent>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            sideOffset={sideOffset}
            alignOffset={alignOffset}
            collisionPadding={90}
            align={align}
            side={side}
            className={cn('z-tooltip', className)}
          >
            <div className='rounded-4 bg-color-text-primary px-8 py-4'>
              <FlexLayout direction='column' spacing={4}>
                {title && (
                  <Body size='small' className='max-w-[250px] text-color-text-inverse'>
                    {title}
                  </Body>
                )}
                {content}
              </FlexLayout>
            </div>
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export { Tooltip };
