import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Button, Grid } from '@swyftx/react-web-design-system';

import { LayoutGridItem } from '@global-components/Grid/LayoutGridItem';

import { TransactionTypeFilter } from '@shared/services';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import {
  TransactionsFiltersAsset,
  TransactionsFiltersCalendar,
  TransactionsFiltersStatus,
  TransactionsFiltersTypes,
} from './filters';
import { ORDERS_TABLE_FILTER_HEIGHT, ORDERS_TABLE_MOBILE_FILTER_HEIGHT } from '../OrdersTable/OrdersTable.settings';

type Props = {
  disabled?: boolean;
  showAssets?: boolean;
  type?: TransactionsRouteType;
};

const TransactionsFilters: React.FC<Props> = observer(({ disabled, showAssets = true, type }) => {
  const { updateFilters, filters } = useContext(TransactionsContext);
  const { t } = useTranslation('orders');
  const { bx } = useContentBreakpoint();

  const areFiltersDirty = useMemo(() => {
    if (filters.searchString || filters.asset) {
      return true;
    }
    if (type === TransactionsRouteType.Deposits) {
      return filters.types.length !== 1 || filters.types[0] !== TransactionTypeFilter.DEPOSIT;
    }
    if (type === TransactionsRouteType.Withdrawals) {
      return filters.types.length !== 1 || filters.types[0] !== TransactionTypeFilter.WITHDRAWAL;
    }
    return (
      filters.types.length !== 2 ||
      filters.types[0] !== TransactionTypeFilter.BUY ||
      filters.types[1] !== TransactionTypeFilter.SELL
    );
  }, [filters, type]);

  const clearFilters = useCallback(() => {
    switch (type) {
      case TransactionsRouteType.Deposits:
        updateFilters({
          types: [TransactionTypeFilter.DEPOSIT],
          statuses: [],
          searchString: '',
          asset: undefined,
        });
        break;
      case TransactionsRouteType.Withdrawals:
        updateFilters({
          types: [TransactionTypeFilter.WITHDRAWAL],
          statuses: [],
          searchString: '',
          asset: undefined,
        });
        break;
      case TransactionsRouteType.Orders:
      default:
        updateFilters({
          types: [TransactionTypeFilter.BUY, TransactionTypeFilter.SELL],
          statuses: [],
          searchString: '',
          asset: undefined,
        });
        break;
    }
  }, [type, updateFilters]);

  return (
    <Box
      id='transactionsFilters.container'
      sx={{
        marginX: bx({ xs: 0, sm: 1 }),
        paddingX: bx({ xs: 1, sm: 0 }),
        paddingY: bx({ xs: 2, sm: 0 }),
      }}
      width='100%'
      height={bx({ xs: ORDERS_TABLE_MOBILE_FILTER_HEIGHT, sm: ORDERS_TABLE_FILTER_HEIGHT })}
    >
      <Grid container direction='row' spacing={2} alignItems='center' justifyContent='flex-start' width='100%'>
        <LayoutGridItem width='350px' xs={12} sm={showAssets ? 3 : 4}>
          <TransactionsFiltersCalendar />
        </LayoutGridItem>
        {showAssets && (
          <LayoutGridItem minWidth='10%' xs={6} sm={2}>
            <TransactionsFiltersAsset />
          </LayoutGridItem>
        )}
        <LayoutGridItem minWidth='10%' xs={6} sm={2}>
          <TransactionsFiltersTypes disabled={disabled} type={type} />
        </LayoutGridItem>
        <LayoutGridItem minWidth='10%' xs={6} sm={2}>
          <TransactionsFiltersStatus disabled={disabled} />
        </LayoutGridItem>
        <LayoutGridItem xs={12} sm={1} width='100%' textAlign='center' display='flex' alignItems='center'>
          <Button
            id='transactionsFilters.buttons.clear'
            sx={{ color: 'text.primary', fontSize: 14 }}
            disabled={!areFiltersDirty}
            onClick={clearFilters}
          >
            {t('transactionsFilters.buttonLabels.clear')}
          </Button>
        </LayoutGridItem>
      </Grid>
    </Box>
  );
});

TransactionsFilters.displayName = 'TransactionsFilters';

export { TransactionsFilters };
