import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Checkbox, MenuItem, Typography } from '@swyftx/react-web-design-system';

import { TransactionTypeFilter } from '@shared/services';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import { orderTypes } from '../TransactionsFilters.types';

interface Props {
  disabled?: boolean;
  type?: TransactionsRouteType;
}

const TransactionsFiltersTypes: React.FC<Props> = observer(({ disabled, type }) => {
  const { updateFilters, filters } = useContext(TransactionsContext);
  const { t } = useTranslation('orders');

  const getFilterValue = () => {
    let value = '';

    if (!filters.types.length) return t('types.placeholder');

    if (filters.types.length === orderTypes().length) return t('types.all');

    filters.types.forEach((filterType, index) => {
      const foundType = orderTypes().find((t) => t.value === filterType);

      if (foundType) {
        value += t(foundType.label as any);
        if (index < filters.types.length - 1) value += ', ';
      }
    });

    return value;
  };

  return (
    <FormControl sx={{ width: '100%', marginLeft: 0, backgroundColor: 'background.paper' }}>
      <Select<TransactionTypeFilter[]>
        sx={{ width: '100%', height: '40px' }}
        id='transactionsFilters.types.select.label'
        autoWidth
        disabled={disabled || false}
        multiple
        displayEmpty
        value={filters?.types || []}
        onChange={(e) => updateFilters({ types: e.target.value as TransactionTypeFilter[] })}
        renderValue={() => getFilterValue()}
      >
        {orderTypes(type).map((orderType) => {
          const selected = filters.types.indexOf(orderType.value) > -1;
          const shouldDisableItem = orderType.disabled || (filters.types.length === 1 && selected);

          return (
            <MenuItem
              id={`orderFilters.types.select.item.${orderType.label}`}
              key={`orderFilters.types.select.item.${orderType.label}`}
              disabled={shouldDisableItem}
              value={orderType.value}
              dense
              sx={{
                width: '100%',
                '& .MuiFormControlLabel-root': {
                  marginRight: 0,
                },
                justifyContent: 'space-between',
                justifyItems: 'center',
              }}
            >
              <Typography id={`orderFilters.types.select.item.${orderType.label}.label`} sx={{ marginRight: 2 }}>
                {t(orderType.label as any)}
              </Typography>
              <Checkbox id={`orderFilters.types.select.item.${orderType.label}.checkbox`} checked={selected} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
});

TransactionsFiltersTypes.displayName = 'TransactionsFiltersTypes';

export { TransactionsFiltersTypes };
