import React, { useCallback, useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Delete, Edit } from '@swyftx/aviary/icons/outlined';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { TransactionHistoryItem } from '@shared/services';
import { UIStore } from '@shared/store';

import { useFetchOrder } from '@hooks/Orders';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

import { ConfirmDeleteOrderModal } from '../ConfirmDeleteOrderModal';

interface Props {
  order: TransactionHistoryItem;
}

export const OrderActions: React.FC<Props> = (props) => {
  const { order } = props;
  const { openModal } = useModal();
  const { invalidateCache } = useTransactionsCache();
  const { deleteOrder } = useContext(TransactionsContext);
  const { addToastMessage } = UIStore.useUIStore;
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState<boolean>(false);
  const { order: fetchedOrder } = useFetchOrder(order.uuid);

  const onDeleteOrder = useCallback(async () => {
    try {
      if (!order) return;
      await deleteOrder(order.uuid);
      invalidateCache();
      setConfirmDeleteModalOpen(false);
      addToastMessage({ severity: 'success', message: 'Order successfully cancelled' });
    } catch {
      addToastMessage({ severity: 'error', message: 'There was an error deleting the order, please try again' });
    }
  }, [addToastMessage, deleteOrder, invalidateCache, order]);

  const onEditSuccess = useCallback(() => {
    invalidateCache();
  }, [invalidateCache]);

  const onEditOrder = useCallback(() => {
    openModal(Modals.EditTriggerOrders, { order: { ...order, pro: fetchedOrder?.pro }, onSuccess: onEditSuccess });
  }, [onEditSuccess, openModal, order, fetchedOrder]);

  return (
    <FlexLayout direction='row' justifyContent='end' spacing={8}>
      <Button size='sm' variant='outlined' layout='icon' leadingIcon={<Edit />} onClick={onEditOrder} />
      <ConfirmDeleteOrderModal
        open={confirmDeleteModalOpen}
        setOpen={setConfirmDeleteModalOpen}
        confirmDelete={onDeleteOrder}
        triggerElement={
          <Button
            size='sm'
            variant='outlined'
            layout='icon'
            leadingIcon={<Delete />}
            onClick={() => setConfirmDeleteModalOpen(true)}
          />
        }
      />
    </FlexLayout>
  );
};
