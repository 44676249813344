import { useEffect, useState } from 'react';

import { WithdrawalLimit, withdrawalService } from '@shared/services';

export const useWithdrawalLimit = () => {
  const [limits, setLimits] = useState<WithdrawalLimit>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await withdrawalService.getWithdrawalLimit();
        setLimits(res);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    })();
  }, []);

  return { loading, limits, error };
};
