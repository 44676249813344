import React, { useCallback, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Heading, Numeric } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { QuickBuyContext } from 'src/lib/trade/context';
import { useQuickBuyAssets, useQuickBuyReview } from 'src/lib/trade/hooks';
import { useQuickBuyAmounts } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAmounts';

import { QuickBuyWidgetReviewItem } from '../QuickBuyWidgetReview/QuickBuyWidgetReviewItem';

const QuickBuyWidgetFailure: React.FC = () => {
  const { setCurrentStep, setTradeError, tradeError: error, amount } = useContext(QuickBuyContext);
  const { countryAsset } = useQuickBuyAssets();
  const { setAmount } = useQuickBuyAmounts();
  const { fee, total, currentPrice, type, paymentMethod, isBuy } = useQuickBuyReview();

  const onDone = useCallback(() => {
    setAmount('', true);
    setTradeError(undefined);
    setCurrentStep('values');
  }, [setAmount, setCurrentStep, setTradeError]);

  const onRetry = useCallback(() => {
    setTradeError(undefined);
    setCurrentStep('review');
  }, [setCurrentStep, setTradeError]);

  return (
    <FlexLayout direction='column' spacing={24} alignItems='center' justifyContent='start' className='h-full w-full'>
      <FlexLayout direction='column' justifyContent='space-between' className='h-full w-full p-24 pt-32' spacing={32}>
        <FlexLayout direction='column' alignItems='center' spacing={8}>
          <Icon color='destructive' className='h-48 w-48 p-8' icon={<Cross className='h-32 w-32' />} />
          <Heading color='primary' size='h3'>
            Order Failed
          </Heading>
          <Numeric color='secondary'>{error}</Numeric>
        </FlexLayout>
        <FlexLayout direction='column' alignItems='center' spacing={16}>
          <QuickBuyWidgetReviewItem title='Order type' value={type} />
          <QuickBuyWidgetReviewItem title={`${isBuy ? 'Buy' : 'Sell'} price`} value={currentPrice} />
          {paymentMethod && isBuy && <QuickBuyWidgetReviewItem title='Pay with' value={paymentMethod.title} />}
          {!isBuy && <QuickBuyWidgetReviewItem title='Sell to' value={`${countryAsset?.code} Wallet`} />}

          <QuickBuyWidgetReviewItem title='Fee' value={fee} valueClassName='line-through' />
          <QuickBuyWidgetReviewItem title='Total' value={total} valueClassName='line-through' />
          <QuickBuyWidgetReviewItem title='Receive' value={amount} valueClassName='line-through' />
        </FlexLayout>

        <FlexLayout direction='column' className='h-full w-full' justifyContent='end' spacing={16}>
          <Button size='lg' variant='filled' className='w-full' onClick={onRetry}>
            Try again
          </Button>
          <Button size='lg' variant='outlined' className='w-full' onClick={onDone}>
            Cancel
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};

export { QuickBuyWidgetFailure };
