import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ShieldPassFilled } from '@swyftx/aviary/icons/filled';
import { Checkbox, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { SendReceiveCryptoWarning } from '@global-components/Transactions';

import { Asset } from '@shared/api/@types/markets';

type Props = {
  asset: Asset;
  doNotShowAgain: boolean;
  setDoNotShowAgain: (checked: boolean) => void;
};

const InfoItem: React.FC<{ icon: JSX.Element; title: string; subTitle: string }> = ({ icon, title, subTitle }) => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.mode === 'light' ? 'grey.50' : 'grey.800' }} borderRadius={1} padding={2}>
      <Stack direction='row' height='100%' alignItems='flex-start' justifyContent='flex-start' spacing={2}>
        {icon}
        <Stack direction='column' height='100%' alignItems='flex-start' justifyContent='space-evenly' spacing={1}>
          <Typography fontSize={pxToRem(12)} fontWeight={600} color='text.primary'>
            {title}
          </Typography>
          <Typography fontSize={pxToRem(12)} color='text.secondary'>
            {subTitle}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

// TODO design system icons when ghp fixed
export const SendCryptoSetup: React.FC<Props> = ({ asset, doNotShowAgain, setDoNotShowAgain }) => {
  const { t } = useTranslation('wallet');

  return (
    <Stack spacing={2}>
      <Typography color='text.secondary' fontSize={pxToRem(16)}>
        {t('withdrawCrypto.models.setup.title', { name: asset.name })}
      </Typography>
      <InfoItem
        icon={<ShieldPassFilled />}
        title={t('withdrawCrypto.models.infoItemVerification.title')}
        subTitle={t('withdrawCrypto.models.infoItemVerification.subTitle')}
      />
      <SendReceiveCryptoWarning type='send' />
      <Checkbox
        value={doNotShowAgain}
        label={t('withdrawCrypto.models.hideCheckBox')}
        onChange={(_, checked) => setDoNotShowAgain(checked)}
      />
    </Stack>
  );
};
