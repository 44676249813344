import { useCallback } from 'react';

import { Modals } from '@global-components/Modals/Modals.enum';

import { UIStore } from '@shared/store';

export const useModal = () => {
  const { setGlobalModalData } = UIStore.useUIStore;

  const openModal = useCallback(
    (modal: Modals, props?: any) => {
      setGlobalModalData({ modal, props });
    },
    [setGlobalModalData],
  );

  const closeModal = useCallback(() => setGlobalModalData(), [setGlobalModalData]);

  return {
    openModal,
    closeModal,
  };
};
