import React, { useContext, useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { Body, Numeric } from '@swyftx/aviary/atoms/Typography';
import { Swap } from '@swyftx/aviary/icons/outlined';
import { HeroInput } from '@swyftx/aviary/molecules/HeroInput';

import { Asset, AssetType } from '@shared/api';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { QuickBuyContext } from 'src/lib/trade/context';
import { useTradeValue } from 'src/lib/trade/hooks/General';
import { useQuickBuyAnalytics } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAnalytics';

type Props = {
  limitAsset: Asset;
  nonLimitAsset: Asset;
  selectedAsset: Asset;
  amount: string;
  error?: boolean;
  nonLimitAmount: string;
  onChange?: (newValue?: string) => void;
  setAmount: (newAmount: string, forceValue: boolean) => void;
  swapLimitAsset: () => void;
};

const QuickBuyInput: React.FC<Props> = observer(
  ({
    nonLimitAsset,
    limitAsset,
    selectedAsset,
    amount,
    setAmount,
    onChange,
    nonLimitAmount,
    swapLimitAsset,
    error,
  }) => {
    const { buyType, setTabValue } = useContext(QuickBuyContext);
    const { parseTradeValue, formattedAmount } = useTradeValue({ limitAsset, amount });
    const { getRate } = RatesStore.useRatesStore;
    const { buyLiquidityFlag, sellLiquidityFlag } = getRate(selectedAsset);
    const { trackUpdateTradeAmount } = useQuickBuyAnalytics();

    const onChangeValue = (value: string) => {
      //Strip 0's from the front of the value unless there is a '.' after the 0's
      let strippedValue = value.replace(/^0+(?!\.|$)/, '').replace(/,/g, '');
      const strippedSplit = strippedValue.split('');

      // Check if all values are 0 if they are, just clear the input
      if (strippedSplit.every((val) => val === '0') && strippedSplit.length > 1) {
        setTabValue({ value: '', assetCode: '' }, { value: '', assetCode: '' });
        setAmount('', true);
        onChange && onChange('');
        return;
      }

      // Add a 0 to the front of the value if it starts with a '.'
      if (strippedValue.startsWith('.')) {
        strippedValue = '0' + strippedValue;
      }

      // Prevent users from entering '00000' etc
      if (strippedValue.length > 1 && strippedValue.split('').every((v) => v === '0')) return;

      const unformattedValue = strippedValue.replace(/,/g, '');

      const newValue = parseTradeValue(unformattedValue);
      if (newValue !== undefined) setAmount(newValue, true);

      // clear tab and tab history on any input change
      setTabValue({ value: '', assetCode: '' }, { value: '', assetCode: '' });
      onChange && onChange(newValue);
    };

    const inputColor = useMemo(() => (error ? 'text-color-text-error' : 'text-color-text-accent'), [error]);
    const flipDisabled = useMemo(
      () => (buyType == 'buy' && buyLiquidityFlag) || (buyType === 'sell' && sellLiquidityFlag),
      [buyLiquidityFlag, buyType, sellLiquidityFlag],
    );

    const placeholder = useMemo(() => {
      if (limitAsset.assetType === AssetType.Crypto) {
        if (limitAsset.price_scale === 0) return '0';

        return '0.'.padEnd(limitAsset.price_scale + 2, '0');
      }

      return '0.00';
    }, [limitAsset]);

    return (
      <FlexLayout direction='column' spacing={8} alignItems='center' className='pt-8'>
        <FlexLayout spacing={4} justifyContent='center' alignItems='center'>
          <Body size='small' color={error ? 'error' : 'accent'} weight='bold' className='!font-mono'>
            {`${limitAsset.code}${limitAsset.assetType === AssetType.Fiat ? '$' : ''}`}
          </Body>
          <HeroInput
            autoFocus
            asset={limitAsset}
            className={inputColor}
            width='auto'
            value={formattedAmount}
            onChange={(e) => onChangeValue(e.target.value)}
            onBlur={(e) => trackUpdateTradeAmount(e.target.value)}
            placeholder={placeholder}
          />
        </FlexLayout>
        <Tooltip
          title={flipDisabled ? 'Unable to change the limit asset when trading against a low liquidity asset' : ''}
        >
          <Button
            variant='outlined'
            size='sm'
            onClick={swapLimitAsset}
            disabled={flipDisabled}
            className='w-auto'
            leadingIcon={<Swap className='h-20 w-20' />}
          >
            {nonLimitAsset.code}
          </Button>
        </Tooltip>
        <Numeric color='secondary' size='small' weight='emphasis' className='text-center'>
          ≈ {formatCurrency(nonLimitAmount, nonLimitAsset, { hideCode: true, appendCode: false }, undefined, true)}{' '}
          {nonLimitAsset.code}
        </Numeric>
      </FlexLayout>
    );
  },
);

export { QuickBuyInput };
