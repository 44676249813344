import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';

import { Cross, SwyftxCompact } from '@swyftx/aviary/icons/outlined';
import { Button, Typography } from '@swyftx/react-web-design-system';

import { AuthContext } from '@routes/Auth/Auth.context';
import TickCircleFilledIcon from '@routes/Auth/assets/TickCircleFilledIcon';

import { observer } from 'mobx-react-lite';
import { SuperheroCircleLogo } from 'src/assets';

type AllowItemProps = {
  text: string;
};

const AllowItem = ({ text }: AllowItemProps) => (
  <Stack direction='row' gap='12px'>
    <TickCircleFilledIcon color='primary' width='20px' height='20px' sx={{ marginLeft: '8px' }} />
    <Typography fontSize='14px' fontWeight={400}>
      {text}
    </Typography>
  </Stack>
);

const Success: React.FC = observer(() => {
  const { email, token, postMessage } = useContext(AuthContext);

  const { t } = useTranslation('auth');
  const [reticulatingSplines, setReticulatingSplines] = useState(false);

  const onContinue = () => {
    if (!reticulatingSplines) {
      setReticulatingSplines(true);
      postMessage({
        type: 'success',
        token,
      });
    }
  };

  return (
    <Stack direction='column' sx={{ width: '100%', height: '100%', flex: 1 }} gap='24px'>
      <Stack direction='column' alignItems='center' gap='16px'>
        <Stack direction='row' alignItems='center' marginBottom='16px'>
          <SwyftxCompact className='h-[56px]' />
          <Cross className='ml-[10px] mr-[20px] h-20 w-20' />
          <SuperheroCircleLogo id='superhero-logo' alt='Superhero' width='48px' height='48px' />
        </Stack>
        <Typography fontSize='14px' fontWeight={400}>
          {t('success.request.reason')}
        </Typography>
        <Typography fontSize='14px' fontWeight={400}>
          {t('success.request.signedInAs', { email })}
        </Typography>
      </Stack>
      <Stack gap='12px'>
        <Typography fontSize='16px' fontWeight={600}>
          {t('success.request.allow')}
        </Typography>
        <AllowItem text={t('success.request.accountDetails')} />
        <AllowItem text={t('success.request.personalInformation')} />
      </Stack>
      <Button variant='contained' sx={{ height: '48px' }} onClick={onContinue} loading={reticulatingSplines}>
        {t('success.buttonLabels.continue')}
      </Button>
    </Stack>
  );
});

Success.displayName = 'Success';

export { Success };
