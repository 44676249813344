export enum Modals {
  ChangePassword = 'change-password',
  DemoModeLearnMore = 'demo-learn-more',
  EditTriggerOrderAnnouncement = 'edit-trigger-order-announcement',
  DisableTwoFactor = 'disable-two-factor',
  EditTriggerOrders = 'edit-trigger-orders',
  EnableTwoFactor = 'enable-two-factor',
  SMSRecovery = 'sms-recovery',
  UpdateEmailAddress = 'update-email',
  UpdatePhone = 'update-phone',
  VerifyPhone = 'verify-phone',
  WhatsNewModal = 'whats-new-modal',
  OrderDetails = 'order-details',
  OTCTradesPermissionModal = 'otc-trades-permission-modal',
  TransactionDetails = 'transaction-details',
  DepositReceive = 'deposit-receive',
  WithdrawSend = 'withdraw-send',
  RestrictedModal = 'restricted',
  PriceAlerts = 'price-alerts',
  NetworkFees = 'network-fees',
  StripeCardDeposit = 'stripe-card-deposit',
  AffiliateSignUp = 'affiliate-sign-up',
  DustingModal = 'dusting',
  NextDustTimeModal = 'next-dust-time',
  HighSlippageReviewModal = 'high-slippage-review-modal',
  ProfitStopLossModal = 'profit-stop-loss-modal',
  ProfitStopLossSuccessModal = 'profit-loss-success-modal',
  AboutCategory = 'about-category',
  MultiTradeLimitation = 'multi-trade-limitation',
  DepositAddressChangesModal = 'deposit-address-changes',

  /* Rewards */
  RedeemSwyftxReward = 'redeem-swyftx-reward',
  RedeemSwyftxEntry = 'redeem-swyftx-entry',
  SwitchAccount = 'switch-account',

  DashboardLayoutSelector = 'dashboard-layout-selector',
  GlobalSearch = 'global-search',
}
