import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { assetService } from '@shared/services';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

import { WalletListRowContainer, WalletListTradingRow } from './components';

type WalletListRowProps = {
  id: string;
  assetId: number;
  walletType: WalletType;
};

const WalletListRow: React.FC<WalletListRowProps> = observer(({ id, walletType, assetId }) => {
  const { t } = useTranslation('wallet');
  const asset = assetService.getAsset(assetId);
  const baseAsset = useBaseAsset();
  const assetBalanceData = asset && useAssetBalance(asset);

  if (!asset || !assetBalanceData || !baseAsset) {
    return (
      <WalletListRowContainer id={id}>
        <FlexLayout direction='column' className='h-full w-full' justifyContent='center' alignItems='center'>
          <Chip size='md' color='secondary'>
            {t('walletList.assetUnavailable')}: {assetId}
          </Chip>
        </FlexLayout>
      </WalletListRowContainer>
    );
  }

  return (
    <WalletListRowContainer id={id} code={asset.code}>
      <WalletListTradingRow
        asset={asset}
        assetBalanceData={assetBalanceData}
        baseAsset={baseAsset}
        balanceKey={walletType === WalletType.ALL_WALLETS ? 'all' : 'availableBalance'}
        assetBalanceKey={walletType === WalletType.ALL_WALLETS ? 'all' : 'trading'}
      />
    </WalletListRowContainer>
  );
});

WalletListRow.displayName = 'WalletListRow';

export { WalletListRow };
