import { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { useFeatureDiscoveryFeatures } from '../hooks/useFeatureDiscoveryFeatures';
import { PlatformFeature } from '../types/FeatureDiscovery.types';

type FeatureDiscoveryContextType = {
  features: PlatformFeature[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
  minimized: boolean;
  setMinimized: Dispatch<SetStateAction<boolean>>;
};

const FeatureDiscoveryContext = createContext<FeatureDiscoveryContextType>({
  features: [],
  show: false,
  showAll: false,
  minimized: false,
  setShow: () => {},
  setShowAll: () => {},
  setMinimized: () => {},
});

const FeatureDiscoveryProvider: React.FC<PropsWithChildren> = observer(({ children }) => {
  const [features, setFeatures] = useState<PlatformFeature[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [minimized, setMinimized] = useState<boolean>(false);

  const discoveryFeatures = useFeatureDiscoveryFeatures({ setMinimized });

  useEffect(() => setFeatures(discoveryFeatures), [discoveryFeatures]);

  return (
    <FeatureDiscoveryContext.Provider value={{ features, show, setShow, showAll, setShowAll, minimized, setMinimized }}>
      {children}
    </FeatureDiscoveryContext.Provider>
  );
});

export { FeatureDiscoveryProvider, FeatureDiscoveryContext };
