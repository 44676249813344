import React from 'react';

import { Stack } from '@swyftx/react-web-design-system';

import { TransactionHistoryItem } from '@shared/services';

import { TransactionsRouteType } from '@routes/Transactions/types';

import {
  OrdersTableActions,
  OrdersTableAddress,
  OrdersTableAmount,
  OrdersTableAsset,
  OrdersTableDate,
  OrdersTableExchangeRate,
  OrdersTableFee,
  OrdersTableTotal,
} from './columns';

type Props = {
  item: TransactionHistoryItem;
  widthPerValueColumn: number;
  type?: TransactionsRouteType;
};

const OrdersTableItemDesktop: React.FC<Props> = ({ item, widthPerValueColumn, type }) => (
  <Stack
    id='ordersTable.item'
    direction='row'
    alignItems='center'
    justifyContent='flex-start'
    width='100%'
    height='90px'
    borderRadius={1}
  >
    <OrdersTableDate item={item} width={250} />
    <OrdersTableAsset item={item} width={80} />
    {!item.orderType && <OrdersTableAddress item={item} width={widthPerValueColumn} type={type} />}
    {item.orderType && <OrdersTableAmount item={item} width={widthPerValueColumn} type={type} />}
    {item.orderType && <OrdersTableExchangeRate item={item} width={widthPerValueColumn} type={type} />}
    <OrdersTableFee item={item} width={widthPerValueColumn} type={type} />
    {item.orderType && <OrdersTableTotal item={item} width={widthPerValueColumn} type={type} />}
    {!item.orderType && <OrdersTableAmount item={item} width={widthPerValueColumn} type={type} />}
    <OrdersTableActions item={item} width={80} />
  </Stack>
);

export { OrdersTableItemDesktop };
