import React from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { DayPicker, DayPickerProps } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const CalendarPicker: React.FC<DayPickerProps> = (props) => {
  const ixXs = useTailwindBreakpoint('xs');

  return (
    <DayPicker
      {...props}
      classNames={{
        dropdown: 'rdp-dropdown hover:!bg-color-background-surface-hover',
        caption_label: 'rdp-caption_label',
        day: 'text-color-text-primary !font-mono !text-14 w-36 h-36 hover:!bg-color-background-surface-hover rounded-[100%] focus:focus-area-info focus-visible:!border-none focus:!bg-color-background-surface-hover',
        day_today:
          'text-color-text-primary !font-mono !text-14 w-36 h-36 hover:bg-color-background-surface-hover rounded-[100%]',
        day_selected: '!bg-color-background-primary-subtle !font-mono !text-color-text-accent rounded-[100%] font-400',
        day_disabled: 'text-color-disabled',
        head: 'text-color-text-secondary text-10 font-400',
        caption: 'p-0 flex flex-row items-center justify-between',
        table: 'w-full',
        month: ixXs
          ? ' w-full'
          : 'bg-color-background-surface-primary p-16 text-card-foreground outline-color-border-main outline outline-1 rounded-16 shadow-sm',
        nav: 'flex flex-row gap-4 items-center',
        nav_button_previous:
          'focus:focus-area-info focus-visible:!border-none outline-1 outline outline-color-border-main text-color-text-secondary !w-20 !h-20 hover:!bg-color-background-surface-hover focus:!bg-color-background-surface-hover',
        nav_icon: 'text-color-text-secondary',
        nav_button_next:
          'focus:focus-area-info focus-visible:!border-none outline-1 outline outline-color-border-main text-color-text-secondary !w-20 !h-20 hover:!bg-color-background-surface-hover focus:!bg-color-background-surface-hover',
      }}
    />
  );
};

export { CalendarPicker };
