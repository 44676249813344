import React, { useMemo } from 'react';

import Box from '@mui/material/Box';

import { Body } from '@swyftx/aviary/atoms/Typography';
import { Card, Notification, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { TradeData, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';

import { observer } from 'mobx-react-lite';

import { Balance, TradeInputVariant } from './TradeInput.data';
import {
  TradeInputFlip,
  TradeInputHeader,
  TradeInputNonLimitRate,
  TradeInputSlider,
  TradeInputValue,
  TradeInputZeroBalance,
} from './components';

type Props = {
  variant?: TradeInputVariant;
  title?: string;
  tradeData?: TradeData;
  asset: Asset;
  baseAsset: Asset;
  assetToDisplay?: Asset;
  error?: string;
  clientSideError?: string;
  flippable?: boolean;
  showBalances?: boolean;
  showEnterAmountBanner?: boolean;
  balanceToUse: Balance;
  value: string;
  slider?: boolean;
  maxValue?: string;
  disabled?: boolean;
  onRemove?: (data?: TradeData) => void;
  lockable?: boolean;
  minPercentage?: number;
  tradeType?: TradeType;
  maxPercentage?: number;
  isOverLowLiquidityThreshold?: boolean;
};

export type TradeInputOnChangeValue = {
  [key: string]: string;
  value: string;
};

// This component will eventually replace TradeInput
const TradeInput: React.FC<Props> = observer(
  ({
    variant = 'singleTrade',
    title,
    asset,
    error,
    clientSideError,
    baseAsset,
    assetToDisplay,
    tradeType,
    balanceToUse,
    slider,
    showEnterAmountBanner,
    lockable,
    showBalances = true,
    flippable = true,
    maxValue = '',
    disabled = false,
    onRemove,
    tradeData,
    minPercentage = 0,
    maxPercentage = 100,
    isOverLowLiquidityThreshold = false,
  }) => {
    const displayAsset = useMemo(() => assetToDisplay ?? asset, [asset, assetToDisplay]);
    const tradeDataKey = useMemo(() => `${asset.id}_${baseAsset.id}`, [baseAsset, asset]);

    const { trading, staking } = useAssetBalance(asset);
    const assetBalance = useMemo(
      () => (balanceToUse === Balance.Staking ? staking : trading),
      [balanceToUse, staking, trading],
    );
    const showZeroBalanceState =
      tradeType === TradeType.Instantly && !Big(assetBalance.balance).gt(0) && assetService.canDepositAsset(asset);

    const content = (() => {
      if (showZeroBalanceState) {
        return <TradeInputZeroBalance asset={asset} />;
      }

      return (
        <Stack spacing={1}>
          <Stack direction='row' alignItems='center'>
            <TradeInputValue tradeDataKey={tradeDataKey} balanceToUse={balanceToUse} />
          </Stack>
          {error && !clientSideError && !isOverLowLiquidityThreshold && (
            <Typography fontSize={12} fontWeight={400} color='error'>
              {error}
            </Typography>
          )}
          {clientSideError && (
            <Typography fontSize={12} fontWeight={400} color='error'>
              {clientSideError}
            </Typography>
          )}
          <TradeInputNonLimitRate tradeDataKey={tradeDataKey} />
          {slider && (
            <TradeInputSlider
              tradeDataKey={tradeDataKey}
              displayAsset={displayAsset}
              balanceToUse={balanceToUse}
              maxValue={maxValue}
              lockable={lockable}
              minPercentage={minPercentage}
              maxPercentage={maxPercentage}
            />
          )}

          {showEnterAmountBanner && (
            <Notification
              title='Enter an amount above'
              severity='info'
              sx={{ '.MuiTypography-root': { fontSize: 14, marginBottom: 0 }, height: 36, alignItems: 'center' }}
            />
          )}
        </Stack>
      );
    })();

    if (variant === 'multiTrade') {
      return (
        <Card
          elevation={0}
          variant='outlined'
          sx={{
            borderRadius: '8px',
            opacity: disabled ? 0.3 : 1,
            pointerEvents: disabled ? 'none' : 'auto',
            width: '100%',
          }}
          contentSx={{
            padding: 1.5,
            '&:last-child': { pb: 1.5 },
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            {displayAsset && (
              <Stack direction='row' alignItems='center' spacing={1} width='calc(100% - 120px)'>
                <AssetIcon asset={displayAsset} size={20} />
                <Typography fontSize={14} fontWeight={500} color='text.primary' width='100%' className='truncate'>
                  {displayAsset.name}
                </Typography>
              </Stack>
            )}
            <TradeInputFlip
              flippable={flippable}
              tradeDataKey={tradeDataKey}
              tradeData={tradeData}
              onRemove={onRemove}
            />
          </Stack>

          <Stack direction='column' spacing={1}>
            <TradeInputHeader from={asset.id} balanceToUse={balanceToUse} showBalances={showBalances} />
            <Box>{content}</Box>
          </Stack>
        </Card>
      );
    }

    return (
      <Stack spacing={0.25}>
        <Stack direction='row' alignItems='center' justifyContent={!title ? 'flex-end' : 'space-between'}>
          {title && <Body color='primary'>{title}</Body>}
          {flippable && <TradeInputFlip tradeDataKey={tradeDataKey} tradeData={tradeData} flippable={flippable} />}
        </Stack>

        <Stack direction='column' spacing={1}>
          <TradeInputHeader from={asset.id} balanceToUse={balanceToUse} showBalances={showBalances} />
          <Card
            elevation={0}
            variant='outlined'
            sx={{
              borderRadius: '8px',
              opacity: disabled ? 0.3 : 1,
              pointerEvents: disabled ? 'none' : 'auto',
            }}
            contentSx={{
              padding: 1.5,
              '&:last-child': { pb: 1.5 },
            }}
          >
            {content}
          </Card>
        </Stack>
      </Stack>
    );
  },
);

export { TradeInput };
