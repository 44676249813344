import { NavigationURLs } from 'src/lib/navigation/types';

export type TransactionsTabType = {
  id: string;
  type: 'orders' | 'deposits' | 'withdrawals';
  label: any;
  path?: NavigationURLs;
};

export const tabItems: TransactionsTabType[] = [
  {
    id: 'transactionsTabs.buttons.orders',
    path: NavigationURLs.TransactionsOrders,
    type: 'orders',
    label: 'transactionsTabs.buttonLabels.orders',
  },
  {
    id: 'transactionsTabs.buttons.deposits',
    path: NavigationURLs.TransactionsDeposits,
    type: 'deposits',
    label: 'transactionsTabs.buttonLabels.deposits',
  },
  {
    id: 'transactionsTabs.buttons.withdrawals',
    path: NavigationURLs.TransactionsWithdrawals,
    type: 'withdrawals',
    label: 'transactionsTabs.buttonLabels.withdrawals',
  },
];
