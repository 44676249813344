import React, { useCallback, useEffect, useRef, useState } from 'react';

import { StackProps, TypographyProps } from '@mui/material';

import { Button, ComponentProps, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

type Props = {
  containerProps?: StackProps;
  delay: number;
  delayText: string;
  restartText?: string;
  disabled?: boolean;
  onTimerFinished?: () => void;
  onTimerRestart?: () => void;
  typographyProps?: TypographyProps;
} & Omit<ComponentProps, 'children'>;

const CountdownDelay: React.FC<Props> = ({
  id,
  containerProps,
  disabled = false,
  delay,
  delayText,
  restartText,
  fullWidth,
  onTimerFinished,
  onTimerRestart,
  typographyProps,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState<number>(delay);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  // maintain a ref to the most up-to-date `onTimerFinished`
  const onTimerFinishedRef = useRef(onTimerFinished);
  onTimerFinishedRef.current = onTimerFinished;

  useEffect(() => {
    setSecondsRemaining(delay);
  }, [delay]);

  const onRestart = useCallback(() => {
    setSecondsRemaining(delay);

    if (onTimerRestart) onTimerRestart();
  }, [onTimerRestart]);

  useEffect(() => {
    if (secondsRemaining <= 0) {
      if (onTimerFinishedRef.current) onTimerFinishedRef.current();
      return undefined;
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;
      setSecondsRemaining((t) => t - 1);
    }, 1000);

    return () => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
    };
  }, [secondsRemaining]);

  const isDelayed = secondsRemaining > 0;

  return (
    <Stack direction='row' width={fullWidth ? '100%' : 'auto'} {...containerProps}>
      {isDelayed && (
        <Typography
          id={`${id}-delay`}
          sx={{ width: '100%' }}
          color='text.disabled'
          // Avoid layout jumps when switching to button
          margin={1}
          // Same text styling when switching to button
          fontWeight={500}
          fontSize={pxToRem(14)}
          {...typographyProps}
        >
          {delayText} {secondsRemaining}s
        </Typography>
      )}
      {!isDelayed && (
        <Button
          variant='text'
          sx={{ marginTop: '0rem', marginBottom: '0.5px' }}
          onClick={onRestart}
          disabled={disabled}
        >
          {restartText ?? delayText}
        </Button>
      )}
    </Stack>
  );
};

export { CountdownDelay };
