import React from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { BuySellButtons } from '@global-components/BuySellButtons';

import { Asset } from '@shared/api';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

interface SingleWalletTradingInfoLinksProps {
  asset: Asset;
}

const SingleWalletTradingInfoLinks: React.FC<SingleWalletTradingInfoLinksProps> = observer(({ asset }) => {
  const { navigate } = useNavigateRoute();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  return (
    <FlexLayout direction='row' className='w-full items-center justify-center' spacing={16}>
      <BuySellButtons asset={asset} openTradePanel />
      {isFeatureEnabled(AppFeature.QuickBuy) && (
        <Button
          className='w-1/2'
          onClick={() => navigate(NavigationURLs.QuickBuy, { state: { assetCode: asset.code } })}
          disabled={!asset.tradable}
        >
          Quick Buy
        </Button>
      )}
    </FlexLayout>
  );
});

export { SingleWalletTradingInfoLinks };
