import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Stack, Tooltip } from '@swyftx/react-web-design-system';

import {
  useProfitStopLossOrderDetails,
  useProfitStopLossAnalytics,
  useProfitStopLossOrder,
  useProfitStopLossSummary,
  useProfitStopLossTours,
  useProfitStopLossTriggerType,
  useProfitStopLossValueCalculation,
  useProfitStopLossAssets,
} from '@global-components/Modals/ProfitStopLoss/ProfitStopLossOrderModal/hooks';
import { TriggerType } from '@global-components/Modals/ProfitStopLoss/types/profitStopLoss.types';

import { Big } from '@shared/safe-big';

import { useScreenBreakpoints } from '@hooks/Layout/useScreenBreakpoints';
import { useFetchOrder } from '@hooks/Orders';
import { useCurrentPrice } from '@hooks/Trade/useCurrentPrice';

import { observer } from 'mobx-react-lite';

import {
  ProfitStopLossTriggerType,
  ProfitStopLossValueCalculationMethod,
  ProfitStopLossValueCalculationValue,
  ProfitStopLossAmount,
  ProfitStopLossLinkedOrder,
  ProfitStopLossSummary,
  ProfitLossPlaceOrderSummary,
} from './components';

type Props = {
  orderUuid: string;
  focusedTriggerType?: TriggerType;
  onClose: () => void;
};

const ProfitStopLossOrderModal: React.FC<Props> = observer(({ orderUuid, focusedTriggerType, onClose }) => {
  const [reviewOrder, setReviewOrder] = useState<boolean>(false);
  const { order } = useFetchOrder(orderUuid);
  const { t } = useTranslation('modals', { keyPrefix: 'profitStopLoss' });
  const { isLargeScreen } = useScreenBreakpoints();

  const { trackProfitStopLossClosed, trackProfitStopLossStarted } = useProfitStopLossAnalytics();
  const { primaryAsset, secondaryAsset } = useProfitStopLossAssets(order);
  const currentPrice = useCurrentPrice(primaryAsset, secondaryAsset, 'bidPrice');
  const { triggerType, setTriggerType } = useProfitStopLossTriggerType(focusedTriggerType);
  const {
    profitLossValueMethod,
    profitLossValueMeasurement,
    profitLossValue,
    setProfitLossValueMethod,
    setProfitLossValueMeasurement,
    setProfitLossValue,
  } = useProfitStopLossValueCalculation({ currentPrice });

  const orderDetails = useProfitStopLossOrderDetails(order);

  const amount = Big(orderDetails?.amount);
  const feeAmount = Big(orderDetails?.feeAmount);
  const purchasePrice = Big(orderDetails?.purchasePrice);

  const {
    estimatedFee,
    estimatedProfitDollars,
    estimatedProfitPercentage,
    estimatedTotal,
    feePercentage,
    triggerPrice,
  } = useProfitStopLossSummary({
    profitLossValue,
    profitLossValueMeasurement,
    profitLossValueMethod,
    triggerType,
    purchasePrice: Big(orderDetails?.purchasePrice),
    amount,
    feeAmount: Big(orderDetails?.feeAmount),
  });

  const { validOrder, executingOrder, executeOrder } = useProfitStopLossOrder({
    secondaryAsset,
    primaryAsset,
    triggerType,
    currentPrice,
    amount: Big(orderDetails?.amount),
    triggerPrice,
    relatedOrderUuid: orderUuid,
    profitLossValueMethod,
    profitLossValueMeasurement,
    estimatedProfitPercentage,
    estimatedProfitDollars,
    estimatedFee,
    profitLossValue,
  });

  const { startModalTour } = useProfitStopLossTours({ profitLossValueMethod, setProfitLossValueMethod });

  useEffect(() => {
    trackProfitStopLossStarted();
  }, []);

  useEffect(() => {
    if (order) startModalTour();
  }, [order, setProfitLossValueMethod, startModalTour]);

  const onCloseModal = () => {
    trackProfitStopLossClosed();
    onClose();
  };

  if (!orderDetails || !orderDetails.amount || !orderDetails.purchasePrice || !orderDetails.feeAmount) return null;

  return (
    <Modal
      id='tradePrompt-modal'
      open
      variant='BottomSheet'
      onClose={onCloseModal}
      HeaderProps={{
        dismissible: true,
        onInfo: reviewOrder || !isLargeScreen ? undefined : () => startModalTour(true),
        infoTooltip: t('labels.infoTooltip'),
        title: reviewOrder
          ? t(triggerType === TriggerType.StopLoss ? 'titles.summaryStopLoss' : 'titles.summaryProfit')
          : t('titles.triggerOrder'),
        divider: true,
      }}
      sx={{
        padding: 0,
        width: '600px',
        height: '85%',
        '.MuiCardContent-root': { height: '100%', padding: 0 },
        '.MuiCardContent-root > .MuiBox-root': { height: '100%' },
        '.MuiCardContent-root > .MuiBox-root ~ .MuiBox-root': { height: 'auto' },
        '.MuiBox-root': { padding: 0 },
      }}
      FooterProps={{
        divider: true,
        content: (
          <Stack direction='row' spacing={1} justifyContent='flex-end' alignItems='center' width='100%' padding={2}>
            {!reviewOrder && (
              <Stack
                id='profit-stop-loss-summary'
                data-spotlightelementid='profit-stop-loss-summary'
                width='100%'
                spacing={2}
              >
                <ProfitStopLossSummary
                  triggerType={triggerType}
                  triggerPrice={triggerPrice}
                  feePercentage={feePercentage}
                  estimatedFee={estimatedFee}
                  estimatedProfitPercentage={estimatedProfitPercentage}
                  estimatedProfitDollars={estimatedProfitDollars}
                  primaryAsset={primaryAsset}
                  secondaryAsset={secondaryAsset}
                  validOrder={validOrder}
                />
                <Button
                  disabled={!validOrder}
                  id='button-review-order'
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  onClick={() => setReviewOrder(true)}
                >
                  {t('buttonLabels.reviewOrder')}
                </Button>
              </Stack>
            )}
            {reviewOrder && (
              <>
                <Button
                  id='button-review-order'
                  color='inherit'
                  size='large'
                  fullWidth
                  onClick={() => setReviewOrder(false)}
                >
                  {t('buttonLabels.back')}
                </Button>
                <Tooltip
                  title={
                    validOrder
                      ? null
                      : t(
                          triggerType === TriggerType.StopLoss
                            ? 'labels.invalidTradeTooltipLower'
                            : 'labels.invalidTradeTooltipHigher',
                          { code: secondaryAsset?.code },
                        )
                  }
                  placement='top'
                >
                  <span style={{ width: '100%' }}>
                    <Button
                      disabled={!validOrder}
                      id='button-review-order'
                      variant='contained'
                      color='primary'
                      size='large'
                      loading={executingOrder}
                      fullWidth
                      onClick={executeOrder}
                    >
                      {t('buttonLabels.placeOrder')}
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
          </Stack>
        ),
      }}
    >
      <Stack direction='column' spacing={2} mb={2}>
        {reviewOrder && (
          <ProfitLossPlaceOrderSummary
            triggerType={triggerType}
            primaryAsset={primaryAsset}
            secondaryAsset={secondaryAsset}
            amount={amount}
            feePercentage={feePercentage}
            estimatedTotal={estimatedTotal}
            triggerPrice={triggerPrice}
            estimatedFee={estimatedFee}
            estimatedProfitPercentage={estimatedProfitPercentage}
            estimatedProfitDollars={estimatedProfitDollars}
          />
        )}

        {!reviewOrder && (
          <>
            <ProfitStopLossLinkedOrder
              primaryAsset={primaryAsset}
              secondaryAsset={secondaryAsset}
              amount={amount}
              purchasePrice={purchasePrice}
            />

            {/* Take Profit/ Stop loss Toggle */}
            <ProfitStopLossTriggerType
              triggerType={triggerType}
              setTriggerType={setTriggerType}
              setProfitLossValue={setProfitLossValue}
            />

            <Stack id='profit-loss-container' data-spotlightelementid='profit-loss-container' spacing={1}>
              {/*  Set Profit target ($,%,Price) */}
              <ProfitStopLossValueCalculationMethod
                triggerType={triggerType}
                profitLossValueMethod={profitLossValueMethod}
                setProfitLossValueMethod={setProfitLossValueMethod}
              />

              {/*  Input field $ amount, label is "Price to take Profit at (trigger price) */}
              <ProfitStopLossValueCalculationValue
                amount={amount}
                feeAmount={feeAmount}
                feePercentage={feePercentage}
                profitLossValueMeasurement={profitLossValueMeasurement}
                profitLossValueMethod={profitLossValueMethod}
                setProfitLossValueMeasurement={setProfitLossValueMeasurement}
                setProfitLossValue={setProfitLossValue}
                profitLossValue={profitLossValue}
                purchasePrice={purchasePrice}
                currentPrice={currentPrice}
                triggerPrice={triggerPrice}
                triggerType={triggerType}
                primaryAsset={primaryAsset}
                secondaryAsset={secondaryAsset}
                estimatedProfitPercentage={estimatedProfitPercentage}
              />
            </Stack>

            {/* Amount of Asset */}
            <ProfitStopLossAmount secondaryAsset={secondaryAsset} amount={amount} />
          </>
        )}
      </Stack>
    </Modal>
  );
});

export { ProfitStopLossOrderModal };
