import { useCallback, useContext, useMemo } from 'react';

import { RateSide } from '@shared/api';
import { DEFAULT_PRICE_SCALE } from '@shared/constants';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';

import { usePortfolioBalance } from 'src/lib/portfolio/hooks/usePortfolioBalance';
import { useCurrentUserFees } from 'src/lib/user-profile/hooks/useCurrentUserFees';

import { useQuickBuyAnalytics } from './useQuickBuyAnalytics';
import { useQuickBuyAssets } from './useQuickBuyAssets';
import { QuickBuyContext } from '../../context';
import { useTradeValue } from '../General';

const useQuickBuyAmounts = () => {
  const { amount, setAmount, setLimitAssetCode, buyType, tradeQuote, tabValue, setTabValue } =
    useContext(QuickBuyContext);
  const { nonLimitAsset, limitAsset, countryAsset, selectedAsset } = useQuickBuyAssets();
  const { trackSwitchedLimitAsset } = useQuickBuyAnalytics();
  const { getCountryBalance } = usePortfolioBalance();
  const { convertRate } = RatesStore.useRatesStore;
  const { parseTradeValue: parseNonLimitTradeValue } = useTradeValue({ limitAsset: nonLimitAsset });
  const { parseTradeValue: parseLimitTradeValue } = useTradeValue({ limitAsset });
  const { lowestFee } = useCurrentUserFees();

  const countryBalance = useMemo(() => getCountryBalance(), [getCountryBalance]);
  const side: RateSide = useMemo(() => (buyType === 'buy' ? 'askPrice' : 'bidPrice'), [buyType]);

  const nonLimitAmount = useMemo(() => {
    if (!limitAsset || !nonLimitAsset || !tradeQuote || !countryAsset) return '';

    let value;

    if (buyType === 'buy') {
      value = limitAsset.id === countryAsset.id ? tradeQuote.amount : tradeQuote.total;
    } else {
      value = limitAsset.id === countryAsset.id ? tradeQuote.total : tradeQuote.amount;
    }

    return parseNonLimitTradeValue(value.toString(), true) || '0';
  }, [buyType, countryAsset, tradeQuote, limitAsset, nonLimitAsset, parseNonLimitTradeValue]);

  const updateAmount = useCallback(
    (newAmount: string, forceSet = false) => {
      if (!limitAsset || !countryAsset || !nonLimitAsset || !selectedAsset) return;
      if (!newAmount) {
        setAmount('');
        return;
      }

      if (
        (buyType === 'buy' && limitAsset.id === countryAsset.id) ||
        (buyType === 'sell' && limitAsset.id === selectedAsset.id) ||
        forceSet
      ) {
        setAmount(newAmount);
      } else {
        // Round up if buying and round down if selling 3 = Round Up, 0 = Round Down
        const rounding = buyType === 'buy' ? 3 : 0;

        const rate = Big(
          convertRate(nonLimitAsset, limitAsset, newAmount, side).round(
            limitAsset.price_scale ?? DEFAULT_PRICE_SCALE,
            rounding,
          ),
        ).times(Big(1).minus(lowestFee));

        const parsedAmount = parseLimitTradeValue(rate.toString(), true, buyType) || '';
        setAmount(parsedAmount);
      }
    },
    [
      limitAsset,
      countryAsset,
      nonLimitAsset,
      selectedAsset,
      buyType,
      setAmount,
      convertRate,
      side,
      lowestFee,
      parseLimitTradeValue,
    ],
  );

  const swapLimitAsset = useCallback(() => {
    if (!limitAsset || !nonLimitAsset) return;

    const assetCode = nonLimitAsset.code;
    setLimitAssetCode(assetCode);
    trackSwitchedLimitAsset({ asset: nonLimitAsset, direction: buyType });

    const { current, previous } = tabValue;
    const hasCurrentValue = current.value !== '';
    const hasPreviousValue = previous.value !== '';

    if (hasCurrentValue) {
      setTabValue({ value: '', assetCode: '' });
      updateAmount(nonLimitAmount, true);
    } else if (hasPreviousValue) {
      setTabValue(previous);
      const isFiat = assetService.isAssetFiatByCode(previous.assetCode);
      if (buyType === 'sell' && !isFiat) {
        updateAmount(Big(previous.value).times(100).toString(), true);
      } else {
        updateAmount(previous.value, true);
      }
    } else {
      updateAmount(nonLimitAmount, true);
    }
  }, [
    buyType,
    limitAsset,
    nonLimitAmount,
    nonLimitAsset,
    setLimitAssetCode,
    setTabValue,
    tabValue,
    trackSwitchedLimitAsset,
    updateAmount,
  ]);

  return {
    amount,
    setAmount: updateAmount,
    countryBalance,
    nonLimitAmount,
    swapLimitAsset,
  };
};

export { useQuickBuyAmounts };
