import { TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { TradeTypesType } from '@Trade/types';

const TradeTypes: TradeTypesType[] = [
  {
    label: 'tradeOrderDetails.labels.instant',
    value: TradeType.Instantly,
  },
  {
    label: 'tradeOrderDetails.labels.onTrigger',
    value: TradeType.OnTrigger,
  },
  {
    label: 'tradeOrderDetails.labels.recurring',
    value: TradeType.Recurring,
  },
];

export { TradeTypes };
