import { useEffect, useState } from 'react';

import { OrderDirection } from '@shared/api';
import { Big } from '@shared/safe-big';

import { useTradeFeesData } from '@routes/Profile/subroutes/TradeFees/useTradeFeesData';

type SafeFeeOrderDetails = {
  amount: string;
  orderDirection: OrderDirection;
  primaryId: number;
  secondaryId: number;
  pro?: boolean;
};

export type FeeEstimateOrderDetails = SafeFeeOrderDetails | null;

export const getEstimatedTriggerFee = async (
  orderDetails: SafeFeeOrderDetails,
  feePercentage?: number,
): Promise<string> => {
  const { amount } = orderDetails;

  try {
    const feePerUnit = feePercentage ? Big(feePercentage).div(100) : Big(0);
    const fee = Big(amount).mul(feePerUnit);
    return fee.toString();
  } catch (_e) {
    return '0';
  }
};

export const useEstimatedTriggerFeeIfNeeded = (
  defaultFee: string,
  isEstimating: boolean,
  orderDetails: FeeEstimateOrderDetails,
) => {
  const [feeAmount, setFeeAmount] = useState<string>(defaultFee);
  const { userFeeStatus } = useTradeFeesData(orderDetails?.pro ? 'pro' : 'standard');

  useEffect(() => {
    if (isEstimating && orderDetails) {
      (async () => {
        const estimatedFee = await getEstimatedTriggerFee(orderDetails, userFeeStatus?.feePercentage);
        setFeeAmount(estimatedFee);
      })();
    } else {
      setFeeAmount(defaultFee);
    }
  }, [defaultFee, isEstimating, orderDetails]);

  return { feeAmount };
};
