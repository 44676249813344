import React from 'react';

import { AppSideBar } from '@swyftx/aviary/molecules/AppSideBar';

import { UIStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useAutoInvestEntryTour } from 'src/lib/feature-discovery/tours/auto-invest/useAutoInvestEntryTour';
import { useTradePageTour } from 'src/lib/feature-discovery/tours/trade/useTradePageTour';

import { useAppSideBar } from './useAppSideBar';

// TODO remove these, currently used for Dynamic Grid calculations
export const DESKTOP_DRAWER_WIDTH = 240;
export const DESKTOP_DRAWER_WIDTH_CLOSED = 56;

const Navigation: React.FC = observer(() => {
  const { state, items, header, footer, onCloseAppBar } = useAppSideBar();
  const { hideSideMenu } = UIStore.useUIStore;

  useAutoInvestEntryTour();
  useTradePageTour();

  if (hideSideMenu) return null;

  return (
    <AppSideBar
      state={state}
      items={items}
      header={header}
      footer={footer}
      onClose={onCloseAppBar}
      itemsClassName='md:pb-96'
    />
  );
});

Navigation.displayName = 'Navigation';

export default Navigation;
