import React, { useEffect } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Tick } from '@swyftx/aviary/icons/outlined';

import { RecurringOrderSource } from '@shared/enums';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { useToggle } from 'react-use';
import { useNavigateExitIntent } from 'src/lib/navigation/hooks/useNavigateExitIntent';
import { FiatDepositDetailsCard } from 'src/lib/portfolio/components/FiatDepositDetailsCard';

import { AutoInvestBalanceWarning } from '../components';
import { useAutoInvestComplete } from '../hooks';

const AutoInvestWidgetComplete: React.FC = observer(() => {
  const {
    countryAsset,
    content,
    hasEnoughBalance,
    selectedPaymentMethod,
    autoInvestOrder,
    bankAddressDetails,
    loading,
    showBalanceWarning,
    diffDays,
    handleDepositCountryAsset,
    handleCreateAnother,
    handleOnCancel,
  } = useAutoInvestComplete();
  const [bankTransferRead, toggleBankTransferRead] = useToggle(false);
  const { removeExitIntent } = useNavigateExitIntent();

  useEffect(() => {
    removeExitIntent();
  }, [removeExitIntent]);

  if (!countryAsset) return null;

  return (
    <FlexLayout direction='column' spacing={24} alignItems='center' justifyContent='start' className='h-full w-full'>
      <FlexLayout direction='column' className='h-full w-full p-16 sm:p-24' spacing={16}>
        <FlexLayout direction='column' alignItems='center' spacing={8}>
          <Icon color='success' className='h-48 w-48 p-8' icon={<Tick className='h-32 w-32' />} />
          <Heading color='primary' size='h3'>
            Order created
          </Heading>
        </FlexLayout>
        {content}

        {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && countryAsset && (
          <>
            {showBalanceWarning && (
              <AutoInvestBalanceWarning orderIsSameDay={false} countryAsset={countryAsset} showActions={false} />
            )}
            {showBalanceWarning && (
              <AutoInvestBalanceWarning severity='info' countryAsset={countryAsset} showActions={false} />
            )}
          </>
        )}

        {selectedPaymentMethod === RecurringOrderSource.DEPOSIT && (
          <>
            {(!autoInvestOrder || !bankAddressDetails) && !loading && (
              <Notification
                severity='destructive'
                title='Error generating bank details'
                content='There was an error generating the deposit details for your recurring order. Please try again'
              />
            )}
            {autoInvestOrder && bankAddressDetails && (
              <FiatDepositDetailsCard
                accountName='Swyftx'
                accountNumber={bankAddressDetails.address}
                bsb={bankAddressDetails.bsb}
                referenceDescription={autoInvestOrder.templateKey}
                minimumDepositAmount={formatCurrency(countryAsset?.min_deposit, countryAsset)}
              />
            )}
          </>
        )}

        <FlexLayout className='h-full w-full' alignItems='end' justifyContent='end' direction='column' spacing={12}>
          {selectedPaymentMethod === RecurringOrderSource.DEPOSIT && (
            <>
              <FlexLayout className='mb-24 w-full' spacing={12} alignItems='start'>
                <Checkbox
                  className='mt-4 h-20 w-20'
                  checked={bankTransferRead}
                  onCheckedChange={toggleBankTransferRead}
                />
                <Body className='cursor-pointer' onClick={toggleBankTransferRead}>
                  I understand that in order for this order to execute, an external bank transfer must be made,
                  referencing this order.
                </Body>
              </FlexLayout>
              <Button
                size='lg'
                variant='filled'
                className='w-full'
                onClick={handleCreateAnother}
                disabled={!bankTransferRead}
              >
                Create another order
              </Button>
              <Button
                size='lg'
                variant='outlined'
                className='w-full'
                onClick={handleOnCancel}
                disabled={!bankTransferRead}
              >
                Dismiss
              </Button>
            </>
          )}

          {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
            <>
              {(diffDays.eq(0) || (hasEnoughBalance && !diffDays.eq(0))) && (
                <Button size='lg' variant='filled' className='w-full' onClick={handleCreateAnother}>
                  Create another order
                </Button>
              )}
              {!hasEnoughBalance && !diffDays.eq(0) && (
                <Button size='lg' variant='filled' className='w-full' onClick={handleDepositCountryAsset}>
                  Deposit {countryAsset.code}
                </Button>
              )}
              <Button size='lg' variant='outlined' className='w-full' onClick={handleOnCancel}>
                Dismiss
              </Button>
            </>
          )}
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { AutoInvestWidgetComplete };
