import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteRenderGroupParams, AutocompleteRenderInputParams, Box, TextField, Theme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { AutocompleteDropdown, AutocompleteOption } from '@global-components/AutocompleteDropdown';
import { CryptoTransferWarning } from '@global-components/message-boxes/CryptoTransferWarning';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';

import { useTheme } from '@hooks/useTheme';

import { observer } from 'mobx-react-lite';

const ICON_SIZE = 24;
const OPTION_HEIGHT = pxToRem(45);

const getDefaultTextFieldStyles = (theme: Theme) => ({
  '& input': {
    fontSize: pxToRem(16),
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: 1,
  },
  fieldset: {
    borderColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[500],
  },
});

type Option = AutocompleteOption<
  number,
  | {
      optionType: 'asset';
      balance?: string;
      type: AssetType;
      code: string;
      displayLabel: string;
    }
  | {
      optionType: 'cryptoWarning';
    }
>;

type Props = {
  id: string;
  assets: Array<Asset>;
  direction: 'deposit' | 'withdraw';
  setSelectedAsset: (asset: Asset | null) => void;
  showBalance?: boolean;
  initialAsset?: Asset;
};

export const TransferModalAssetPicker: React.FC<Props> = observer(
  ({ assets, direction, setSelectedAsset, id, showBalance, initialAsset }) => {
    const { t } = useTranslation('common', { keyPrefix: 'transferModal.assetPicker' });
    const [selectedOption, setSelectedOption] = useState<Option | null>();
    const ref = useRef<HTMLInputElement | null>(null);
    const { balances, canTransferCrypto } = UserStore.useUserStore;
    const { getAsset } = assetService;
    const { theme } = useTheme();
    const showCrypto = canTransferCrypto();

    // pre-fill selection if there is an asset passed in
    if (initialAsset) {
      useMemo(() => {
        setSelectedOption({
          label: `${initialAsset.name}-${initialAsset.code}`,
          value: initialAsset.id,
          extraState: {
            optionType: 'asset',
            displayLabel: initialAsset.name,
            type: initialAsset.assetType,
            code: initialAsset.code,
          },
        });
      }, []);
    }

    const autocompleteOptions = useMemo<Array<Option>>(() => {
      let availableAssets = assets;

      if (!showCrypto) {
        availableAssets = availableAssets.filter((a) => a.assetType === AssetType.Fiat);
      }

      const options = availableAssets.map<Option>((asset) => ({
        label: `${asset.name}-${asset.code}`,
        value: asset.id,
        extraState: {
          optionType: 'asset',
          displayLabel: asset.name,
          type: asset.assetType,
          code: asset.code,
          ...(!showBalance
            ? {
                balance:
                  Number(balances[asset.id]?.availableBalance) > 0
                    ? formatCurrency(
                        Big(balances[asset.id].availableBalance),
                        asset,
                        {
                          hideCode: true,
                          appendCode: false,
                        },
                        undefined,
                        true,
                      )
                    : '',
              }
            : {}),
        },
      }));

      if (!showCrypto) {
        options.push({
          label: 'boop',
          value: 0,
          extraState: {
            optionType: 'cryptoWarning',
          },
        });
      }

      return options;
    }, [assets, balances, showBalance, showCrypto]);

    const handleChange = (option?: Option) => {
      setSelectedOption(option);
      setSelectedAsset(option ? getAsset(option.value) || null : null);
      if (ref?.current) ref.current.blur();
    };

    const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Option) =>
      option.extraState?.optionType === 'asset' ? (
        <li {...props}>
          <Stack
            justifyContent='space-between'
            height={OPTION_HEIGHT}
            alignItems='center'
            direction='row'
            padding={0}
            width='100%'
          >
            <Stack spacing={1.5} alignItems='center' direction='row'>
              <AssetIcon asset={option.extraState.code} size={ICON_SIZE} />
              <Typography fontSize={pxToRem(16)}>{option.extraState.displayLabel}</Typography>
              <Typography fontSize={pxToRem(14)} color='text.secondary'>
                {option.extraState.code}
              </Typography>
            </Stack>
            {option.extraState.balance ? (
              <Stack spacing={0.5} direction='row'>
                <Typography fontSize={pxToRem(16)} number>
                  {option.extraState.balance}
                </Typography>

                <Typography fontSize={pxToRem(16)} number color='text.secondary'>
                  {option.extraState.code}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        </li>
      ) : (
        <CryptoTransferWarning direction={direction} />
      );

    // I can possibly add a short debounce to the input with inputProps.onInputChange if it looks like all assets from send/receive is going be slow
    const renderInput = (params: AutocompleteRenderInputParams) => {
      // only show the name we had to append code to make it searchable
      const value = params?.inputProps?.value as string;
      const splitValue = value.split('-');

      return (
        <>
          <InputLabel
            required
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              marginBottom: 1,
            }}
          >
            {direction === 'deposit' ? t('deposit.title') : t('withdraw.title')}
          </InputLabel>
          <TextField
            {...params}
            placeholder={direction === 'deposit' ? t('deposit.placeholder') : t('withdraw.placeholder')}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              ...{
                startAdornment:
                  selectedOption?.extraState?.optionType === 'asset' &&
                  (params.inputProps as { value: string }).value === selectedOption.label ? ( // Nice typings, mui...
                    <Stack justifyContent='center' alignItems='center' paddingRight={1} paddingLeft={1} height='100%'>
                      <AssetIcon asset={selectedOption.extraState.code} size={ICON_SIZE} />
                    </Stack>
                  ) : (
                    <Stack justifyContent='center' alignItems='center' paddingRight={1} paddingLeft={1} height='100%'>
                      <Box
                        className='bg-color-background-surface-secondary'
                        borderRadius={ICON_SIZE / 2}
                        minHeight={ICON_SIZE}
                        minWidth={ICON_SIZE}
                        component='span'
                      />
                    </Stack>
                  ),
              },
            }}
            inputProps={{
              ...params.inputProps,
              value: splitValue[0],
            }}
            sx={getDefaultTextFieldStyles(theme)}
          />
        </>
      );
    };

    const onDeselect = () => {
      setSelectedOption(null);
      setSelectedAsset(null);
    };

    const groupBy = (option: Option) =>
      option.extraState?.optionType === 'asset' ? option.extraState.type.toString() : 'cryptoWarning';

    const renderGroup = (params: AutocompleteRenderGroupParams) => {
      const border = params.group === AssetType.Fiat.toString() ? '1px solid' : 'none';

      return (
        <Box borderBottom={border} borderColor='divider' key={params.key}>
          {params.children}
        </Box>
      );
    };

    return (
      <AutocompleteDropdown
        customRenderOption={renderOption}
        customRenderInput={renderInput}
        options={autocompleteOptions}
        renderGroup={renderGroup}
        onDeselect={onDeselect}
        onChange={handleChange}
        value={selectedOption}
        groupBy={groupBy}
        height='100%'
        id={id}
      />
    );
  },
);
