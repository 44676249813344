import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { TrendingCategoryTopTradedAsset } from './TrendingCategoryTopTradedAsset';

const TrendingCategoryTopTradedAssets: React.FC = () => {
  const { t } = useTranslation('assets');

  return (
    <FlexLayout direction='column' spacing={8} className='w-full md:w-1/3'>
      <Heading size='h5'>{t('trendingCategory.topTradedTitle')}</Heading>
      <Body color='secondary' size='small'>
        {t('trendingCategory.topTradedSubTitle')}
      </Body>
      <TrendingCategoryTopTradedAsset assetCode='TAO' />
      <TrendingCategoryTopTradedAsset assetCode='FET' />
      <TrendingCategoryTopTradedAsset assetCode='RENDER' />
      <TrendingCategoryTopTradedAsset assetCode='AKT' />
      <TrendingCategoryTopTradedAsset assetCode='AIOZ' />
    </FlexLayout>
  );
};

export { TrendingCategoryTopTradedAssets };
