import React, { useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import { CrossInCircleFilled } from '@swyftx/aviary/icons/filled';
import { Cross } from '@swyftx/aviary/icons/outlined';
import { Card, IconButton } from '@swyftx/react-web-design-system';

import { UIStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const AUTO_HIDE_DURATION = 5000;

const getAnchorOrigin = (isMobile: boolean, anchorOrigin?: SnackbarOrigin): SnackbarOrigin =>
  isMobile
    ? {
        vertical: 'bottom',
        horizontal: 'center',
      }
    : anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'right',
      };

const getMinWidth = (isMobile: boolean) => (isMobile ? '100%' : '400px');

const GlobalMessageBoxes: React.FC = observer(() => {
  const [closedMap, setClosedMap] = useState<Map<string, boolean>>(new Map()); // Decouples the open state to the error message value to allow it time to show the closing transition
  const { messageBoxes, removeMessageBox } = UIStore.useUIStore;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (uuid: string) => {
    setClosedMap(new Map(closedMap.set(uuid, true)));

    setTimeout(() => {
      const closedMapBuff = new Map(closedMap);
      closedMapBuff.delete(uuid);
      setClosedMap(closedMapBuff);
      removeMessageBox(uuid);
    }, 200);
  };

  const width = getMinWidth(isMobile);

  return (
    <>
      {Array.from(messageBoxes).map(([uuid, data]) => (
        <Snackbar
          ClickAwayListenerProps={{
            onClickAway: () => {},
          }} // removes onClickAway
          autoHideDuration={AUTO_HIDE_DURATION}
          onClose={() => handleClose(uuid)}
          anchorOrigin={getAnchorOrigin(isMobile, data.anchorOrigin)}
          key={`${uuid}`}
          open={!closedMap.get(uuid)}
        >
          <div style={{ minWidth: width }}>
            <Card
              elevation={3}
              sx={{
                padding: '0 !important',
                '& .MuiCardContent-root': { padding: '0 !important' },
                width: '100%',
              }}
            >
              <Box bgcolor='background.paper' position='relative' borderRadius={2} width='100%' padding={2}>
                {!data.hideDismiss && (
                  <Box position='absolute' right={theme.spacing(2)} top={theme.spacing(2)}>
                    <IconButton size='small' onClick={() => handleClose(uuid)}>
                      {isMobile ? (
                        <Cross className='text-color-text-primary' />
                      ) : (
                        <CrossInCircleFilled className='h-12 w-12 text-color-text-primary' />
                      )}
                    </IconButton>
                  </Box>
                )}
                <Box width='100%'>{data.content}</Box>
              </Box>
            </Card>
          </div>
        </Snackbar>
      ))}
    </>
  );
});

GlobalMessageBoxes.displayName = 'GlobalMessageBoxes';

export { GlobalMessageBoxes };
