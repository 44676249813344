import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';

import { Button } from '@swyftx/aviary/atoms/Button/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ArrowChevronRight } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';
import { EnhancedTab } from '@swyftx/aviary/molecules/EnhancedTabs/EnhancedTabs.types';
import { Stack, Tooltip, Typography } from '@swyftx/react-web-design-system';

import { ViewportLayout } from '@shared/events';
import { assetService } from '@shared/services/assetService';
import { AppStore, UniversalTradeStore, UserStore } from '@shared/store';
import { TradeAssetAction, TradeSide, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { EntityPermissions, usePermissions } from '@hooks/Permissions';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { TradeTypes } from './TradeOrderDetailsType.data';

interface Props {
  tradePanel?: boolean;
  dismissible?: boolean;
}

type OrderTypeTabType = TradeType.Instantly | TradeType.OnTrigger;

type OrderTab = EnhancedTab<OrderTypeTabType>;

const orderTypeTabs: OrderTab[] = [
  {
    title: 'Instantly',
    value: TradeType.Instantly,
  },
  {
    title: 'On Trigger',
    value: TradeType.OnTrigger,
  },
];

const TradeOrderDetailsType: React.FC<Props> = observer((props) => {
  const { tradePanel, dismissible } = props;
  const { t } = useTranslation('trade');
  const { tradeType, setTradeType, setTradeAssets, tradeFrom, tradeTo, setShowGlobalTrade } = UniversalTradeStore;
  const { pathname } = useLocation();
  const avo = useAvo();

  const baseAssets = assetService.getBaseAssets();
  const countryAsset = useCountryAsset();

  const { isUserVerified } = UserStore.useUserStore;
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { isDemo } = AppStore.useAppStore;
  const { enabled: canCreateRecurringOrder } = usePermissions({
    permissions: [EntityPermissions.ORDERS_CREATE],
  });

  const theme = useTheme();
  const layout: ViewportLayout = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop';

  const isVerified = isUserVerified();

  const changeTradeType = (newTradeType: TradeType) => {
    // Fire event for trade analytics: order type selected
    avo.tradeTypeSelected({
      screen: pathname,
      swyftxPro: 'false',
      layout,
      tradeType: newTradeType,
    });

    switch (newTradeType) {
      case TradeType.OnTrigger:
        if (tradeFrom.length > 1) {
          setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
        }

        if (tradeTo.length > 1) {
          setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
        }

        if (
          baseAssets.findIndex((a) => tradeFrom.includes(a) || tradeTo.includes(a)) === -1 &&
          tradeFrom.length > 0 &&
          tradeTo.length > 0
        ) {
          setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
          setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
        }
        break;
      case TradeType.Recurring:
        setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
        if (countryAsset) setTradeAssets([countryAsset.id], TradeSide.To, TradeAssetAction.Remove);
        break;
      default:
        break;
    }

    setTradeType(newTradeType);
  };

  const disabledMap = useMemo<Partial<Record<TradeType, string>>>(
    () => ({
      [TradeType.Recurring]: (() => {
        if (isDemo) {
          return t('tradePanel.tabs.labels.demo');
        }

        if (!canCreateRecurringOrder) {
          return t('tradePanel.tabs.labels.noPermissions');
        }

        if (!isVerified) {
          return t('tradePanel.tabs.labels.verificationNeeded');
        }

        return undefined;
      })(),
    }),
    [isDemo, isVerified, canCreateRecurringOrder, t],
  );

  if (tradePanel) {
    return (
      <FlexLayout direction='column' className='w-full overflow-x-hidden' spacing={8}>
        <FlexLayout direction='row' className='w-full items-center justify-between p-2'>
          <Body weight='emphasis' size='large' className='text-color-text-primary'>
            Order Type
          </Body>
          {dismissible && (
            <Button
              variant='outlined'
              layout='icon'
              leadingIcon={<ArrowChevronRight />}
              tooltip='Close trade panel'
              onClick={() => setShowGlobalTrade(false)}
            />
          )}
        </FlexLayout>
        <EnhancedTabs<OrderTypeTabType>
          variant='default'
          tabs={orderTypeTabs}
          onChange={(value) => changeTradeType(value)}
          value={tradeType as OrderTypeTabType}
        />
      </FlexLayout>
    );
  }

  return (
    <Stack direction='column' spacing={1}>
      <Typography fontSize={14} fontWeight={500}>
        {t('tradePanel.labels.whenToPlace')}
      </Typography>
      <Stack
        direction='row'
        className='hide-scrollbar bg-color-background-surface-secondary'
        spacing={0.5}
        borderRadius={6}
        sx={{ overflowX: 'auto', padding: 0.25 }}
      >
        {TradeTypes.map((type) => {
          const disabledTooltip = disabledMap?.[type.value];
          const isDisabled = Boolean(disabledTooltip);
          const isSelected = tradeType === type.value;

          if (type.value === TradeType.OnTrigger && !isFeatureEnabled(AppFeature.TriggerTrade)) {
            return null;
          }

          if (type.value === TradeType.Recurring && isFeatureEnabled(AppFeature.AutoInvest)) {
            return null;
          }

          // While we're mapping through types may as well reset the type if a disabled one is selected
          if (isSelected && isDisabled) {
            changeTradeType(TradeType.Instantly);
          }

          return (
            <Tooltip key={type.value} title={disabledTooltip || ''}>
              <span className='w-full'>
                <Button
                  focusStyle={false}
                  className='w-full'
                  key={type.label}
                  variant={isSelected ? 'filled' : 'ghost'}
                  color={isSelected ? 'primary' : 'subtle'}
                  size='md'
                  disabled={isDisabled}
                  onClick={() => changeTradeType(type.value)}
                >
                  {t(type.label as any)}
                </Button>
              </span>
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
});

TradeOrderDetailsType.displayName = 'TradeOrderDetailsType';

export { TradeOrderDetailsType };
