import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button as AviaryButton, Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List, ListItem } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Dollar } from '@swyftx/aviary/icons/outlined';

import { Asset } from '@shared/api';
import { WithdrawalAddress } from '@shared/services';
import { cn } from '@shared/utils/lib/ui';

import { WalletContext } from '@Wallet/Wallet.context';

import { observer } from 'mobx-react-lite';

import { SingleWalletManageAccountDetailsModal } from './components';

interface SingleWalletFiatWithdrawalWithdrawManageAccountsProps {
  asset: Asset;
  isFiat: boolean;
  fullWidth?: boolean;

  createAccountHandler: () => void;
  onClose?: () => void;
  buttonSize?: 'sm' | 'md';
}

const SingleWalletManageAccounts: React.FC<SingleWalletFiatWithdrawalWithdrawManageAccountsProps> = observer(
  ({ asset, isFiat, buttonSize = 'sm', fullWidth = true, createAccountHandler, onClose }) => {
    const { addresses, updateAddresses } = useContext(WalletContext);

    const [fetchingAddresses, setFetchingAddresses] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState<WithdrawalAddress | null>(null);
    const [accountDetailsOpen, setAccountDetailsOpen] = useState(false);
    const { t } = useTranslation('wallet');
    const isXs = useTailwindBreakpoint('xs');

    const title = isFiat
      ? t('manageAccounts.fiatTitle', { code: asset.code })
      : t('manageAccounts.cryptoTitle', { code: asset.code });

    const details = isFiat ? t('manageAccounts.fiatDetails') : t('manageAccounts.cryptoDetails');

    useEffect(() => {
      (async () => {
        setFetchingAddresses(true);
        await updateAddresses(asset.code);
        setFetchingAddresses(false);
      })();
    }, [addresses, updateAddresses, asset]);

    const closeModal = () => {
      setIsOpen(false);
      if (onClose) onClose();
    };

    return (
      <>
        <AviaryButton
          onClick={() => setIsOpen(true)}
          variant='ghost'
          className={fullWidth ? 'w-full' : ''}
          size={buttonSize}
        >
          {t('manageAccounts.button')}
        </AviaryButton>
        <Modal
          id='manage-fiat-withdrawal-address-modal'
          position={isXs ? 'bottom' : 'center'}
          className={cn('sm:min-w-[600px] sm:max-w-[600px]', accountDetailsOpen && 'hidden')}
          open={isOpen}
          onClose={closeModal}
          title={title}
        >
          <FlexLayout direction='column' spacing={8} className='px-16'>
            <Body className='pr-16' color='secondary'>
              {details}
            </Body>

            <FlexLayout direction='row' alignItems='center' justifyContent='space-between'>
              <Body size='large' weight='bold'>
                {isFiat ? 'Accounts' : 'Addresses'}
              </Body>

              {asset && (
                <Button
                  variant='ghost'
                  color='accent'
                  loading={fetchingAddresses}
                  onClick={() => updateAddresses(asset.code)}
                >
                  {t('manageAccounts.refreshAddresses')}
                </Button>
              )}
            </FlexLayout>
            <Separator className='w-full' />

            <List>
              {addresses === undefined && (
                <>
                  <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
                  <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
                  <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
                  <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
                  <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
                  <div className='h-24 w-32 animate-pulse bg-color-background-surface-secondary' />
                </>
              )}
              {addresses.get(asset.code)?.map((address: WithdrawalAddress) => (
                <ListItem
                  key={address.id}
                  onClick={() => {
                    setAccountDetailsOpen(true);
                    setSelectedAddress(address);
                  }}
                  className='w-full'
                >
                  <FlexLayout direction='row' className='w-full' alignItems='center' justifyContent='space-between'>
                    <FlexLayout direction='row' spacing={16} justifyContent='start' alignItems='center'>
                      <Avatar color='secondary' size='md'>
                        <Dollar className='h-18 w-18 text-color-text-success' />
                      </Avatar>

                      <FlexLayout className='fs-mask' direction='column' alignItems='start' justifyContent='center'>
                        <Body weight='emphasis' color='primary'>
                          {address.label}
                        </Body>
                        <Body color='secondary'>{address.address_details.address}</Body>
                      </FlexLayout>
                    </FlexLayout>

                    {!address.verified && (
                      <Chip color='primary' size='sm'>
                        Confirm Email
                      </Chip>
                    )}
                  </FlexLayout>
                </ListItem>
              ))}
            </List>
          </FlexLayout>
          <FlexLayout direction='row' justifyContent='end' spacing={16} className='p-16'>
            <Button variant='ghost' onClick={closeModal}>
              {t('manageAccounts.cancel')}
            </Button>
            <Button
              variant='filled'
              onClick={() => {
                createAccountHandler();
              }}
            >
              {t('manageAccounts.addNewAccount')}
            </Button>
          </FlexLayout>
        </Modal>
        {selectedAddress && (
          <SingleWalletManageAccountDetailsModal
            isFiat={isFiat}
            accountDetailsOpen={accountDetailsOpen}
            address={selectedAddress}
            setAccountDetailsOpen={setAccountDetailsOpen}
          />
        )}
      </>
    );
  },
);

export { SingleWalletManageAccounts };
