import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body, BodyLink, Heading, Utility } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowLineOnlyRight, Cross, Delete, Loading } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset } from '@shared/api';
import { Big } from '@shared/safe-big';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { PriceAlertsModalActiveItem } from './PriceAlertsModalActiveItem';
import { PriceAlertsModalTriggeredItem } from './PriceAlertsModalTriggeredItem';
import { PriceAlert, PriceAlertType, TriggeredPriceAlert } from '../../dynamicPriceAlerts.types';
import { useFetchDynamicPriceAlertsCache } from '../../hooks';
import { useDismissTriggerNotifications } from '../../hooks/useDismissTriggerNotifications';
import { useDynamicPriceAlerts } from '../../hooks/useDynamicPriceAlerts';
import { CreatePriceAlertModal } from '../CreatePriceAlertModal';
import { DeleteAllPriceAlertModal } from '../DeleteAllPriceAlertModal';
import { DeletePriceAlertModal } from '../DeletePriceAlertModal';
import { DynamicPriceAlertFilters } from '../DynamicPriceAlertFilters';
import { useDynamicPriceAlertFilters } from '../DynamicPriceAlertFilters/useDynamicPriceAlertFilters';
import { EditPriceAlertModal } from '../EditPriceAlertModal';
import { PriceAlertEmpty } from '../PriceAlertEmpty/PriceAlertEmpty';

type Props = { primary: number; secondary: number; triggers?: number[] };

const PriceAlertsModal: React.FC<PropsWithChildren<Props>> = observer(
  ({ primary, secondary, triggers = [], children }) => {
    const { getAssetById } = useMarkets();
    const [open, setOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<PriceAlertType>(triggers.length > 0 ? 'triggered' : 'active');
    const [editAlert, setEditAlert] = useState<PriceAlert>();
    const [deleteAlert, setDeleteAlert] = useState<PriceAlert>();
    const [deleteAllAlerts, setDeleteAllAlerts] = useState<boolean>(false);
    const [createAlert, setCreateAlert] = useState<Asset>();
    const { filterTypes, setFilterTypes, onRemoveAllFilters, dateSort, setDateSort } = useDynamicPriceAlertFilters();
    const { dismissNotifications } = useDismissTriggerNotifications();
    const { invalidateCache } = useFetchDynamicPriceAlertsCache();
    const listRef = useRef<HTMLDivElement>(null);
    const { navigate, location } = useNavigateRoute();
    const { setTradeAssets } = UniversalTradeStore;

    const { isFeatureEnabled } = useIsFeatureEnabled();

    const isXs = useTailwindBreakpoint('xs');
    const {
      activeAlerts,
      groupedTriggerAlerts,
      hasNoAlerts,
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage,
      isFetchingTriggeredAlerts,
    } = useDynamicPriceAlerts({
      secondary,
      filterTypes,
      dateSort,
    });

    useEffect(() => {
      if (open) {
        dismissNotifications(triggers);
      } else {
        invalidateCache();
      }
    }, [dismissNotifications, triggers, open, invalidateCache]);

    const hasTypeAlerts = useMemo(() => {
      if (alertType === 'active' && activeAlerts.length > 0) return true;
      if (alertType === 'triggered' && Object.keys(groupedTriggerAlerts).length > 0) return true;

      return false;
    }, [alertType, activeAlerts, groupedTriggerAlerts]);

    const asset = useMemo(() => getAssetById(secondary), [secondary, getAssetById]);
    const baseAsset = useMemo(() => getAssetById(primary), [primary, getAssetById]);

    const hasActiveFilters = useMemo(() => filterTypes.length > 0, [filterTypes]);

    const handleOpenChange = useCallback((o?: boolean) => {
      setOpen(!!o);
      if (!o) {
        setEditAlert(undefined);
        setDeleteAlert(undefined);
      }
    }, []);

    const modalOpen = useMemo(
      () => open && !deleteAlert && !editAlert && !createAlert && !deleteAllAlerts,
      [createAlert, deleteAlert, deleteAllAlerts, editAlert, open],
    );

    const handleOnChangeAlertType = useCallback(
      (newType: PriceAlertType) => {
        setAlertType(newType);
        invalidateCache();
      },
      [invalidateCache],
    );

    const onScrollList = useCallback(
      (e: Event) => {
        if (isFetchingTriggeredAlerts || isFetchingNextPage || !hasNextPage || alertType === 'active') return;

        const element = e.target as HTMLElement;
        const scrollHeight = element.scrollHeight - element.clientHeight;
        const scrollPos = element.scrollTop;
        if (Big(scrollHeight).minus(scrollPos).lte(120)) {
          fetchNextPage();
        }
      },
      [isFetchingTriggeredAlerts, isFetchingNextPage, hasNextPage, alertType, fetchNextPage],
    );

    const onClickItem = useCallback(
      (item: TriggeredPriceAlert) => {
        const primaryAsset = getAssetById(item.primary);
        const secondaryAsset = getAssetById(item.secondary);

        setOpen(false);

        // If we have no assets or we are already on the trade page, do nothing
        if (!primaryAsset || !secondaryAsset || location.pathname.includes('/trade')) return;

        setTradeAssets([secondaryAsset.id], TradeSide.To, TradeAssetAction.Replace);
        setTradeAssets([primaryAsset.id], TradeSide.From, TradeAssetAction.Replace);
        navigate(NavigationURLs.UniversalTradeAsset, { pathParams: { asset: secondaryAsset?.code } });
      },
      [getAssetById, location.pathname, navigate, setTradeAssets],
    );

    return (
      <>
        <Modal
          triggerElement={children}
          position={isXs ? 'bottom' : 'center'}
          className='h-[90vh] min-w-[100vw] sm:h-[80vh] sm:min-h-[73vh] sm:!min-w-[512px] sm:!max-w-[512px]'
          onOpenChange={handleOpenChange}
          open={modalOpen}
        >
          <FlexLayout
            direction='column'
            alignItems='start'
            justifyContent='space-between'
            className='h-[90vh] w-full overflow-hidden p-24 sm:h-[80vh] sm:min-h-[73vh]'
            spacing={8}
          >
            <FlexLayout direction='column' alignItems='start' justifyContent='start' spacing={8} className='w-full'>
              <FlexLayout alignItems='center' justifyContent='space-between' className='w-full pb-24'>
                <FlexLayout alignItems='center' spacing={12}>
                  <AssetIcon asset={asset} size={32} />
                  <Heading size='h4'>Your {asset?.name} price alerts</Heading>
                </FlexLayout>
                <FlexLayout alignItems='center' spacing={12}>
                  {isFeatureEnabled(AppFeature.DynamicPriceAlerts) && (
                    <Button
                      layout='icon'
                      leadingIcon={<Delete />}
                      disabled={!hasTypeAlerts}
                      variant='outlined'
                      tooltip={`Delete all ${alertType} alerts`}
                      onClick={() => setDeleteAllAlerts(true)}
                    />
                  )}
                  <Button
                    layout='icon'
                    leadingIcon={<Cross />}
                    variant='outlined'
                    onClick={() => handleOpenChange(false)}
                    tooltip={`Delete all ${alertType} alerts`}
                  />
                </FlexLayout>
              </FlexLayout>
              <EnhancedTabs<PriceAlertType>
                tabs={[
                  { title: 'Active', value: 'active' },
                  { title: 'Triggered', value: 'triggered' },
                ]}
                variant='default'
                fullWidth
                value={alertType}
                onChange={handleOnChangeAlertType}
              />
              {(hasTypeAlerts || filterTypes.length > 0) && (
                <DynamicPriceAlertFilters
                  align='start'
                  showAssetFilter={false}
                  showDeleteAlerts={false}
                  dateSort={dateSort}
                  setDateSort={setDateSort}
                  filterTypes={filterTypes}
                  alertType={alertType}
                  setFilterTypes={setFilterTypes}
                />
              )}
            </FlexLayout>
            {hasTypeAlerts ? (
              <List className='h-full w-full pt-4' ref={listRef} onScroll={onScrollList}>
                {alertType === 'active' ? (
                  activeAlerts.map((alert) => (
                    <PriceAlertsModalActiveItem
                      key={alert.dateUpdated}
                      alert={alert}
                      onDeleteAlert={setDeleteAlert}
                      onEditAlert={setEditAlert}
                    />
                  ))
                ) : (
                  <FlexLayout direction='column' className='w-full'>
                    {Object.keys(groupedTriggerAlerts).map((date) => (
                      <React.Fragment key={`${dateSort}_${date}`}>
                        <FlexLayout alignItems='center' className='h-24'>
                          <Utility variant='overline' className='font-bold'>
                            {date}
                          </Utility>
                        </FlexLayout>
                        {groupedTriggerAlerts[date].map((alert) => (
                          <PriceAlertsModalTriggeredItem
                            key={`${dateSort}_${alert.dateTriggered}`}
                            alert={alert}
                            onClick={() => onClickItem(alert)}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </FlexLayout>
                )}
              </List>
            ) : (
              <>
                {!isFetchingNextPage && !isFetchingTriggeredAlerts ? (
                  <FlexLayout
                    className='h-[50vh] w-full py-24 sm:h-[400px]'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <PriceAlertEmpty
                      alertType={alertType}
                      showCreateButton={false}
                      filtersActive={hasActiveFilters}
                      onRemoveAllFilters={onRemoveAllFilters}
                    />
                  </FlexLayout>
                ) : (
                  <FlexLayout className='w-full p-16' alignItems='center' justifyContent='center'>
                    <Loading className='animate-spin' />
                  </FlexLayout>
                )}
              </>
            )}

            <FlexLayout direction='column' className='w-full pt-12' spacing={16} alignItems='center'>
              <Button size='lg' className='w-full' onClick={() => setCreateAlert(asset)}>
                {hasNoAlerts ? 'Add your first alert' : 'Create new price alert'}
              </Button>
              {isFeatureEnabled(AppFeature.DynamicPriceAlerts) && (
                <FlexLayout alignItems='center' spacing={4}>
                  <Body color='secondary'>Looking for all alerts?</Body>
                  <Link to={NavigationURLs.PriceAlerts}>
                    <FlexLayout alignItems='center' spacing={4} className='cursor-pointer hover:underline'>
                      <BodyLink color='accent'>View all alerts</BodyLink>
                      <ArrowLineOnlyRight className='h-16 w-16 text-color-text-accent' />
                    </FlexLayout>
                  </Link>
                </FlexLayout>
              )}
            </FlexLayout>
          </FlexLayout>
        </Modal>

        {editAlert && baseAsset && <EditPriceAlertModal alert={editAlert} onClose={() => setEditAlert(undefined)} />}
        {deleteAlert && (
          <DeletePriceAlertModal
            alert={deleteAlert}
            onClose={() => setDeleteAlert(undefined)}
            onDelete={() => setDeleteAlert(undefined)}
          />
        )}
        {createAlert && (
          <CreatePriceAlertModal type='single' asset={createAlert} onClose={() => setCreateAlert(undefined)} />
        )}
        {deleteAllAlerts && (
          <DeleteAllPriceAlertModal
            alertType={alertType}
            secondary={asset?.id}
            onClose={() => setDeleteAllAlerts(false)}
            onDelete={() => setDeleteAllAlerts(false)}
          />
        )}
      </>
    );
  },
);

export { PriceAlertsModal };
