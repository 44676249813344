import { PostRecurringOrderResponse } from '@shared/api/@types/recurringOrder';

export enum TradeType {
  Instantly = 'Instantly',
  OnTrigger = 'On trigger',
  Recurring = 'Recurring',
}

export enum TradeDisplayedAssetKey {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum TradeSide {
  From = 'tradeFrom',
  To = 'tradeTo',
}

export enum OrderStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export enum TradeVariant {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum TradeAssetAction {
  Replace,
  Add,
  Remove,
}

export type TradePercentage = {
  percentage?: number;
  locked?: boolean;
};

export enum TradeState {
  PlaceOrder,
  SelectToAsset,
  SelectFromAssets,
  OrderSummary,
  MultiTrade,
  Recurring,
}

/* export enum TradeType { */
/*  Trading, */
/*  Staking, */
/* } */

type TradeCache = {
  tradeFrom: number[];
  tradeTo: number[];
};

export type TradeData = {
  from: number;
  to: number;
  limit: number;
  balance?: string;
  trigger?: string;
  customTrigger?: string;
  tradeType?: TradeType;
  fee?: number; // values from getRate method
  amount?: string; // values from getRate method
  price?: string; // values from getRate method
  ratePrice?: string;
  total?: string; // values from getRate method
  executedAmount?: string; // values after the order executed
  executedTotal?: string; // values after the order executed
  /** {@link https://www.investopedia.com/terms/s/slippage.asp|What is slippage?} */
  slippagePercentage?: number;
  isHighSlippage?: boolean;
  orderUuid?: string;
};

export type TradeUIData = {
  from: number;
  to: number;
  percentage?: number;
  locked?: boolean;
  error?: string; // any error returned from the server
  clientSideError?: string; // error that is calculated on the client side
  isOverLowLiquidityThreshold?: boolean;
  orderStatus?: OrderStatus;
  loading?: boolean;
  interactedWith?: 'input' | 'slider' | 'sliderInput';
};

export type UniversalTradeStateType = {
  maxTradeValue?: string;
  tradeState: TradeState;
  tradeType: TradeType;
  tradeVariant: TradeVariant;
  tradeFrom: number[];
  tradeTo: number[];
  tradeKeys: Array<string>;
  tradeData: { [key: string]: TradeData }; // key is fromId_toId
  tradeUIData: { [key: string]: TradeUIData };
  tradeCache: TradeCache;
  tradePercentages: { [key: number]: TradePercentage };
  closeTo?: any;
  multiTradeError?: string;
  executingOrders: boolean;
  orderStatus: OrderStatus;
  depositDetails?: PostRecurringOrderResponse;
  showGlobalTrade: boolean;
};

export type UniversalTradeActionType = {
  setShowGlobalTrade: (showGlobalTrade: boolean) => void;
  setMaxTradeValue: (maxTradeValue: string) => void;
  setTradeState: (state: TradeState) => void;
  setTradeType: (type: TradeType) => void;
  setTradeVariant: (type: TradeVariant) => void;
  setTradeAssets: (assets: number[], side: TradeSide, action?: TradeAssetAction, pairWith?: number[]) => void;
  setTradeData: (tradeFrom: number, tradeTo: number, data: Partial<TradeData>) => void;
  setTradeUIData: (
    tradeFrom: number,
    tradeTo: number,
    data: Partial<TradeUIData>,
    updateBalances?: boolean,
  ) => TradeUIData;
  setCloseTo: (closeTo?: any) => void;
  setTradeCache: (assets: number[], side: TradeSide, action?: TradeAssetAction) => void;
  setExecutingOrders: (executingOrders: boolean) => void;
  setMultiTradeError: (error: string) => void;
  setOrderStatus: (orderStatus: OrderStatus) => void;
  setDepositDetails: (depositDetails?: PostRecurringOrderResponse) => void;
  cleanup: () => void;
};

export interface UniversalTradeStoreSchema extends UniversalTradeStateType, UniversalTradeActionType {}
