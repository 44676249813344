import { useCallback, useMemo } from 'react';

import { DarkModeEnum } from '@shared/enums';
import { ThemeStore } from '@shared/store';

const useToggleTheme = () => {
  const { darkMode, setDarkMode } = ThemeStore.useThemeStore;

  const isDarkMode = useMemo(() => darkMode === DarkModeEnum.DARK || darkMode === DarkModeEnum.MIDNIGHT, [darkMode]);

  const isMidnightMode = useMemo(() => darkMode === DarkModeEnum.MIDNIGHT, [darkMode]);
  const isLightMode = useMemo(() => darkMode === DarkModeEnum.LIGHT, [darkMode]);

  const toggleDarkMode = useCallback(
    (checked?: boolean) => {
      setDarkMode(checked ? DarkModeEnum.DARK : DarkModeEnum.LIGHT);
    },
    [setDarkMode],
  );

  return {
    toggleDarkMode,
    setDarkMode,
    darkMode,
    isLightMode,
    isDarkMode,
    isMidnightMode,
  };
};

export { useToggleTheme };
