import React, { createContext, PropsWithChildren } from 'react';

import { AddCryptoAddressFormData } from '@global-components/Modals/SendCrypto/steps/SendCryptoAddAddress';

import { api, Asset } from '@shared/api';
import { assetService, WithdrawalAddress, withdrawalService } from '@shared/services';
import storage, { StorageKey } from '@shared/storage';
import { UIStore } from '@shared/store';

import { WalletSort } from '@hooks/useSort/useSort';
import WalletService from '@services/WalletService';

import { WalletLayoutType, WalletType } from '@Wallet/types';

import { useLocalObservable } from 'mobx-react-lite';

type WalletListSort = {
  key?: WalletSort;
  ascending?: boolean;
};

type WalletState = {
  addresses: Map<string, WithdrawalAddress[]>;
  walletSort: WalletListSort;
  hideZeroBalances: boolean;
  walletType: WalletType;
  walletListSearchValue: string;
  token: string;
  walletLayoutByType: { [key in WalletType]: WalletLayoutType };
};

interface WalletAddAddress {
  asset: Asset;
  formData: AddCryptoAddressFormData;
  pin?: string;
}

interface AddAddressResponse {
  mfaType: string;
  error?: boolean;
}

type WalletAction = {
  addAddress: (params: WalletAddAddress) => Promise<AddAddressResponse>;
  deleteAddress: (address: WithdrawalAddress) => void;
  updateAddresses: (addressCode: string) => void;
  updateSort: (sortOption: WalletListSort) => void;
  setHideZeroBalances: (hideZeroBalances: boolean) => void;
  setWalletType: (walletType: WalletType) => void;
  setWalletListSearchValue: (value: string) => void;
  setWalletLayoutByType: (walletType: WalletType, layoutType: WalletLayoutType) => void;
};

interface WalletStoreSchema extends WalletState, WalletAction {}

const initialValues: WalletStoreSchema = {
  walletSort: {
    key: WalletSort.Amount,
    ascending: false,
  },
  addresses: new Map(),
  hideZeroBalances: false,
  walletType: WalletType.ALL_WALLETS,
  walletListSearchValue: '',
  walletLayoutByType: {
    [WalletType.ALL_WALLETS]: WalletLayoutType.LIST,
    [WalletType.TRADING_WALLETS]: WalletLayoutType.LIST,
    [WalletType.EARNING_WALLETS]: WalletLayoutType.TILES,
  },
  token: '',
  addAddress: () =>
    Promise.resolve({
      mfaType: '',
      error: false,
    }),
  deleteAddress: () => {},
  updateAddresses: () => {},
  updateSort: () => {},
  setHideZeroBalances: () => {},
  setWalletType: () => {},
  setWalletListSearchValue: () => {},
  setWalletLayoutByType: () => {},
};

export const WalletContext = createContext<WalletStoreSchema>(initialValues);

export const WalletProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { addToastMessage } = UIStore.useUIStore;

  const store = useLocalObservable(() => ({
    ...initialValues,
    updateAddresses: async (addressCode: string) => {
      const addresses = await WalletService.getWithdrawAddresses(addressCode);
      store.addresses.set(addressCode, addresses);
    },
    addAddress: async ({ formData, asset, pin }: WalletAddAddress) => {
      const { label, address, selectedCryptoNetworkId, metaDataPayload } = formData;

      try {
        const response = await withdrawalService.addCryptoWithdrawAddress(
          asset.code,
          label,
          address,
          selectedCryptoNetworkId,
          metaDataPayload,
          store.token,
          pin || undefined,
        );

        api.endpoints.getWithdrawAddresses.resetCache();

        await store.updateAddresses(asset.code);

        store.token = response.token;

        return {
          mfaType: response.mfaType,
        };
      } catch (error: any) {
        addToastMessage({ severity: 'error', message: error?.errorMessage || 'Something went wrong' });
      }
      return {
        mfaType: '',
      };
    },
    deleteAddress: async (address: WithdrawalAddress) => {
      try {
        await WalletService.removeWithdrawalAddress(address.id, address.code.toString());
        api.endpoints.getWithdrawAddresses.resetCache();

        const asset = assetService.getAsset(address.code);
        if (asset) await store.updateAddresses(asset.code);

        addToastMessage({ severity: 'success', message: 'Successfully removed address' });
      } catch (error) {
        addToastMessage({ severity: 'error', message: 'Something went wrong please try again' });
      }
    },
    updateSort: (sortOption: WalletListSort) => {
      store.walletSort = { ...store.walletSort, ...sortOption };
    },
    setHideZeroBalances: (hideZeroBalances: boolean) => {
      store.hideZeroBalances = hideZeroBalances;
      storage.setItem(StorageKey.HIDE_ZERO_BALANCES, store.hideZeroBalances);
    },
    setWalletType: (walletType: WalletType) => {
      store.walletType = walletType;
    },
    setWalletListSearchValue: (value: string) => {
      store.walletListSearchValue = value;
    },
    setWalletLayoutByType: (walletType: WalletType, layoutType: WalletLayoutType) => {
      store.walletLayoutByType[walletType] = layoutType;
    },
  }));

  return <WalletContext.Provider value={store}>{children}</WalletContext.Provider>;
};
