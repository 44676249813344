import { useCallback, useMemo } from 'react';

import { Asset, AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';

import { useRates } from 'src/lib/rates/hooks';

const useMarkets = () => {
  const { assetsInitialized } = AppStore.useAppStore;
  const { userProfile } = UserStore.useUserStore;
  const { getRate } = useRates();

  const assets = useMemo(() => {
    if (!assetsInitialized) return [];

    return assetService.getActiveAssetList();
  }, [assetsInitialized]);

  const isAssetListed = useCallback(
    (assetCode: string) => {
      if (!assetsInitialized) return false;

      return assetService.getAssetByCode(assetCode) !== undefined;
    },
    [assetsInitialized],
  );

  const isFavourite = useCallback(
    (id: number) => {
      if (!userProfile?.userSettings.favouriteAssets) return false;

      return Object.keys(userProfile?.userSettings.favouriteAssets).includes(id.toString());
    },
    [userProfile?.userSettings.favouriteAssets],
  );

  const isNew = useCallback(
    (asset: Asset, maxAmount = 10) => {
      const newAssets = Array.from(assets)
        .sort((a, b) => Big(a.id).cmp(b.id))
        .slice(assets.length - maxAmount);

      return newAssets.find((a) => a.id === asset.id) !== undefined;
    },
    [assets],
  );

  const getAssetByName = useCallback(
    (name: string) => assets.find((a) => a.name.toLocaleLowerCase() === name.toLocaleLowerCase()),
    [assets],
  );
  const getAssetById = useCallback((id?: number) => assets.find((a) => a.id === id), [assets]);
  const getAssetByCode = useCallback((code?: string) => assets.find((a) => a.code === code), [assets]);
  const getAssetMarketCap = useCallback(
    (asset: Asset) => Big(asset.circulatingSupply).times(getRate(asset).midPrice),
    [getRate],
  );

  const getAssetsById = useCallback(
    (ids: number[]): Asset[] =>
      ids.map((id) => getAssetById(id)).filter((asset) => asset && !asset.delisting && asset.active) as Asset[],
    [getAssetById],
  );

  const getAssetsByCode = useCallback(
    (codes: string[]): Asset[] =>
      codes.map((code) => getAssetByCode(code)).filter((asset) => asset && !asset.delisting && asset.active) as Asset[],
    [getAssetByCode],
  );

  const getAssetsByCategoryId = useCallback(
    (categoryId: number): Asset[] => assets.filter((a) => a.categoryIds.includes(categoryId)),
    [assets],
  );

  const getBaseAssets = useCallback((): Asset[] => assets.filter((a) => a.isBaseAsset), [assets]);

  const isAssetFiat = useCallback((asset?: Asset | null) => asset?.assetType === AssetType.Fiat, []);

  const getAssetsByType = useCallback((type: AssetType) => assets.filter((a) => a.assetType === type), [assets]);

  return {
    assets,
    isAssetListed,
    getAssetByName,
    getAssetById,
    getAssetsById,
    getAssetsByCode,
    getAssetsByCategoryId,
    getAssetByCode,
    getBaseAssets,
    isFavourite,
    isNew,
    isAssetFiat,
    getAssetsByType,
    getAssetMarketCap,
  };
};

export { useMarkets };
