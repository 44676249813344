import { SwyftxError } from '@shared/error-handler';
import { isUndefined } from '@shared/utils';

import { Storage } from './@types';
import logger from '../logger';

let swyftxStorage: any;
/* eslint-disable
  @typescript-eslint/no-unused-expressions,
  import/no-extraneous-dependencies,
  import/newline-after-import
*/

declare let sessionStorage: any;
swyftxStorage = sessionStorage;
/* eslint-enable */

const logTag = 'SHARED_STORAGE';

/**
 * Possible keys for items stored in local storage
 * Make sure the desired got restored by updating the dataStorageEffect() in useDataPersistence.ts
 *
 * If the data should be kept even though the user logged out the app,
 * add the corresponding key into doNotClear set in storage.logout()
 *
 * @see storage#logout
 */
export enum StorageKey {
  ACCESS_TOKEN = 'accessToken_v1',
  CURRENT_ACCOUNT = 'currentAccount_v1',
  CURRENT_ACCOUNT_COLOR = 'currentAccountColor_v1',
  PERSONAL_ACCESS_TOKEN = 'personalAccessToken_v1',
  ANGULAR_ACCESS_TOKEN = 'access_token',
  AUTO_LOGOUT = 'sessionTimeout_v1',
  BIOMETRICS = 'biometrics_v1',
  CHARTS = 'charts_v1',
  CONTENT_CARDS = 'contentCards_v1',
  DARK_MODE = 'darkMode_v1',
  DEMO = 'demo_v1',
  EMAIL = 'email_v1',
  ID_TOKEN = 'idToken_v1',
  LANGUAGE = 'language_v1',
  MOBILE_API_KEY_REF = 'mobile:apiKeyRef_v1', // The Reference of api key is exclusively used for biometric
  PRICE_ALERT_NOTICE_READ = 'priceAlertNoticeRead_v1',
  RECEIVE_LANDING_READ = 'receiveLandingRead_v1',
  DEPOSIT_LANDING_READ = 'depositLandingRead_v1',
  DEPOSIT_CRYPTO_LANDING_READ = 'depositCryptoLandingRead_v1',
  DEPOSIT_CRYPTO_LANDING_PART_TWO_READ = 'depositCryptoLandingPartTwoRead_v1',
  DEPOSIT_CRYPTO_LANDING_PART_THREE_READ = 'depositCryptoLandingPartThreeRead_v1',
  DEPOSIT_CRYPTO_LANDING_PART_FOUR_READ = 'depositCryptoLandingPartFourRead_v1',
  DEPOSIT_CRYPTO_LANDING_PART_FIVE_READ = 'depositCryptoLandingPartFiveRead_v1',
  SEND_CRYPTO_LANDING_READ = 'sendCryptoLandingRead_v1',
  REMEMBER_ME = 'rememberMe_v1',
  SEND_LANDING_READ = 'sendLandingRead_v1',
  SEND_SCAM_LANDING_READ = 'sendScamLandingRead_v1',
  SEND_CRYPTO_SCAM_LANDING_READ = 'sendCryptoScamLandingRead_v1',
  EDIT_TRIGGER_ORDER_ANNOUNCEMENT_READ = 'editTriggerOrderAnnouncementRead_v1',
  SKIP_BIOMETRICS = 'skipBiometrics_v1',
  SYSTEM_THEME = 'systemTheme_v1',
  USER_DATA = 'userData_v1',
  WITHDRAWAL_LANDING_READ = 'withdrawalLandingRead_v1',
  DUSTING_MESSAGE_PROMPT = 'dustingMessagePrompt_v1',
  HIDE_ZERO_BALANCES_NOTICE_READ = 'hideZeroBalancesNoticeRead_v1',
  HIDE_ZERO_BALANCES = 'hideZeroBalances_v1',
  SWYFTX_UI_VERSION = 'swyftxUiVersion_v1',
  SHOW_UNIVERSAL_TRADE_PANEL = 'showUniversalTradePanel_v1',
  PIN_NAVIGATION_PANEL = 'pinNavigationPanel_v1',
  DISMISS_REFER_A_FRIEND_BANNER = 'dismissReferAFriendBanner_v1',
  SHOW_MARKET_TICKER = 'showMarketTicker_v1',
  STOP_LOSS_ENTRY_TOUR_VIEWED = 'stopLossEntryTourViewed_v1',
  STOP_LOSS_TOUR_VIEWED = 'stopLossTourViewed_v1',
  DO_NOT_SHOW_STOP_LOSS_TRIGGER_NOTES = 'doNotShowStopLossTriggerNotes_v1',
  MULTI_TRADE_LIMITATION_READ = 'multiTradeLimitationRead_v1',
  VIEWED_REWARDS = 'viewedRewards_v1',
  FETCH_ASSETS_FROM_ASSET_SERVICE = 'fetchAssetsFromAssetService_v1',
  VIEWED_INLINE_NETWORK_CHANGE_NOTIFICATION = 'viewedInlineNetworkChangeNotification',
  TRADE_LIST_EXPAND_STATE = 'tradeListExpandedState_v1',
  DASHBOARD_LAYOUT_TOUR = 'dashboardLayoutTour_v1',
  MARKET_TICKER_MODE = 'marketTickerMode_v1',
  MARKET_TICKER_SORT = 'marketTickerSort_v1',
  AUTO_INVEST_ENTRY_TOUR = 'autoInvestEntryTour_v1',
  CURRENT_PLATFORM = 'currentPlatform_v1',
  TRADE_PAGE_TOUR_VIEWED = 'tradePageTourViewed_v1',
  SWYFTX_PRO_TOUR_VIEWED = 'swyftxProTourViewed_v1',
  ORDERS_TILE_MINIMIZED = 'ordersTileMinimized_v1',
  NAVIGATION_HOVER_OPEN = 'navigationHoverOpen_v1',
  FIREBLOCKS_DEPOSIT_ADDRESS_CHANGES_READ = 'fireblocksDepositAddressChangesRead_v2',
  SHOW_REBRAND_BANNER = 'showRebrandBanner_v1',

  // ENTITIES
  DISMISS_ENTITY_APPLICATION_SUBMITTED_BANNER = 'dismissEntityApplicationSubmittedBanner_v1',
  DISMISS_ENTITY_APPLICATION_APPROVED_BANNER = 'dismissEntityApplicationApprovedBanner_v1',
  DISMISS_ENTITY_VERIFY_MEMBERS_BANNER = 'dismissEntityVerifyMembersBanner_v1',
  DISMISS_ENTITY_DEPOSIT_FUNDS_BANNER = 'dismissEntityDepositFundsBanner_v1',
  ENTITY_CTA_DISMISSED = 'entityCtaDismissed_v1',

  // PRO
  SHOW_ORDERBOOK = 'showOrderBook_v1',
  SHOW_ORDERS_AND_BALANCES = 'showOrdersAndBalances_v1',

  // Charts
  SHOW_SIMPLE_VOLUME = 'showSimpleVolume_v1',
  SHOW_HISTORICAL_ORDERS = 'showHistoricalOrders_v1',
  SHOW_OPEN_ORDERS = 'showOpenOrders_v1',
  SHOW_PREVIEW_ORDERS = 'showPreviewOrders_v1',

  // UTP
  SHOW_CURRENT_ASSET_ONLY = 'showCurrentAssetOnly_v1',
  HIDE_CANCELLED_ORDERS = 'hideCancelledOrders_v1',

  // Asset Discovery V4 A/B Test
  SEEN_TRENDING_AD4_MODAL = 'seenTrendingAD4Modal_v1',
}

/**
 * This storage library is an unencrypted, asynchronous, persistent, key-value storage system.
 *
 * The core storage integrated depending if react native app or react web
 * The AsyncStorage is used for mobile app and sessionStorage is for web
 */
const storage: Storage = {
  setItem: async (key, value) => {
    try {
      await swyftxStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      new SwyftxError(logTag, 'Failed to setItem in storage', err).log();
    }
  },

  getItem: async (key) => {
    try {
      const value = await swyftxStorage.getItem(key);
      return JSON.parse(value);
    } catch (err) {
      logger.error(logTag, 'Unable to get item from storage', err);
      return null;
    }
  },

  removeItem: async (key) => {
    try {
      await swyftxStorage.removeItem(key);
    } catch (err) {
      new SwyftxError(logTag, 'Failed to removeItem in storage', err).log();
    }
  },

  clear: async () => {
    try {
      await swyftxStorage.clear();
    } catch (err) {
      new SwyftxError(logTag, 'Failed to clear in storage', err).log();
    }
  },

  /*
   * This method allows you to batch the fetching of items given an array of key inputs.
   * Return the corresponding key-value objects ordered by following the sequence of the array of key inputs
   *
   * Usage: multiGet(['key1', 'key2']) => {{'key1': 'key1_value'}, {'key2', 'key2_null_value'}}
   */
  multiGet: async (keys) => {
    if (isUndefined(swyftxStorage.multiGet)) {
      const values: any = {};

      for (const key of keys) {
        const item = await storage.getItem(key);
        if (item !== undefined) values[key] = item;
      }

      return values;
    }

    try {
      const values: Array<Array<string>> = (await swyftxStorage.multiGet(keys)) || [];

      return values.reduce((result, item) => {
        if (item.length === 2) {
          return {
            ...result,
            [item[0]]: JSON.parse(item[1]),
          };
        }

        return result;
      }, {});
    } catch (err) {
      return null;
    }
  },

  multiSet: async (pairs) => {
    if (isUndefined(swyftxStorage.multiSet)) {
      for (const key in pairs) {
        storage.setItem(key, pairs[key]);
      }

      return;
    }

    try {
      const objectPairsToKeyValuePairArrays = [];
      for (const key in pairs) {
        objectPairsToKeyValuePairArrays.push([key, JSON.stringify(pairs[key])]);
      }

      await swyftxStorage.multiSet(objectPairsToKeyValuePairArrays);
    } catch (err) {
      new SwyftxError(logTag, 'Failed to multiSet in storage', err).log();
    }
  },

  logout: async () => {
    const doNotClear = [
      StorageKey.DEMO,
      StorageKey.LANGUAGE,
      StorageKey.BIOMETRICS,
      StorageKey.MOBILE_API_KEY_REF,
      StorageKey.ID_TOKEN,
      StorageKey.REMEMBER_ME,
      StorageKey.SKIP_BIOMETRICS,
      StorageKey.EMAIL,
    ];
    const clearing: Promise<void>[] = [];
    for (const key in StorageKey) {
      const lookup = (StorageKey as any)[key];
      if (!doNotClear.includes(lookup)) {
        clearing.push(storage.removeItem(lookup));
      }
    }
    await Promise.all(clearing);
  },
};

export default storage;
