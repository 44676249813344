import React, { PropsWithChildren, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';

import { TailWindTheme, useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { Themes } from '@swyftx/react-web-design-system';

import { DarkModeEnum } from '@shared/enums';
import { ThemeStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

type Props = PropsWithChildren<{
  mode?: DarkModeEnum;
}>;

const DefaultTheme: React.FC<Props> = observer(({ mode, children }) => {
  const { darkMode } = ThemeStore.useThemeStore;
  const { forceSetTheme } = useTailwindTheme();

  useEffect(() => {
    switch (mode) {
      case DarkModeEnum.DARK:
        forceSetTheme(TailWindTheme.Dark);
        break;
      case DarkModeEnum.LIGHT:
        forceSetTheme(TailWindTheme.Light);
        break;
      case DarkModeEnum.MIDNIGHT:
        forceSetTheme(TailWindTheme.Midnight);
        break;
      default:
        forceSetTheme();
    }
  }, [forceSetTheme, mode]);

  useEffect(() => () => forceSetTheme(), []);

  return <ThemeProvider theme={Themes[mode || darkMode].default}>{children}</ThemeProvider>;
});

export { DefaultTheme };
