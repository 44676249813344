import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { ListItem } from '@swyftx/aviary/atoms/List';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { FormattedText } from '@global-components/Text';

import { orderStatus, orderStatusColor, orderType } from '@shared/api/@types/portfolio';
import { assetService, TransactionHistoryItem } from '@shared/services';
import { formatDateTime } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { useOrder } from '@hooks/Orders';

import { observer } from 'mobx-react-lite';

type Props = {
  order: TransactionHistoryItem;
};

const AssetOrderItem: React.FC<Props> = observer(({ order }) => {
  const { t } = useTranslation('orders');
  const { getValue, getTriggerPrice, getOrderQuantity } = useOrder(order);
  const { openModal } = useModal();

  const primaryAsset = assetService.getAsset(order.primaryAsset);
  const secondaryAsset = assetService.getAsset(order.secondaryAsset);

  const orderColor = useMemo(() => {
    switch (orderStatusColor[order.status]) {
      case 'error':
        return 'bg-color-background-error';
      case 'success':
        return 'bg-color-background-success';
      case 'warning':
        return 'bg-color-background-warning';
      case 'primary':
      default:
        return 'bg-color-background-primary';
    }
  }, [order.status]);

  const getOrderDetails = () => (
    <>
      <FlexLayout
        direction='row'
        className='mb-8 items-start justify-start @md:items-center @md:justify-between'
        spacing={12}
        alignItems='center'
      >
        <FlexLayout direction='row' spacing={12} alignItems='center'>
          {order.orderType && (
            <Body size='small' weight='emphasis'>
              {t(orderType[order.orderType] as any)}
            </Body>
          )}
          <div className={cn('h-8 w-8 rounded-[100%]', orderColor)} />
          <Body size='small' weight='emphasis'>
            {t(orderStatus[order.statusRaw] as any)}
          </Body>
        </FlexLayout>
        <Body size='small' color='primary' className='uppercase @md:hidden'>
          {formatDateTime(new Date(order.date))}
        </Body>
      </FlexLayout>
      <Body color='secondary' className='hidden uppercase @md:block'>
        {formatDateTime(new Date(order.date))}
      </Body>
    </>
  );

  const getOrderRate = () => (
    <FlexLayout
      alignItems='center'
      className='mb-4 items-end justify-between @md:mb-0 @md:items-center @md:justify-end'
      spacing={8}
    >
      <Utility className='block @md:hidden'>Trigger price</Utility>
      <FlexLayout direction='row' spacing={4} alignItems='center'>
        <FormattedText
          typographyProps={{ weight: 'emphasis', color: 'primary' }}
          value={getTriggerPrice()}
          currency={primaryAsset}
          secondaryText={{
            typographyProps: { color: 'secondary' },
            value: `${primaryAsset?.code}/${secondaryAsset?.code}`,
          }}
        />
      </FlexLayout>
    </FlexLayout>
  );

  const renderOrderQuantity = () => (
    <FlexLayout
      direction='row'
      alignItems='center'
      className='mb-4 items-end justify-between @md:mb-0 @md:items-center @md:justify-end'
      spacing={2}
    >
      <Utility className='block @md:hidden'>Amount</Utility>
      <FlexLayout direction='row' spacing={4} alignItems='center'>
        <FormattedText
          formatBalance
          typographyProps={{ weight: 'emphasis', color: 'primary' }}
          value={getOrderQuantity()}
          currency={secondaryAsset}
          formatOpts={{ hideCode: true, appendCode: false }}
          secondaryText={{
            typographyProps: { color: 'secondary' },
            value: secondaryAsset?.code,
          }}
        />
      </FlexLayout>
    </FlexLayout>
  );

  const getOrderTotal = () => (
    <FlexLayout
      direction='row'
      alignItems='center'
      className='mb-4 items-end justify-between @md:mb-0 @md:items-center @md:justify-end'
      spacing={4}
    >
      <Utility className='block @md:hidden'>Total</Utility>
      <FlexLayout direction='row' spacing={4} alignItems='center'>
        <FormattedText
          typographyProps={{ weight: 'emphasis', color: 'primary' }}
          value={getValue(order.primaryAsset)}
          currency={primaryAsset}
          formatOpts={{ appendCode: false, hideCode: true }}
          secondaryText={{
            typographyProps: { color: 'primary' },
            value: primaryAsset?.code,
          }}
        />
      </FlexLayout>
    </FlexLayout>
  );

  return (
    <ListItem onClick={() => openModal(Modals.OrderDetails, { orderId: order.uuid, transaction: order })}>
      <FlexLayout className='mb-16 w-full flex-col p-16 pb-0 @md:flex-row' alignItems='center'>
        <div className='w-full text-left @md:w-3/12'>{getOrderDetails()}</div>
        <div className='w-full text-left @md:w-3/12 @md:text-right'>{getOrderRate()}</div>
        <div className='w-full text-left @md:w-3/12 @md:text-right'>{renderOrderQuantity()}</div>
        <div className='w-full text-left @md:w-3/12 @md:text-right'>{getOrderTotal()}</div>
      </FlexLayout>
    </ListItem>
  );
});

export default AssetOrderItem;
