/* eslint-disable camelcase */
export interface LiveRates {
  [key: number]: LiveRate | undefined;
}

export interface LiveRate {
  midPrice: string;
  askPrice: string;
  bidPrice: string;
  buyLiquidityFlag: boolean;
  sellLiquidityFlag: boolean;
  dailyPriceChange: string;
}

export type RateSide = 'midPrice' | 'askPrice' | 'bidPrice';

export interface Volume {
  '24H': number;
}

export interface Urls {
  explorer: string;
  reddit: string;
  twitter: string;
  website: string;
}

export interface Supply {
  circulating: number;
  total?: number;
  max?: number;
}

/* Note these are from the backend */
export enum AssetType {
  Fiat = 1,
  Crypto = 2,
}

export interface AssetVolume {
  [key: number]: {
    day: string;
    week: string;
    month: string;
  };
}

export interface AssetPriceChange {
  week: number;
  month: number;
}

interface PriceChange {
  week?: number;
  month?: number;
}

export interface Asset extends AssetInformation {
  /** @deprecated use `categories` */
  category?: string;
  categories?: string[];
  categoryIds: number[];
  circulatingSupply: number | string;
  color: string;
  customWarningText?: string;
  description?: string;
  displayRank: string;
  hasDetail: boolean;
  maxSupply: number | string;
  mineable?: boolean;
  priceChange: AssetPriceChange;
  rank: number;
  rankSuffix?: string;
  spread?: string;
  totalSupply: number | string;
  urls?: Urls;
  volume: AssetVolume;
}

export interface AssetDetails {
  id: number;
  description: string;
  name: string;
  category: string;
  mineable: boolean;
  spread: string;
  rank?: number;
  rankSuffix: string;
  volume?: Volume;
  priceChange?: PriceChange;
  urls: Urls;
  supply: Supply;
}

export interface AssetInformation {
  id: number;
  code: string;
  name: string;
  assetType: AssetType;
  primary: number;
  secondary: number;
  deposit_enabled: number;
  withdraw_enabled: number;
  min_deposit: number;
  min_withdrawal: number;
  tradable: number;
  price_scale: number;
  minimum_order_increment: string;
  minWithdrawalIncrementE: number;
  minimum_order: number;
  subAssetOf?: number;
  contract: string;
  assetDepositScale?: number;
  isBaseAsset: number;
  delisting: number;
  buyDisabled: number;
  networks: Network[];
  liquidityFlag?: boolean;
  sellEnabled?: number;
  active: boolean;
}

export type MetadataTag = 'memo' | 'destination_tag' | 'message' | 'payment_id';

export interface Network {
  id: number;
  networkName: string;
  networkNameFull: string;
  memo: number;
  destinationTag: number;
  message: number;
  paymentId: number;
  addressFormats: string;
  withdrawFee: number;
  withdrawMin: number;
  minWithdrawalIncrementE: number;
  metadataTag: MetadataTag;
  withdrawDisabled: boolean;
  withdrawDisableForce: boolean;
  depositDisabled: boolean;
  depositDisableForce: boolean;
}
