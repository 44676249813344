import React, { useEffect, useState } from 'react';

import { Copy } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

type Props = {
  title: string;
  value: string;
};

const COPIED_TIMEOUT = 5000;

const DepositInformationItem: React.FC<Props> = ({ title, value }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, COPIED_TIMEOUT);
    }
  }, [copied]);

  const copyValue = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
    } catch (e) {
      setCopied(false);
    }
  };

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
      <Typography fontSize={14} fontWeight={500} color='text.secondary'>
        {title}
      </Typography>

      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography PII color={copied ? 'primary' : 'text.primary'} fontSize={14} fontWeight={400}>
          {copied ? 'Copied to clipboard!' : value}
        </Typography>

        {hasClipboardPermission === 'granted' && (
          <Button variant='text' icon onClick={copyValue}>
            <Copy className='mr-8' />
            Copy
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export { DepositInformationItem };
