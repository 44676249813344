import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Paste } from '@swyftx/aviary/icons/outlined';
import { Button, pxToRem, Stack, Typography, TypographyProps } from '@swyftx/react-web-design-system';

import { ContentDivider } from '@global-components/ContentDivider';
import { CountdownDelay } from '@global-components/CountdownDelay';
import { CodeInput } from '@global-components/Input';

import { UIStore, UserStore } from '@shared/store';

import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

type Props = {
  code: string;
  setCode: (code: string) => void;
  onSubmit: () => Promise<void>;
  overrideSubmitLabel?: string;
  autoSubmit?: boolean;
  maxAttempts?: number;
  onResendCode?: () => void;
  onCancel?: () => void;
};

const CODE_LENGTH = 6;
const CODE_SEND_DELAY = 400; // ms
const INITIAL_CODE_TIMEOUT = 30; // seconds

const contentTypographyProps: TypographyProps = {
  fontSize: pxToRem(16),
  color: 'text.secondary',
};

export const MobileVerification: React.FC<Props> = ({
  overrideSubmitLabel,
  maxAttempts,
  autoSubmit,
  code,
  onResendCode,
  onSubmit,
  onCancel,
  setCode,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'verification' });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [attempts, setAttempts] = useState(0);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-read' });

  const { addToastMessage } = UIStore.useUIStore;
  const { getMaskedPhoneNumber } = UserStore.useUserStore;

  const [resendTime, setResendTime] = useState(INITIAL_CODE_TIMEOUT);

  const handleResendCode = () => {
    setAttempts(0);
    if (onResendCode) {
      setResendTime(resendTime + INITIAL_CODE_TIMEOUT);
      onResendCode();
    }
  };

  const onChangeCode = useCallback(
    (newCode: string[]) => {
      setCode(newCode.join(''));
      setError('');
    },
    [setCode],
  );

  const updateCodeFromClipboard = () => {
    navigator.clipboard.readText().then((text) => {
      addToastMessage({ severity: 'info', message: t('misc.pastedFromClipboard') });
      setCode(text);
    });
  };

  const handleSubmit = useCallback(async () => {
    setAttempts((currentAttempts) => currentAttempts + 1);
    setLoading(true);
    try {
      await onSubmit();
    } catch (e: any) {
      setError(e.message);
      addToastMessage({ severity: 'error', message: t('mobile.couldNotVerify') });
    }
    setLoading(false);
  }, [addToastMessage, onSubmit, t]);

  useEffect(() => {
    const timer = (() => {
      if (code && code.length === CODE_LENGTH && autoSubmit) {
        return window.setTimeout(() => {
          handleSubmit();
        }, CODE_SEND_DELAY);
      }
      return null;
    })();
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [code, handleSubmit, autoSubmit]);

  const showRateLimitError = (() => {
    if (maxAttempts !== undefined) {
      // Add the error if we've reached max attempts
      if (attempts >= maxAttempts) {
        return true;
      }
    }

    return false;
  })();

  return (
    <Stack spacing={3} alignItems='flex-start'>
      <Typography PII color='text.secondary' fontSize={pxToRem(16)}>
        {t('mobile.actionComplete')} <strong>{getMaskedPhoneNumber()}</strong>.
      </Typography>
      <Typography {...contentTypographyProps}>{t('mobile.doNotShare')}</Typography>
      {showRateLimitError && (
        <Typography color='error' sx={{ fontSize: pxToRem(12) }}>
          {t('misc.maxAttemptsReached')}
        </Typography>
      )}
      <Box width='100%' alignItems='center' justifyContent='center'>
        <CodeInput
          forceDisabled={showRateLimitError}
          onChange={onChangeCode}
          id='mobile-verification-code'
          length={CODE_LENGTH}
          hidePasteButton
          error={error}
          value={code}
        />
      </Box>
      <Stack direction='row' spacing={2} alignItems='center'>
        {hasClipboardPermission === 'granted' && (
          <Button variant='text' onClick={updateCodeFromClipboard}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Paste className='h-20 w-20 text-color-text-primary' />
              <Typography fontSize={pxToRem(14)} fontWeight={500} color='primary'>
                {t('misc.pasteFromClipboard')}
              </Typography>
            </Stack>
          </Button>
        )}
        {onResendCode ? (
          <CountdownDelay
            id='mobile-verification-resend-countdown'
            delay={resendTime}
            delayText={t('misc.resendCodeIn')}
            restartText={t('misc.resendCode')}
            onTimerRestart={handleResendCode}
          />
        ) : null}
      </Stack>
      {!autoSubmit ? (
        <Stack spacing={2} position='relative' width='100%'>
          <ContentDivider containerPadding={2} />
          <Stack direction='row' width='100%' height='100%' alignItems='center' justifyContent='flex-end' spacing={2}>
            {onCancel ? (
              <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onCancel}>
                {t('misc.cancel')}
              </Button>
            ) : null}
            <Button
              disabled={!code || code.length !== CODE_LENGTH || showRateLimitError}
              onClick={handleSubmit}
              variant='contained'
              loading={loading}
              disableElevation
            >
              {overrideSubmitLabel || t('misc.submit')}
            </Button>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};
