import { TransactionStatusFilter, TransactionTypeFilter } from '@shared/services';

import { TransactionsRouteType } from '@routes/Transactions/types';

const orderTypes = (type?: TransactionsRouteType) => [
  {
    value: TransactionTypeFilter.BUY,
    label: 'types.BUY',
    disabled: type ? [TransactionsRouteType.Deposits, TransactionsRouteType.Withdrawals].includes(type) : false,
  },
  {
    value: TransactionTypeFilter.SELL,
    label: 'types.SELL',
    disabled: type ? [TransactionsRouteType.Deposits, TransactionsRouteType.Withdrawals].includes(type) : false,
  },
  {
    value: TransactionTypeFilter.DEPOSIT,
    label: 'types.DEPOSIT',
    disabled: type ? ![TransactionsRouteType.Deposits].includes(type) : false,
  },
  {
    value: TransactionTypeFilter.WITHDRAWAL,
    label: 'types.WITHDRAWAL',
    disabled: type ? ![TransactionsRouteType.Withdrawals].includes(type) : false,
  },
];

const orderStatuses = [
  { value: TransactionStatusFilter.COMPLETED, label: 'status.completed' },
  { value: TransactionStatusFilter.FAILED, label: 'status.failed' },
  { value: TransactionStatusFilter.PENDING, label: 'status.open' },
];

export { orderStatuses, orderTypes };
