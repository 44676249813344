import { useEffect } from 'react';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';

export const useTriggerOrderAnnouncement = (shouldShow = true) => {
  const [hasReadAnnouncement, setHasReadAnnouncement] = useLocalStorage<boolean>(
    StorageKey.EDIT_TRIGGER_ORDER_ANNOUNCEMENT_READ,
  );
  const { openModal } = useModal();
  const shouldOpenAnnouncement = shouldShow && !hasReadAnnouncement;

  useEffect(() => {
    if (shouldOpenAnnouncement) {
      openModal(Modals.EditTriggerOrderAnnouncement);
      setHasReadAnnouncement(true);
    }
  }, [openModal, setHasReadAnnouncement, shouldOpenAnnouncement]);
};
