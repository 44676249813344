import React from 'react';

import { Error, SignIn, Success, TwoFactorLogin, NewLoginDetected } from '@Auth/components';
import { AuthStep } from '@Auth/types';

export const authSteps: { [key in AuthStep]: React.ReactElement } = {
  [AuthStep.SignIn]: <SignIn />,
  [AuthStep.TwoFactorLogin]: <TwoFactorLogin />,
  [AuthStep.Success]: <Success />,
  [AuthStep.Error]: <Error />,
  [AuthStep.NewLoginDetected]: <NewLoginDetected />,
};
