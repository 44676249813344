import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  assetCode: string;
};

const TrendingCategoryTopTradedAsset: React.FC<Props> = ({ assetCode }) => {
  const { getAssetByCode } = useMarkets();

  const asset = useMemo(() => getAssetByCode(assetCode), [assetCode, getAssetByCode]);

  if (!asset) return null;

  return (
    <FlexLayout spacing={8} justifyContent='space-between' alignItems='center' className='py-8'>
      <FlexLayout spacing={16} justifyContent='space-between' alignItems='center'>
        <AssetIcon asset={asset} size={20} />
        <FlexLayout direction='column'>
          <Body size='small' weight='emphasis' className='w-[120px] truncate'>
            {asset.name}
          </Body>
          <Body size='xsmall' color='secondary'>
            {asset.code}
          </Body>
        </FlexLayout>
      </FlexLayout>
      <NumericDataItem data={`${asset.priceChange.week.toFixed(2)}%`} size='medium' />
    </FlexLayout>
  );
};

export { TrendingCategoryTopTradedAsset };
