import React from 'react';

import { AppFeature } from 'src/config';

export enum WalletType {
  ALL_WALLETS = 'All wallets',
  TRADING_WALLETS = 'Trading wallets',
  EARNING_WALLETS = 'Earn wallets',
}

export enum WalletLayoutType {
  LIST = 'List',
  TILES = 'Tiles',
}

export type WalletTypeTab = {
  id: string;
  value: WalletType;
  miniLabel: any; // TODO translation types check docs?
  label: any;
  leading?: React.ReactNode;
  activeLeading?: React.ReactNode;
  type: 'all' | 'trade' | 'earn';
  feature: AppFeature;
};

export const typeToWalletType = {
  all: WalletType.ALL_WALLETS,
  trade: WalletType.TRADING_WALLETS,
  earn: WalletType.EARNING_WALLETS,
};
