import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutocompleteRenderInputParams, Box, TextField, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';
import { AutocompleteDropdown, AutocompleteOption } from '@global-components/AutocompleteDropdown';

import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useTheme } from '@hooks/useTheme';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { observer } from 'mobx-react-lite';

const getDefaultTextFieldStyles = (theme: Theme) => ({
  '& input': {
    fontSize: pxToRem(16),
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'flex-start',
    height: '40px',
    padding: 1,
    'MuiOutlinedInput-input': {
      paddingY: 0,
    },
    input: {
      paddingTop: '0 !important',
    },
  },
  fieldset: {
    borderColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
  },
});

type Option = AutocompleteOption<string, { label: string; code: string }>;
const ICON_SIZE = 20;

const TransactionsFiltersAsset: React.FC = observer(() => {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
  const { updateFilters, filters } = useContext(TransactionsContext);
  const { t } = useTranslation('orders');
  const { theme } = useTheme();
  const { balances } = UserStore.useUserStore;

  const assets = assetService.getAssetList().filter((x) => balances[x.id]);

  useEffect(() => {
    if (filters.asset) {
      setSelectedOption({
        label: `${filters.asset.name}-${filters.asset.code}`,
        value: filters.asset.code,
        extraState: { label: filters.asset.name, code: filters.asset.code },
      });
    } else {
      setSelectedOption(undefined);
    }
  }, [filters.asset]);

  const autocompleteOptions = useMemo(
    () =>
      assets
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((asset) => ({
          label: `${asset.name}-${asset.code}`,
          value: asset.code,
          extraState: {
            label: asset.name,
            code: asset.code,
          },
        })),
    [assets],
  );

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: Option) => (
    <li {...props}>
      <Stack justifyContent='space-between' alignItems='center' direction='row' padding={0} width='100%'>
        <Stack spacing={1.5} alignItems='center' direction='row'>
          <AssetIcon asset={option.value} size={ICON_SIZE} />
          <Stack spacing={0} alignItems='flex-start' justifyContent='flex-start' direction='column'>
            <Typography fontSize={16}>{option.extraState?.label}</Typography>
            <Typography fontSize={14} color='text.secondary'>
              {option.extraState?.code}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </li>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    // only show the name we had to append code to make it searchable
    <TextField
      {...params}
      placeholder={t('asset.placeholder')}
      InputProps={{
        ...params.InputProps,
        ...{
          startAdornment:
            selectedOption?.extraState && (params.inputProps as { value: string }).value === selectedOption.label ? (
              <Stack justifyContent='center' alignItems='center' paddingRight={1} paddingLeft={1} height='100%'>
                <AssetIcon asset={selectedOption.extraState.code} size={ICON_SIZE} />
              </Stack>
            ) : (
              <Stack justifyContent='center' alignItems='center' paddingRight={1} paddingLeft={1} height='100%'>
                <Box
                  bgcolor={theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[600]}
                  borderRadius={ICON_SIZE / 2}
                  minHeight={ICON_SIZE}
                  minWidth={ICON_SIZE}
                  component='span'
                />
              </Stack>
            ),
        },
      }}
      inputProps={{
        ...params.inputProps,
        value: selectedOption?.extraState?.code ?? params.inputProps.value,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          if (params.inputProps.onChange) params.inputProps.onChange(e);
          setSelectedOption(undefined);
        },
      }}
      sx={getDefaultTextFieldStyles(theme)}
    />
  );
  return (
    <FormControl sx={{ width: '100%', marginLeft: 0, backgroundColor: 'background.paper', height: '40px' }}>
      <AutocompleteDropdown
        height='40px'
        id='entity-member-country-dropdown'
        options={autocompleteOptions}
        value={selectedOption}
        placeholder={t('asset.placeholder')}
        onChange={(e) => {
          setSelectedOption(e);
          updateFilters({ asset: assetService.getAssetByCode(e.value) });
        }}
        customRenderOption={renderOption}
        customRenderInput={renderInput}
        onDeselect={() => updateFilters({ asset: undefined })}
      />
    </FormControl>
  );
});

TransactionsFiltersAsset.displayName = 'TransactionsFiltersAsset';

export { TransactionsFiltersAsset };
