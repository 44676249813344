import React, { useMemo, useState } from 'react';

import Done from '@mui/icons-material/Done';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import { Menu, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { WithdrawalReason, withdrawalService } from '@shared/services';

import { observer } from 'mobx-react-lite';
import { useSize } from 'react-use';

import { reasonLabels } from '../SendCryptoModal.const';

type Props = {
  setSelectedReason: (option: WithdrawalReason | null) => void;
  selectedReason: WithdrawalReason | null;
};

const SendCryptoReasonDropdown: React.FC<Props> = observer(({ setSelectedReason, selectedReason }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [widthSize, { width }] = useSize(<Box width='100%' height={0} />);
  const theme = useTheme();

  const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600];
  const bgColor = theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[700];

  const reasons = useMemo(() => withdrawalService.getSortedWithdrawalReasons().map((reason) => reason), []);

  return (
    <>
      <ButtonBase component='div' onClick={handleOpen}>
        <Stack
          justifyContent='space-between'
          border='1px solid'
          borderRadius='5px'
          borderColor={borderColor}
          bgcolor={bgColor}
          height={pxToRem(40)}
          direction='row'
          width='100%'
          spacing={2}
          padding={1}
        >
          <Typography fontSize={pxToRem(16)} color='text.secondary'>
            {selectedReason ? reasonLabels[selectedReason.key] : 'Select a reason'}
          </Typography>
          <KeyboardArrowDown width={pxToRem(14)} height={pxToRem(14)} />
        </Stack>
      </ButtonBase>
      {widthSize}
      <Menu
        onClose={handleClose}
        sx={{
          '& li': {
            minWidth: width,
          },
          '& ul': {
            maxHeight: '20rem',
          },
          '& .MuiList-root': {
            paddingY: 0,
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={Boolean(anchorEl)}
      >
        {reasons.map((reason) => (
          <MenuItem onClick={() => setSelectedReason(reason)} key={reason.key}>
            <Stack direction='row' justifyContent='space-between' width='100%' alignItems='center'>
              <Typography sx={{ padding: 1 }} fontSize={pxToRem(16)}>
                {reasonLabels[reason.key]}
              </Typography>
              {selectedReason === reason && <Done fontSize='small' color='primary' />}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

SendCryptoReasonDropdown.displayName = 'SendCryptoReasonDropdown';

export { SendCryptoReasonDropdown };
