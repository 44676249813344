import { TabItem } from '@swyftx/aviary/atoms/Tabs';
import { MarketMoversType } from '@swyftx/aviary/organisms/Assets/MarketMoversCard/MarketMoversCard.types';

import { Asset } from '@shared/api';

export type MarketSelectAssetListType = 'buy' | 'sell' | 'priceAlert' | 'multi';

export type MarketAssetFilterType = 'all' | 'owned' | 'new' | 'favourites';

export type MarketActivityType = 'favourites' | 'gainers' | 'losers' | 'new' | 'owned';

export type PortfolioViewDensity = 'detailed' | 'simple';

export type PortfolioPerformanceTab = TabItem<MarketActivityType>;

export const defaultMarketActivityTabs: PortfolioPerformanceTab[] = [
  {
    title: 'Favourites',
    value: 'favourites',
  },
  {
    title: 'Gainers',
    value: 'gainers',
  },
  {
    title: 'Losers',
    value: 'losers',
  },
  {
    title: 'New',
    value: 'new',
  },
];

export type CategoriesTableData = {
  rank: string;
  category: string;
  numAssets: string;
  gainersLosers: string;
  dailyChange: string;
  weeklyChange: string;
  monthlyChange: string;
  dailyVolume: string;
  marketCap: string;
  trade: string;

  // xs
  marketCapChange: string;
};

export type MarketActivityCard = {
  key: MarketMoversType;
  title: string;
  actions: React.ReactElement | null;
  assets: Asset[];
};
