import React, { useCallback, useContext, useMemo } from 'react';

import capitalize from '@mui/utils/capitalize';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Icon } from '@swyftx/aviary/atoms/Icon';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';
import { Tick } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Big } from '@shared/safe-big';
import { formatCurrency } from '@shared/utils';

import { observer } from 'mobx-react-lite';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { QuickBuyContext } from 'src/lib/trade/context';
import { useQuickBuyPaymentMethods } from 'src/lib/trade/hooks';
import { useQuickBuyAmounts } from 'src/lib/trade/hooks/QuickBuy/useQuickBuyAmounts';

import { QuickBuyWidgetReviewItem } from '../QuickBuyWidgetReview/QuickBuyWidgetReviewItem';

const QuickBuyWidgetComplete: React.FC = observer(() => {
  const { getAssetById } = useMarkets();
  const { setCurrentStep, completedOrder, buyType } = useContext(QuickBuyContext);
  const { setAmount } = useQuickBuyAmounts();
  const { getPaymentOption, selectedPaymentMethod } = useQuickBuyPaymentMethods();
  const { navigate } = useNavigateRoute();

  const primaryAsset = useMemo(
    () => getAssetById(completedOrder?.order?.primary_asset),
    [getAssetById, completedOrder?.order.primary_asset],
  );
  const secondaryAsset = useMemo(
    () => getAssetById(completedOrder?.order?.secondary_asset),
    [getAssetById, completedOrder?.order.secondary_asset],
  );

  const paymentMethod = useMemo(
    () => getPaymentOption(selectedPaymentMethod),
    [getPaymentOption, selectedPaymentMethod],
  );

  const feePercentage = useMemo(
    () => (completedOrder?.feePercentage ? Big(completedOrder.feePercentage).times(100).toString() : undefined),
    [completedOrder?.feePercentage],
  );

  const onDone = () => {
    setAmount('', true);
    setCurrentStep('values');
  };

  const destinationWallet = useMemo(() => {
    if (buyType === 'buy') {
      return secondaryAsset?.code;
    }

    return primaryAsset?.code;
  }, [buyType, secondaryAsset, primaryAsset]);

  const viewInWallet = useCallback(() => {
    navigate(NavigationURLs.SingleWallet, { pathParams: { type: 'all', asset: destinationWallet } });
  }, [destinationWallet, navigate]);

  const fee = useMemo(
    () =>
      formatCurrency(Big(completedOrder?.feePercentage).mul(Big(completedOrder?.order.total)), primaryAsset, {
        appendCode: true,
      }),
    [completedOrder?.feePercentage, completedOrder?.order.total, primaryAsset],
  );

  return (
    <FlexLayout direction='column' spacing={16} alignItems='center' justifyContent='start' className='h-full w-full'>
      <FlexLayout direction='column' className='h-full w-full p-24 pt-32' spacing={24}>
        <FlexLayout direction='column' alignItems='center' spacing={4}>
          <Icon color='success' className='h-48 w-48 p-8' icon={<Tick className='h-32 w-32' />} />
          <Heading color='primary' size='h3'>
            Order completed
          </Heading>
          <Body color='secondary'>{destinationWallet} has been added to your wallet.</Body>
        </FlexLayout>
        <FlexLayout direction='column' alignItems='center' spacing={16}>
          <QuickBuyWidgetReviewItem title='Order type' value={capitalize(buyType)} />
          <QuickBuyWidgetReviewItem
            title={buyType === 'buy' ? 'Buy price' : 'Sell price'}
            value={formatCurrency(completedOrder?.order.rate, primaryAsset, { appendCode: true })}
          />
          {paymentMethod && buyType === 'buy' && (
            <QuickBuyWidgetReviewItem title='Paid with' value={paymentMethod.title} />
          )}
          {buyType === 'sell' && <QuickBuyWidgetReviewItem title='Sold to' value={`${primaryAsset?.code} Wallet`} />}
          <QuickBuyWidgetReviewItem title={`Fee ${feePercentage ? `(${feePercentage}%)` : ''}`} value={fee} />

          {buyType === 'buy' && (
            <>
              <QuickBuyWidgetReviewItem
                title='Total spent'
                value={formatCurrency(completedOrder?.order.total, primaryAsset, { appendCode: true })}
              />

              {secondaryAsset && primaryAsset && completedOrder?.order.amount && (
                <QuickBuyWidgetReviewItem
                  title='Amount received'
                  icon={<AssetIcon asset={secondaryAsset} size={16} />}
                  value={formatCurrency(completedOrder?.order.amount, secondaryAsset)}
                  subValue={`${formatCurrency(
                    Big(completedOrder.order.total).mul(Big(1).minus(Big(completedOrder?.feePercentage))),
                  )} ${primaryAsset.code}`}
                />
              )}
            </>
          )}

          {buyType === 'sell' && (
            <>
              {secondaryAsset && primaryAsset && completedOrder?.order.amount && (
                <QuickBuyWidgetReviewItem
                  title='Total sold'
                  icon={<AssetIcon asset={secondaryAsset} size={16} />}
                  value={formatCurrency(completedOrder?.order.amount, secondaryAsset)}
                  subValue={`${formatCurrency(
                    Big(completedOrder.order.total).mul(Big(1).minus(Big(completedOrder?.feePercentage))),
                    primaryAsset,
                  )} ${primaryAsset.code}`}
                />
              )}

              <QuickBuyWidgetReviewItem
                title='Amount received'
                value={formatCurrency(completedOrder?.order.total, primaryAsset)}
              />
            </>
          )}
        </FlexLayout>

        <FlexLayout className='h-full w-full' alignItems='end' justifyContent='end' direction='column' spacing={8}>
          <Button size='lg' variant='filled' className='w-full' onClick={viewInWallet}>
            View in wallet
          </Button>
          <Button size='lg' variant='outlined' className='w-full' onClick={onDone}>
            Make another trade
          </Button>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
});

export { QuickBuyWidgetComplete };
