import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const AddFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M13.5 2.75C13.5 2.05964 12.9404 1.5 12.25 1.5C11.5596 1.5 11 2.05964 11 2.75V11.25H2.75C2.05964 11.25 1.5 11.8096 1.5 12.5C1.5 13.1904 2.05964 13.75 2.75 13.75H11V21.25C11 21.9404 11.5596 22.5 12.25 22.5C12.9404 22.5 13.5 21.9404 13.5 21.25V13.75H21.25C21.9404 13.75 22.5 13.1904 22.5 12.5C22.5 11.8096 21.9404 11.25 21.25 11.25H13.5V2.75Z'
      fill='currentColor'
    />
  </svg>
);

export { AddFilled };
