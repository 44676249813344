import { useCallback } from 'react';

import { Asset, AssetHistoryItem, AssetType, OrderType } from '@shared/api';
import { FiatCodeEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { formatCurrency, formatDateTime } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { useTransactionOrderType } from './useTransactionOrderType';
import { useTransferOrderType } from './useTransferOrderType';
import { isTransfer } from '../utils/Transactions.utils';

const useTransactionDetails = () => {
  const { convertRate } = RatesStore.useRatesStore;
  const countryAsset = useCountryAsset();
  const { parseOrderType, parseOrderStatus } = useTransactionOrderType();
  const { parseTransferOrderType, parseTransferStatus } = useTransferOrderType();

  const isSellOrder = (transaction: AssetHistoryItem) => {
    if (!transaction.orderType) return false;

    return [
      OrderType.StopSell,
      OrderType.DustSell,
      OrderType.MarketSell,
      OrderType.OTCSell,
      OrderType.TriggerSell,
    ].includes(transaction.orderType);
  };

  const getAmount = useCallback((transaction: AssetHistoryItem, includeFee = true) => {
    const { limitAsset, secondaryAsset, secondaryAmount, feeAmount } = transaction;

    if (!includeFee || limitAsset === secondaryAsset) return Big(secondaryAmount).abs();

    if (isSellOrder(transaction)) {
      return Big(secondaryAmount).abs().minus(feeAmount).abs();
    } else {
      return Big(secondaryAmount).abs().plus(feeAmount).abs();
    }
  }, []);

  const getTotal = useCallback(
    (transaction: AssetHistoryItem, includeFee = true) => {
      const { limitAsset, primaryAsset, primaryAmount, feeAmount, secondaryAmount } = transaction;

      if (!countryAsset) return Big(0);

      if (isTransfer(transaction)) {
        return Big(convertRate(transaction.secondaryAsset, countryAsset, secondaryAmount, 'midPrice')).abs();
      }

      if (!includeFee || limitAsset === primaryAsset) return Big(primaryAmount).abs();

      if (isSellOrder(transaction)) {
        return Big(primaryAmount).abs().plus(feeAmount).abs();
      } else {
        return Big(primaryAmount).abs().minus(feeAmount).abs();
      }
    },
    [convertRate, countryAsset],
  );

  const getTriggerPrice = useCallback(
    (transaction: AssetHistoryItem, primaryAsset?: Asset, secondaryAsset?: Asset, options?: { noSuffix?: boolean }) => {
      const { noSuffix } = options || {};
      if (isTransfer(transaction)) return '-';

      let value = Big(0);

      // If the transaction has a trigger price, use it but not for market trades
      if (
        transaction.trigger &&
        transaction.orderType &&
        ![OrderType.MarketBuy, OrderType.MarketSell].includes(transaction.orderType)
      ) {
        if (isSellOrder(transaction)) {
          value = Big(1).div(transaction.trigger);
        } else {
          value = Big(transaction.trigger);
        }
      } else {
        const amount = getAmount(transaction);
        const total = getTotal(transaction);
        value = total.div(amount);
      }

      const triggerPrice = formatCurrency(value, primaryAsset, { appendCode: false, hideCode: true });

      if (noSuffix) return triggerPrice;

      return `${triggerPrice} ${primaryAsset?.code}/${secondaryAsset?.code}`;
    },
    [getTotal],
  );

  const isMarketOrder = useCallback(
    (transaction: AssetHistoryItem) =>
      transaction.orderType !== null && [OrderType.MarketBuy, OrderType.MarketSell].includes(transaction.orderType),
    [],
  );

  const getTransactionDetails = useCallback(
    (transaction: AssetHistoryItem, options?: { noSuffix?: boolean }) => {
      let primaryAsset = assetService.getAsset(transaction.primaryAsset);
      const secondaryAsset = assetService.getAsset(transaction.secondaryAsset);
      const fallbackAsset = assetService.getAssetByCode(FiatCodeEnum.AUD);

      if (isTransfer(transaction)) {
        primaryAsset = countryAsset || fallbackAsset;
      }

      return {
        orderType: isTransfer(transaction) ? parseTransferOrderType(transaction) : parseOrderType(transaction),
        date: formatDateTime(transaction.date),
        amount: formatCurrency(getAmount(transaction, true), secondaryAsset, {
          isApproximate: transaction.limitAsset === transaction.primaryAsset && !isMarketOrder(transaction),
        }),
        approximateAmount: transaction.limitAsset === transaction.primaryAsset && !isMarketOrder(transaction),
        amountAssetCode: secondaryAsset?.code,
        totalAssetCode: primaryAsset?.code,
        triggerPrice: getTriggerPrice(transaction, primaryAsset, secondaryAsset, options),
        rawTriggerPrice: getTotal(transaction).div(getAmount(transaction)).toNumber(),
        total: formatCurrency(getTotal(transaction, true), primaryAsset, {
          appendCode: primaryAsset?.assetType !== AssetType.Fiat && !options?.noSuffix,
          isApproximate: transaction.limitAsset === transaction.secondaryAsset && !isMarketOrder(transaction),
        }),
        approximateTotal: transaction.limitAsset === transaction.secondaryAsset && !isMarketOrder(transaction),

        status: isTransfer(transaction) ? parseTransferStatus(transaction) : parseOrderStatus(transaction),
      };
    },
    [
      countryAsset,
      getAmount,
      getTotal,
      getTriggerPrice,
      isMarketOrder,
      parseOrderStatus,
      parseOrderType,
      parseTransferOrderType,
      parseTransferStatus,
    ],
  );

  return {
    getTransactionDetails,
  };
};

export { useTransactionDetails };
