import React, { useContext, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { WalletFilled } from '@swyftx/aviary/icons/filled';
import { Wallet } from '@swyftx/aviary/icons/outlined';

import { StorageKey } from '@shared/storage';

import { WalletContext } from '@Wallet/Wallet.context';

import { observer } from 'mobx-react-lite';

const WalletListHideZeroBalancesFilter: React.FC = observer(() => {
  const [hideZeroBalancesNoticeRead, setHideZeroBalancesNoticeRead] = useState<boolean>(
    Boolean(JSON.parse(localStorage.getItem(StorageKey.HIDE_ZERO_BALANCES_NOTICE_READ) || 'false')),
  );
  const { setHideZeroBalances, hideZeroBalances } = useContext(WalletContext);

  const dismiss = () => {
    localStorage.setItem(StorageKey.HIDE_ZERO_BALANCES_NOTICE_READ, JSON.stringify(true));
    setHideZeroBalancesNoticeRead(true);
  };

  return (
    <>
      <Modal
        open={!hideZeroBalancesNoticeRead && hideZeroBalances}
        onClose={dismiss}
        title='Empty wallets hidden'
        className='max-h-[305px] w-full max-w-[600px]'
      >
        <FlexLayout direction='column' spacing={24} className='px-24 pb-24'>
          <Body size='large'>
            You’ve just chosen to hide wallets with zero balance. If you want to view them again, simply tap on the same
            icon.
          </Body>
          <Body size='large'>Psst! We won’t show you this again!</Body>

          <FlexLayout className='w-full' alignItems='center' justifyContent='end'>
            <Button onClick={dismiss} size='lg'>
              Dismiss
            </Button>
          </FlexLayout>
        </FlexLayout>
      </Modal>

      <Button
        layout='icon'
        variant='outlined'
        onClick={() => setHideZeroBalances(!hideZeroBalances)}
        tooltip={hideZeroBalances ? 'Show small balances' : 'Hide small balances'}
        leadingIcon={
          hideZeroBalances ? (
            <WalletFilled id='wallet-list-icon' className='h-20 w-20 text-color-text-accent' />
          ) : (
            <Wallet id='wallet-list-icon' className='h-20 w-20 text-color-text-primary' />
          )
        }
      />
    </>
  );
});

WalletListHideZeroBalancesFilter.displayName = 'WalletListHideZeroBalancesFilter';

export { WalletListHideZeroBalancesFilter };
