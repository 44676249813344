import { useMemo } from 'react';

import { HistoricalOrder } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { formatCurrency } from '@shared/utils';

import { getOrderSide } from 'src/lib/UniversalTrade/components/OrdersTile/utils';

import { isTriggerOrder } from '../utils';

export const useOrderAmount = (order: HistoricalOrder) => {
  const orderSide = getOrderSide(order.order_type);

  const rate = useMemo(() => {
    if (order.rate) return order.rate;

    if (orderSide === 'Buy') return order.trigger || Big(1);

    return Big(1).div(order.trigger || 1);
  }, [order.rate, order.trigger, orderSide]);

  const amount = useMemo(() => {
    if (order.amount) return order.amount;

    if (order.quantity_asset === order.secondary_asset) return order.quantity;

    return Big(order.quantity).div(rate);
  }, [order.amount, order.quantity, order.quantity_asset, order.secondary_asset, rate]);

  const amountPlusFee = useMemo(() => {
    if (order.feeAsset === order.secondary_asset) {
      return Big(amount)
        .plus(order.feeAmount || 0)
        .toString();
    }
    return amount.toString();
  }, [amount, order.feeAmount, order.feeAsset, order.secondary_asset]);

  const secondaryAmount = useMemo(() => Big(amountPlusFee).times(rate).toString(), [amountPlusFee, rate]);

  const secondaryAsset = assetService.getAsset(order?.secondary_asset);
  const primaryAsset = assetService.getAsset(order?.primary_asset);

  if (isTriggerOrder(order.order_type)) {
    return {
      amount: formatCurrency(amount, secondaryAsset, {
        appendCode: true,
        hideCode: false,
        isApproximate: order.quantity_asset !== order.secondary_asset,
      }),
      secondaryAmount: formatCurrency(secondaryAmount, primaryAsset, {
        appendCode: true,
        hideCode: false,
        isApproximate: order.quantity_asset !== order.primary_asset,
      }),
    };
  }

  return {
    amount: formatCurrency(amountPlusFee, secondaryAsset, { appendCode: true, hideCode: false }),
    secondaryAmount: formatCurrency(secondaryAmount, primaryAsset, { hideCode: false, appendCode: true }),
  };
};
