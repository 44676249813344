import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { Asset } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';

import { QuickFilterUsedValueType } from 'src/context/Avo/generated-avo';

import { MarketAssetFilterType } from '../types/Markets.types';

const useMarketAnalytics = () => {
  const { pathname: screen } = useLocation();
  const avo = useAvo();

  const getQuickFilterValue = useCallback((quickFilterUsed: MarketAssetFilterType): QuickFilterUsedValueType => {
    switch (quickFilterUsed) {
      case 'favourites':
        return 'Favourites';
      case 'new':
      case 'owned':
        return 'Owned';
      case 'all':
      default:
        return 'All assets';
    }
  }, []);

  const assetSelected = useCallback(
    (
      asset: Asset,
      searchValue: string,
      quickFilterUsed: MarketAssetFilterType,
      modalName?: string,
      featureName?: string,
    ) => {
      avo.assetSelected({
        assetCode: asset.code,
        screen,
        searchValue,
        quickFilterUsed: getQuickFilterValue(quickFilterUsed),
        modalName,
        featureName,
      });
    },
    [avo, getQuickFilterValue, screen],
  );

  return {
    assetSelected,
  };
};

export { useMarketAnalytics };
