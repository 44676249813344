import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Skeleton, Stack } from '@swyftx/react-web-design-system';

import { CryptoNetworkSelector } from '@global-components/CryptoNetworkSelector';

import { Asset, Network } from '@shared/api/@types/markets';
import { CryptoAddress } from '@shared/api/@types/trade';
import { UIStore } from '@shared/store';

import WalletService from '@services/WalletService';

import { observer } from 'mobx-react-lite';

import { GenerateDepositAddress } from './GenerateDepositAddress';
import { ReceiveCryptoInformationItem } from './ReceiveCryptoInformationItem';
import { ReceiveCryptoNotifications } from './ReceiveCryptoNotifications';

type Props = {
  asset: Asset;
};

const ReceiveCryptoReceiveDetails: React.FC<Props> = observer(({ asset }) => {
  const { addToastMessage } = UIStore.useUIStore;
  const [network, setNetwork] = useState<Network | undefined>();
  const [address, setAddress] = useState<CryptoAddress | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [generateAddressLoading, setGenerateAddressLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setAddress(undefined);
    setNetwork(undefined);
    setError(false);
  }, [asset]);

  // Filters out force disabled networks as these are permanently off
  const filteredNetworks = useMemo(() => asset.networks.filter((n) => !n.depositDisableForce), [asset]);
  const hasNoNetworksAvailable = useMemo(() => !filteredNetworks.length, [filteredNetworks.length]);

  const getDepositAddress = useCallback(async () => {
    if (!network || Boolean(network.depositDisabled)) {
      setError(false);
      setLoading(false);
      return;
    }
    try {
      setError(false);
      setLoading(true);
      setAddress(undefined);
      const depositAddress = await WalletService.getDepositAddressV2(asset.id, network.id);
      if (depositAddress.addresses.length === 1) {
        setAddress(depositAddress.addresses[0]);
      }
    } catch (e) {
      setAddress(undefined);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [asset.id, network]);

  useEffect(() => {
    if (network && filteredNetworks.find((n) => n.id === network.id)) getDepositAddress();
  }, [network, asset.id, filteredNetworks, getDepositAddress]);

  const generateDepositAddress = useCallback(async () => {
    if (!asset || !network) return;
    setGenerateAddressLoading(true);
    try {
      const generatedDepositAddress = await WalletService.generateDepositAddressV2(asset.id, network.id);
      if (generatedDepositAddress.addresses.length === 1) setAddress(generatedDepositAddress.addresses[0]);
    } catch (e) {
      addToastMessage({
        severity: 'error',
        message: 'There was an error generating deposit address. Please try again or contact support.',
      });
    }
    setGenerateAddressLoading(false);
  }, [addToastMessage, asset, network]);

  const memoTitle = useMemo(() => {
    if (network?.destinationTag) return 'destination tag';
    return 'memo';
  }, [network]);

  if (hasNoNetworksAvailable) {
    return (
      <FlexLayout direction='column' spacing={16}>
        <Notification title='Crypto deposits unavailable for this asset' severity='destructive'>
          <Body>We do not currently support crypto deposits for this asset</Body>
        </Notification>
      </FlexLayout>
    );
  }

  return (
    <FlexLayout direction='column' spacing={16} className='PII'>
      <CryptoNetworkSelector asset={asset} selectedNetworkId={network?.id} onSelectNetwork={setNetwork} />

      {network && (
        <FlexLayout direction='column' spacing={16}>
          <ReceiveCryptoNotifications network={network} asset={asset} address={address} error={error} />

          {!network?.depositDisabled && address && address.address_details.address && (
            <ReceiveCryptoInformationItem
              title='Address'
              subTitle={`This deposit address is intended specifically for ${asset.code} deposits on the ${network.networkName} network.`}
              value={address?.address_details.address}
              disabled={network?.depositDisabled}
            />
          )}

          {!address && !loading && !network?.depositDisabled && (
            <GenerateDepositAddress
              generateDepositAddress={generateDepositAddress}
              generateAddressLoading={generateAddressLoading}
            />
          )}

          {!network?.depositDisabled && address && address.address_details.metadata && (
            <ReceiveCryptoInformationItem
              title={`Deposit ${memoTitle}`}
              value={address?.address_details.metadata}
              subTitle={`This deposit ${memoTitle} is intended specifically for ${asset.code} deposits on the ${network.networkName} network.`}
              disabled={network?.depositDisabled}
            />
          )}
          {loading && (
            <Stack direction='column'>
              <Skeleton variant='text' width='150px' height='20px' sx={{ marginBottom: 1 }} />
              <Stack direction='row' spacing={2}>
                <Skeleton variant='rectangular' width='100px' height='100px' />
                <Stack direction='column' width='100%'>
                  <Skeleton variant='rectangular' width='100%' height='48px' sx={{ marginBottom: 3 }} />
                  <Skeleton variant='text' width='300px' height='18px' />
                </Stack>
              </Stack>
            </Stack>
          )}
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

export { ReceiveCryptoReceiveDetails };
