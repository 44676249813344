import React from 'react';

import { ThemeProvider } from '@mui/material/styles';

import { Themes } from '@swyftx/react-web-design-system';

import { ThemeStore } from '@shared/store';
import { EntityColor } from '@shared/store/userStore/@types/userTypes';

import { observer } from 'mobx-react-lite';

type Props = {
  entityColor: EntityColor;
  children?: React.ReactNode;
};

const EntityTheme: React.FC<Props> = observer(({ children, entityColor }) => {
  const { darkMode } = ThemeStore.useThemeStore;

  return <ThemeProvider theme={Themes[darkMode][entityColor]}>{children}</ThemeProvider>;
});

export { EntityTheme };
