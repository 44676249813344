import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';

import { WithdrawalAddress } from '@shared/services';

import { AccountDetails } from '../AccountDetails';
import { RemoveConfirmation } from '../RemoveConfirmation';

interface SingleWalletManageAccountsProps {
  accountDetailsOpen: boolean;
  address: WithdrawalAddress;
  setAccountDetailsOpen: Dispatch<SetStateAction<boolean>>;
  isFiat: boolean;
}

export const SingleWalletManageAccountDetailsModal: React.FC<SingleWalletManageAccountsProps> = ({
  address,
  accountDetailsOpen,
  setAccountDetailsOpen,
  isFiat,
}) => {
  const [modalTitle, setModalTitle] = React.useState<string>('Account Details');
  const [confirmAccountRemoval, setConfirmAccountRemoval] = React.useState(false);

  useEffect(() => {
    if (confirmAccountRemoval) {
      setModalTitle(isFiat ? 'Remove Account' : 'Remove Address');
    } else {
      setModalTitle(isFiat ? 'Account Details' : 'Address Details');
    }
  }, [confirmAccountRemoval, isFiat]);

  return (
    <Modal
      id='manage-fiat-withdrawal-address-manage-accounts-remove-confirmation'
      open={accountDetailsOpen}
      onClose={() => {
        setAccountDetailsOpen(false);
        setConfirmAccountRemoval(false);
      }}
      className='w-[600px]'
      title={modalTitle}
    >
      <FlexLayout direction='column' className='px-24 pb-24'>
        {confirmAccountRemoval && (
          <RemoveConfirmation
            isFiat={isFiat}
            address={address}
            setConfirmRemoveAccount={setConfirmAccountRemoval}
            setAccountDetailsOpen={setAccountDetailsOpen}
          />
        )}

        {!confirmAccountRemoval && (
          <AccountDetails address={address} isFiat={isFiat} setConfirmRemoveAccount={setConfirmAccountRemoval} />
        )}
      </FlexLayout>
    </Modal>
  );
};
