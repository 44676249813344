import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableAddress: React.FC<OrdersTableColumnProps> = ({ item, mobile, width }) => {
  const { t } = useTranslation('orders');

  const getAddressMetadata = () => {
    const { bsb, memo, reference } = item.addressMetadata || {};

    if (bsb) {
      return ` (BSB ${bsb})`;
    }
    if (memo) {
      return ` (Memo ${memo})`;
    }
    if (reference) {
      return ` (Reference ${reference})`;
    }

    return '';
  };

  const getAddress = () => {
    const { address } = item;

    if (!address) return '';

    if (address.length / 2 > 5) {
      return `${address.slice(0, 5)}....${address.slice(address.length - 5)}`;
    }
    return `....${address.slice(address.length - 5)}`;
  };

  return (
    <Stack
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      flex={2}
      paddingRight={mobile ? 1 : 0}
      key={`${item.uuid}_amount`}
      justifyContent={mobile ? 'space-between' : 'flex-end'}
      alignItems={mobile ? 'flex-start' : 'center'}
      direction='row'
    >
      {mobile && (
        <Typography color='text.secondary' fontWeight={400} fontSize={14}>
          {t('ordersTable.headers.address')}
        </Typography>
      )}
      <Stack direction='column' alignItems='flex-end' spacing={0}>
        <Typography number color='text.primary' fontSize={14}>
          {item.label}
        </Typography>
        <Typography number color='text.secondary' fontSize={16} textAlign='right'>
          {getAddress()} {getAddressMetadata()}
        </Typography>
      </Stack>
    </Stack>
  );
};

export { OrdersTableAddress };
