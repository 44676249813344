import React, { useMemo } from 'react';

import { cn } from '@shared/utils/lib/ui';

import { ChipProps, chipIconVariants, chipVariants } from './Chip.styles';
import { FlexLayout } from '../Layout/Flex';
import { Tooltip } from '../Tooltip/Tooltip';
import { Body } from '../Typography/Body';

const Chip = React.forwardRef<HTMLDivElement, Omit<ChipProps, 'style'>>(
  ({ className, leadingIcon, size = 'md', variant = 'default', bodyProps, color = 'info', children, ...rest }, ref) => {
    const fontSize = useMemo(() => {
      switch (size) {
        case 'sm':
          return 'xsmall';
        case 'md':
          return 'small';
        default:
          return 'small';
      }
    }, [size]);

    const content = useMemo(() => {
      if (!children) return null;

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Body size={fontSize} {...bodyProps} weight='emphasis'>
          {children}
        </Body>
      );
    }, [children, fontSize, bodyProps]);

    return (
      <div
        ref={ref}
        className={cn(chipVariants({ variant, size, color }), className)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <Tooltip sideOffset={16} side='bottom'>
          <FlexLayout alignItems='center' justifyContent='center' spacing={4} className='w-full'>
            {leadingIcon &&
              React.cloneElement(leadingIcon, {
                className: cn(chipIconVariants({ variant, size, color }), leadingIcon.props.className),
              })}
            {content}
          </FlexLayout>
        </Tooltip>
      </div>
    );
  },
);

Chip.displayName = 'Chip';

export { Chip, chipVariants };
