import { useCallback, useContext, useEffect, useMemo } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { TourGuideContext, TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { useFeatureDiscovery } from '../../hooks/useFeatureDiscovery';

export const useTradePageTour = () => {
  const { startTour } = useContext(TourGuideContext);
  const [viewedTour, setViewedTour] = useLocalStorage<boolean>(StorageKey.TRADE_PAGE_TOUR_VIEWED, false);
  const [viewedAutoInvestTour] = useLocalStorage<boolean>(StorageKey.AUTO_INVEST_ENTRY_TOUR);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { showFeatureDiscovery } = useFeatureDiscovery();
  const tradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const { navigate } = useNavigateRoute();

  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const isSmallScreen = useMemo(() => isXs || isSm, [isSm, isXs]);

  const navigateToTradePage = useCallback(() => {
    navigate(NavigationURLs.UniversalTrade);
  }, [navigate]);

  const tourItem = useMemo(
    (): TourGuideData => ({
      name: 'Auto Invest Entry Tour',
      description: 'Shows how to access the new Auto Invest page',
      showSteps: true,
      steps: [
        {
          title: 'Trade page updates',
          description: 'We’ve simplified the Trade page to make finding, viewing and trading assets easier.',
          image: 'trade_tour',
          spotlightElementId: 'nav_item_trade',
          alignment: isSmallScreen ? 'center' : 'right',
          borderRadius: 16,
          onNext: navigateToTradePage,
        },
        {
          title: 'Change asset',
          description: 'Here is where you can change the asset you are currently viewing.',
          spotlightElementId: 'asset-picker',
          alignment: 'center',
          borderRadius: 16,
        },
        {
          title: 'Asset price & information',
          description: 'Find out more about the asset here, including latest price and 24hr changes.',
          spotlightElementId: 'asset-stats',
          alignment: isSmallScreen ? 'center' : 'center',
          borderRadius: 16,
        },
        {
          title: 'Like the look of this asset?',
          description: 'Here is where you can add it to your favourites list, set a price alert or start trading.',
          spotlightElementId: 'asset-actions',
          alignment: isSmallScreen ? 'center' : 'left',
          borderRadius: 16,
        },
        {
          title: 'Set up charts how you like ‘em',
          description: 'Change time frames, chart types, add indicators or adjust overlays here.',
          spotlightElementId: 'tradingview-chart-options',
          alignment: isSmallScreen ? 'center' : 'right',
          borderRadius: 16,
        },
        {
          title: 'Swap to simple charts',
          description: 'Don’t need all the bells and whistles? Swap over to our simple charts for less clutter.',
          spotlightElementId: 'tradingview-chart-actions',
          alignment: isSmallScreen ? 'center' : 'left',
          borderRadius: 16,
        },
        {
          title: 'Keep up to date',
          description: 'View your owned assets, open orders and historical orders all in one spot.',
          spotlightElementId: 'orders-tile',
          alignment: isSmallScreen ? 'center' : 'right',
          borderRadius: 16,
        },
        {
          title: 'Ready to take action?',
          description: 'Buy, sell and swap here. You can trade one-to-one, many-to-one, or one-to-many!',
          spotlightElementId: 'trade-panel',
          alignment: isSmallScreen ? 'center' : 'top',
          borderRadius: 16,
        },
        {
          title: 'Have feedback?',
          description: 'We want you to have the best experience possible, and that requires your feedback.',
          spotlightElementId: 'trade-feedback-button',
          alignment: isSmallScreen ? 'center' : 'center',
          borderRadius: 16,
        },
      ],
      variant: 'spotlight',
      showBackdrop: true,
      tourKey: StorageKey.TRADE_PAGE_TOUR_VIEWED,
    }),
    [isSmallScreen, navigateToTradePage],
  );

  useEffect(() => {
    if (!viewedTour && tradePageEnabled && !isSmallScreen && !showFeatureDiscovery && viewedAutoInvestTour) {
      startTour(tourItem, () => setViewedTour(true));
    }
  }, [
    isSmallScreen,
    setViewedTour,
    showFeatureDiscovery,
    startTour,
    tourItem,
    tradePageEnabled,
    viewedAutoInvestTour,
    viewedTour,
  ]);
};
