import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@swyftx/react-web-design-system';

import { MobileVerification } from '@global-components/Forms/Verification/MobileVerification';

type Props = {
  code: string;
  onSubmit: () => Promise<void>;
  onClose: () => void;
  setCode: (code: string) => void;
  overrideSubmitLabel?: string;
  maxAttempts?: number;
  onResendCode?: () => void;
};

export const MobileVerificationModal: React.FC<Props> = ({
  overrideSubmitLabel,
  maxAttempts,
  code,
  onResendCode,
  onSubmit,
  onClose,
  setCode,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'verification' });

  return (
    <Modal
      sx={{ width: '700px', maxHeight: 'calc(100% - 2rem)', '.MuiCardContent-root': { height: '100%' }, padding: 3 }}
      HeaderProps={{ title: t('mobile.title'), dismissible: true, divider: true }}
      id='mobile-verification-modal'
      onClose={onClose}
      open
      PII
    >
      <MobileVerification
        overrideSubmitLabel={overrideSubmitLabel}
        onResendCode={onResendCode}
        maxAttempts={maxAttempts}
        onSubmit={onSubmit}
        onCancel={onClose}
        setCode={setCode}
        code={code}
      />
    </Modal>
  );
};
