import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Search from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import { Input } from '@swyftx/react-web-design-system';

import { useTheme } from '@hooks/useTheme';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { observer } from 'mobx-react-lite';
import { useDebounce } from 'react-use';

type Props = {
  width: string;
  focused?: boolean;
  onFocus?: () => void;
};

const TransactionsFiltersSearch: React.FC<Props> = observer(({ width, focused, onFocus }) => {
  const { filters, updateFilters } = useContext(TransactionsContext);
  const [value, setValue] = useState<string>(filters.searchString);
  const { t } = useTranslation('orders');
  const { isLightTheme } = useTheme();

  useDebounce(() => updateFilters({ searchString: value }), 200, [value]);

  useEffect(() => {
    if (filters.searchString === '') {
      setValue('');
    }
  }, [filters.searchString]);

  return (
    <Input
      id='transactionsFilters.search.input'
      onChange={(e) => setValue(e.target.value)}
      placeholder={t('transactionsFilters.search.placeholder')}
      autoFocus={focused}
      onFocus={onFocus}
      size='small'
      InputProps={{
        sx: {},
      }}
      sx={{
        borderColor: isLightTheme ? '#c4c4c4' : '#4e5154',
        width,
        '.MuiInputBase-root': {
          backgroundColor: 'background.paper',
        },
        fieldset: {
          borderColor: isLightTheme ? '#c4c4c4' : '#4e5154',
        },
      }}
      inputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search id='transactionsFilters.search.input.icon' />
          </InputAdornment>
        ),
      }}
      value={value}
    />
  );
});

TransactionsFiltersSearch.displayName = 'TransactionsFiltersSearch';

export { TransactionsFiltersSearch };
