import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { WithdrawalAddress } from '@shared/services';

import { WalletContext } from '@Wallet/Wallet.context';

interface RemoveConfirmationProps {
  address: WithdrawalAddress;
  setConfirmRemoveAccount: Dispatch<SetStateAction<boolean>>;
  setAccountDetailsOpen: Dispatch<SetStateAction<boolean>>;
  isFiat: boolean;
}

export const RemoveConfirmation: React.FC<RemoveConfirmationProps> = ({
  address,
  setConfirmRemoveAccount,
  setAccountDetailsOpen,
  isFiat,
}) => {
  const { deleteAddress } = useContext(WalletContext);
  const [deleteAccount, setAccountForDeletion] = React.useState<WithdrawalAddress | null>(null);

  const { t } = useTranslation('wallet');

  useEffect(() => {
    (async () => {
      if (deleteAccount) {
        await deleteAddress(address);
        setAccountForDeletion(null);
        setConfirmRemoveAccount(false);
        setAccountDetailsOpen(false);
      }
    })();
  }, [deleteAccount, address, setAccountForDeletion, setConfirmRemoveAccount, setAccountDetailsOpen, deleteAddress]);

  return (
    <FlexLayout direction='column' spacing={24}>
      <Body>
        {isFiat ? t('manageAccounts.removeConfirmation.fiatTitle') : t('manageAccounts.removeConfirmation.cryptoTitle')}
      </Body>
      <FlexLayout direction='row' justifyContent='end' spacing={8}>
        <Button
          variant='ghost'
          onClick={() => {
            setConfirmRemoveAccount(false);
          }}
        >
          {t('manageAccounts.removeConfirmation.cancel')}
        </Button>
        <Button
          variant='filled'
          color='destructive'
          loading={deleteAccount !== null}
          onClick={() => {
            setAccountForDeletion(address);
          }}
        >
          {t('manageAccounts.removeConfirmation.remove')}
        </Button>
      </FlexLayout>
    </FlexLayout>
  );
};
