import { StakingInfo } from '../../@types/staking';
import { Methods, request } from '../rest';

const stakingEndpoints = {
  getAssetStakingRewards: ({ query }: { query: { assetCode: string } }) =>
    request({
      path: '/staking/rewards/:assetCode?',
      method: Methods.GET,
      query,
      auth: true,
    }),

  getStakingOfferings: () =>
    request({
      path: '/staking/offerings/',
      method: Methods.GET,
      auth: true,
    }),

  getStakingExpectedRewards: () =>
    request({
      path: '/staking/expectedRewards/',
      method: Methods.GET,
      auth: true,
    }),

  stakeAmount: ({ data }: { data: { offerId: string; amount: string } }) =>
    request({
      path: '/staking/deposit/',
      method: Methods.POST,
      auth: true,
      data,
    }),

  destakeAmount: ({ data }: { data: { offerId: string; amount: string } }) =>
    request({
      path: '/staking/withdraw/',
      method: Methods.POST,
      auth: true,
      data,
    }),

  getStakingHistory: ({ query }: { query: { types?: string; assets: string } }) =>
    request({
      path: '/staking/history/',
      method: Methods.GET,
      auth: true,
      query,
    }),

  getStakingInfo: (): StakingInfo =>
    request({
      path: '/staking/info/',
      method: Methods.GET,
      auth: true,
    }),

  optInStaking: (): void =>
    request({
      path: '/staking/optIn',
      method: Methods.POST,
      auth: true,
    }),
};

export default stakingEndpoints;
