import { useCallback, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import env from '@shared/config';

import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { FeatureDiscoveryContext } from 'src/lib/feature-discovery/context/FeatureDiscovery.context';

const PROVIDE_FEEDBACK_PARAMS = 'provide-feedback';
const EXCLUDED_PROP_KEYS = ['modal', 'open', 'selected'];

const useProcessSearchParams = () => {
  const [searchParams] = useSearchParams();
  const { setShowAll } = useContext(FeatureDiscoveryContext);
  const feedbackID = env.USERSNAP_FEEDBACK_PROJECT_ID;
  const usersnapApi = useUsersnapApi();

  const { openModal } = useModal();

  // Additional open logic if the element is not found
  const handleNotFoundOpenParams = useCallback(
    (openId: string): boolean => {
      // If we are trying to open a header item. We should open the menu instead if it cant be found
      if (openId.includes('header-')) {
        const menu = document.getElementById('header-menu');
        if (!menu) return false;
        menu.click();

        // If its the header-profile also open the switch account modal
        if (openId === 'header-profile') {
          openModal(Modals.SwitchAccount);
        }

        return true;
      }

      return false;
    },
    [openModal],
  );

  const processModalParams = useCallback(() => {
    const modal = searchParams.get('modal');
    if (modal === null) return false;

    const pathModal = modal as Modals;

    if (Object.values(Modals).includes(pathModal)) {
      // Process all props
      let props: { [key: string]: string | number } = {};

      Array.from(searchParams.keys())
        .filter((k) => !EXCLUDED_PROP_KEYS.includes(k))
        .forEach((key) => {
          const value = searchParams.get(key);
          if (value) props[key] = Number.isNaN(Number(value)) ? value : Number(value);
        });

      searchParams.keys();
      openModal(pathModal, props);
      return true;
    }

    return false;
  }, [openModal, searchParams]);

  const processOtherParams = useCallback(() => {
    const modal = searchParams.get('modal');

    if (modal === null) return false;

    if (modal === Modals.WhatsNewModal) {
      setShowAll(true);
      return true;
    } else if (modal === PROVIDE_FEEDBACK_PARAMS) {
      usersnapApi?.show(feedbackID).then((widgetApi) => widgetApi.open({}));
      return true;
    }

    return false;
  }, [feedbackID, searchParams, setShowAll, usersnapApi]);

  const processOpenParams = useCallback(() => {
    const openId = searchParams.get('open');

    if (openId === null) return false;

    const element = document.getElementById(openId);

    if (!element) {
      return handleNotFoundOpenParams(openId);
    }

    element.click();
    return true;
  }, [handleNotFoundOpenParams, searchParams]);

  useEffect(() => {
    if (processOtherParams()) return;
    if (processOpenParams()) return;
    if (processModalParams()) return;
  }, [openModal, processModalParams, processOpenParams, processOtherParams, searchParams]);
};

export { useProcessSearchParams };
