import React, { useCallback } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { EnhancedTable } from '@swyftx/aviary/molecules/EnhancedTable';

import { Category } from '@shared/api/@types/categories';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { AssetCategoriesTableData } from '../AssetCategories.types';
import { useAssetCategoriesTable } from '../hooks/useAssetCategoriesTable';

interface Props {
  search: string;
}

export const AssetCategoriesTable: React.FC<Props> = observer((props) => {
  const { search } = props;
  const { headers, sort, onSort, data } = useAssetCategoriesTable(search);
  const { navigate } = useNavigateRoute();
  const isXs = useTailwindBreakpoint('xs');

  const navigateToCategory = useCallback(
    (category: Category) => navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: category.id } }),
    [navigate],
  );

  return (
    <EnhancedTable<AssetCategoriesTableData>
      data-container='asset-categories-table'
      headers={headers}
      data={data}
      sort={sort}
      onSort={onSort}
      lazyLoad={!isXs}
      itemsPerPage={20}
      onClickItem={(item) => navigateToCategory(item.actions.value as Category)}
    />
  );
});
