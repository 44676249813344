import { useCallback, useEffect, useMemo, useState } from 'react';

import { ThemeStore } from '@shared/store';
export const tailwindTheme = {};

export enum TailWindTheme {
  Dark = 'dark',
  Midnight = 'midnight',
  Light = 'light',
}

enum TailWindPalette {
  Personal = 'personal',
  Entity1 = 'entity1',
  Entity2 = 'entity2',
  Entity3 = 'entity3',
  Entity4 = 'entity4',
}

const useTailwindTheme = () => {
  const { darkMode } = ThemeStore.useThemeStore;
  const [theme, setTheme] = useState<TailWindTheme>(darkMode as unknown as TailWindTheme);
  const [palette, setPalette] = useState<TailWindPalette>(TailWindPalette.Personal);

  const getTheme = (target: HTMLElement) => {
    if (target.classList.contains('midnight')) {
      return TailWindTheme.Midnight;
    } else if (target.classList.contains('dark')) {
      return TailWindTheme.Dark;
    } else {
      return TailWindTheme.Light;
    }
  };

  const getPalette = (target: HTMLElement) => {
    const bodyClasses = target.classList;

    if (bodyClasses.contains('entity1')) {
      return TailWindPalette.Entity1;
    } else if (bodyClasses.contains('entity2')) {
      return TailWindPalette.Entity2;
    } else if (bodyClasses.contains('entity3')) {
      return TailWindPalette.Entity3;
    } else if (bodyClasses.contains('entity4')) {
      return TailWindPalette.Entity4;
    } else {
      return TailWindPalette.Personal;
    }
  };

  const documentRootObserver = useMemo(
    () =>
      new MutationObserver((event) => {
        if (event.length > 0) {
          setTheme(getTheme(event[0].target as HTMLElement));
        }
      }),
    [],
  );

  const bodyObserver = useMemo(
    () =>
      new MutationObserver((event) => {
        if (event.length > 0) {
          setPalette(getPalette(event[0].target as HTMLElement));
        }
      }),
    [],
  );

  useEffect(() => {
    setTheme(getTheme(document.documentElement));

    if (document.documentElement) {
      documentRootObserver.observe(document.documentElement, { attributes: true, attributeFilter: ['class'] });
    }

    if (document.body) {
      bodyObserver.observe(document.body, { attributes: true, attributeFilter: ['class'] });
    }

    return () => {
      documentRootObserver.disconnect();
      bodyObserver.disconnect();
    };
  }, [documentRootObserver, bodyObserver]);

  const applyTheme = useCallback((newTheme: TailWindTheme) => {
    if (newTheme === TailWindTheme.Dark) {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('midnight', 'light');
    } else if (newTheme === TailWindTheme.Midnight) {
      document.documentElement.classList.add('midnight');
      document.documentElement.classList.remove('dark', 'light');
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark', 'midnight');
    }
  }, []);

  const forceSetTheme = useCallback(
    (forceTheme?: TailWindTheme) => {
      if (theme === forceTheme) return;

      if (!forceTheme) {
        applyTheme(theme);
        return;
      }

      applyTheme(forceTheme);
    },
    [applyTheme, theme],
  );

  const isLightMode = useMemo(() => theme === TailWindTheme.Light, [theme]);
  const isDarkMode = useMemo(() => theme !== TailWindTheme.Light, [theme]);
  const isMidnightMode = useMemo(() => theme === TailWindTheme.Midnight, [theme]);

  return {
    theme,
    palette,
    isDarkMode,
    isLightMode,
    isMidnightMode,
    forceSetTheme,
  };
};

export { useTailwindTheme };
