import React, { useCallback, useMemo } from 'react';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ElementContainer } from '@swyftx/aviary/molecules/ElementContainer';
import { EnhancedTable, EnhancedTableData } from '@swyftx/aviary/molecules/EnhancedTable';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetHistoryItem, AssetHistoryItemStatus, TransactionType } from '@shared/api';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { observer } from 'mobx-react-lite';
import { useDashboardSyncState } from 'src/lib/dashboard/hooks/useDashboardSyncState';
import { DashboardWidgets } from 'src/lib/dashboard/types/Dashboard.widgets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { TransactionsTableEmpty } from './TransactionsTableEmpty';
import { useTransactionsTable } from '../../hooks/useTransactionsTable';
import { TransactionsTab, TransactionsTableData, TransactionsType } from '../../types/Transactions.types';
import { isTransfer } from '../../utils/Transactions.utils';

type Props = {
  title: string;
  asset?: Asset;
  tabs?: TransactionsTab[];
};

const widgetId: DashboardWidgets = 'transactions';

const TransactionsWidget: React.FC<Props> = observer(({ title, asset, tabs }) => {
  const { navigate } = useNavigateRoute();
  const { openModal } = useModal();
  const { userStatistics, userProfile } = UserStore.useUserStore;
  const [transactionsType, setTransactionsType] = useDashboardSyncState<TransactionsType>({
    widgetId,
    stateKey: 'transactionsType',
    defaultValue: tabs?.[0].value || 'recent',
  });

  const types = useMemo(
    () => (transactionsType === 'open' ? [TransactionType.Buy, TransactionType.Sell] : undefined),
    [transactionsType],
  );

  const statuses = useMemo(
    () =>
      transactionsType === 'open'
        ? [AssetHistoryItemStatus.Pending]
        : [AssetHistoryItemStatus.Cancelled, AssetHistoryItemStatus.Completed, AssetHistoryItemStatus.Failed],
    [transactionsType],
  );

  const { headers, data, onSort, initialSort } = useTransactionsTable({
    asset,
    transactionsType,
    types,
    statuses,
    widgetId,
    userId: userProfile?.intercom?.uuid,
  });

  const viewAllTransactions = useCallback(() => {
    navigate(NavigationURLs.TransactionsOrders);
  }, [navigate]);

  const onSelectItem = (item: EnhancedTableData<TransactionsTableData, AssetHistoryItem>) => {
    if (!item.value) return;

    if (isTransfer(item.value)) {
      const transactionAsset = assetService.getAsset(item.value.secondaryAsset);
      if (transactionAsset) openModal(Modals.TransactionDetails, { transaction: item.value, asset: transactionAsset });
    } else {
      openModal(Modals.OrderDetails, { orderId: item.value.uuid, transaction: item.value, deleteModal: false });
    }
  };

  if (!userStatistics?.orders) return null;

  return (
    <ElementContainer
      title={title}
      className='w-full'
      actions={
        <FlexLayout alignItems='baseline' justifyContent='end' className='w-full'>
          <Body color='accent' size='small' weight='emphasis' onClick={viewAllTransactions} className='cursor-pointer '>
            View transactions
          </Body>
        </FlexLayout>
      }
    >
      <Card className='h-full w-full overflow-hidden @sm:max-h-[380px] @sm:min-h-[380px]'>
        {tabs && (
          <FlexLayout
            alignItems='center'
            justifyContent='start'
            className='max-h-[5.31rem] min-h-[5.31rem] overflow-x-auto overflow-y-hidden border-b border-color-border-main p-16 @sm:p-24'
          >
            <EnhancedTabs<TransactionsType>
              variant='child'
              tabs={tabs}
              onChange={setTransactionsType}
              value={transactionsType}
            />
          </FlexLayout>
        )}
        <EnhancedTable<TransactionsTableData, AssetHistoryItem>
          data-container='transactions-table'
          headers={headers}
          data={data}
          inset
          itemsPerPage={5}
          sort={initialSort}
          onSort={onSort}
          onClickItem={onSelectItem}
        >
          <TransactionsTableEmpty transactionsType={transactionsType} />
        </EnhancedTable>
      </Card>
    </ElementContainer>
  );
});

export { TransactionsWidget };
