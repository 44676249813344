import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import Select from '@mui/material/Select';

import { Tick } from '@swyftx/aviary/icons/outlined';
import { MenuItem, Skeleton, Stack, Typography } from '@swyftx/react-web-design-system';

import { WithdrawalReasonEnum, withdrawalService } from '@shared/services';

type Props = {
  value: WithdrawalReasonEnum;
  onChange: (val: WithdrawalReasonEnum) => void;
  title: string;
  loading?: boolean;
};

const WithdrawReasonDropdown: React.FC<Props> = ({ value, onChange, title, loading = false }) => {
  const { t } = useTranslation('wallet');
  const reasons = useMemo(() => withdrawalService.getSortedWithdrawalReasons(), []);
  const selectedReason = useMemo(() => reasons.filter((reason) => reason.key === value)[0], [value]);

  if (loading) {
    return (
      <Stack spacing={1}>
        <Skeleton variant='text' width='120px' height='21px' />
        <Skeleton variant='rounded' width='100%' height='56px' />
      </Stack>
    );
  }

  return (
    <Stack spacing={1}>
      <Typography fontSize={14} fontWeight={500} color='text.primary'>
        {title}
      </Typography>
      <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
        <Select
          id='reason-for-withdrawing'
          value={!selectedReason ? '' : t(`${selectedReason.label}` as any)}
          sx={{
            width: '100%',
            '.MuiTypography-root': {
              fontSize: 14,
            },
          }}
          onChange={(e) => onChange(e.target.value)}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography fontSize={16} fontWeight='400' color='text.secondary'>
                  {t('singleWalletFiatWithdrawal.selectReason')}
                </Typography>
              );
            }

            return (
              <Typography fontSize={16} fontWeight='400'>
                {t(`${selectedReason.label}` as any)}
              </Typography>
            );
          }}
        >
          {reasons.map((reason) => (
            <MenuItem key={reason.key} value={reason.key}>
              <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
                <Typography fontSize={16} fontWeight='400'>
                  {t(`${reason.label}` as any)}
                </Typography>
                {reason.key === value && <Tick className='text-color-text-primary' />}
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Stack>
  );
};

export { WithdrawReasonDropdown };
