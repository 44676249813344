import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { assetService } from '@shared/services';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

import { WalletListTileContainer, WalletListTradingTile } from './components';

type WalletListTileProps = {
  id: string;
  assetId: number;
  walletType: WalletType;
};

const WalletListTile: React.FC<WalletListTileProps> = observer(({ id, walletType, assetId }) => {
  const { t } = useTranslation('wallet');
  const asset = assetService.getAsset(assetId);
  const baseAsset = useBaseAsset();
  const assetBalanceData = asset && useAssetBalance(asset);

  if (!asset || !assetBalanceData || !baseAsset) {
    return (
      <WalletListTileContainer id={id}>
        <FlexLayout className='h-full w-full' direction='column' justifyContent='center' alignItems='center'>
          <Chip color='secondary' size='md' className='w-fit'>
            {t('walletList.assetUnavailable')}: {assetId}
          </Chip>
        </FlexLayout>
      </WalletListTileContainer>
    );
  }

  return (
    <WalletListTileContainer id={id} code={asset.code}>
      <WalletListTradingTile
        asset={asset}
        assetBalanceData={assetBalanceData}
        walletType={walletType}
        balanceKey={walletType === WalletType.ALL_WALLETS ? 'all' : 'availableBalance'}
        assetBalanceKey={walletType === WalletType.ALL_WALLETS ? 'all' : 'trading'}
      />
    </WalletListTileContainer>
  );
});

WalletListTile.displayName = 'WalletListTile';

export { WalletListTile };
