import { WithdrawalReasonEnum } from '@shared/services';

// TODO translations
export const reasonLabels: Record<WithdrawalReasonEnum, string> = {
  [WithdrawalReasonEnum.PayGoods]: 'Paying for goods or services',
  [WithdrawalReasonEnum.PayRansomware]: 'Paying for ransomware',
  [WithdrawalReasonEnum.SendToFriend]: 'Sending to a friend',
  [WithdrawalReasonEnum.TradeOnOtherExchange]: 'Trading on another exchange',
  [WithdrawalReasonEnum.TransferToBank]: 'Transfer to my bank account',
  [WithdrawalReasonEnum.TransferToPlatform]: 'Transfer to custody platform',
  [WithdrawalReasonEnum.TransferToWallet]: 'Transfer to personal wallet',
  [WithdrawalReasonEnum.UnhappyWithSwyftx]: 'Not happy with Swyftx',
};
