import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Numeric } from '@swyftx/aviary/atoms/Typography';

type Props = {
  className?: string;
  date: string;
};

const TransactionsDate: React.FC<Props> = ({ className, date }) => (
  <FlexLayout justifyContent='start' className={className}>
    <Numeric size='small' className='truncate'>
      {date}
    </Numeric>
  </FlexLayout>
);

export { TransactionsDate };
