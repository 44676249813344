import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { cn } from '@shared/utils/lib/ui';

type Props = {
  className?: string;
  renderDivider?: boolean;
  isHeader?: boolean;
} & Pick<HTMLAttributes<HTMLTableRowElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'>;

const TableRow = React.forwardRef<HTMLTableRowElement, PropsWithChildren<Props>>(
  ({ className, onClick, children, renderDivider = true, isHeader = false }, ref) => (
    <tr
      ref={ref}
      className={cn(
        'relative w-full bg-color-background-surface-primary ',
        renderDivider && !isHeader ? 'border-b border-color-border-main' : '',
        className,
      )}
      onClick={onClick}
    >
      {children}
      {renderDivider && isHeader && <div className='absolute bottom-0 left-0 h-1 w-full bg-color-border-main' />}
    </tr>
  ),
);

TableRow.displayName = 'TableRow';

export { TableRow };
