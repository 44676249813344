import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionTab } from '@routes/Transactions/components/TransactionsTabs/TransactionTab';
import { tabItems, TransactionsTabType } from '@routes/Transactions/components/TransactionsTabs/TransactionsTabs.data';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

type Props = {
  id: string;
  type?: string;
};

const TransactionsTabs: React.FC<Props> = observer(({ id, type }) => {
  const { navigate } = useNavigateRoute();
  const { resetPage } = useContext(TransactionsContext);

  const onTabChange = (tab: TransactionsTabType) => {
    resetPage();
    if (tab.path) navigate(tab.path);
  };

  return (
    <FlexLayout alignItems='center' justifyContent='space-between' className='h-[65px] w-full'>
      <FlexLayout id={id} direction='row' spacing={16} className='h-[65px] overflow-x-auto'>
        {tabItems.map((tab) => (
          <TransactionTab key={tab.id} tab={tab} onTabChange={onTabChange} type={type} />
        ))}
      </FlexLayout>
      <Link to={NavigationURLs.ProfileTaxReports} className='hidden @sm:block'>
        <Button size='lg'>Transaction Reports</Button>
      </Link>
    </FlexLayout>
  );
});

export { TransactionsTabs };
