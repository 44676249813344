import { api, Asset, AssetType, CreateOrderParams, CreateOrderResponse, OrderType, SwapPayload } from '@shared/api';
import { MAX_TRADE_ASSETS } from '@shared/constants';
import { FiatIdEnum } from '@shared/enums';
import { SwyftxError } from '@shared/error-handler';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UniversalTradeStore, UserStore } from '@shared/store';
import {
  OrderStatus,
  TradeAssetAction,
  TradeData,
  TradeSide,
  TradeState,
  TradeType,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';
import { allSettled } from '@shared/utils/lib/allSettled';

import {
  AssetTradePercentage,
  FeeBreakdown,
  FeeDetail,
  HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD,
} from '@hooks/Trade/types/useUniversalTrade.types';
import TradeService from '@services/TradeService';

import { getOrderAction } from '@Trade/components/UniversalTradePanel/components/TradeFeeBreakdown/TradeFeeBreakdown.utils';

import { TradeActionEnum } from '../types';

export const createTradeUtilityStore = () => {
  const store = {
    MAX_TRADE_ASSETS,
    getTradeOverviewDataKeys: (tradeKeys: string[], baseAsset?: Asset) => {
      const baseAssets = assetService.getBaseAssets();
      const parsedKeys: string[] = [];
      const dedupedKeys: string[] = [];

      tradeKeys.forEach((key) => parsedKeys.push(key));

      const everyFrom = parsedKeys.map((pk) => pk.split('_')[0]).every((v) => baseAssets.includes(Number(v)));
      const everyTo = parsedKeys.map((pk) => pk.split('_')[1]).every((v) => baseAssets.includes(Number(v)));

      if (everyFrom || everyTo) return tradeKeys;

      // combine all keys together so we have a single string with all the assets
      const merged = parsedKeys.join('_');

      // split on the _ so we now have an array of all the assets (there will be duplicates)
      const allAssets = merged.split('_').map(Number);
      // Get only the unique assets
      const dedupedAssets = Array.from(new Set<number>(allAssets));

      dedupedAssets.forEach((a) => {
        const baseId = baseAsset ? baseAsset.id : FiatIdEnum.USD;
        const from = baseId;
        const to = a;

        if (to === from) return;

        dedupedKeys.push(`${from}_${to}`);
      });

      return dedupedKeys;
    },
    get canTradeTo() {
      const { tradeFrom, tradeTo } = UniversalTradeStore;
      if (tradeFrom.length <= 1 && tradeTo.length < MAX_TRADE_ASSETS) return true;
      if (tradeFrom.length > 1 && tradeTo.length < 1) return true;

      return false;
    },
    get canTradeFrom() {
      const { tradeFrom, tradeTo } = UniversalTradeStore;
      if (tradeTo.length <= 1 && tradeFrom.length < MAX_TRADE_ASSETS) return true;
      if (tradeTo.length > 1 && tradeFrom.length < 1) return true;

      return false;
    },
    get tradeUIDataErrors() {
      const errors: string[] = [];

      UniversalTradeStore.tradeKeys.forEach((key) => {
        const uiData = UniversalTradeStore.tradeUIData[key];
        const isOverLowLiquidityThreshold = uiData.isOverLowLiquidityThreshold ? 'true' : '';
        const error = uiData.clientSideError || uiData.error || isOverLowLiquidityThreshold || '';

        if (error) {
          errors.push(error);
        }
      });

      return errors;
    },

    get highSlippageTrades() {
      const highSlippageTradeData: TradeData[] = [];

      UniversalTradeStore.tradeKeys.forEach((key) => {
        const data = UniversalTradeStore.tradeData[key];
        if ((data.slippagePercentage || 0) > HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD || data.isHighSlippage) {
          highSlippageTradeData.push(UniversalTradeStore.tradeData[key]);
        }
      });

      return highSlippageTradeData;
    },

    get tradeUIDataLoading() {
      const loading: string[] = [];

      UniversalTradeStore.tradeKeys.forEach((key) => {
        if (UniversalTradeStore.tradeUIData[key].loading) {
          loading.push(key);
        }
      });

      return loading;
    },

    get totalTrades() {
      if (!UniversalTradeStore.tradeFrom.length || !UniversalTradeStore.tradeTo.length) {
        return 0;
      }

      if (UniversalTradeStore.tradeFrom.length === 1) {
        return UniversalTradeStore.tradeTo.length;
      }

      return UniversalTradeStore.tradeFrom.length;
    },

    /**
     * Primarily used for recurring orders and multi buy to distribute trade percentage amounts between assets evenly
     *
     * @param assetIds assets that need percentage values
     * @param excludedAssets assets that should be excluded from the even distribution of remaining percent
     * @param maxPercentage max percentage able to be distributed to assets
     * @returns AssetTradePercentage[] which includes correct percentage splits for each asset
     */
    calculateAssetPercentages: (
      assetIds: number[],
      excludedAssets: AssetTradePercentage[],
      maxPercentage: number,
    ): AssetTradePercentage[] => {
      const assetPercentages: AssetTradePercentage[] = [];
      let assignedRemainingPercent = false;

      const nonRoundedEvenlyDistributedPercent = maxPercentage / (assetIds.length - excludedAssets.length);
      const roundedDistributedPercent = Math.floor(nonRoundedEvenlyDistributedPercent);
      // Subtract 1 to take into account the asset being assigned remaining percent
      const percentageRemaining =
        maxPercentage - roundedDistributedPercent * (assetIds.length - excludedAssets.length - 1);

      assetIds.forEach((assetId) => {
        const excluded = excludedAssets.find((a) => a.assetId === assetId);

        if (excluded) {
          assetPercentages.push(excluded);
          return;
        }

        // assign remaining percent to first non excluded asset
        assetPercentages.push({
          assetId,
          isLocked: false,
          percent: !assignedRemainingPercent ? percentageRemaining : roundedDistributedPercent,
        });

        assignedRemainingPercent = true;
      });

      return assetPercentages;
    },

    convertCacheToTradeAssets: (side: TradeSide) => {
      UniversalTradeStore.setTradeAssets(UniversalTradeStore.tradeCache[side], side, TradeAssetAction.Replace);
      UniversalTradeStore.setTradeCache([], side, TradeAssetAction.Replace);
      UniversalTradeStore.setTradeState(TradeState.PlaceOrder);
    },

    getTradePercentage: (asset: number) => UniversalTradeStore.tradePercentages[asset],

    resetTradeAssets: () => {
      UniversalTradeStore.setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
      UniversalTradeStore.setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
      UniversalTradeStore.setExecutingOrders(false);
      UniversalTradeStore.setOrderStatus(OrderStatus.Idle);
    },

    getFeeDetail(userCountry?: Asset): FeeDetail | null {
      const orders = Object.values(UniversalTradeStore.tradeData);
      const fromAssets = new Set();
      const toAssets = new Set();
      orders.forEach((e) => fromAssets.add(e.from));
      orders.forEach((e) => toAssets.add(e.to));
      const isMultiSell = fromAssets.size > 1 && toAssets.size === 1;
      const fOrder = orders[0];
      const fromAsset = assetService.getAsset(fOrder.from)!;
      const toAsset = assetService.getAsset(fOrder.to)!;
      const orderAction = getOrderAction(fromAsset, toAsset);

      let commonAssetId = fOrder.from;
      if (orders.length === 1) {
        // 1:1 have fees in the non-limited asset
        commonAssetId = fOrder.limit === fOrder.from ? fOrder.to : fOrder.from;
      } else if (isMultiSell) {
        commonAssetId = fOrder.to;
      }

      // check if we show the approx indicator
      const totalPrefix =
        commonAssetId === fOrder.limit && [TradeActionEnum.Buy, TradeActionEnum.Sell].includes(orderAction) ? '' : '~';

      let orderTotal = Big(0);
      const breakdowns: FeeBreakdown[] = [];
      orders.forEach((order) => {
        const { amount, total, to, fee, ratePrice, customTrigger, limit, from } = order;

        const fromQty = total;
        const toQty = amount;
        const swapOrder = this.isSwap(order);
        const isLimitFrom = from === limit;
        const isTrigger = UniversalTradeStore.tradeType === TradeType.OnTrigger;
        const exchangeRate = isTrigger ? customTrigger : ratePrice;
        const inverse = orderAction === TradeActionEnum.Sell;

        const feeInLimit = Big(isLimitFrom ? fromQty : toQty).times(fee || 0);
        let feeInNonLimit;
        if (swapOrder) {
          feeInNonLimit = isLimitFrom ? feeInLimit.div(exchangeRate || 1) : feeInLimit.times(exchangeRate || 1);
        } else if (isTrigger) {
          if (inverse) {
            feeInNonLimit = isLimitFrom ? feeInLimit.times(exchangeRate || 1) : feeInLimit.div(exchangeRate || 1);
          } else {
            feeInNonLimit = isLimitFrom ? feeInLimit.div(exchangeRate || 1) : feeInLimit.times(exchangeRate || 1);
          }
        } else {
          feeInNonLimit = isLimitFrom ? feeInLimit.div(exchangeRate || 1) : feeInLimit.times(exchangeRate || 1);
        }

        const feeInUserCountry =
          userCountry &&
          RatesStore.useRatesStore
            .convertRate(isLimitFrom ? from : to, userCountry?.id, feeInLimit)
            .toFixed(userCountry.price_scale);

        // approx is also shown on non-limit asset
        // hardcode it below, it's a bit pointless but
        // makes life easier for people using it
        const breakdown = {
          from,
          to,
          feeInLimit,
          feeInNonLimit,
          assetInLimit: limit,
          assetInNonLimit: isLimitFrom ? to : from,
          feeInUserCountry: feeInUserCountry ?? 'N/A',
          limitPrefix: swapOrder ? '~' : '',
          nonLimitPrefix: '~',
        };
        breakdowns.push(breakdown);

        orderTotal = orderTotal.add(commonAssetId === limit ? feeInLimit : feeInNonLimit);
      });

      const totalInUserCountry =
        userCountry &&
        RatesStore.useRatesStore
          .convertRate(commonAssetId, userCountry?.id, orderTotal)
          .toFixed(userCountry?.price_scale);

      return {
        totalPrefix,
        totalAssetId: commonAssetId,
        totalFee: orderTotal,
        totalInUserCountry: totalInUserCountry ?? 'N/A',
        breakdowns,
      };
    },

    getDisplayExchangeRate(): string[] {
      const isTrigger = UniversalTradeStore.tradeType === TradeType.OnTrigger;
      const orders = Object.values(UniversalTradeStore.tradeData);

      return orders.map((order) => {
        const fromAsset = assetService.getAsset(order.from);
        const toAsset = assetService.getAsset(order.to);
        let formattedRate = 'N/A';
        let formattedCode = '';
        if (fromAsset && toAsset) {
          const orderAction = getOrderAction(fromAsset, toAsset);
          const exchangeRate = isTrigger ? order.customTrigger : order.ratePrice;
          if (exchangeRate) {
            const inverse = orderAction === TradeActionEnum.Sell;
            const displayExchangeRate = !inverse || isTrigger ? exchangeRate : Big(1).div(exchangeRate);
            const displayExchangeAsset = !inverse ? fromAsset : toAsset;

            formattedRate = formatCurrency(displayExchangeRate, displayExchangeAsset, {
              hideCode: true,
              appendCode: false,
            });
            formattedCode = !inverse ? `${fromAsset?.code}/${toAsset?.code}` : `${toAsset?.code}/${fromAsset?.code}`;
          }
        }

        return `${formattedRate} ${formattedCode}`;
      });
    },

    getPrimarySecondaryAssets: (assetIds: Asset['id'][]) => {
      const assetA = assetService.getAsset(assetIds[0]);
      const assetB = assetService.getAsset(assetIds[1]);

      let primaryAsset;
      let secondaryAsset;

      if (assetA?.isBaseAsset) {
        primaryAsset = assetA;
        secondaryAsset = assetB;
      } else if (assetB?.isBaseAsset) {
        primaryAsset = assetB;
        secondaryAsset = assetA;
      }

      // ensure user base is always primary
      const { userBaseCurrency } = UserStore.useUserStore;
      if (secondaryAsset?.id === userBaseCurrency) {
        const tmp = primaryAsset;
        primaryAsset = secondaryAsset;
        secondaryAsset = tmp;
      }

      return { primaryAsset, secondaryAsset };
    },

    getOrderType: (data: TradeData): OrderType => {
      const { trigger, customTrigger, from, to } = data;

      const baseAssets = assetService.getBaseAssets();
      const toAsset = assetService.getAsset(to);
      const fromAsset = assetService.getAsset(from);
      const isBaseAssetSell = baseAssets.includes(from);
      const isBaseAssetBuy = baseAssets.includes(to);
      const bothBase = isBaseAssetBuy && isBaseAssetSell;

      // If we have a custom trigger we are performing a Limit or Stop order
      if (customTrigger && UniversalTradeStore.tradeType !== TradeType.Instantly) {
        const bothBaseAndFromCrypto = bothBase && fromAsset?.assetType === AssetType.Crypto;
        const isSell = !isBaseAssetSell || bothBaseAndFromCrypto;
        const bigTrigger = Big(trigger);
        const bigCustomTrigger = Big(customTrigger);

        const bothCrypto = fromAsset?.assetType === AssetType.Crypto && toAsset?.assetType === AssetType.Crypto;

        // work out if the custom price is higher than the current exchange rate
        const isHigherCustom = bothBaseAndFromCrypto
          ? Big(1).div(bigCustomTrigger).lt(bigTrigger)
          : bigCustomTrigger.gt(isSell && bothCrypto ? Big(1).div(bigTrigger) : bigTrigger);

        if (isSell) {
          return isHigherCustom ? OrderType.TriggerSell : OrderType.StopSell;
        }

        return isHigherCustom ? OrderType.StopBuy : OrderType.TriggerBuy;
      }
      return isBaseAssetBuy && toAsset?.assetType === AssetType.Fiat ? OrderType.MarketSell : OrderType.MarketBuy;
    },

    getTrigger(data: TradeData): string | undefined {
      const { customTrigger, trigger } = data;
      const orderType: OrderType = this.getOrderType(data);

      if (!customTrigger || UniversalTradeStore.tradeType === TradeType.Instantly) return trigger;

      const bigCustomTrigger = Big(customTrigger);

      switch (orderType) {
        case OrderType.TriggerSell:
        case OrderType.StopSell:
          return Big(1).div(bigCustomTrigger).toString();
        case OrderType.TriggerBuy:
        case OrderType.StopBuy:
          return bigCustomTrigger.toString();
        case OrderType.MarketBuy:
        case OrderType.MarketSell:
        default:
          return trigger;
      }
    },

    getQuantity: (data: TradeData): string => {
      const { limit, from, total, amount, balance } = data;

      if (!amount || !total || !balance) return '';

      if (UniversalTradeStore.tradeType === TradeType.OnTrigger) {
        return balance;
      }

      return limit === from ? total : amount;
    },
    isSwap: (data: TradeData) => {
      if (!data) return false;

      const { from, to } = data;
      const baseAssets = assetService.getBaseAssets();

      return !baseAssets.includes(from) && !baseAssets.includes(to);
    },

    processCreateMarketOrderResponse: (response: CreateOrderResponse) => {
      const { primary_asset, secondary_asset, amount, total, order_type } = response.order;

      const { orderUuid } = response;

      const data = Object.values(UniversalTradeStore.tradeData).find(
        (t) =>
          (t.from === primary_asset && t.to === secondary_asset) ||
          (t.from === secondary_asset && t.to === primary_asset),
      );

      if (data) {
        const executedAmount = order_type === OrderType.MarketBuy ? total.toString() : amount.toString();
        const executedTotal = order_type === OrderType.MarketBuy ? amount.toString() : total.toString();

        UniversalTradeStore.setTradeData(data.from, data.to, { executedAmount, executedTotal, orderUuid });
        UniversalTradeStore.setTradeUIData(data.from, data.to, { orderStatus: OrderStatus.Success });
      }
    },

    async executeTrades(onFinish: () => void, onError?: (error: string) => void, userCountry?: Asset) {
      UniversalTradeStore.setExecutingOrders(true);
      const swapOrders: SwapPayload[] = [];
      const marketOrders: CreateOrderParams[] = [];
      Object.values(UniversalTradeStore.tradeData).forEach(async (data: TradeData) => {
        const { from, to, limit, amount, total, balance } = data;
        const trigger = this.getTrigger(data);
        const fromAsset = assetService.getAsset(from);
        const toAsset = assetService.getAsset(to);

        if (!amount || Big(amount).lte(0) || Big(total).lte(0)) return;

        // Set the current trade to Pending
        UniversalTradeStore.setTradeUIData(from, to, { orderStatus: OrderStatus.Pending });

        if (this.isSwap(data)) {
          swapOrders.push({
            buy: to,
            intermediateAssetId: userCountry?.id || FiatIdEnum.USD,
            limitAsset: from,
            limitQty: Big(balance)
              .round(fromAsset?.price_scale || 18, 0)
              .toNumber(),
            sell: from,
          });
        } else {
          const limitAsset = assetService.getAsset(limit);
          const orderType = this.getOrderType(data);

          if (!limitAsset || !fromAsset || !toAsset || !total) return;

          marketOrders.push({
            assetQuantity: limitAsset.code,
            orderType,
            primary: TradeService.isBuy(orderType) ? fromAsset.code : toAsset.code,
            quantity: this.getQuantity(data),
            secondary: TradeService.isBuy(orderType) ? toAsset.code : fromAsset.code,
            trigger,
          });
        }
      });

      const promises: Promise<any>[] = [];

      if (marketOrders.length > 1) {
        promises.push(api.endpoints.createBundleOrder({ data: marketOrders }));
      } else if (marketOrders.length === 1 && swapOrders.length === 0) {
        try {
          const order = marketOrders[0];
          const orderResponse = await api.endpoints.createOrder({ data: order });

          let data;

          if (orderResponse?.data?.order) {
            this.processCreateMarketOrderResponse(orderResponse.data);
          } else {
            const primary = assetService.getAssetByCode(order.primary);
            const secondary = assetService.getAssetByCode(order.secondary);
            const quantity = assetService.getAssetByCode(order.assetQuantity);

            data = Object.values(UniversalTradeStore.tradeData).find(
              (t) =>
                (t.from === primary?.id && t.to === secondary?.id) ||
                (t.from === secondary?.id && t.to === primary?.id),
            );

            if (data && primary && secondary && quantity) {
              const executedAmount = order.quantity.toString();
              const executedTotal = Big(order.trigger).times(order.quantity).toString();

              UniversalTradeStore.setTradeData(data.from, data.to, { executedAmount, executedTotal });
              UniversalTradeStore.setTradeUIData(data.from, data.to, { orderStatus: OrderStatus.Success });
            }
          }

          UniversalTradeStore.setExecutingOrders(false);
          onFinish();
          return;
        } catch (e) {
          const { errorMessage } = e as SwyftxError;
          const { primary, secondary, orderType } = marketOrders[0];
          const primaryAsset = assetService.getAssetByCode(primary);
          const secondaryAsset = assetService.getAssetByCode(secondary);

          if (!primaryAsset || !secondaryAsset) return;

          const fromAsset = TradeService.isBuy(orderType) ? primaryAsset : secondaryAsset;
          const toAsset = TradeService.isBuy(orderType) ? secondaryAsset : primaryAsset;

          UniversalTradeStore.setTradeUIData(fromAsset.id, toAsset.id, {
            orderStatus: OrderStatus.Error,
            error: errorMessage,
          });

          UniversalTradeStore.setExecutingOrders(false);
          if (onError) onError(errorMessage);
          return;
        }
      } else if (marketOrders.length && swapOrders.length) {
        const [order] = marketOrders;
        promises.push(api.endpoints.createOrder({ data: order }));
      }

      if (swapOrders.length > 0) {
        promises.push(api.endpoints.swapMulti({ data: swapOrders }));
      }

      const responses = await allSettled(promises);

      // check if any requests hard failed
      for (const response of responses) {
        if (response.status === 'rejected') {
          throw response.reason;
        } else if (response?.value?.data.length > 0) {
          for (const responseData of response.value.data) {
            let data;

            if (responseData.buyResult) {
              const { buyResult, sellResult } = responseData;
              const { secondaryAssetId: sellAssetId } = sellResult.order || {};
              const { secondaryAssetId: buyAssetId } = buyResult.order || {};

              data = Object.values(UniversalTradeStore.tradeData).find(
                (t) => t.from === sellAssetId && t.to === buyAssetId,
              );
            } else {
              const { primary_asset, secondary_asset, amount, total, order_type } = responseData.order;
              const { orderUuid } = responseData;

              data = Object.values(UniversalTradeStore.tradeData).find(
                (t) =>
                  (t.from === primary_asset && t.to === secondary_asset) ||
                  (t.from === secondary_asset && t.to === primary_asset),
              );

              if (data) {
                const executedAmount = order_type === OrderType.MarketBuy ? total.toString() : amount.toString();
                const executedTotal = order_type === OrderType.MarketBuy ? amount.toString() : total.toString();

                UniversalTradeStore.setTradeData(data.from, data.to, { executedAmount, executedTotal, orderUuid });
              }
            }

            if (data) {
              UniversalTradeStore.setTradeUIData(data.from, data.to, { orderStatus: OrderStatus.Success });
            }
          }
        } else if (response.value?.data?.order) {
          this.processCreateMarketOrderResponse(response.value.data);
        }
      }
      UniversalTradeStore.setExecutingOrders(false);
      onFinish();
    },

    getMaxAssets: (side: TradeSide) => {
      const reverse = side === TradeSide.To ? TradeSide.From : TradeSide.To;

      return UniversalTradeStore[reverse].length > 1 || UniversalTradeStore.tradeType === TradeType.OnTrigger ? 1 : 20;
    },

    getReceiveValue() {
      let value = Big(0);

      if (!UniversalTradeStore.tradeKeys.length) return value;

      UniversalTradeStore.tradeKeys.forEach((key: string) => {
        const { amount } = UniversalTradeStore.tradeData[key];

        value = value.plus(Big(amount));
      });

      return value.toString();
    },

    getMaxValue: () => {
      const { tradeState, maxTradeValue } = UniversalTradeStore;

      const isMultiTrade = tradeState === TradeState.MultiTrade;
      const isMultipleTo = UniversalTradeStore.tradeFrom.length === 1;

      if (isMultiTrade && isMultipleTo) return maxTradeValue;

      return '';
    },

    getSwapTradeDataKeys(baseAsset: Asset): string[] {
      const swapTradeDataKeys: string[] = [];
      const baseAssets = assetService.getBaseAssets();

      UniversalTradeStore.tradeKeys.forEach((key) => {
        const data = UniversalTradeStore.tradeData[key];
        if (!data) return;

        const { from, to } = data;

        const isPrimaryFrom = baseAssets.includes(from);
        const isPrimaryTo = baseAssets.includes(to);

        // If one of the assets is primary we can just pass the key
        if (isPrimaryFrom || isPrimaryTo) {
          swapTradeDataKeys.push(key);
        } else {
          // Otherwise grab both keys and combine with the baseAsset
          swapTradeDataKeys.push(`${baseAsset.id}_${from}`);
          swapTradeDataKeys.push(`${baseAsset.id}_${to}`);
        }
      });

      return swapTradeDataKeys;
    },

    getTradeAssetIds: (side: TradeSide, userCountry?: Asset) => {
      if (UniversalTradeStore.tradeType === TradeType.OnTrigger) {
        const baseAssets = assetService.getBaseAssets();
        const tradeToLength = UniversalTradeStore.tradeTo.length;
        const tradeFromLength = UniversalTradeStore.tradeFrom.length;

        if (side === TradeSide.From && tradeToLength === 1 && !baseAssets.includes(UniversalTradeStore.tradeTo[0])) {
          return baseAssets;
        }
        if (side === TradeSide.To && tradeFromLength === 1 && !baseAssets.includes(UniversalTradeStore.tradeFrom[0])) {
          return baseAssets;
        }
      }

      if (UniversalTradeStore.tradeType === TradeType.Recurring && userCountry) {
        return assetService.getActiveAssets().filter((a) => a !== userCountry?.id);
      }

      return undefined;
    },

    canTrade: (canTradeDemo = true) => {
      const { isUserVerified } = UserStore.useUserStore;
      const { isDemo } = AppStore.useAppStore;

      return isUserVerified() || (isDemo && canTradeDemo);
    },
  };

  return store;
};
