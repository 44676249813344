import { useMemo } from 'react';

import { useCategories } from './useCategories';

const AI_CATEGORY_ID = 28;

const useTrendingCategory = () => {
  const { categories } = useCategories();

  const trendingCategory = useMemo(() => categories.find((c) => c.id === AI_CATEGORY_ID), [categories]);

  return { trendingCategory };
};

export { useTrendingCategory };
