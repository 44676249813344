import { Auth, Profile, TradePriceHistory, UserSettings } from '@shared/api';
import { Asset } from '@shared/api/@types/markets';
import { AffiliateInfoResponse, UserBalances, UserStatistics } from '@shared/api/@types/user';
import {
  AccountStatusEnum,
  EntityMemberNotificationStatusEnum,
  EntityTypeEnum,
  FiatCodeEnum,
  FiatIdEnum,
  GreenIdStatusEnum,
  KycStatusEnum,
} from '@shared/enums';
import { StakingOfferings } from '@shared/services/stakingDetailService';

export enum EntityColor {
  'Purple' = 0,
  'Magenta' = 1,
  'Teal' = 2,
  'Orange' = 3,
}

export interface ChartPreference {
  interval: string;
  type: number;
  priceSide: 'bid' | 'ask';
  showMarks: boolean;
  indicators: string[];
}

export interface EntityAccount {
  uuid: string;
  name: string;
  initials?: string;
  isEntity?: boolean;
  nonTradingAccount?: boolean;
  entityColorIndex?: EntityColor;
  checkIfVerified: boolean;
}

export interface EntityMember {
  country?: string;
  email: string;
  entityType: EntityTypeEnum;
  expiry?: number;
  isExpired?: boolean;
  name: string;
  notificationEnabled?: EntityMemberNotificationStatusEnum;
  uuid?: string;
  parentUuid?: string;
  stakeholder?: number;
  scope: string;
  verified?: GreenIdStatusEnum;
}

export interface Affiliate extends Partial<AffiliateInfoResponse> {
  isLoadingState: boolean;
  isErrorState: boolean;
}

export type UserStateType = {
  affiliate: Affiliate;
  assetsToDust: number[];
  balances: UserBalances;
  chartPreferences: ChartPreference;
  entityAccounts: EntityAccount[];
  entityColorIndex?: number;
  entityMembers: EntityMember[];
  entityUuid?: string;
  hasStakingBalance?: boolean;
  isLoggedIn: boolean;
  previousUserAuth?: Auth;
  previousUserProfile?: Profile;
  recaptchaToken: string;
  scopeArray: string[];
  stakingInfo?: StakingOfferings;
  tradePriceHistory: TradePriceHistory;
  userAuth?: Auth;
  userBaseCurrency: FiatIdEnum;
  userCountryCurrency: FiatIdEnum;
  userCountryCurrencyCode: FiatCodeEnum;
  userId: string;
  userProfile?: Profile;
  userStatistics?: UserStatistics;
  refreshRecaptcha?: boolean;
  baseAsset?: Asset;
};

export interface UserStoreSchema extends UserStateType {
  canTransferCrypto: () => boolean;
  confirmPassword: (password: string) => Promise<boolean>;
  getAccountStatus: () => AccountStatusEnum;
  getBalance: (asset: Asset | Asset['id']) => string;
  getEntityMember: () => EntityMember | undefined;
  getMaskedPhoneNumber: () => string;
  getMaskedEmail: () => string;
  getName: () => string | undefined | string | string;
  isEntity: () => boolean;
  isKyc0Required: () => boolean;
  isKyc1Complete: () => boolean;
  isKyc1Required: () => boolean;
  isKyc2Complete: () => boolean;
  isKyc2Required: () => boolean;
  getHumanReadableKycStatus: (kyc?: KycStatusEnum) => string;
  isNZ: () => boolean;
  isRestricted: () => boolean;
  isUserVerified: () => boolean;
  userHasCompletedOnboarding: () => boolean;
  userHasPendingIdVerification: () => boolean;
  resetAssetsToDust: () => void;
  resetEntityAccounts: () => void;
  resetEntityColorIndex: () => void;
  resetEntityMembers: () => void;
  resetPreviousUserProfile: () => void;
  resetUserProfile: () => void;
  setAssetsToDust: (assetIds: number[]) => void;
  setAuth: (auth?: Auth, isEntity?: boolean) => void;
  setBalances: (balances: UserBalances) => void;
  setBaseAsset: (baseAsset?: Asset) => void;
  setChartPreferences: (preferences: ChartPreference) => void;
  setEntityAccounts: (entityAccounts: EntityAccount[]) => void;
  setEntityColorIndex: (entityColorIndex: number) => void;
  setEntityMembers: (entityMembers: EntityMember[]) => void;
  setEntityUuid: (entityUuid?: string) => void;
  setFavourites: (assetId: Asset['id'], isFavourite: boolean) => void;
  setGreenIdInfo: (greenIdRef: string, greenIdStatus: GreenIdStatusEnum) => void;
  setOtcOptIn: (optIn: boolean) => void;
  setPreviousAuth: (auth?: Auth) => void;
  setPreviousUserProfile: (profile: Profile) => void;
  setRecaptchaToken: (token: string) => void;
  setRefreshRecaptcha: (refreshRecaptcha: boolean) => void;
  setStakingInfo: (stakingInfo: StakingOfferings) => void;
  setTradePriceHistory: (tradePriceHistory: TradePriceHistory) => void;
  setUserCurrency: (currencyId: Asset['id'], assetCode: Asset['code']) => void;
  setUserProfile: (profile: Profile) => void;
  setUserSettings: (userSettings: UserSettings) => void;
  setUserStatistics: (userStatistics?: UserStatistics) => void;
  toggleAssetToDust: (assetId: number) => void;
  sourceOfWealthStatus: () => KycStatusEnum;
}
