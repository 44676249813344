import React from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { AppHeaderAssetActions } from '@global-components/AppHeader/AppHeaderAssetActions';
import { BuySellButtons } from '@global-components/BuySellButtons';

import { Asset } from '@shared/api';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';

import { observer } from 'mobx-react-lite';
import { NavigationURLs } from 'src/lib/navigation/types';
import { TradingViewSide } from 'src/lib/trade/components/TradingViewWidget/types';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

import { AssetPrice } from './AssetStats/AssetPrice';
import { AssetStats } from './AssetStats/AssetStats';
import { TradePageVariant } from '../../universalTradePage.consts';
import { AssetInfoPopover } from '../AssetInfoPopover/AssetInfoPopover';
import { AssetPicker } from '../AssetPicker/AssetPicker';

interface HeaderProps {
  variant: TradePageVariant;
  selectedAsset: Asset;
  baseNavigationUrl?: NavigationURLs;
  priceSide: TradingViewSide;
}

export const UniversalTradeHeader: React.FC<HeaderProps> = observer((props) => {
  const { variant, selectedAsset, baseNavigationUrl, priceSide } = props;
  const isXs = useTailwindBreakpoint('xs');
  const baseAsset = useBaseAsset();
  const { isSwyftxPro } = useSwyftxPro();

  if (isXs)
    return (
      <FlexLayout direction='column' className={isSwyftxPro ? 'w-full p-4' : 'p-12'} spacing={16}>
        <FlexLayout direction='row' className='w-full items-center justify-between' spacing={24}>
          <AssetPicker asset={selectedAsset} className='w-full' baseNavigationUrl={baseNavigationUrl} />
          <AppHeaderAssetActions assetCode={selectedAsset.code} tradePanel assetInfo />
        </FlexLayout>
        <FlexLayout direction='row' className='w-full'>
          <AssetStats selectedAsset={selectedAsset} priceSide={priceSide} />
        </FlexLayout>
      </FlexLayout>
    );

  return (
    <FlexLayout
      direction='row'
      className={cn(
        'flex  w-full items-center justify-start ',
        variant === TradePageVariant.ADVANCED ? 'min-h-80 bg-color-background-pageBG px-16' : '',
      )}
    >
      <FlexLayout direction='column' spacing={12} className='!w-full'>
        <FlexLayout
          direction='row'
          spacing={24}
          className={cn(
            'flex w-full items-center pr-16  @xl:py-0',
            variant === TradePageVariant.ADVANCED ? 'py-48 lg:py-16' : '',
          )}
          data-spotlightelementId='asset-stats'
        >
          <AssetPicker
            asset={selectedAsset}
            className='min-w-[16.5rem] max-w-[16.5rem]'
            baseNavigationUrl={baseNavigationUrl}
          />
          {!isSwyftxPro && <AssetInfoPopover asset={selectedAsset} className='hidden @xl:block' />}
          <AssetPrice
            selectedAsset={selectedAsset}
            side={priceSide}
            baseAsset={baseAsset}
            className='flex @md:hidden'
          />

          <FlexLayout direction='row' className='hidden !w-full items-center @md:flex' spacing={24}>
            <AssetStats selectedAsset={selectedAsset} priceSide={priceSide} />
          </FlexLayout>
        </FlexLayout>
        <FlexLayout direction='row' className='flex w-full @md:hidden' spacing={24}>
          <AssetStats selectedAsset={selectedAsset} hidePrice priceSide={priceSide} />
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        spacing={8}
        className='h-full flex-col items-end justify-center @md:w-1/5 @lg:w-[30%] @lg:flex-row @lg:items-center @lg:justify-end @xl:w-[25rem]'
        data-spotlightelementId='asset-actions'
      >
        {isSwyftxPro && <AssetInfoPopover asset={selectedAsset} className='hidden @xl:block' />}
        <AppHeaderAssetActions assetCode={selectedAsset.code} tradePanel assetInfo className='!pr-0' />
        {!isSwyftxPro && <BuySellButtons asset={selectedAsset} variant='aviary' quickBuy className='hidden @sm:flex' />}
      </FlexLayout>
    </FlexLayout>
  );
});
