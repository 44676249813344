import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailVerification } from '@global-components/Forms/Verification/EmailVerification';
import { MobileVerification } from '@global-components/Forms/Verification/MobileVerification';
import { SendCryptoAddAddress } from '@global-components/Modals/SendCrypto/steps';
import { AddCryptoAddressFormData } from '@global-components/Modals/SendCrypto/steps/SendCryptoAddAddress';
import Wizard from '@global-components/Wizard';

import { Asset } from '@shared/api';
import { withdrawalService } from '@shared/services';
import { UIStore } from '@shared/store';

type Props = {
  asset: Asset;
  onClose: () => void;
  refetchAddresses?: () => void;
};

enum TransferAddressCryptoModalStep {
  Form = 0,
  PhoneVerification = 1,
  EmailVerification = 2,
}

export const TransferAddressCryptoModal: React.FC<Props> = ({ asset, onClose, refetchAddresses }) => {
  const [stepIndex, setStepIndex] = useState<TransferAddressCryptoModalStep>(TransferAddressCryptoModalStep.Form);
  const [addressFormData, setAddressFormData] = useState<AddCryptoAddressFormData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);

  const { addToastMessage } = UIStore.useUIStore;
  const { t } = useTranslation('common');

  const addAddress = async () => {
    try {
      if (addressFormData) {
        const { label, address, selectedCryptoNetworkId, metaDataPayload } = addressFormData;

        const response = await withdrawalService.addCryptoWithdrawAddress(
          asset.code,
          label,
          address,
          selectedCryptoNetworkId,
          metaDataPayload,
          token,
          pin || undefined,
        );

        if (refetchAddresses) {
          refetchAddresses();
        }

        setToken(response.token);

        return {
          mfaType: response.mfaType,
        };
      }
      throw new Error(t('addAddress.genericError'));
    } catch (error: any) {
      addToastMessage({ severity: 'error', message: error?.errorMessage || t('addAddress.genericError') });
      return {
        mfaType: 'error',
      };
    }
  };

  const onAddAddressSubmit = async () => {
    if (addressFormData) {
      setLoading(true);
      try {
        const { mfaType } = await addAddress();
        setLoading(false);

        switch (mfaType) {
          case 'sms': {
            setStepIndex(TransferAddressCryptoModalStep.PhoneVerification);
            break;
          }
          case 'email': {
            setStepIndex(TransferAddressCryptoModalStep.EmailVerification);
            break;
          }
          case 'error': {
            break;
          }
          default: {
            onClose();
            addToastMessage({ severity: 'success', message: t('addAddress.success') });
            break;
          }
        }
      } catch (error: any) {
        addToastMessage({ severity: 'error', message: error?.message || t('addAddress.genericError') });
      }
    }
  };

  return (
    <Wizard.Container
      open
      stepIndex={stepIndex}
      onClose={onClose}
      maintainStateOnIndexes={[TransferAddressCryptoModalStep.Form]}
    >
      {[
        // Form
        <Wizard.Step
          key={TransferAddressCryptoModalStep.Form}
          title={t('addAddress.title')}
          onAction={onAddAddressSubmit}
          locked={!addressFormData || !buttonEnabled}
          actionLoading={loading}
          stepLoading={loading}
          onClose={onClose}
        >
          <SendCryptoAddAddress asset={asset} setFormData={setAddressFormData} setCanContinue={setButtonEnabled} />
        </Wizard.Step>,

        // Mobile verification
        <Wizard.Step
          key={TransferAddressCryptoModalStep.PhoneVerification}
          title={t('verification.mobile.title')}
          onClose={onClose}
          actionLoading={loading}
          hideActions
        >
          <MobileVerification
            onSubmit={onAddAddressSubmit}
            code={pin}
            setCode={setPin}
            onCancel={onClose}
            onResendCode={async () => {
              setToken('');
              setPin('');
              await addAddress();
            }}
          />
        </Wizard.Step>,

        // Email verification
        <Wizard.Step
          key={TransferAddressCryptoModalStep.EmailVerification}
          title={t('verification.email.title')}
          onAction={onClose}
          actionLoading={loading}
          onClose={onClose}
        >
          <EmailVerification />
        </Wizard.Step>,
      ]}
    </Wizard.Container>
  );
};
