import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@swyftx/aviary/atoms/Avatar';
import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { LogOut } from '@swyftx/aviary/icons/outlined';

import { Profile } from '@shared/api';
import { EntityAccount } from '@shared/store/userStore/@types/userTypes';

import { useAvo } from '@hooks/Avo/useAvo';
import { useEntityOnboardingApplication } from '@hooks/Entity/useEntityOnboardingApplication';
import { useLogout } from '@hooks/Logout';

import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';
import { SwitchPlatformMenuItem } from 'src/lib/user-profile/components/SwitchPlatformMenuItem/SwitchPlatformMenuItem';

import { AppHeaderMenuProfile } from './AppHeaderMenuProfile';
import { EntityAccountSwitcherNew } from './EntityAccountSwitcherNew';
import { EntityAccountSwitcherOld } from './EntityAccountSwitcherOld';
import { Menu, MenuItem } from '../Menu';

export type AppHeaderMenuItem = {
  icon: React.ReactNode | string;
  text: string;
  color?: 'primary' | 'error';
  separatorAfter?: boolean;
  navigatePath?: NavigationURLs;
};

interface AppHeaderMenuProps {
  user?: Profile;
  entityAccounts: EntityAccount[];
  isUserVerified: boolean;
  openCreateEntity?: () => void;
  isEntity?: boolean;
  userHasCompletedOnboarding: boolean;
  isKyc1Complete: boolean;
  menuItems: AppHeaderMenuItem[];
  selectAccount: (account: EntityAccount) => void;
  selectedAccount?: string;
  switchingAccount?: string;
  switchingAccountError?: string;
}

const AppHeaderMenu: React.FC<AppHeaderMenuProps> = observer((props) => {
  const {
    user,
    isUserVerified,
    menuItems,
    entityAccounts,
    openCreateEntity,
    selectAccount,
    selectedAccount,
    switchingAccount,
    switchingAccountError,
    isKyc1Complete,
    userHasCompletedOnboarding,
    isEntity,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const logout = useLogout();

  const { swyftxProEnabled, isSwyftxPro } = useSwyftxPro();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const entityOnboardingFormEnabled = isFeatureEnabled(AppFeature.EntityOnboarding);
  const location = useLocation();

  const { t } = useTranslation('profile');
  const { navigate } = useNavigateRoute();
  const avo = useAvo();
  const {
    entityHistoryFetching,
    inProgressSubmissions,
    startNewEntityApplication,
    goToExistingApplication,
    hasASubmittedSubmission,
  } = useEntityOnboardingApplication();

  const onOpenMenu = useCallback(() => {
    if (!open) avo.profileDropdownClicked({ screen: location.pathname });
    setOpen(true);
  }, [avo, location.pathname, open]);

  return (
    <Menu
      triggerContent={
        <Button
          id='header-profile'
          layout='icon'
          className='ml-4 p-0'
          onClick={onOpenMenu}
          leadingIcon={
            <Avatar text={user?.name.first && user?.name.first[0]} size='lg' className='h-[2.25rem] w-[2.25rem]' />
          }
        />
      }
      open={open}
      onOpenChange={setOpen}
    >
      <AppHeaderMenuProfile isEntity={isEntity} isUserVerified={isUserVerified} user={user} />
      {swyftxProEnabled && (
        <>
          <Separator className='mt-8' />
          <SwitchPlatformMenuItem />
        </>
      )}
      <Separator />
      <FlexLayout direction='column' spacing={0}>
        {!isSwyftxPro && (
          <FlexLayout direction='column'>
            <Body size='xsmall' color='secondary' className='my-4'>
              Switch account
            </Body>
            {!entityOnboardingFormEnabled && (
              <EntityAccountSwitcherOld
                entityAccounts={entityAccounts}
                selectAccount={selectAccount}
                selectedAccount={selectedAccount}
                userHasCompletedOnboarding={userHasCompletedOnboarding}
                isKyc1Complete={isKyc1Complete}
                openCreateEntity={openCreateEntity}
              />
            )}
            {entityOnboardingFormEnabled && (
              <EntityAccountSwitcherNew
                entityAccounts={entityAccounts}
                selectAccount={selectAccount}
                selectedAccount={selectedAccount}
                switchingAccount={switchingAccount}
                switchingAccountError={switchingAccountError}
                entityHistoryFetching={entityHistoryFetching}
                inProgressSubmissions={inProgressSubmissions}
                startNewEntityApplication={startNewEntityApplication}
                goToExistingApplication={goToExistingApplication}
                hasASubmittedSubmission={!!hasASubmittedSubmission}
              />
            )}
            <Separator className='my-8' />
            {menuItems.map((item) => (
              <React.Fragment key={item.text}>
                <MenuItem
                  key={item.text}
                  icon={item.icon}
                  text={t(item.text as any)}
                  color={item.color}
                  action={item.navigatePath ? () => navigate(item.navigatePath!) : undefined}
                />
                {item.separatorAfter && <Separator className='my-8' />}
              </React.Fragment>
            ))}
          </FlexLayout>
        )}
        <FlexLayout onClick={logout} direction='column'>
          <MenuItem
            color='error'
            icon={<LogOut width={24} height={24} className='text-color-icon-error' />}
            text='Log out'
          />
        </FlexLayout>
      </FlexLayout>
    </Menu>
  );
});

AppHeaderMenu.displayName = 'AppHeaderMenu';

const ProfiledAppHeaderMenu = Sentry.withProfiler(AppHeaderMenu);

export { ProfiledAppHeaderMenu as AppHeaderMenu };
