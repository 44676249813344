import { Asset } from '@shared/api';
import { Network } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';

const canSend = (asset: Asset) => {
  let successfulNetworksCount = 0;

  asset.networks.forEach((network: Network) => {
    if (!network.withdrawDisableForce) {
      successfulNetworksCount += 1;
    }
  });

  return successfulNetworksCount > 0;
};

export const canSendWithdraw = (asset: Asset) => {
  const isAssetFiat = assetService.isAssetFiat(asset?.id);

  if (isAssetFiat) return true;

  if (!asset.withdraw_enabled) return false;

  return canSend(asset);
};

export const fetchAssets = (assets: number[]) =>
  assets.map((assetId) => assetService.getAsset(assetId)!).filter((asset) => asset !== undefined);
