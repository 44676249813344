import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const SwapFilled: React.FC<Props> = ({ className, ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M5.00213 9.528C4.86013 9.699 4.64813 9.799 4.42413 9.799C4.20013 9.799 3.98913 9.699 3.84713 9.528L2.43113 7.819C2.24513 7.595 2.20513 7.284 2.32913 7.021C2.45313 6.758 2.71713 6.59 3.00813 6.59H3.67413V6.54C3.67413 4.175 5.59813 2.25 7.96213 2.25C8.37613 2.25 8.71213 2.586 8.71213 3C8.71213 3.414 8.37613 3.75 7.96213 3.75C6.42513 3.75 5.17413 5.002 5.17413 6.54V6.59H5.83913C6.13013 6.59 6.39413 6.758 6.51813 7.021C6.64213 7.283 6.60213 7.595 6.41713 7.819L5.00213 9.528Z'
      fill='currentColor'
    />
    <path
      d='M16.6875 3C18.8735 3 20.6455 4.77 20.6455 6.96C20.6455 9.14 18.8735 10.92 16.6875 10.92C14.5015 10.92 12.7285 9.14 12.7285 6.96C12.7285 4.77 14.5015 3 16.6875 3Z'
      fill='currentColor'
    />
    <path
      d='M7.31152 13.0801C5.12552 13.0801 3.35352 14.8601 3.35352 17.0401C3.35352 19.2301 5.12552 21.0001 7.31152 21.0001C9.49752 21.0001 11.2706 19.2301 11.2706 17.0401C11.2706 14.8601 9.49752 13.0801 7.31152 13.0801Z'
      fill='currentColor'
    />
    <path
      d='M20.1531 14.4675L21.5691 16.1815C21.7541 16.4055 21.7941 16.7155 21.6701 16.9785C21.5461 17.2415 21.2811 17.4095 20.9911 17.4095H20.3251V17.4595C20.3251 19.8255 18.4011 21.7495 16.0371 21.7495C15.6231 21.7495 15.2871 21.4135 15.2871 20.9995C15.2871 20.5855 15.6231 20.2495 16.0371 20.2495C17.5741 20.2495 18.8251 18.9975 18.8251 17.4595V17.4095H18.1601C17.8701 17.4095 17.6051 17.2415 17.4811 16.9785C17.3581 16.7155 17.3961 16.4055 17.5821 16.1815L18.9971 14.4675C19.2821 14.1215 19.8681 14.1215 20.1531 14.4675Z'
      fill='currentColor'
    />
  </svg>
);

export { SwapFilled };
