import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InlineNotification } from '@swyftx/aviary/atoms/InlineNotification';
import { Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset } from '@shared/api';
import Links from '@shared/constants/links';

import { LimitedFunctionalityType } from '@hooks/Assets/useAssetLimitedFunctionality';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';

type Props = {
  assets: Asset[];
  limitedFunctionalityType: LimitedFunctionalityType;
};

const LimitedFunctionalityNotificationItem: React.FC<Props> = ({ assets, limitedFunctionalityType }) => {
  const { t } = useTranslation('assets', { keyPrefix: 'limitedFunctionality' });
  const { openModal } = useModal();
  const names = assets.map((a) => a.name);

  // This component is injected into the translations component which is where the text comes from
  const getLimitedNotificationItemComponent = () => {
    switch (limitedFunctionalityType) {
      case LimitedFunctionalityType.RedenominationInProgress:
      case LimitedFunctionalityType.RedenominationCompleted:
        return (
          <Typography
            display='inline'
            fontSize={12}
            fontWeight={500}
            color='primary'
            onClick={() =>
              // HACK: we'll fix this properly by getting the details from useAssetLimitedFunctionality and LD
              window.open('https://swyftx.com/announcement/front-redenomination/', '_blank', 'noopener,noreferrer')
            }
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          />
        );
      case LimitedFunctionalityType.LowLiquidity:
      case LimitedFunctionalityType.Sending:
      case LimitedFunctionalityType.Receiving:
      case LimitedFunctionalityType.SendingReceiving:
        return (
          <Typography
            display='inline'
            fontSize={12}
            fontWeight={500}
            color='primary'
            onClick={() => window.open(Links.help.lowLiquidityAndLimited, '_blank', 'noopener,noreferrer')}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          />
        );
      case LimitedFunctionalityType.HighSlippage:
        return (
          <Typography
            display='inline'
            fontSize={12}
            fontWeight={500}
            color='primary'
            onClick={() => openModal(Modals.HighSlippageReviewModal, { showActions: false })}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          />
        );
      default:
        limitedFunctionalityType as never;
        return <></>;
    }
  };

  return (
    <InlineNotification severity='warning' className='full-width'>
      <Typography fontWeight={400} fontSize={12} marginTop='-1px !important' width='85%'>
        <Trans
          t={t}
          i18nKey={`labels.${limitedFunctionalityType}${names.length > 1 ? 'multiple' : ''}`}
          values={{ assetNames: names.join(', '), slippageThreshold: HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD }}
          components={[
            <Typography fontWeight={600} display='inline' fontSize={12} key='limitedFunctionalityNotificationItem' />,
            getLimitedNotificationItemComponent(),
          ]}
        />
      </Typography>
    </InlineNotification>
  );
};

export { LimitedFunctionalityNotificationItem };
