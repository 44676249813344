import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Image } from '@swyftx/aviary/atoms/Image';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { CloseUniversalTrade } from '@global-components/CloseUniversalTrade/CloseUniversalTrade';
import { LimitedFunctionalityNotificationAccordion } from '@global-components/LimitedFunctionalityNotification/LimitedFunctionalityNotificationAccordion';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { UniversalTradeStore, UserStore } from '@shared/store';
import { TradeState, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { usePermissions } from '@hooks/Permissions';
import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { TradeAssetInput } from '@Trade/components/UniversalTradePanel/components/TradeAssetInput';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useDemoMode } from 'src/lib/utils/hooks';

import {
  TradeOrderDetailsContainer,
  TradeOrderDetailsContinue,
  TradeOrderDetailsPriceChart,
  TradeOrderDetailsSummary,
  TradeOrderDetailsTriggerInput,
  TradeOrderDetailsType,
} from './components';

type Props = {
  dismissible?: boolean;
  tradePanel?: boolean;
};

const TradeOrderDetails: React.FC<Props> = observer(({ dismissible, tradePanel }) => {
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });
  const { isEntity } = UserStore.useUserStore;
  const { setDemoMode, isDemo } = useDemoMode();
  const { tradeFrom, tradeTo, tradeType, tradeData, setShowGlobalTrade } = UniversalTradeStore;
  const { isSwap } = useUniversalTradeUtilityStore();
  const singleTrade = tradeFrom.length === 1 && tradeTo.length === 1;
  const noTrade = tradeFrom.length === 0 || tradeTo.length === 0;
  const isXs = useTailwindBreakpoint('xs');
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const universalTradePageEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const { enabled: tradeEnabled, validKYC } = usePermissions({
    permissions: [], // Remove this one we want to check permissions
    // TODO permissions: [EntityPermissions.ORDERS_CREATE], // Reenable this one we want permission checking
  });

  const assets = useMemo(() => {
    const tradeFromAssets = tradeFrom.map(assetService.getAsset).filter((t) => t !== undefined) as Asset[];
    const tradeToAssets = tradeTo.map(assetService.getAsset).filter((t) => t !== undefined) as Asset[];

    return [...tradeFromAssets, ...tradeToAssets];
    // For some reason this will not run unless we actually check the length of the array as well
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeFrom.length, tradeTo.length, tradeFrom, tradeTo]);

  const openVerification = () => {
    navigate(isEntity() ? NavigationURLs.ProfileEntityLinkedMembers : NavigationURLs.ProfileVerification);
    setShowGlobalTrade(false);
  };

  const renderTitle = () => (
    <Stack justifyContent='flex-start' paddingTop={2} paddingX={3} direction='row' alignItems='center' spacing={1}>
      <Typography fontSize={20} fontWeight={700} color='text.primary'>
        {t('labels.order')}
      </Typography>
      {dismissible && <CloseUniversalTrade />}
    </Stack>
  );

  const renderTradeNoAccess = () => {
    if (!isEntity()) return null;
    if (validKYC && (tradeEnabled || isDemo)) return null;

    return (
      <Stack height='100%' paddingX={4} direction='column' alignItems='center' justifyContent='center' spacing={1}>
        <Image image='notifications' className='h-[120px] w-[120px]' usePalette />
        <Typography fontWeight={600} fontSize={18} textAlign='center'>
          {t('labels.noAccess')}
        </Typography>
        <Typography textAlign='center'> {t('labels.readOnly')}</Typography>
      </Stack>
    );
  };

  const renderVerify = () => {
    if (validKYC || isDemo) return null;

    return (
      <Stack height='100%' paddingX={4} direction='column' alignItems='center' justifyContent='center' spacing={2}>
        <Image image='trade' alt='verify' className='h-[120px] w-[120px]' />
        <Typography fontWeight={600} fontSize={18} textAlign='center'>
          {t('labels.verify')}
        </Typography>
        <Typography textAlign='center'> {t('labels.needToVerify')}</Typography>
        <Button variant='contained' size='large' fullWidth onClick={openVerification}>
          {t('buttonLabels.verifyNow')}
        </Button>
        <Button variant='outlined' size='large' color='inherit' fullWidth onClick={() => setDemoMode(true)}>
          {t('buttonLabels.demoMode')}
        </Button>
      </Stack>
    );
  };

  const renderOrderDetails = () => {
    if (!isDemo && (!tradeEnabled || !validKYC)) return null;

    return (
      <Stack spacing={3}>
        <TradeOrderDetailsType tradePanel={tradePanel} dismissible={dismissible} />
        <TradeOrderDetailsContainer />
        {isXs && !universalTradePageEnabled && (singleTrade || tradeType === TradeType.OnTrigger) && (
          <TradeOrderDetailsPriceChart />
        )}
        {tradeType === TradeType.OnTrigger && (
          <TradeOrderDetailsTriggerInput tradeDataKey={`${tradeFrom[0]}_${tradeTo[0]}`} />
        )}
        {singleTrade && (
          <Box marginBottom={1} marginTop={3}>
            <TradeAssetInput
              tradeDataKey={`${tradeFrom[0]}_${tradeTo[0]}`}
              flippable={!isSwap(tradeData[`${tradeFrom[0]}_${tradeTo[0]}`])}
            />
          </Box>
        )}
      </Stack>
    );
  };

  const renderBottomBar = () => {
    if (!tradeEnabled || !validKYC) return null;
    const isRecurring = tradeType === TradeType.Recurring;
    const disabled = isRecurring && tradeTo.length === 0;

    return (
      <Box bottom={0} width='100%' zIndex={5} sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
        {assets && (
          <Box>
            <LimitedFunctionalityNotificationAccordion assets={assets} assetsToIgnore={tradeFrom} />
          </Box>
        )}
        {((!singleTrade && !noTrade) || (!singleTrade && tradeType === TradeType.Recurring)) && (
          <TradeOrderDetailsContinue
            state={isRecurring ? TradeState.Recurring : TradeState.MultiTrade}
            disabled={disabled}
          />
        )}

        {(singleTrade || noTrade) && tradeType !== TradeType.Recurring && (
          <TradeOrderDetailsSummary
            tradeDataKey={`${tradeFrom.length ? tradeFrom[0] : ''}_${tradeTo.length ? tradeTo[0] : ''}`}
          />
        )}
      </Box>
    );
  };

  return (
    <Stack direction='column' position='relative' spacing={0} sx={{ height: '100%', width: '100%', overflowY: 'auto' }}>
      {!tradePanel && renderTitle()}
      <Box height='calc(100%)' overflow='auto' padding={{ xs: 2, lg: 3 }}>
        {renderTradeNoAccess()}
        {renderVerify()}
        {renderOrderDetails()}
      </Box>
      {renderBottomBar()}
    </Stack>
  );
});

TradeOrderDetails.displayName = 'TradeOrderDetails';

export { TradeOrderDetails };
