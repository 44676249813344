import React, { useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Document } from '@swyftx/aviary/icons/outlined';

import { SearchInputAviary } from '@global-components/Input/SearchInput/SearchInputAviary';

import { WalletContext } from '@Wallet/Wallet.context';
import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { DustSmallBalancesButton } from 'src/lib/markets/components';
import { usePortfolioReport } from 'src/lib/portfolio/hooks/usePortfolioReport';
import { useDemoMode } from 'src/lib/utils/hooks';

import { WalletListHideZeroBalancesFilter, WalletListLayoutFilter, WalletListSortFilter } from './components';

const WalletListFilterBar: React.FC = observer(() => {
  const { walletType, walletListSearchValue, setWalletListSearchValue } = useContext(WalletContext);
  const { downloadReport } = usePortfolioReport();
  const { isDemo } = useDemoMode();
  const { isFeatureEnabled } = useIsFeatureEnabled();

  return (
    <FlexLayout
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      spacing={8}
      className='w-full @container'
    >
      <FlexLayout direction='row' spacing={8} alignItems='center' className='w-full @sm:w-auto'>
        <Body weight='bold' color='primary' size='large'>
          {walletType}
        </Body>
      </FlexLayout>

      <FlexLayout direction='row' justifyContent='center' alignItems='center' spacing={8} className='h-full'>
        {walletType !== WalletType.EARNING_WALLETS && isFeatureEnabled(AppFeature.PortfolioBalanceReport) && (
          <Button
            layout='icon'
            disabled={isDemo}
            variant='outlined'
            leadingIcon={<Document className='h-20 w-20' />}
            tooltip='Download balances report'
            onClick={downloadReport}
          />
        )}
        {walletType !== WalletType.EARNING_WALLETS && <DustSmallBalancesButton layout='icon' />}
        {walletType !== WalletType.EARNING_WALLETS && <WalletListHideZeroBalancesFilter />}
        <SearchInputAviary
          id='walletList-search-filter'
          placeholder='Search for wallet'
          onChange={(e) => setWalletListSearchValue(e.target.value)}
          onDelete={() => {
            setWalletListSearchValue('');
          }}
          value={walletListSearchValue}
          containerClassName='bg-color-background-surface-primary border border-color-border-main h-[36px] rounded-[16px] max-w-[190px] hidden @md:flex'
        />
        <WalletListLayoutFilter className='hidden @md:block' />
        <WalletListSortFilter />
      </FlexLayout>
    </FlexLayout>
  );
});

export { WalletListFilterBar };
