import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Loading, Stack } from '@swyftx/react-web-design-system';

import { AssetHistoryItemStatus } from '@shared/api';
import { assetService, defaultTransactionFilter, TransactionHistoryItem, walletService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { DashboardBaseTile } from '@Dashboard/components';
import { DashboardProps } from '@Dashboard/components/DashboardBaseTile/DashboardBaseTile.data';

import { observer } from 'mobx-react-lite';

import { ActivityTileEmptyView, ActivityTileTableHeader, ActivityTileTransactionItem } from './components';

const ActivityTile: React.FC<DashboardProps> = observer(({ dragProps, tile }) => {
  const { userAuth, balances } = UserStore.useUserStore;
  const { isDemo } = AppStore.useAppStore;
  const [activityTransactions, setActivityTransactions] = useState<TransactionHistoryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation('dashboard');
  const { orders } = useContext(TransactionsContext);
  const openOrders = useMemo(() => orders.filter((order) => order.status === AssetHistoryItemStatus.Pending), [orders]);

  // get transactions for the activity tile
  const getActivityTransactions = async () => {
    try {
      setLoading(true);
      const { tableItems } = await walletService.getAllTransactionHistory(
        1,
        defaultTransactionFilter.startDate,
        defaultTransactionFilter.endDate,
        true,
        10,
        defaultTransactionFilter.typeFilters,
        defaultTransactionFilter.statusFilters,
        defaultTransactionFilter.sort,
        defaultTransactionFilter.order,
        undefined,
      );

      setActivityTransactions(tableItems);
    } catch (error) {
      // TODO: catch error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Hook into user auth because this was calling before user was authenticated
    if (userAuth) getActivityTransactions();
  }, [userAuth, balances, openOrders, isDemo]);

  const renderContent = () => {
    if (loading) {
      return (
        <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
          <Loading />
        </Stack>
      );
    }

    if (activityTransactions.length < 1) {
      return (
        <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
          <ActivityTileEmptyView />
        </Stack>
      );
    }

    return (
      <>
        <ActivityTileTableHeader tile={tile} />
        <Box sx={{ overflowY: 'auto' }}>
          {activityTransactions.map((transaction, index) => {
            const secondaryAsset = assetService.getAsset(transaction.secondaryAsset);
            if (!secondaryAsset) return null;
            return (
              <ActivityTileTransactionItem
                key={transaction.uuid}
                transaction={transaction}
                tile={tile}
                showDivider={index < activityTransactions.length - 1}
              />
            );
          })}
        </Box>
      </>
    );
  };

  return (
    <DashboardBaseTile dragProps={dragProps} tileName={tile.name} title={t('activityTile.title')} noPadding>
      <Box height='100%' position='relative' display='flex'>
        <Stack direction='column' width='100%'>
          {renderContent()}
        </Stack>
      </Box>
    </DashboardBaseTile>
  );
});

ActivityTile.displayName = 'ActivityTile';

export { ActivityTile };
