import React, { useCallback } from 'react';

import { Stack } from '@swyftx/react-web-design-system';

import { TransferTabItem } from '@global-components/Modals/TransferModal/components/TransferTabs/TransferTabItem/TransferTabItem';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { useTheme } from '@hooks/useTheme';
import { getDepositMethods } from '@routes/Wallet/subroutes/SingleWallet/getDepositMethods';

type Props = {
  transferType: DepositMethodEnum;
  asset: Asset;
  setTransferType: (transferType: DepositMethodEnum) => void;
};

export const DepositTabs: React.FC<Props> = ({ transferType, setTransferType, asset }) => {
  const { isLightTheme } = useTheme();
  const depositMethods = useCallback(() => getDepositMethods(asset.code), [asset.code]);

  const tabs = depositMethods().map((method) => ({
    onClick: () => setTransferType(method.method),
    selected: transferType === method.method,
    label: method.title,
    icon: isLightTheme ? method.icon : method.darkModeIcon || method.icon,
    showIcon: method.showIcon,
  }));

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }}>
      {tabs.map((tabProps, index) => (
        <TransferTabItem key={`deposit-tab-${index}`} {...tabProps} direction='column' spacing={1} />
      ))}
    </Stack>
  );
};
