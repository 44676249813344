import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';

import { BuyButton } from '@global-components/BuySellButtons';

import { Category } from '@shared/api/@types/categories';
import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { TrendingCategoryTopTradedAssets } from './TrendingCategoryTopTradedAssets';
import { TrendingCryptoNarrative } from './TrendingCryptoNarrative';

type Props = {
  defaultOpen?: boolean;
  trendingCategory: Category;
};

const CategoryTrendingModal: React.FC<Props> = ({ trendingCategory, defaultOpen = false }) => {
  const { getAssetsByCategoryId } = useMarkets();
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const [seenModal, setSeenModal] = useLocalStorage<boolean>(StorageKey.SEEN_TRENDING_AD4_MODAL, false);
  const isXs = useTailwindBreakpoint('xs');
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('assets');

  const onOpenChange = useCallback(
    (o?: boolean) => {
      setOpen(!!o);
      if (!o) setSeenModal(true);
    },
    [setSeenModal],
  );

  return (
    <Modal
      title={trendingCategory.name}
      open={open && !seenModal}
      onOpenChange={onOpenChange}
      position={isXs ? 'bottom' : 'center'}
      className='sm:min-w-[576px] md:min-w-[700px] lg:min-w-[800px]'
    >
      <FlexLayout direction='column' className='px-16 pb-16 md:px-24 md:pb-24' spacing={16}>
        <Body color='primary' className='line-clamp-3'>
          {trendingCategory.description}
        </Body>
        <FlexLayout className='flex-col md:flex-row' spacing={24} justifyContent='space-between'>
          <TrendingCryptoNarrative />
          <div className='w-1 bg-color-border-main' />
          <TrendingCategoryTopTradedAssets />
        </FlexLayout>
        <FlexLayout alignItems='center' justifyContent='end' spacing={16}>
          <Button
            variant='outlined'
            size={isXs ? 'md' : 'lg'}
            onClick={() => navigate(NavigationURLs.SingleCategory, { pathParams: { categoryId: trendingCategory.id } })}
          >
            {t('trendingCategory.learnMore')}
          </Button>
          <span>
            <BuyButton
              assets={getAssetsByCategoryId(trendingCategory.id)}
              color='primary'
              size={isXs ? 'md' : 'lg'}
              variant='aviary'
              customLabel={t('trendingCategory.buyBundle')}
            />
          </span>
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};

export { CategoryTrendingModal };
