import React, { useMemo } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { FormattedText } from '@global-components/Text';

import { Asset, AssetType } from '@shared/api';
import { assetService } from '@shared/services';
import { AppStore, RatesStore } from '@shared/store';

import { AssetBalanceData } from '@hooks/Assets/useAssetBalance';
import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { BalanceKey } from '@utils/balance';

import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

import { WalletListTileAssetDetails } from '../WalletListTileAssetDetails';

type Props = {
  asset: Asset;
  assetBalanceData: AssetBalanceData;
  walletType: WalletType;
  balanceKey: BalanceKey;
  assetBalanceKey: 'trading' | 'all';
};

const WalletListTradingTile: React.FC<Props> = observer(
  ({ asset, assetBalanceData, walletType, balanceKey, assetBalanceKey }) => {
    const { isDemo } = AppStore.useAppStore;
    const isFiat = asset && assetService.isAssetFiat(asset.id);
    const baseAsset = useBaseAsset();
    const isBaseAssetFiat = useMemo(() => baseAsset?.assetType === AssetType.Fiat, [baseAsset]);
    const { getRate } = RatesStore.useRatesStore;

    const getRow = (children: React.ReactNode) => (
      <FlexLayout className='w-full'>
        <FlexLayout className='h-full w-full' direction='row' alignItems='center' justifyContent='space-between'>
          {children}
        </FlexLayout>
      </FlexLayout>
    );

    return (
      <FlexLayout className='h-full w-full @container' direction='column' justifyContent='space-between'>
        {/* asset details  */}
        <FlexLayout direction='row' className='w-full' justifyContent='center'>
          <WalletListTileAssetDetails
            asset={asset}
            assetBalanceData={assetBalanceData}
            walletType={walletType}
            balanceKey={balanceKey}
            assetBalanceKey={assetBalanceKey}
          />
        </FlexLayout>

        <FlexLayout direction='column' className='h-full w-full' justifyContent='end' spacing={4}>
          {/* value */}
          {getRow(
            <>
              <Body color='primary'>Value</Body>
              <FormattedText
                typographyProps={{ size: 'large', weight: 'emphasis' }}
                value={assetBalanceData[assetBalanceKey].value}
                currency={baseAsset}
                dynamicCurrency={asset}
                formatOpts={{ appendCode: true, priceScale: isBaseAssetFiat ? 2 : baseAsset?.price_scale }}
              />
            </>,
          )}

          {/* amount */}
          {getRow(
            <>
              <Body color='primary'>Amount</Body>
              <FormattedText
                typographyProps={{ size: 'large' }}
                value={assetBalanceData[assetBalanceKey].balance ?? '0'}
                currency={asset}
                formatOpts={{ appendCode: true }}
              />
            </>,
          )}

          {/* Current price */}
          {!isFiat &&
            !isDemo &&
            getRow(
              <>
                <Body color='primary'>Current Price</Body>

                <FormattedText
                  typographyProps={{ size: 'large' }}
                  value={getRate(asset).midPrice ?? '0'}
                  currency={baseAsset}
                  dynamicCurrency={asset}
                />
              </>,
            )}

          {/* Average buy price */}
          {!isFiat &&
            !isDemo &&
            getRow(
              <>
                <Body color='primary'>Average buy Price</Body>
                <FormattedText
                  typographyProps={{ size: 'large' }}
                  value={assetBalanceData.trading.averageBuyPrice ?? '0'}
                  currency={baseAsset}
                  dynamicCurrency={asset}
                />
              </>,
            )}
        </FlexLayout>
      </FlexLayout>
    );
  },
);

export { WalletListTradingTile };
