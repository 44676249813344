import React, { useEffect, useState } from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';

import { Skeleton, Stack } from '@swyftx/react-web-design-system';

interface Props {
  num: number;
}

const VirtualizedListSkeleton: React.FC<Props> = ({ num }) => {
  const [skeletonItems, setSkeletonItems] = useState<Array<{ key: number }>>();

  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const newSkeletonItems = [];
    for (let i = 0; i < num; i += 1) {
      newSkeletonItems.push({ key: i });
    }

    setSkeletonItems(newSkeletonItems);
  }, [num]);

  const renderSkeleton = (key: number) => (
    <Stack direction='row' alignItems='center' display='flex' flex='1 0 auto' width='100%' height='90px' key={key}>
      <Box flex='30 0 auto' width='30px'>
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <Skeleton variant='rectangular' width='20px' height='20px' sx={{ marginLeft: '10px', marginRight: '4px' }} />
        </Stack>
      </Box>

      <Box flex='150 0 auto' width='150px'>
        <Stack direction='row' alignItems='center' justifyContent='flex-start'>
          <Skeleton variant='circular' width='30px' height='30px' sx={{ marginRight: '8px' }} />

          <Stack direction='column' height='100%' alignItems='flex-start' justifyContent='center'>
            <Skeleton variant='text' width='90px' height='24px' />
            <Stack direction='row' alignItems='center'>
              <Skeleton variant='text' width='30px' height='20px' />
              <Skeleton variant='text' width='50px' height='20px' />
            </Stack>
          </Stack>
        </Stack>
      </Box>

      {isTabletScreen && (
        <Box flex='150 0 auto' width='150px'>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <Skeleton variant='text' width='120px' height='20px' sx={{ marginRight: 2 }} />
          </Stack>
        </Box>
      )}

      <Box flex='80 0 auto' width='80px'>
        <Stack direction='row' alignItems='center' justifyContent='flex-end'>
          <Skeleton variant='text' width='60px' height='20px' sx={{ marginRight: 2 }} />
        </Stack>
      </Box>

      {isTabletScreen && (
        <Box flex='80 0 auto' width='80px'>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <Skeleton variant='text' width='60px' height='20px' sx={{ marginRight: 2 }} />
          </Stack>
        </Box>
      )}

      {isTabletScreen && (
        <Box flex='80 0 auto' width='80px'>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <Skeleton variant='text' width='60px' height='20px' sx={{ marginRight: 2 }} />
          </Stack>
        </Box>
      )}

      {isTabletScreen && (
        <Box flex='80 0 auto' width='80px'>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <Skeleton variant='text' width='60px' height='20px' sx={{ marginRight: 2 }} />
          </Stack>
        </Box>
      )}

      {isLargeScreen && (
        <Box flex='80 0 auto' width='80px'>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <Skeleton variant='text' width='60px' height='20px' sx={{ marginRight: 2 }} />
          </Stack>
        </Box>
      )}

      {isLargeScreen && (
        <Box flex='80 0 auto' width='80px'>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <Skeleton variant='text' width='60px' height='20px' sx={{ marginRight: 2 }} />
          </Stack>
        </Box>
      )}

      {isTabletScreen && (
        <Box flex='150 0 auto' width='150px'>
          <Stack direction='row' alignItems='center' width='100%' justifyContent='center' spacing={1}>
            <Skeleton variant='rectangular' width='45%' height='35px' />
            <Skeleton variant='rectangular' width='45%' height='35px' />
          </Stack>
        </Box>
      )}
    </Stack>
  );

  if (!skeletonItems) return null;

  return <Box>{skeletonItems.map((skeletonItem) => renderSkeleton(skeletonItem.key))}</Box>;
};

export { VirtualizedListSkeleton };
