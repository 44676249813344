import React from 'react';

import { Button, Modal, pxToRem, Stack, Typography, TypographyProps } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

const contentTypographyProps: TypographyProps = {
  fontSize: pxToRem(16),
  color: 'text.secondary',
};

type Props = {
  onClose: () => void;
};

// TODO convert content to Verifications/EmailVerification
export const EmailVerificationModal: React.FC<Props> = ({ onClose }) => {
  const { userProfile } = UserStore.useUserStore;
  const email = userProfile?.email;

  return (
    <Modal
      PII
      id='email-verification-modal'
      open
      onClose={onClose}
      sx={{ width: '700px', maxHeight: 'calc(100% - 2rem)', '.MuiCardContent-root': { height: '100%' }, padding: 3 }}
      HeaderProps={{ title: 'Verification via email required', dismissible: true, divider: true }}
    >
      <Stack spacing={3} alignItems='flex-start'>
        <Typography {...contentTypographyProps}>
          The action you are trying to complete requires email verification. An email will be sent to{' '}
          <Typography {...contentTypographyProps} fontWeight={600} display='inline'>
            {email}.
          </Typography>
        </Typography>
        <Typography {...contentTypographyProps}>
          Please check your email inbox and follow the steps to verify and enable withdrawals to this new address
        </Typography>

        <Stack direction='row' width='100%' justifyContent='flex-end'>
          <Button sx={{ align: 'right' }} variant='contained' onClick={() => onClose()}>
            OK, I understand
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
