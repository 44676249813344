import React, { useCallback, useMemo } from 'react';

import { IndividualTrustForm } from '@shared/api';

import { useEntityOnboardingService } from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useIndividualTrusteeMembersStep } from '@routes/EntityOnboarding/hooks/useIndividualTrusteeMembersStep';

import { observer } from 'mobx-react-lite';

import { IndividualPageLayout } from './components/IndividualPageLayout';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';
import { EntityOnboardingPageLayout } from '../EntityOnboardingPageLayout';

export const TrustAddBeneficiariesStep: React.FC = observer(() => {
  const entityOnboardingService = useEntityOnboardingService();

  const {
    members: beneficiaries,
    addMemberOpen,
    onAddMember,
    onCancel,
    continueDisabled,
    handleRemove,
    toggleAddMemberOpen,
    onEditMember,
  } = useIndividualTrusteeMembersStep('beneficiary');

  const rawBeneficiaries = useMemo(() => beneficiaries.map((member) => member.individual), [beneficiaries]);

  const handleSubmit = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'NEXT',
        data: { beneficiaries: rawBeneficiaries } as IndividualTrustForm,
      }),
    [entityOnboardingService, rawBeneficiaries],
  );

  const handleBack = useCallback(
    () =>
      entityOnboardingService.send({
        type: 'BACK',
        data: { beneficiaries: rawBeneficiaries } as IndividualTrustForm,
      }),
    [entityOnboardingService, rawBeneficiaries],
  );

  return (
    <EntityOnboardingStepContainer
      customTitle='Add beneficiaries'
      submitDisabled={continueDisabled}
      onSubmit={handleSubmit}
      onBack={handleBack}
    >
      <EntityOnboardingPageLayout subtitle='List all individuals who are beneficiaries of your Trust. We are required to collect this for regulatory purposes.'>
        <IndividualPageLayout
          title='Please add all beneficiaries'
          members={beneficiaries}
          onAddMember={onAddMember}
          handleRemove={handleRemove}
          onCancel={onCancel}
          addMemberOpen={addMemberOpen}
          toggleAddMemberOpen={toggleAddMemberOpen}
          memberType='beneficiary'
          onEditMember={onEditMember}
        />
      </EntityOnboardingPageLayout>
    </EntityOnboardingStepContainer>
  );
});
