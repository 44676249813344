import React, { useState } from 'react';

import { Box } from '@mui/material';

import { Stack } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';

import { TransferDepositReceive } from './TransferDepositReceive';
import { TransferWithdrawSend } from './TransferWithdrawSend';
import { TransferModalDetailsTabs } from './components/TransferModalDetailsTabs/TransferModalDetailsTabs';
import { TransferEnum } from '../TransferModal.types';
import {
  TransferModalErrorContentStandard,
  TransferModalErrorContentVerification,
} from '../components/TransferModalErrorContent';
import { useTransferModalErrors } from '../components/TransferModalErrorContent/TransferModalErrorContent.hooks';

type Props = {
  initialTransferType: TransferEnum;
  onClose: () => void;
  selectedAsset?: Asset;
  initialDepositType?: DepositMethodEnum;
};

export const TransferModalDetailsStep: React.FC<Props> = ({
  initialTransferType,
  initialDepositType,
  selectedAsset,
  onClose,
}) => {
  const [transferType, setTransferType] = useState<TransferEnum>(initialTransferType);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [onAction, setOnAction] = useState<() => void>(() => {});
  const error = useTransferModalErrors(transferType, selectedAsset?.assetType);
  const [hideContinueButton, setHideContinueButton] = useState(true);

  const renderContent = () => {
    if (error) {
      if (error.errorType !== 'standard') {
        return (
          <TransferModalErrorContentVerification
            title={error.title}
            subTitle={error.subTitle}
            errorType={error.errorType}
            showButton={error.errorType !== 'limit'}
          />
        );
      }

      return <TransferModalErrorContentStandard title={error.title} subTitle={error.subTitle} />;
    }

    if (transferType === TransferEnum.DepositReceive) {
      return (
        <TransferDepositReceive
          initialAsset={selectedAsset}
          initialDepositType={initialDepositType}
          setHideContinue={setHideContinueButton}
          setOnAction={setOnAction}
          setDisabled={setDisabled}
          onClose={onClose}
        />
      );
    }

    return (
      <TransferWithdrawSend
        initialAsset={selectedAsset}
        setHideContinue={setHideContinueButton}
        setOnAction={setOnAction}
        setDisabled={setDisabled}
        onClose={onClose}
        onReceive={() => setTransferType(TransferEnum.DepositReceive)}
      />
    );
  };

  return (
    <Wizard.Step
      hideContinue={hideContinueButton}
      footerProps={{ padding: 2 }}
      hideActions={Boolean(error)}
      onAction={onAction}
      onClose={onClose}
      locked={disabled}
    >
      <Stack>
        <TransferModalDetailsTabs transferType={transferType} setTransferType={setTransferType} />
        <Box padding={2}>{renderContent()}</Box>
      </Stack>
    </Wizard.Step>
  );
};
