import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { SendCryptoFilled } from '@swyftx/aviary/icons/filled';

import { SendCryptoModal } from '@global-components/Modals';
import { AssetTransactionHistoryModal } from '@global-components/Modals/AssetTransactionHistoryModal';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useWithdrawalAddresses } from '@global-components/Modals/SendCrypto/SendCryptoModal.hooks';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { SourceOfWealthRequiredNotification } from '@global-components/SourceOfWealthNotification/SourceOfWealthRequiredNotification';
import { CryptoTransferWarning } from '@global-components/message-boxes/CryptoTransferWarning';

import { Asset } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';

import { usePermissions, UserPermissions } from '@hooks/Permissions';
import { useWithdrawalPermission } from '@hooks/Withdraw/useWithdrawalPermission';
import { useGlobalResolveRestrictions } from '@hooks/useGlobalResolveRestrictions';
import { TransactionTypeFilter } from '@services/WalletService';

import { WalletContext } from '@Wallet/Wallet.context';
import { WithdrawalLimit } from '@Wallet/components/WithdrawalLimit';
import { SingleWalletManageAccounts } from '@Wallet/subroutes/SingleWallet/components/SingleWalletManageAccounts/SingleWalletManageAccounts';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';

import { SingleWalletSendInfo } from './components/SingleWalletSendInfo';

type Props = {
  asset: Asset;
};

const SingleWalletSend: React.FC<Props> = observer(({ asset }) => {
  const { updateAddresses } = useContext(WalletContext);
  const { isDemo } = AppStore.useAppStore;
  const { userProfile, isRestricted, canTransferCrypto, isKyc2Required } = UserStore.useUserStore;
  const [showAddAddress, setShowAddAddress] = useState<boolean>(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState<boolean>(false);
  const { withdrawalAddresses, refreshAddresses } = useWithdrawalAddresses(asset.code, updateAddresses);
  const [addingAddress, setAddingAddress] = useState<boolean>(true);
  const { t } = useTranslation('wallet');
  const { openModal } = useModal();
  const withdrawalBlockedStatus = useWithdrawalPermission(asset.code);
  const resolveRestrictions = useGlobalResolveRestrictions();
  const isFiat = asset && assetService.isAssetFiat(asset.id);
  const canTransfer = isFiat || canTransferCrypto();

  const { isFeatureEnabled } = useIsFeatureEnabled();
  const sourceOfWealthRequired = isFeatureEnabled(AppFeature.SourceOfWealth) && isKyc2Required();

  const withdrawalsBlocked = withdrawalBlockedStatus.blocked;

  const { enabled, element: PermissionsChip } = usePermissions({
    permissions: [UserPermissions.WITHDRAW_FUNDS],
    disabledOptions: { chip: true },
  });

  // check for limit save limit message
  const withdrawDisabled = useMemo(
    () => Boolean(!userProfile?.accountStatus?.withdrawals || 0) || isRestricted(),
    [userProfile, isRestricted],
  );

  const withdrawalDisabled = useMemo(
    () => !asset.withdraw_enabled || asset.networks.every((network) => network.withdrawDisabled) || !canTransfer,
    [asset, canTransfer],
  );

  const handleSendCryptoClick = () => {
    setAddingAddress(false);
    openModal(Modals.WithdrawSend, { selectedAsset: asset });
  };

  const handleClose = () => {
    refreshAddresses();
    setShowAddAddress(false);
  };

  const getAccordionTrailing = () => {
    if (isDemo) {
      return (
        <Chip size='md' color='secondary'>
          {t('demoMode.notAvailable')}
        </Chip>
      );
    }

    if (!enabled) return PermissionsChip;

    return undefined;
  };

  const Content: React.FC = () => {
    if (withdrawalsBlocked) {
      return (
        <Notification
          severity='destructive'
          title={asset ? `Withdrawals blocked for ${asset.name}` : 'Withdrawals blocked'}
          actions={
            <Button variant='ghost' className='intercom-trigger-bot-withdrawals-blocked'>
              Contact support
            </Button>
          }
          content={withdrawalBlockedStatus.message}
        />
      );
    }
    if (withdrawalDisabled) {
      if (canTransfer) {
        return (
          <Notification
            severity='destructive'
            title='Withdrawal disabled'
            content='Withdrawals are currently disabled for this asset.'
          />
        );
      }
      return <CryptoTransferWarning direction='withdraw' />;
    }
    if (withdrawDisabled && !withdrawalDisabled) {
      return (
        <Notification
          severity='destructive'
          title={t('singleWalletWithdrawal.labels.disabledTitle')}
          content={t('singleWalletWithdrawal.labels.disabledDescription')}
          actions={
            <Button variant='ghost' onClick={resolveRestrictions}>
              {t('singleWalletWithdrawal.buttonLabels.resolve')}
            </Button>
          }
        />
      );
    }
    if (canTransfer) {
      return <WithdrawalLimit />;
    }

    return null;
  };

  return (
    <>
      <Accordion type='single' collapsible>
        <AccordionItem value={isDemo || !enabled ? '' : 'send'}>
          <AccordionTrigger disabled={isDemo || !enabled}>
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
              <FlexLayout alignItems='center'>
                <SendCryptoFilled className='h-20 w-20 text-color-text-accent' alt='send crypto' />
                <Body weight='bold' className='ml-8'>
                  {t('singleWalletWithdrawal.title')}
                </Body>
              </FlexLayout>

              {getAccordionTrailing()}
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent>
            <FlexLayout direction='column' spacing={16}>
              {sourceOfWealthRequired && <SourceOfWealthRequiredNotification />}
              <SingleWalletSendInfo assetType={asset.assetType} assetCode={asset.code} />
              <Content />
              {!withdrawDisabled && !withdrawalsBlocked && (
                <Button size='lg' disabled={withdrawalDisabled} className='w-full' onClick={handleSendCryptoClick}>
                  Send {asset.code.toUpperCase()}
                </Button>
              )}

              <FlexLayout direction='row' alignItems='center' className='w-full'>
                {withdrawalAddresses?.length > 0 && canTransfer ? (
                  <SingleWalletManageAccounts
                    asset={asset}
                    isFiat={isFiat}
                    buttonSize='md'
                    createAccountHandler={() => {
                      setShowAddAddress(true);
                    }}
                  />
                ) : (
                  <Button
                    variant='ghost'
                    className='w-full'
                    onClick={() => setShowAddAddress(true)}
                    disabled={!canTransfer}
                  >
                    Add Address
                  </Button>
                )}

                <Button
                  variant='ghost'
                  onClick={() => setShowTransactionHistory(true)}
                  className='w-full'
                  disabled={!canTransfer}
                >
                  {t('singleWalletFiatDeposit.selector.buttonLabels.withdrawHistory')}
                </Button>
              </FlexLayout>
            </FlexLayout>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {showAddAddress && (
        <SendCryptoModal asset={asset} onClose={handleClose} skipLanding addingAddress={addingAddress} />
      )}

      {showTransactionHistory && (
        <AssetTransactionHistoryModal
          onClose={() => setShowTransactionHistory(false)}
          type={TransactionTypeFilter.WITHDRAWAL}
          asset={asset}
        />
      )}
    </>
  );
});

SingleWalletSend.displayName = 'SingleWalletSend';

export { SingleWalletSend };
