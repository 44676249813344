import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export enum LabelSwitchSide {
  Left,
  Right,
}

type Props = {
  leftLabel: string;
  rightLabel: string;
  initialSide?: LabelSwitchSide;
  onChange: (side: LabelSwitchSide) => void;
};

export const LabelSwitch: React.FC<Props> = ({ leftLabel, onChange, rightLabel, initialSide }) => {
  const [selectedSide, setSelectedSide] = React.useState<LabelSwitchSide>(initialSide || LabelSwitchSide.Left);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newSide: LabelSwitchSide) => {
    if (newSide !== null) {
      setSelectedSide(newSide);
      onChange(newSide);
    }
  };

  return (
    <ToggleButtonGroup
      value={selectedSide}
      exclusive
      color='primary'
      onChange={handleChange}
      className='h-[1.5rem] rounded-24'
      size='small'
      aria-label={selectedSide === LabelSwitchSide.Left ? leftLabel : rightLabel}
      fullWidth
    >
      <ToggleButton value={LabelSwitchSide.Left}>{leftLabel}</ToggleButton>
      <ToggleButton value={LabelSwitchSide.Right}>{rightLabel}</ToggleButton>
    </ToggleButtonGroup>
  );
};
