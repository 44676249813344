import React, { useContext, useEffect } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Edit, Layout } from '@swyftx/aviary/icons/outlined';
import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';

import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { DashboardContext } from '@Dashboard/Dashboard.context';

import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import { DashboardLayoutSelectorModal } from 'src/lib/dashboard/components/Modals';
import { useFeatureDiscovery } from 'src/lib/feature-discovery/hooks/useFeatureDiscovery';
import { useDashboardLayoutTour } from 'src/lib/feature-discovery/tours/dashboard/dashboardLayout.tour';

type Props = {
  hideCustomise?: boolean;
};

const AppHeaderDashboardActions: React.FC<Props> = observer(({ hideCustomise = false }) => {
  const { userProfile } = UserStore.useUserStore;
  const { editMode, setEditMode, saveTiles } = useContext(DashboardContext);
  const [viewedTour, setViewedTour] = useLocalStorage<boolean>(StorageKey.DASHBOARD_LAYOUT_TOUR, false);
  const dashboardTour = useDashboardLayoutTour();
  const { startTour, stopTour } = useContext(TourGuideContext);
  const { showFeatureDiscovery } = useFeatureDiscovery();

  useEffect(() => {
    if (!viewedTour && !showFeatureDiscovery) {
      startTour(dashboardTour);
      setViewedTour(true);
    }
  }, [dashboardTour, setViewedTour, showFeatureDiscovery, startTour, viewedTour]);

  return (
    <FlexLayout alignItems='center' spacing={8}>
      <>
        <DashboardLayoutSelectorModal onOpen={stopTour}>
          <Button
            variant='outlined'
            className='hidden lg:block'
            size='sm'
            data-spotlightelementid='change-layout-button'
            layout='default'
            leadingIcon={<Layout className='text-color-text-secondary' />}
          >
            Layout
          </Button>
        </DashboardLayoutSelectorModal>

        <DashboardLayoutSelectorModal>
          <Button
            variant='outlined'
            size='sm'
            className='block lg:hidden'
            data-spotlightelementid='change-layout-button-xs'
            layout='icon'
            leadingIcon={<Layout className='text-color-text-secondary' />}
          >
            Layout
          </Button>
        </DashboardLayoutSelectorModal>
      </>
      {!hideCustomise && (
        <>
          <Button
            className='hidden lg:block'
            id='app-header-edit-button'
            size='sm'
            layout='default'
            data-spotlightelementid='customize-layout-button'
            variant={editMode ? 'filled' : 'outlined'}
            onClick={editMode ? () => saveTiles(userProfile) : () => setEditMode(!editMode)}
            leadingIcon={<Edit />}
          >
            Customise
          </Button>
          <Button
            id='app-header-edit-button'
            className='block lg:hidden'
            size='sm'
            layout='icon'
            data-spotlightelementid='customize-layout-button'
            variant={editMode ? 'filled' : 'outlined'}
            onClick={editMode ? () => saveTiles(userProfile) : () => setEditMode(!editMode)}
            leadingIcon={<Edit />}
          >
            Customise
          </Button>
        </>
      )}
    </FlexLayout>
  );
});

export { AppHeaderDashboardActions };
