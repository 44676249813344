import { useCallback } from 'react';

import {
  GenerateDepositAddressParams,
  GenerateDepositAddressResponse,
  ProfileResponse,
  RemoveWithdrawalAddressParams,
  UpdateUserCurrencyArgs,
} from '@shared/api';
import { Methods } from '@shared/api/lib/rest';
import env from '@shared/config';
import { DepositMethodEnum } from '@shared/enums';
import {
  AnalyticsEvent,
  AnalyticsEventType,
  RequestCompleteEvent,
  subscribeToEvent,
  SwyftxEvent,
  unsubscribeFromEvent,
} from '@shared/events';
import { assetService } from '@shared/services';
import { AppStore, UserStore } from '@shared/store';
import { getIP } from '@shared/utils';

import { typeToWalletType } from '@Wallet/types';

import { useIntercom } from 'react-use-intercom';

enum IntercomEvent {
  AppError = 'User Application Error',
  UpdateInstalled = '{platform} Update Installed',
  RequestPasswordReset = 'Requested Password Reset',
  LoggedIn = 'Logged In',
  Review = 'clicked review {platform}',
  ChangeBaseCurrency = 'Changed Default Currency',
  BecameAffiliate = 'Became Affiliate',
  AddAffiliate = 'Manual Tapfiliate, using Ad-Blocker',
  GreenIDError = 'Green-Id Error',
  BuyLedger = 'Clicked Buy Ledger',
  CancelledOrder = 'Cancelled Order',
  GenerateDepositAddress = 'Generated Deposit Address',
  RemoveWithdrawalAddress = 'Removed Withdrawal Address',
  CheckDeposit = 'Check Deposit',
  SelectedDepositMethod = 'Selected {type} deposit method',
}

const useIntercomEvent = () => {
  const { trackEvent } = useIntercom();

  const getEmail = () => UserStore.useUserStore.userProfile?.email ?? 'UNKNOWN';
  const getMFAEnabled = () => UserStore.useUserStore.userProfile?.metadata?.mfa_enabled.toString() ?? 'UNKNOWN';
  const isDemoMode = () => AppStore.useAppStore.isDemo;

  const track = (event: string, data: any) => {
    trackEvent(event, data);
  };

  /** Logged when user request password reset link (POST: /auth/resetPassword/) */
  const requestPasswordReset = () => {
    track(IntercomEvent.RequestPasswordReset, {});
  };

  /** Logged when a user logs in */
  const loggedIn = async () => {
    const data = {
      IP: await getIP(),
      Email: getEmail(),
      MFA: getMFAEnabled(),
      uiVersion: env.COMMIT_HASH,
      browser: navigator.userAgent,
    };
    track(IntercomEvent.LoggedIn, data);
  };

  /** Logged when a user changes their base currency (POST: /user/currency/) */
  const changeBaseCurrency = (request: RequestCompleteEvent) => {
    const args = request.data as UpdateUserCurrencyArgs;
    const response = request.response as ProfileResponse;
    const data = {
      'Default Asset': response.profile.currency.code.toUpperCase(),
      'Previous Default Asset': assetService.getAsset(args.profile.previous)?.code.toUpperCase() ?? 'UNKNOWN',
    };
    track(IntercomEvent.ChangeBaseCurrency, data);
  };

  /** Logged when a user becomes an affiliate (POST: /user/affiliations/affiliate/) */
  const becomeAffiliate = () => {
    track(IntercomEvent.BecameAffiliate, {});
  };

  /** Logged when there is an error from Green ID */
  const greenIdError = (error: string) => {
    const data = {
      error,
    };
    track(IntercomEvent.GreenIDError, data);
  };

  /** Logged when a user selects a ledger from the store and is redirect to their website */
  const buyLedger = () => {
    const data = {
      typeToWalletType,
    };
    track(IntercomEvent.BuyLedger, data);
  };

  /** Logged when a user deletes an order (DELETE: /orders/:orderId) */
  const cancelledOrder = (request: RequestCompleteEvent) => {
    if (isDemoMode()) return;

    const { orderId } = request.params! as { orderId: string };

    const data = {
      'Order ID': orderId,
    };
    track(IntercomEvent.CancelledOrder, data);
  };

  /** Logged when a user generates a deposit address (POST: /address/deposit/:code/:variant/:amount?/) */
  const generateDepositAddress = async (request: RequestCompleteEvent) => {
    const response = request.data as GenerateDepositAddressResponse;
    const params = request.params as GenerateDepositAddressParams;

    const data = {
      ...response.address.address_details,
      'Currency Code': params.code,
    };
    track(IntercomEvent.GenerateDepositAddress, data);
  };

  /** Logged when user removes a withdrawal address (DELETE: address/withdraw/) */
  const removeWithdrawalAddress = (request: RequestCompleteEvent) => {
    const params = request.params as RemoveWithdrawalAddressParams;

    const data = {
      Asset: params.code,
      'Address ID': params.id,
    };
    track(IntercomEvent.RemoveWithdrawalAddress, data);
  };

  /** Logged when changing fiat deposit methods on deposit screen */
  const selectDepositMethod = (type?: DepositMethodEnum) => {
    switch (type) {
      case DepositMethodEnum.MONOOVA_DIRECT_CREDIT:
      case DepositMethodEnum.NZD_BANK_TRANSFER:
        track(IntercomEvent.SelectedDepositMethod.replace('{type}', 'bankTransfer'), {});
        break;
      case DepositMethodEnum.BANXA:
        track(IntercomEvent.SelectedDepositMethod.replace('{type}', 'creditCard'), {});
        break;
      case DepositMethodEnum.MONOOVA_PAYID:
        track(IntercomEvent.SelectedDepositMethod.replace('{type}', 'payId'), {});
        break;
      default:
        break;
    }
  };

  const onEvent = (event: AnalyticsEvent<any>) => {
    if (event.type === AnalyticsEventType.RequestComplete) {
      const data = event.data as RequestCompleteEvent;

      if (data.path === '/auth/resetPassword/' && data.method === Methods.POST) {
        requestPasswordReset();
      }
      if (data.path === '/user/currency/' && data.method === Methods.POST) {
        changeBaseCurrency(data);
      }
      if (data.path === '/user/affiliations/affiliate/' && data.method === Methods.POST) {
        becomeAffiliate();
      }
      if (data.path === '/orders/:orderId/' && data.method === Methods.DELETE) {
        cancelledOrder(data);
      }
      if (data.path === '/address/deposit/:code/:variant/:amount?/' && data.method === Methods.POST) {
        generateDepositAddress(data);
      }
      if (data.path === 'address/withdraw/' && data.method === Methods.DELETE) {
        removeWithdrawalAddress(data);
      }
    }
  };

  const cleanup = () => {
    unsubscribeFromEvent(SwyftxEvent.Analytics, onEvent);
  };

  const init = useCallback(() => {
    cleanup();

    // subscribe to analytics events
    subscribeToEvent(SwyftxEvent.Analytics, onEvent);
  }, []);

  return {
    init,
    cleanup,
    track,
    buyLedger,
    greenIdError,
    selectDepositMethod,
    loggedIn,
  };
};

export { useIntercomEvent };
