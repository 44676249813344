import React, { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import { Input, TextFieldProps } from '@swyftx/react-web-design-system';

import BsbValidator from '@hooks/validator/types/bsb.validator';
import { useValidation } from '@hooks/validator/useValidation';

type BsbInputFormProps = {
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  setValid?: (valid: boolean) => void;
  success?: boolean;
} & TextFieldProps;

const BsbInput: React.FC<BsbInputFormProps> = ({ id, attachForm, onChange, value, setValid, ...props }) => {
  const theme = useTheme();
  const invalidChars = ['-', '+', 'e', '.'];

  const validatorProps = useValidation([new BsbValidator('BSB address is not valid')], true, onChange, value);

  const isValidKey = (key: string) => {
    if (invalidChars.includes(key)) return false;
    return true;
  };

  const handleOnChange = (e: any) => {
    const val = e.target.value;

    // max bsb length is 6
    if (String(val).length > 6 || !isValidKey(val)) return;

    if (validatorProps.onChange) validatorProps.onChange(e);
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('Text');
    const parsedData = pastedData.replace(/[-\s]/g, '');

    handleOnChange({ target: { value: parsedData } });
  };

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  useEffect(() => {
    if (attachForm) attachForm(id!, validatorProps.validate);
  }, [attachForm]);

  return (
    <Input
      PII
      id={id}
      type='number'
      label='BSB'
      placeholder='Enter BSB'
      ContainerProps={{ sx: { marginBottom: '1rem' } }}
      InputProps={{ sx: { height: '48px', fontSize: '14px' } }}
      {...props}
      {...validatorProps}
      onKeyDown={(evt) => !isValidKey(evt.key) && evt.preventDefault()}
      onChange={handleOnChange}
      onPaste={handleOnPaste}
      sx={{
        width: '100%',
        '.MuiFormHelperText-root': {
          color: validatorProps.success ? theme.palette.success.main : theme.palette.error.main,
        },
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          appearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          appearance: 'textfield',
        },
      }}
    />
  );
};

export { BsbInput };
