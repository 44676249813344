import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionItem, AccordionContent, AccordionTrigger } from '@swyftx/aviary/atoms/Accordion/Accordion';
import { Button } from '@swyftx/aviary/atoms/Button';
import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { Deposit, Information } from '@swyftx/aviary/icons/outlined';

import { DepositFundsInfoModal } from '@global-components/Modals';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';
import { AppStore, UserStore } from '@shared/store';

import { EntityPermissions, usePermissions } from '@hooks/Permissions';

import { DepositMethodSelector } from '@Wallet/subroutes/SingleWallet/components/SingleWalletFiatDeposit/components/DepositModalSelector';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { SingleWalletFiatDepositSelector } from './components/SingleWalletFiatDepositSelector/SingleWalletFiatDepositSelector';
import { SingleWalletFiatDepositVerify } from './components/SingleWalletFiatDepositVerify/SingleWalletFiatDepositVerify';

type Props = {
  asset: Asset;
};

const SingleWalletFiatDeposit: React.FC<Props> = observer(({ asset }) => {
  const { isDemo } = AppStore.useAppStore;
  const { isUserVerified, userProfile } = UserStore.useUserStore;

  const { t } = useTranslation('wallet');
  const { navigate } = useNavigateRoute();

  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [depositMethod, setDepositMethod] = useState<DepositMethodEnum | undefined>();

  const { enabled, element: PermissionsChip } = usePermissions({
    permissions: [EntityPermissions.ADDRESS_READ],
    disabledOptions: { chip: true },
  });

  const openInfo = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowInfoModal(true);
  };

  const openVerification = () => {
    navigate(NavigationURLs.ProfileVerification);
  };

  const depositDisabled = useMemo(() => !(userProfile?.accountStatus?.deposits || 0), [userProfile]);

  const getAccordionTrailing = () => {
    if (isDemo) {
      return (
        <Chip size='md' color='secondary' variant='subtle'>
          {t('demoMode.notAvailable')}
        </Chip>
      );
    }

    if (!enabled) return PermissionsChip;

    return undefined;
  };

  return (
    <>
      <Accordion type='single' defaultValue='deposit' collapsible>
        <AccordionItem value={isDemo || !enabled ? '' : 'deposit'}>
          <AccordionTrigger disabled={isDemo || !enabled}>
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full'>
              <FlexLayout direction='row' alignItems='center'>
                <Deposit />
                <Body weight='bold' className='ml-8' size='large'>
                  {t('singleWalletFiatDeposit.title')}
                </Body>
              </FlexLayout>
              {!isDemo && enabled && (
                <Button
                  layout='icon'
                  onClick={openInfo}
                  leadingIcon={<Information />}
                  variant='ghost'
                  className='mr-8'
                />
              )}
              {getAccordionTrailing()}
            </FlexLayout>
          </AccordionTrigger>
          <AccordionContent>
            {depositDisabled && (
              <FlexLayout direction='column' spacing={2}>
                <Notification severity='destructive' title={t('singleWalletFiatDeposit.labels.disabledTitle')}>
                  <Body size='small'>{t('singleWalletFiatDeposit.labels.disabledDescription')}</Body>
                </Notification>
                <Button variant='filled' size='lg' className='w-full' onClick={openVerification}>
                  {t('singleWalletFiatDeposit.buttonLabels.resolve')}
                </Button>
              </FlexLayout>
            )}
            {!depositDisabled && !isUserVerified() && <SingleWalletFiatDepositVerify />}
            {!depositDisabled && isUserVerified() && (
              <SingleWalletFiatDepositSelector setDepositMethod={setDepositMethod} asset={asset} />
            )}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <DepositMethodSelector asset={asset} setDepositMethod={setDepositMethod} method={depositMethod} />
      {showInfoModal && <DepositFundsInfoModal asset={asset} onClose={() => setShowInfoModal(false)} />}
    </>
  );
});

SingleWalletFiatDeposit.displayName = 'SingleWalletFiatDeposit';

export { SingleWalletFiatDeposit };
