import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Tooltip, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { AssetType } from '@shared/api';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore } from '@shared/store';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useOrderFee } from '@routes/Transactions/Orders.hooks';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableFee: React.FC<OrdersTableColumnProps> = observer(({ item, mobile, width, type }) => {
  const { is } = useContentBreakpoint();
  const baseAsset = useBaseAsset();
  const { t } = useTranslation('orders');
  const { convertRate } = RatesStore.useRatesStore;

  const primaryAsset = useMemo(() => assetService.getAsset(item.primaryAsset), [item.primaryAsset]);
  const { feeAsset, feeAmount, isTriggerFee } = useOrderFee(item);
  const countryAsset = useCountryAsset();

  const feePrefix = (() => {
    if (isTriggerFee) {
      return 'Est. ';
    }
    return '';
  })();

  const nonFeeAsset =
    useMemo(
      () => assetService.getAsset(feeAsset?.id === item.primaryAsset ? item.secondaryAsset : item.primaryAsset),
      [feeAsset, item.primaryAsset, item.secondaryAsset],
    ) || baseAsset;

  const feeCountryValue = feeAsset && countryAsset ? convertRate(feeAsset, countryAsset, feeAmount) : Big(0);
  const isFeeAssetNonFiat =
    feeAsset && nonFeeAsset && (feeAmount || item.txFeeAmount) && feeAsset.assetType !== AssetType.Fiat;
  const showCountryValueTooltip = isFeeAssetNonFiat && feeCountryValue.gt(0) && !isTriggerFee;
  const hideFee = !mobile && !is.xl && type && type === TransactionsRouteType.Orders;

  if (hideFee) return null;

  return (
    <Stack
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      flex={2}
      key={`${item.uuid}_fee`}
      justifyContent={mobile ? 'space-between' : 'flex-end'}
      alignItems='center'
      paddingRight={mobile ? 1 : 0}
      direction='row'
      spacing={0.4}
    >
      {mobile && (
        <Typography fontSize={14} color='text.secondary' fontWeight={400}>
          {t('ordersTable.headers.fee')}
        </Typography>
      )}
      <Stack direction='row' justifyContent='flex-end' alignItems={mobile ? 'center' : 'flex-end'} spacing={1}>
        <Stack direction='row' spacing={1}>
          <Tooltip
            title={
              showCountryValueTooltip
                ? formatCurrency(feeCountryValue, countryAsset, {
                    appendCode: true,
                  })
                : ''
            }
          >
            <span>
              <FormattedText
                prefix={feePrefix}
                formatBalance
                value={Big(feeAmount || '').plus(item?.txFeeAmount || '')}
                currency={feeAsset || primaryAsset}
                formatOpts={{
                  appendCode: false,
                  hideCode: true,
                }}
                typographyProps={{
                  className: cn(isFeeAssetNonFiat ? 'underline decoration-dotted' : ''),
                }}
              />
            </span>
          </Tooltip>
        </Stack>
        <Typography number color='text.secondary'>
          {feeAsset?.code}
        </Typography>
      </Stack>
    </Stack>
  );
});

export { OrdersTableFee };
