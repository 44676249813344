import { useMemo } from 'react';

import { ratesService } from '@shared/services';
import { RatesStore } from '@shared/store';

const useRates = () => {
  const { initialised, loading, rates, getRate, convertRate, setLoading } = RatesStore.useRatesStore;

  const isInitialised = useMemo(() => {
    if (loading) return true;

    return initialised;
  }, [initialised, loading]);

  const forceUpdateRates = () => ratesService.forceUpdate();

  return { isInitialised, loading, rates, getRate, convertRate, setLoading, forceUpdateRates };
};

export { useRates };
