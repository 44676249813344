import React, { useCallback, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { StorageKey } from '@shared/storage';

export const EntityApplicationApprovedBanner: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);

  const handleDismiss = useCallback(() => {
    localStorage.setItem(StorageKey.DISMISS_ENTITY_APPLICATION_APPROVED_BANNER, 'true');
    setOpen(false);
  }, []);

  if (!open) return null;

  return (
    <FlexLayout className='w-full'>
      <Card className='w-full p-24'>
        <FlexLayout direction='row' className='items-center justify-between'>
          <FlexLayout alignItems='center' justifyContent='space-between' spacing={16}>
            <Image image='transfer_fees' fileType='svg' className='w-64' />

            <FlexLayout direction='column' className='w-full' spacing={4}>
              <Heading size='h6'>Entity application approved!</Heading>
              <Body color='secondary' size='small'>
                Access your new entity account through the profile menu to start trading.
              </Body>
            </FlexLayout>
          </FlexLayout>
          <FlexLayout direction='row' spacing={8}>
            <Button variant='outlined' onClick={handleDismiss}>
              Dismiss
            </Button>
            <Button>Switch to entity account</Button>
          </FlexLayout>
        </FlexLayout>
      </Card>
    </FlexLayout>
  );
};
