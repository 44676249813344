import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowLineOnlyDown, ArrowLineOnlyUp } from '@swyftx/aviary/icons/outlined';
import { Stack, Tooltip, Typography } from '@swyftx/react-web-design-system';

import { Column } from '@global-components/VirtualizedTable/VirtualizedTable.types';

import { TransactionOrder } from '@shared/services';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

type Props = {
  column: Column;
  widthOverride: number;
  type?: TransactionsRouteType;
};

const OrdersTableHeader: React.FC<Props> = observer(({ column, widthOverride, type }) => {
  const { paddingLeft, hidden, align, width, label, accessor, noSort } = column;
  const { updateFilters, filters } = useContext(TransactionsContext);
  const { sort, order } = filters;
  const countryAsset = useCountryAsset();
  const { t } = useTranslation('orders');

  const calculatedWidth = useMemo(() => {
    if (!width) return widthOverride;

    if (Number.isNaN(Number(width))) {
      return width;
    }

    return `${width}px`;
  }, [width, widthOverride]);

  const updateSortDirection = () => {
    if (accessor === undefined || noSort) return;

    switch (order) {
      case TransactionOrder.ASC:
        updateFilters({ sort: accessor, order: TransactionOrder.DESC });
        break;
      case TransactionOrder.DESC:
        updateFilters({ sort: accessor, order: TransactionOrder.ASC });
        break;
      default:
        updateFilters({ sort: accessor, order: TransactionOrder.DESC });
        break;
    }
  };

  const renderSortIcon = () => {
    if (noSort) return null;
    if (sort !== accessor) {
      return (
        <ArrowLineOnlyDown
          className='cursor-pointer text-color-text-disabled'
          onClick={updateSortDirection}
          id={`${label}.sort.default`}
        />
      );
    }

    switch (order) {
      case TransactionOrder.DESC:
        return (
          <ArrowLineOnlyDown
            className='cursor-pointer text-color-text-accent'
            onClick={updateSortDirection}
            id={`${label}.sort.desc`}
          />
        );
      case TransactionOrder.ASC:
        return (
          <ArrowLineOnlyUp
            className='cursor-pointer text-color-text-accent'
            onClick={updateSortDirection}
            id={`${label}.sort.asc`}
          />
        );
      default:
        return null;
    }
  };

  const feeTooltip = useMemo(() => {
    if (column.accessor !== 'feeAmount' || !countryAsset) return '';

    switch (type) {
      case TransactionsRouteType.Deposits:
        return t('noFeeTooltipDeposits');
      case TransactionsRouteType.Withdrawals:
        if (countryAsset.code === 'AUD') return t('noFeeTooltipWithdrawalsAUD');
        return t('noFeeTooltipWithdrawalsNZD');
      default:
        return '';
    }
  }, [column, countryAsset, t, type]);

  return (
    <Stack
      id='ordersTable.headers'
      direction='row'
      alignItems='center'
      justifyContent={align}
      spacing={1}
      width={calculatedWidth ?? `${widthOverride}px`}
      minWidth={calculatedWidth ?? `${widthOverride}px`}
      maxWidth={calculatedWidth ?? `${widthOverride}px`}
    >
      <Tooltip key={column.label} title={feeTooltip}>
        <span>
          <Typography
            id={label}
            onClick={updateSortDirection}
            sx={
              noSort
                ? undefined
                : {
                    cursor: 'pointer',
                    '&:hover': {
                      color: 'text.primary',
                    },
                  }
            }
            paddingLeft={paddingLeft}
            display={hidden ? 'none' : 'block'}
            variant='overline'
            fontWeight={500}
            color='text.secondary'
          >
            {label.length > 0 && t(label)}
          </Typography>
        </span>
      </Tooltip>
      {renderSortIcon()}
    </Stack>
  );
});

export { OrdersTableHeader };
