import React, { createContext, PropsWithChildren } from 'react';

import { api } from '@shared/api';
import { Big } from '@shared/safe-big';
import { walletService } from '@shared/services';

import { initialValues, TransactionsFilterOptions, TransactionsStoreSchema } from '@routes/Transactions/types';

import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { useLocalObservable } from 'mobx-react-lite';

const LOADING_DELAY = 500;
const MAX_PER_PAGE = 25;

export const TransactionsContext = createContext<TransactionsStoreSchema>(initialValues);

export const TransactionsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const store = useLocalObservable(
    (): TransactionsStoreSchema => ({
      ...initialValues,
      fetchOrders: async (delayLoadingState, force, updateDuplicates) => {
        const [dateFrom, dateTo] = store.filters.dateRange;
        store.fetching = true;

        if (force) {
          api.endpoints.getAllTransactionHistory.resetCache();
          api.endpoints.getOrdersHistory.resetCache();
        }

        try {
          const transactions = await walletService.getAllTransactionHistory(
            store.page,
            dateFrom ? dateFrom?.toJSDate() : new Date(2018, 6, 1),
            dateTo ? dateTo?.toJSDate() : DateTime.now().endOf('day').toJSDate(),
            false,
            MAX_PER_PAGE,
            store.filters.types || [],
            store.filters.statuses || [],
            store.filters.sort || undefined,
            store.filters.order ?? undefined,
            store.filters.asset ?? undefined,
          );

          store.maxPages = Math.ceil(Big(transactions.recordCount).div(MAX_PER_PAGE).toNumber());

          // cleaner looking live-updates
          if (delayLoadingState) {
            setTimeout(() => {
              store.fetching = false;
            }, LOADING_DELAY);
          } else {
            store.fetching = false;
          }

          if (updateDuplicates) {
            store.orders = transactions.tableItems;
          } else {
            const dedupedOrders = transactions.tableItems.filter((ti) => !store.orders.find((o) => o.uuid === ti.uuid));
            store.orders = [...store.orders, ...dedupedOrders];
          }
        } finally {
          store.fetching = false;
        }
      },
      updateFilters: (filters: Partial<TransactionsFilterOptions>) => {
        store.filters = { ...store.filters, ...filters };
        store.orders = [];
        store.page = 1;
        store.fetchOrders(undefined, true);
      },
      setShowFilters: (showFilters: boolean) => {
        store.showFilters = showFilters;
      },
      incrementPage: () => {
        if (store.fetching || store.page >= store.maxPages) return;
        store.page += 1;
        store.fetchOrders();
      },
      resetPage: () => {
        store.orders = [];
        store.page = 1;
      },
      deleteOrder: async (orderUuid: string) => {
        try {
          await api.endpoints.cancelOrder({ params: { orderId: orderUuid } });
        } catch (e) {
          Sentry.captureException(e);
        } finally {
          store.fetchOrders(false, true);
        }
      },
      forceUpdateOrders() {
        setTimeout(() => {
          store.fetchOrders(false, true, true);
        }, 1000);
      },
    }),
  );

  return <TransactionsContext.Provider value={store}>{children}</TransactionsContext.Provider>;
};
