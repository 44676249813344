import React, { useCallback, useState } from 'react';

import { Card, CardContent, CardHeader } from '@swyftx/aviary/atoms/Card';
import { LineChart } from '@swyftx/aviary/atoms/Charts';
import {
  LineChartData,
  LineChartOverlays,
  LineChartTooltipData,
  LineChartTooltipPosition,
} from '@swyftx/aviary/atoms/Charts/LineChart/LineChart.types';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { useTailwindContainerBreakpoint } from '@swyftx/aviary/hooks/useTailwindContainerBreakpoint';
import { useTailwindTheme } from '@swyftx/aviary/hooks/useTailwindTheme';
import { NumericDataItem } from '@swyftx/aviary/molecules/DataItem/NumericDataItem';
import { CurrencyType } from '@swyftx/aviary/utils/currency/currency.types';

import { Asset } from '@shared/api';
import { formatCurrency } from '@shared/utils';
import { cn } from '@shared/utils/lib/ui';

import * as Sentry from '@sentry/react';
import { useMeasure } from 'react-use';

import { PortfolioPerformanceTab, PortfolioPeriodType, PortfolioViewDensity } from './PortfolioPerformanceCard.types';
import { PortfolioPerformanceCardLineChartTooltip } from './PortfolioPerformanceCardLineChartTooltip';
import { PortfolioPerformanceTabs } from './PortfolioPerformanceTabs';

type Props = {
  id: string;
  title: string;
  actions?: React.ReactNode;
  emptyState?: React.ReactElement | null;
  portfolioGrowthValue: string;
  portfolioGrowthPercent: string;
  depositedValue?: string;
  totalTradedValue?: string;
  withdrawnValue?: string;
  currency?: Asset;
  chartData?: LineChartData;
  loading?: boolean;
  overlays?: LineChartOverlays;
  className?: string;
  tabs?: PortfolioPerformanceTab[];
  periodValue: PortfolioPeriodType;
  onChangePeriod: (newPeriod: PortfolioPeriodType) => void;
  showAxes?: boolean;
  variant?: PortfolioViewDensity;
};

const PortfolioPerformanceCard: React.FC<Props> = ({
  id,
  actions,
  portfolioGrowthValue,
  depositedValue,
  emptyState,
  withdrawnValue,
  totalTradedValue,
  currency,
  chartData,
  loading = false,
  tabs,
  periodValue,
  overlays,
  onChangePeriod,
  showAxes = true,
  className,
  variant = 'detailed',
}) => {
  const [tooltipInfo, setTooltipInfo] = useState<{
    position: LineChartTooltipPosition | null;
    data: LineChartTooltipData | null;
  }>({ position: null, data: null });

  const { isDarkMode } = useTailwindTheme();
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const { atLeast } = useTailwindContainerBreakpoint({ containerName: id });

  const tooltipCallback = useCallback(
    (tooltipPosition: LineChartTooltipPosition | null, tooltipData: LineChartTooltipData | null) => {
      setTooltipInfo({ data: tooltipData, position: tooltipPosition });
    },
    [],
  );

  return (
    <Card className={`@container ${className}`} data-container={id}>
      <CardHeader className='p-16 py-16 @sm:px-24 @sm:pt-24'>
        <FlexLayout className='w-full' alignItems='start' justifyContent='space-between'>
          <FlexLayout direction='column' spacing={8} className='w-full'>
            <FlexLayout
              direction={atLeast.sm && variant === 'detailed' ? 'row' : 'column'}
              alignItems='start'
              spacing={variant === 'simple' ? 16 : 16}
              className='w-full flex-wrap'
            >
              <FlexLayout
                spacing={variant === 'simple' ? 32 : 32}
                alignItems='center'
                className={cn('flex-wrap', variant === 'simple' ? 'w-3/4' : 'w-[calc(100%-100px)]')}
                justifyContent='start'
              >
                <FlexLayout direction='column' spacing={8}>
                  <NumericDataItem
                    title='Performance'
                    size={atLeast.sm ? 'xlarge' : 'medium'}
                    percentage
                    loading={!currency || loading}
                    data={portfolioGrowthValue}
                  >
                    {formatCurrency(portfolioGrowthValue, currency)}
                  </NumericDataItem>
                </FlexLayout>

                {depositedValue && (
                  <FlexLayout direction='column' spacing={8}>
                    <NumericDataItem
                      title='Deposited'
                      loading={!currency || loading}
                      size={atLeast.sm ? 'xlarge' : 'medium'}
                      color='primary'
                      data={depositedValue}
                    />
                  </FlexLayout>
                )}

                {withdrawnValue && (
                  <FlexLayout direction='column' spacing={8} className='hidden sm:block'>
                    <NumericDataItem
                      title='Withdrawn'
                      loading={!currency || loading}
                      size={atLeast.sm ? 'xlarge' : 'medium'}
                      color='primary'
                      data={withdrawnValue}
                    />
                  </FlexLayout>
                )}
                {totalTradedValue && (
                  <FlexLayout direction='column' spacing={8} className='hidden xl:block'>
                    <NumericDataItem
                      title='Traded'
                      loading={!currency || loading}
                      size={atLeast.sm ? 'xlarge' : 'medium'}
                      color='primary'
                      data={totalTradedValue}
                    />
                  </FlexLayout>
                )}
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>
          {actions && <>{actions}</>}
        </FlexLayout>
      </CardHeader>
      <CardContent className='h-full p-16 pt-0 @sm:px-24' ref={ref}>
        <FlexLayout
          direction='column'
          spacing={16}
          justifyContent={!chartData ? 'center' : 'end'}
          alignItems='center'
          className='h-full'
        >
          <FlexLayout direction='column' justifyContent='space-between' spacing={16} className='h-full w-full'>
            {(!chartData || loading) && !emptyState && (
              <div
                className={cn('h-full w-full', loading ? 'animate-pulse bg-color-background-surface-secondary' : '')}
              />
            )}
            {chartData && !loading && (
              <LineChart
                className='block'
                idKey='portfolio-performance-chart'
                tooltipCallback={tooltipCallback}
                chartData={chartData}
                disableLoadingAnimation
                loading={loading}
                strokeWidth={1}
                overlays={overlays}
                showAxes={showAxes}
                width={width}
                areaColor={!isDarkMode ? currency?.color || 'primary' : 'primary'}
                currencyCode={currency?.code || 'AUD'}
                currencyType={(currency?.assetType || CurrencyType.Fiat) as unknown as CurrencyType}
              />
            )}
            <PortfolioPerformanceCardLineChartTooltip tooltipInfo={tooltipInfo} />
            {!chartData && !loading && <div className='h-full w-full'>{emptyState}</div>}
            {tabs && <PortfolioPerformanceTabs tabs={tabs} value={periodValue} onChange={onChangePeriod} />}
          </FlexLayout>
        </FlexLayout>
      </CardContent>
    </Card>
  );
};

export const ProfiledPortfolioPerformanceCard = Sentry.withProfiler(PortfolioPerformanceCard);

export { ProfiledPortfolioPerformanceCard as PortfolioPerformanceCard };
