import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Image } from '@swyftx/aviary/atoms/Image';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { GameFilled } from '@swyftx/aviary/icons/filled';
import { Tick, Loading, CrossInCircle } from '@swyftx/aviary/icons/outlined';
import { Divider, Stack, Typography } from '@swyftx/react-web-design-system';

import { CloseUniversalTrade } from '@global-components/CloseUniversalTrade/CloseUniversalTrade';
import { LimitedFunctionalityNotificationAccordion } from '@global-components/LimitedFunctionalityNotification/LimitedFunctionalityNotificationAccordion';
import { Modals } from '@global-components/Modals/Modals.enum';
import { useProfitStopLossTours } from '@global-components/Modals/ProfitStopLoss/ProfitStopLossOrderModal/hooks';
import { useModal } from '@global-components/Modals/useModal.hooks';
import { FormattedText } from '@global-components/Text';

import { Asset, AssetType, OrderType } from '@shared/api';
import { FiatIdEnum } from '@shared/enums';
import { ViewportLayout } from '@shared/events';
import { Big } from '@shared/safe-big';
import {
  assetService,
  balanceService,
  portfolioService,
  ratesService,
  TransactionTypeFilter,
  userStatisticsService,
} from '@shared/services';
import { AppStore, RatesStore, UniversalTradeStore, UserStore } from '@shared/store';
import {
  OrderStatus,
  TradeAssetAction,
  TradeSide,
  TradeState,
  TradeType,
} from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';
import { useHighSlippage } from '@hooks/Trade';
import { HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD } from '@hooks/Trade/types/useUniversalTrade.types';
import { useUniversalTradeUtilityStore } from '@hooks/Trade/useUniversalTradeUtilityStore';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { getAssetBreakdowns } from '@utils/analytics';

import { TradeFeeBreakdown } from '@Trade/components/UniversalTradePanel/components/TradeFeeBreakdown';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { AutoInvestAsset } from 'src/lib/auto-invest/autoInvest.types';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

import { TradeOrderDetailsReviewItem } from './components';

type Props = {
  dismissible?: boolean;
};

const LOW_TRADE_THRESHOLD = 5;

const TradeOrderDetailsReview: React.FC<Props> = observer(({ dismissible }) => {
  const { startEntryTour } = useProfitStopLossTours({});
  const [showFeeBreakdown, setShowFeeBreakdown] = useState(false);
  const { executeTrades, getFeeDetail, getDisplayExchangeRate, getOrderType } = useUniversalTradeUtilityStore();
  const {
    setTradeState,
    setTradeType,
    setShowGlobalTrade,
    setTradeAssets,
    tradeData,
    tradeType,
    tradeFrom,
    tradeTo,
    orderStatus,
    setOrderStatus,
  } = UniversalTradeStore;
  const { isEntity, isNZ, userCountryCurrency } = UserStore.useUserStore;
  const countryAsset = useCountryAsset();
  const { updateFilters } = useContext(TransactionsContext);
  const { getRate, convertRate } = RatesStore.useRatesStore;
  const { isDemo } = AppStore.useAppStore;
  const { t } = useTranslation('common');
  const { t: tradeT } = useTranslation('trade', { keyPrefix: 'tradeOrderReview' });
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { calculateSlippage } = useHighSlippage();
  const { openModal } = useModal();
  const { invalidateCache } = useTransactionsCache();
  const avo = useAvo();
  const { navigate } = useNavigateRoute();
  const universalTradeEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const isOnTradePage = location.pathname.includes(NavigationURLs.UniversalTrade) && universalTradeEnabled;

  const {
    slippagePercentage,
    currentPrice,
    fromAsset,
    toAsset,
    isHighSlippage,
    showTakeProfitStopLossButton,
    showCreateAutoInvest,
  } = useMemo(() => {
    const tradeKeys = Object.keys(tradeData);
    // only return slippage and current price for 1 -> 1 trades
    if (tradeKeys.length === 1) {
      const data = tradeData[tradeKeys[0]];
      const fromAsset = assetService.getAsset(data.from);
      const toAsset = assetService.getAsset(data.to);
      const isTradingBaseAsset = Boolean(fromAsset?.isBaseAsset) || Boolean(toAsset?.isBaseAsset);

      if (fromAsset && toAsset) {
        const tradeValue = data.total
          ? convertRate(fromAsset, countryAsset?.id || FiatIdEnum.AUD, data.total, 'midPrice')
          : Big(0);
        const { slippage, currentPrice: marketPrice } = calculateSlippage(fromAsset, toAsset, Big(data.ratePrice));
        const { sellLiquidityFlag } = getRate(toAsset);
        return {
          fromAsset,
          toAsset,
          currentPrice: marketPrice,
          slippagePercentage: slippage,
          isHighSlippage: data.isHighSlippage,
          showCreateAutoInvest: fromAsset.id === userCountryCurrency,
          showTakeProfitStopLossButton:
            tradeValue.gte(LOW_TRADE_THRESHOLD) &&
            !sellLiquidityFlag &&
            isTradingBaseAsset &&
            fromAsset.assetType === AssetType.Fiat &&
            getOrderType(data) === OrderType.MarketBuy,
        };
      }
    }

    return {
      fromAsset: undefined,
      toAsset: undefined,
      slippagePercentage: 0,
      currentPrice: 0,
      isHighSlippage: false,
      showTakeProfitStopLossButton: false,
      showCreateAutoInvest: tradeFrom.length === 1 && tradeFrom[0] === userCountryCurrency,
    };
  }, [
    calculateSlippage,
    convertRate,
    countryAsset?.id,
    getOrderType,
    getRate,
    tradeData,
    tradeFrom,
    userCountryCurrency,
  ]);

  const feeDetail = getFeeDetail(countryAsset);
  const displayRates = getDisplayExchangeRate();

  const { pathname } = useLocation();

  const theme = useTheme();
  const layout: ViewportLayout = useMediaQuery(theme.breakpoints.down('md')) ? 'mobile' : 'desktop';

  useEffect(() => {
    if (showTakeProfitStopLossButton && orderStatus === OrderStatus.Success) {
      startEntryTour();
    }
  }, [showTakeProfitStopLossButton, orderStatus, startEntryTour]);

  const onPlaceOrder = () => {
    // Fire event for trade analytics: order details reviewed (single asset btn)
    avo.orderPlaced({
      ...getAssetBreakdowns(tradeData),
      tradeType,
      layout,
      screen: pathname,
    });

    setOrderStatus(OrderStatus.Pending);
    executeTrades(
      async () => {
        await ratesService.forceUpdate();

        const updateValuesPromise = [
          balanceService.forceUpdate(),
          portfolioService.forceUpdate(),
          userStatisticsService.forceUpdate(),
        ];

        await Promise.all(updateValuesPromise);

        updateFilters({
          types: [TransactionTypeFilter.BUY, TransactionTypeFilter.SELL],
          statuses: [],
          asset: undefined,
        });

        setOrderStatus(OrderStatus.Success);

        invalidateCache();
      },
      () => {
        setOrderStatus(OrderStatus.Error);
      },
      countryAsset,
    );
  };

  const onEditOrder = () => {
    setTradeState(tradeFrom.length > 1 || tradeTo.length > 1 ? TradeState.MultiTrade : TradeState.PlaceOrder);
  };

  const onPlaceAnotherOrder = () => {
    setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
    setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
    setTradeState(TradeState.PlaceOrder);
    setOrderStatus(OrderStatus.Idle);
  };

  const onSetTakeProfitStopLoss = () => {
    const tradeKeys = Object.keys(tradeData);
    const data = tradeData[tradeKeys[0]];
    openModal(Modals.ProfitStopLossModal, { orderUuid: data.orderUuid });
  };

  const onClose = () => {
    setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
    setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
    setTradeState(TradeState.PlaceOrder);
    setShowGlobalTrade(false);
    setOrderStatus(OrderStatus.Idle);
  };

  const onMakeRecurring = () => {
    setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);

    if (isFeatureEnabled(AppFeature.AutoInvest)) {
      const selectedAssets: AutoInvestAsset[] = assetService.getAssets(tradeTo).map((asset) => ({
        assetCode: asset.code,
        percentage: 0,
        locked: false,
      }));

      navigate(NavigationURLs.AutoInvestCreate, { state: { selectedAssets } });
      onClose();
    } else {
      setTradeState(TradeState.PlaceOrder);
      setTradeType(TradeType.Recurring);
      setOrderStatus(OrderStatus.Idle);
    }

    // Fire event for trade analytics: make recurring order clicked
    avo.recurringOrderUpsellClicked({
      screen: pathname,
      layout,
    });
  };

  const tradeToHasAUD = useMemo(() => tradeTo.some((assetId) => assetId === FiatIdEnum.AUD), [tradeTo]);

  const showRecurringAction = !isDemo && !isEntity() && !isNZ() && tradeType === TradeType.Instantly && !tradeToHasAUD;

  const assets = useMemo(() => {
    const tradeFromAssets = tradeFrom.map(assetService.getAsset).filter((e) => e !== undefined) as Asset[];
    const tradeToAssets = tradeTo.map(assetService.getAsset).filter((e) => e !== undefined) as Asset[];

    return [...tradeFromAssets, ...tradeToAssets];
  }, [tradeFrom, tradeTo]);

  const bgClassName = useMemo(() => {
    switch (orderStatus) {
      case OrderStatus.Error:
        return 'bg-color-background-error';
      case OrderStatus.Success:
        return 'bg-color-background-success';
      case OrderStatus.Idle:
      case OrderStatus.Pending:
      default:
        return 'bg-color-background-primary';
    }
  }, [orderStatus]);

  const reviewText = (() => {
    switch (tradeType) {
      case TradeType.OnTrigger:
        return 'You can edit and delete open trigger orders from the Transactions page.';
      case TradeType.Recurring:
        return 'You can edit and delete recurring orders from the Recurring orders page.';
      case TradeType.Instantly:
      default:
        return 'Once your order is placed it cannot be edited.';
    }
  })();

  return (
    <Stack direction='column' spacing={0} sx={{ height: '100%', overflowY: 'auto' }}>
      <Stack
        direction='column'
        padding={2}
        alignItems='center'
        justifyContent='space-between'
        spacing={2}
        sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
      >
        <Stack width='100%' direction='row' alignItems='center' justifyContent='flex-start'>
          <Typography fontSize={20} fontWeight={700} width='100%' textAlign='left'>
            {orderStatus === OrderStatus.Idle && tradeT('labels.reviewTitle')}
            {orderStatus === OrderStatus.Pending && tradeT('labels.orderProcessing')}
            {orderStatus === OrderStatus.Success && tradeT('labels.orderPlaced')}
            {orderStatus === OrderStatus.Error && tradeT('labels.orderError')}
          </Typography>
          {dismissible && <CloseUniversalTrade />}
        </Stack>
      </Stack>

      <Stack
        direction='column'
        position='relative'
        marginTop='0 !important'
        height='100%'
        sx={{ overflowY: 'scroll', overflowX: 'hidden' }}
      >
        <Stack width='100%' alignItems='center' justifyContent='center' padding={2} spacing={1}>
          <Stack
            width='100%'
            minHeight='175px'
            borderRadius={1}
            alignItems='center'
            justifyContent='center'
            spacing={1}
            className={bgClassName}
          >
            {orderStatus === OrderStatus.Idle && (
              <>
                <Image image='trade' className='h-[100px] w-[100px]' alt='review' usePalette />
                <Body size='small' className='w-full text-center' color='inverse'>
                  {reviewText}
                </Body>
              </>
            )}
            {orderStatus === OrderStatus.Pending && (
              <>
                <Stack alignItems='center' justifyContent='center' className='h-[100px]'>
                  <Loading className='h-32 w-32 animate-spin text-color-text-primary' />
                </Stack>
                <Body className='w-full text-center' color='inverse' size='small'>
                  {tradeT('labels.placing')}
                </Body>
              </>
            )}
            {orderStatus === OrderStatus.Success && (
              <>
                <Stack alignItems='center' justifyContent='center' className='h-[100px]'>
                  <Tick className='h-64 w-64 text-white' />
                </Stack>
                <Body size='small' className='w-full text-center' color='white' weight='emphasis'>
                  {tradeT('labels.placed')}
                </Body>
              </>
            )}

            {orderStatus === OrderStatus.Error && (
              <>
                <Stack alignItems='center' justifyContent='center' className='h-[100px]'>
                  <CrossInCircle className='h-64 w-64 text-white' />
                </Stack>
                <Body size='small' className='w-full text-center' color='white' weight='emphasis'>
                  Something went wrong. Please try again
                </Body>
              </>
            )}

            {isDemo && (
              <Stack
                direction='row'
                spacing={2}
                alignItems='center'
                justifyContent='flex-start'
                width={{ xs: '100%', md: '80%' }}
                marginTop={1}
                sx={{ backgroundColor: 'background.paper', borderRadius: 2, padding: 1 }}
              >
                <Box display='flex' sx={{ svg: { path: { fill: '#FFCB36' } } }}>
                  <GameFilled className='h-[1.5rem] w-[1.5rem]' />
                </Box>
                <Typography fontSize={14} fontWeight={500} color='text.primary'>
                  {t('demoMode.labels.trading')}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          padding={2}
          sx={{
            position: 'sticky',
            backgroundColor: 'background.paper',
            borderBottom: '1px solid',

            borderColor: 'divider',
          }}
          zIndex={200}
          height={48}
          top={0}
        >
          <Typography color='text.secondary' fontWeight={600} fontSize={12}>
            {tradeT('labels.from')}
          </Typography>
          <Typography color='text.secondary' fontWeight={600} fontSize={12}>
            {tradeT('labels.to')}
          </Typography>
        </Stack>
        <Divider sx={{ marginX: '-1rem !important' }} />
        <Box height='100%'>
          {Object.keys(tradeData).map((tradeDataKey) => (
            <TradeOrderDetailsReviewItem
              key={tradeDataKey}
              tradeDataKey={tradeDataKey}
              parentOrderStatus={orderStatus}
            />
          ))}
        </Box>
      </Stack>
      <Stack spacing={1} sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
        {assets && (
          <Box marginBottom={1}>
            <LimitedFunctionalityNotificationAccordion assets={assets} assetsToIgnore={tradeFrom} />
          </Box>
        )}
        {isHighSlippage && (
          <>
            {Big(currentPrice).gt(0) && (
              <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingX={3}>
                <Stack spacing={1} direction='row' alignItems='center'>
                  <Typography color='text.primary' fontWeight={500} fontSize={14}>
                    {tradeT('labels.currentPrice')}
                  </Typography>
                </Stack>
                <Stack direction='column' alignItems='flex-end'>
                  {currentPrice && (
                    <FormattedText
                      typographyProps={{ color: 'primary', weight: 'emphasis', size: 'large' }}
                      secondaryText={{
                        typographyProps: {
                          size: 'large',
                          color: 'secondary',
                          className: 'ml-8',
                        },
                        value: `${fromAsset?.code}/${toAsset?.code}`,
                      }}
                      value={currentPrice}
                      currency={fromAsset}
                      formatOpts={{ appendCode: false }}
                    />
                  )}
                </Stack>
              </Stack>
            )}
            {slippagePercentage && (
              <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingX={3}>
                <Stack spacing={1} direction='row' alignItems='center'>
                  <Typography color='text.primary' fontWeight={500} fontSize={14}>
                    {tradeT('labels.slippage')}
                  </Typography>
                </Stack>
                <Stack direction='column' alignItems='flex-end'>
                  {slippagePercentage && (
                    <Typography
                      color={slippagePercentage > HIGH_SLIPPAGE_PERCENTAGE_THRESHOLD ? 'error' : 'warning.main'}
                      fontWeight={500}
                      fontSize={16}
                      number
                    >
                      ~{slippagePercentage.toFixed(2)}%
                    </Typography>
                  )}
                </Stack>
              </Stack>
            )}
          </>
        )}
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingX={3}>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Typography color='text.primary' fontWeight={600} fontSize={14}>
              {tradeT('labels.rate')}
            </Typography>
          </Stack>
          <Stack direction='column' alignItems='flex-end'>
            {displayRates && (
              <Typography color='text.primary' fontWeight={500} fontSize={16} number>
                {displayRates[0]}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%' paddingX={3}>
          <Stack spacing={0.5} direction='row' alignItems='center' justifyContent='flex-start'>
            <Typography color='text.primary' fontWeight={500} fontSize={14}>
              {tradeT('labels.fees')}
            </Typography>
            <IconButton sx={{ width: '20px', height: '20px' }} size='small' onClick={() => setShowFeeBreakdown(true)}>
              <InfoOutlined color='primary' fontSize='small' />
            </IconButton>
          </Stack>
          <Stack direction='column' alignItems='flex-end'>
            {feeDetail && (
              <FormattedText
                typographyProps={{ color: 'primary', weight: 'emphasis', size: 'large' }}
                prefix={feeDetail.totalPrefix}
                value={feeDetail.totalFee}
                currency={assetService.getAsset(feeDetail.totalAssetId)}
                formatOpts={{ appendCode: true }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {orderStatus === OrderStatus.Idle && (
        <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={1} padding={2}>
          <Button size='lg' variant='outlined' onClick={onEditOrder} className='w-full'>
            {tradeT('buttonLabels.back')}
          </Button>
          <Button size='lg' color='success' onClick={onPlaceOrder} className='w-full'>
            {tradeT('buttonLabels.placeOrder')}
          </Button>
        </Stack>
      )}
      {orderStatus !== OrderStatus.Idle && (!showRecurringAction || showTakeProfitStopLossButton) && (
        <Stack
          direction={showTakeProfitStopLossButton ? 'column' : 'row'}
          alignItems='center'
          justifyContent='space-between'
          spacing={1}
          padding={2}
        >
          {showTakeProfitStopLossButton && (
            <Button
              id='setTakeProfitStopLoss.button'
              data-spotlightelementid='setTakeProfitStopLoss.button'
              color='primary'
              disabled={orderStatus === OrderStatus.Pending}
              onClick={onSetTakeProfitStopLoss}
              size='lg'
              className='w-full'
            >
              {tradeT('buttonLabels.setTakeProfitStopLoss')}
            </Button>
          )}

          <Button
            variant={showTakeProfitStopLossButton ? 'outlined' : 'filled'}
            color={showTakeProfitStopLossButton ? 'primary' : 'success'}
            disabled={orderStatus === OrderStatus.Pending}
            onClick={onPlaceAnotherOrder}
            size='lg'
            className='w-full'
          >
            {tradeT('buttonLabels.placeAnotherOrder')}
          </Button>
          {!isOnTradePage && (
            <Button variant='outlined' onClick={onClose} size='lg' className='w-full'>
              {tradeT('buttonLabels.close')}
            </Button>
          )}
        </Stack>
      )}
      {orderStatus !== OrderStatus.Idle &&
        orderStatus !== OrderStatus.Error &&
        showRecurringAction &&
        !showTakeProfitStopLossButton && (
          <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={1} padding={2}>
            {showCreateAutoInvest && (
              <Button
                color='primary'
                size='lg'
                className='w-full'
                disabled={orderStatus === OrderStatus.Pending}
                onClick={onMakeRecurring}
              >
                {isFeatureEnabled(AppFeature.AutoInvest)
                  ? 'Create auto invest order'
                  : tradeT('buttonLabels.makeRecurringOrder')}
              </Button>
            )}
            <Button
              variant='outlined'
              size='lg'
              className='w-full'
              disabled={orderStatus === OrderStatus.Pending}
              onClick={onPlaceAnotherOrder}
            >
              {tradeT('buttonLabels.placeAnotherOrder')}
            </Button>
            <Button size='lg' className='w-full' variant='outlined' onClick={onClose}>
              {tradeT('buttonLabels.close')}
            </Button>
          </Stack>
        )}
      <TradeFeeBreakdown open={showFeeBreakdown} onClose={() => setShowFeeBreakdown(false)} />
    </Stack>
  );
});

export { TradeOrderDetailsReview };
