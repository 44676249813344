// eslint-disable-next-line max-len
import React, { useContext } from 'react';

import { DateRange } from '@swyftx/react-web-design-system';

import { CalendarPicker } from '@global-components/CalendarPicker';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { UpdateFilterAction } from '@routes/Transactions/types';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

const TransactionsFiltersCalendar: React.FC = observer(() => {
  const { filters, updateFilters } = useContext(TransactionsContext);

  const handleChange = (dateRange: DateRange<DateTime>) => {
    if (
      dateRange[0]?.toMillis() !== filters.dateRange[0]?.toMillis() ||
      dateRange[1]?.toMillis() !== filters.dateRange[1]?.toMillis()
    ) {
      updateFilters({ dateRange }, UpdateFilterAction.Refresh);
    }
  };

  return (
    <CalendarPicker
      id='transactions-history-calendar-picker'
      setDateRange={handleChange}
      dateRange={filters.dateRange}
    />
  );
});

TransactionsFiltersCalendar.displayName = 'TransactionsFiltersCalendar';

export { TransactionsFiltersCalendar };
