import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode';
import { VirtualizedListSkeleton } from '@global-components/VirtualizedTable/VirtualizedListSkeleton';

import { useAppStore } from '@shared/store/appStore';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import { getColumns } from './OrdersTable.data';
import { OrdersTableDesktop } from './OrdersTable.desktop';
import { OrdersTableMobile } from './OrdersTable.mobile';
import { OrdersTableMobileItemSkeleton } from './OrdersTableMobileItem.skeleton';

type Props = {
  width: number | null;
  height: number | null;
  showFilters: boolean;
  type: TransactionsRouteType;
};

const OrdersTable: React.FC<Props> = observer(({ width, height, showFilters, type }) => {
  const { orders, fetching } = useContext(TransactionsContext);
  const { is, isMobile } = useContentBreakpoint();
  const { isDemo } = useAppStore;
  const { t } = useTranslation('orders');

  const columns = useMemo(() => getColumns(is.xl, type), [is.xl, type]);

  if (!width || !height) {
    if (isMobile) {
      return <OrdersTableMobileItemSkeleton num={10} />;
    }
    return <VirtualizedListSkeleton num={10} />;
  }

  if (isDemo && type !== TransactionsRouteType.Orders) {
    return (
      <Box mt={4}>
        <UnavailableDemoMode subTitle={t(`demo.${type}.title`)} />
      </Box>
    );
  }

  return (
    <Box
      id='orders-table-container'
      sx={{
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginBottom: isMobile ? 0 : 1,
        height: '100%',
      }}
    >
      {isMobile ? (
        <OrdersTableMobile items={orders} height={height} loading={fetching} showFilters={showFilters} type={type} />
      ) : (
        <OrdersTableDesktop
          items={orders}
          loading={fetching}
          columns={columns}
          height={height}
          width={width}
          type={type}
        />
      )}
    </Box>
  );
});

OrdersTable.displayName = 'OrdersTable';

export { OrdersTable };
