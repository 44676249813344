import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Checkbox, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { observer } from 'mobx-react-lite';

type Props = {
  setDoNotShowAgain: (checked: boolean) => void;
  setCanContinue: (checked: boolean) => void;
  doNotShowAgain: boolean;
};

export const SendCryptoScamWarning: React.FC<Props> = observer(
  ({ doNotShowAgain, setDoNotShowAgain, setCanContinue }) => {
    const { t } = useTranslation('wallet');
    const [hasConfirmed, setHasConfirmed] = useState(false);

    useEffect(() => {
      if (!hasConfirmed) {
        setCanContinue(false);
      } else {
        setCanContinue(true);
      }
    }, [hasConfirmed]);

    return (
      <Stack spacing={2}>
        <Typography color='text.secondary' fontSize={pxToRem(16)}>
          {t('withdrawCrypto.scamWarning.giveaways')}
        </Typography>
        <Box component='ul' sx={{ listStyleType: 'disc' }} color='text.secondary'>
          <Box component='li'>
            <Typography color='text.secondary' fontSize={pxToRem(16)}>
              {t('withdrawCrypto.scamWarning.giveaways')}
            </Typography>
          </Box>
          <Box component='li'>
            <Typography color='text.secondary' fontSize={pxToRem(16)}>
              {t('withdrawCrypto.scamWarning.adverts')}
            </Typography>
          </Box>
          <Box component='li'>
            <Typography color='text.secondary' fontSize={pxToRem(16)}>
              {t('withdrawCrypto.scamWarning.impersonation')}
            </Typography>
          </Box>
        </Box>
        <Checkbox
          value={hasConfirmed}
          label={t('withdrawCrypto.scamWarning.withdrawalCheckboxLabel')}
          onChange={(_, checked) => setHasConfirmed(checked)}
        />
        <Checkbox
          value={doNotShowAgain}
          label={t('withdrawCrypto.scamWarning.dontShowAgain')}
          onChange={(_, checked) => setDoNotShowAgain(checked)}
        />
      </Stack>
    );
  },
);
