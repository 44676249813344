import { useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { api, HistoricalOrder, OrderType } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { assetService, TransactionHistoryItem } from '@shared/services';
import { UIStore, UniversalTradeStore } from '@shared/store';
import { OrderStatus, TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useAvo } from '@hooks/Avo/useAvo';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';
import { useTransactionsCache } from 'src/lib/transactions/hooks/useTransactionsCache';

interface Props {
  order?: HistoricalOrder;
  onClose: () => void;
  onEditSuccess?: () => void;
  transaction?: TransactionHistoryItem;
}

export const UseOrderInfoActions = (props: Props) => {
  const { onClose, onEditSuccess, order, transaction } = props;
  const { addToastMessage } = UIStore.useUIStore;
  const { deleteOrder } = useContext(TransactionsContext);
  const { invalidateCache } = useTransactionsCache();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const universalTradeEnabled = isFeatureEnabled(AppFeature.UniversalTradePage);
  const { navigate } = useNavigateRoute();
  const avo = useAvo();
  const { setTradeAssets, setShowGlobalTrade, setTradeData, cleanup, setOrderStatus } = UniversalTradeStore;
  const { pathname } = useLocation();
  const orderAsset = assetService.getAsset(order?.secondary_asset ?? 3);
  const { openModal } = useModal();

  const downloadUserOrder = useCallback(async () => {
    try {
      if (!order) return;
      const res = await api.endpoints.downloadOrderInvoice({
        data: {
          orderIds: [order.orderUuid],
        },
      });

      const uInt8 = new Uint8Array(res.data);
      const blob = new Blob([uInt8], { type: res.headers['content-type'] });

      const name = `Swyftx Order Invoice - ${order.orderUuid}.pdf`;

      // on browser download file use a "clicked" <a/> object
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 0);
    } catch (e) {
      const swyftxError: SwyftxError = e as SwyftxError;
      addToastMessage({ severity: 'error', message: swyftxError.errorMessage });
    }
  }, [addToastMessage, order]);

  const onDeleteOrder = useCallback(async () => {
    try {
      if (!order) return;
      await deleteOrder(order.orderUuid);
      invalidateCache();
      onClose();
      addToastMessage({ severity: 'success', message: 'Order successfully cancelled' });
    } catch {
      addToastMessage({ severity: 'error', message: 'There was an error deleting the order, please try again' });
    }
  }, [addToastMessage, deleteOrder, invalidateCache, onClose, order]);

  const getOrderAsset = useCallback(
    (from: boolean) => {
      if (!order) return;
      switch (order.order_type) {
        case OrderType.StopBuy:
        case OrderType.TriggerBuy:
        case OrderType.MarketBuy:
          return from ? order?.primary_asset : order?.secondary_asset;
        case OrderType.StopSell:
        case OrderType.TriggerSell:
        case OrderType.MarketSell:
          return from ? order?.secondary_asset : order?.primary_asset;
        default:
          return from ? order?.primary_asset : order?.secondary_asset;
      }
    },
    [order],
  );

  const onReOrder = useCallback(() => {
    cleanup();
    setShowGlobalTrade(false);
    setOrderStatus(OrderStatus.Idle);

    if (universalTradeEnabled) {
      navigate(NavigationURLs.UniversalTrade);
    }
    if (!order) return;

    const tradeFrom = getOrderAsset(true)!;
    const tradeTo = getOrderAsset(false)!;
    setTradeAssets([tradeFrom], TradeSide.From, TradeAssetAction.Replace);
    setTradeAssets([tradeTo], TradeSide.To, TradeAssetAction.Replace);
    setTradeData(tradeFrom, tradeTo, {
      limit: order.quantity_asset,
      balance: order.quantity?.toString(),
    });
    setShowGlobalTrade(true);
    onClose();
  }, [
    cleanup,
    getOrderAsset,
    navigate,
    onClose,
    order,
    setOrderStatus,
    setShowGlobalTrade,
    setTradeAssets,
    setTradeData,
    universalTradeEnabled,
  ]);

  const viewAsset = useCallback(() => {
    if (orderAsset) {
      avo.viewedSingleAssetPage({
        screen: pathname,
        assetName: orderAsset.name,
        assetId: orderAsset.id,
        assetCode: orderAsset.code,
      });
      onClose();
      navigate(NavigationURLs.SingleAsset, { pathParams: { asset: orderAsset.code } });
    } else {
      addToastMessage({ message: 'Something went wrong, please try again', severity: 'error' });
    }
  }, [addToastMessage, avo, navigate, onClose, orderAsset, pathname]);

  const onEditOrder = useCallback(
    (editOrder?: HistoricalOrder) => {
      if (!editOrder || !transaction) return;

      openModal(Modals.EditTriggerOrders, { order: { ...transaction, pro: editOrder.pro }, onSuccess: onEditSuccess });
    },
    [onEditSuccess, openModal, transaction],
  );

  return { downloadUserOrder, onDeleteOrder, viewAsset, onReOrder, onEditOrder };
};
