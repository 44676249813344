import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TourGuideContext } from '@swyftx/aviary/molecules/TourGuide';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { DepositMethodEnum, FiatCodeEnum } from '@shared/enums';
import { assetService } from '@shared/services';
import { StorageKey } from '@shared/storage';
import { UserStore } from '@shared/store';

import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { DepositAddressChangesContent } from '../components/DepositAddressChanges/DepositAddressChangesContent';
import { useAssetCatgoriesTour } from '../tours';
import { PlatformFeature } from '../types/FeatureDiscovery.types';

type Props = {
  setMinimized: Dispatch<SetStateAction<boolean>>;
};

const useFeatureDiscoveryFeatures = ({ setMinimized }: Props) => {
  const { userCountryCurrencyCode } = UserStore.useUserStore;
  const { t } = useTranslation('featureDiscovery', { keyPrefix: 'features' });
  const assetCatgoriesTour = useAssetCatgoriesTour();
  const { startTour, stopTour } = useContext(TourGuideContext);
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const { navigate } = useNavigateRoute();
  const { openModal, closeModal } = useModal();

  const depositAddressKey = useMemo(() => {
    if (isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart5)) {
      return StorageKey.DEPOSIT_CRYPTO_LANDING_PART_FIVE_READ;
    }

    if (isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart4)) {
      return StorageKey.DEPOSIT_CRYPTO_LANDING_PART_FOUR_READ;
    }

    if (isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart3)) {
      return StorageKey.DEPOSIT_CRYPTO_LANDING_PART_THREE_READ;
    }

    if (isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart2)) {
      return StorageKey.DEPOSIT_CRYPTO_LANDING_PART_TWO_READ;
    }

    return StorageKey.DEPOSIT_CRYPTO_LANDING_READ;
  }, [isFeatureEnabled]);

  const features: PlatformFeature[] = useMemo(
    () => [
      {
        key: 'deposit-address',
        imageKey: 'depositAddress',
        title: 'Changes to crypto deposit addresses',
        content:
          'The way we generate crypto deposit addresses has changed. As a result, you will need to generate a new address for any crypto assets you deposit moving forward. Any crypto deposits to previous addresses may not be recoverable.',
        actionLabel: 'I understand, continue',
        typeLabel: 'Platform announcement',
        footerContent: <DepositAddressChangesContent />,
        shouldShowFeature: () => isFeatureEnabled(AppFeature.FireblocksDepositAddressAnnouncement),
        onAction: () => closeModal(),
        onMaximize: () => closeModal(),
        routes: [NavigationURLs.Dashboard],
        modalClassName: 'sm:max-h-[50rem]',
      },
      {
        key: depositAddressKey,
        imageKey: 'depositAddress',
        title: t('depositAddress.title'),
        content: t('depositAddress.content'),
        actionLabel: t('depositAddress.actionLabel'),
        typeLabel: t('depositAddress.typeLabel'),
        shouldShowFeature: () =>
          isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarning) ||
          isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart2) ||
          isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart3) ||
          isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart4) ||
          isFeatureEnabled(AppFeature.ShowDeprecatedAddressWarningPart5),
        onAction: () =>
          openModal(Modals.DepositAddressChangesModal, {
            onClose: () => setMinimized(false),
          }),
        onMaximize: () => closeModal(),
        routes: [NavigationURLs.Dashboard],
      },
      {
        key: 'asset-categories',
        imageKey: 'assetCategories',
        title: t('assetCategories.title'),
        content: t('assetCategories.content'),
        actionLabel: 'Explore Categories',
        typeLabel: t('assetCategories.typeLabel'),
        onAction: () => startTour(assetCatgoriesTour),
        onMaximize: () => stopTour(true),
        routes: [NavigationURLs.Dashboard, NavigationURLs.AssetsAll, NavigationURLs.AssetsCategories],
      },
      {
        key: 'portfolio-transfer',
        imageKey: 'portfolioTransfer',
        title: t('portfolioTransfer.title'),
        content: t('portfolioTransfer.content'),
        actionLabel: t('portfolioTransfer.actionLabel'),
        typeLabel: t('portfolioTransfer.typeLabel'),
        onAction: () => navigate(NavigationURLs.PortfolioTransfer),
        routes: [NavigationURLs.Dashboard],
      },
      {
        key: 'deposit-stripe',
        imageKey: 'depositStripe',
        title: t('depositStripe.title'),
        content: t('depositStripe.content'),
        actionLabel: t('depositStripe.actionLabel'),
        typeLabel: t('depositStripe.typeLabel'),
        onAction: () => {
          openModal(Modals.DepositReceive, {
            initialDepositType: DepositMethodEnum.BANXA,
            selectedAsset: assetService.getAssetByCode(FiatCodeEnum.AUD),
            onClose: () => setMinimized(false),
          });
        },
        shouldShowFeature: () => {
          if (!isFeatureEnabled(AppFeature.Stripe)) return false;
          if (isFeatureEnabled(AppFeature.StripeAUOnly) && userCountryCurrencyCode !== FiatCodeEnum.AUD) return false;

          return true;
        },
        onMaximize: () => closeModal(),
        routes: [NavigationURLs.Dashboard],
      },
    ],
    [
      assetCatgoriesTour,
      closeModal,
      depositAddressKey,
      isFeatureEnabled,
      navigate,
      openModal,
      setMinimized,
      startTour,
      stopTour,
      t,
      userCountryCurrencyCode,
    ],
  );

  return features;
};

export { useFeatureDiscoveryFeatures };
