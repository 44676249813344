import { useCallback, useMemo } from 'react';

import { OrderType } from '@shared/api/@types/portfolio';
import { Big } from '@shared/safe-big';
import { TransactionHistoryItem } from '@shared/services';
import { formatCurrency } from '@shared/utils';

const useOrder = (order: TransactionHistoryItem) => {
  const getOrderAsset = useCallback(
    (from: boolean) => {
      const { primaryAsset, secondaryAsset } = order;

      const { orderType } = order;
      switch (orderType) {
        case OrderType.StopBuy:
        case OrderType.TriggerBuy:
        case OrderType.MarketBuy:
          return from ? primaryAsset : secondaryAsset;
        case OrderType.StopSell:
        case OrderType.TriggerSell:
        case OrderType.MarketSell:
          return from ? secondaryAsset : primaryAsset;
        default:
          return from ? primaryAsset : secondaryAsset;
      }
    },
    [order],
  );

  const isSell = useCallback((transactionOrder: TransactionHistoryItem) => {
    const { orderType } = transactionOrder;

    switch (orderType) {
      case OrderType.StopBuy:
      case OrderType.TriggerBuy:
      case OrderType.MarketBuy:
        return false;
      case OrderType.StopSell:
      case OrderType.TriggerSell:
      case OrderType.MarketSell:
        return true;
      default:
        return false;
    }
  }, []);

  const addFeePrimary = useCallback(
    (amount: Big, transactionOrder: TransactionHistoryItem) => {
      const { feeAmount } = transactionOrder;

      const isSellOrder = isSell(transactionOrder);

      if (isSellOrder) {
        return amount.plus(feeAmount);
      }

      return amount.minus(feeAmount);
    },
    [isSell],
  );

  const addFeeSecondary = useCallback(
    (amount: Big, transactionOrder: TransactionHistoryItem) => {
      const { feeAmount } = transactionOrder;

      const isSellOrder = isSell(transactionOrder);

      if (isSellOrder) {
        return amount.minus(feeAmount);
      }

      return amount.plus(feeAmount);
    },
    [isSell],
  );

  const getTriggerPrice = useCallback(() => {
    const { primaryAmount, secondaryAmount, limitAsset, primaryAsset } = order;

    const primaryIsLimit = limitAsset === primaryAsset;
    const pAmount = Big(primaryAmount).abs();
    const sAmount = Big(secondaryAmount).abs();

    const pAmountWithFee = primaryIsLimit ? pAmount : addFeePrimary(pAmount, order);
    const sAmountWithFee = primaryIsLimit ? addFeeSecondary(sAmount, order) : sAmount;

    return pAmountWithFee.div(sAmountWithFee).toString();
  }, [addFeePrimary, addFeeSecondary, order]);

  const getTriggerPriceFormatted = useCallback(
    (hideSeparator = true) => {
      const price = getTriggerPrice();
      return formatCurrency(price, undefined, { appendCode: false, hideSeparator });
    },
    [getTriggerPrice],
  );

  const getOrderQuantity = useCallback(() => {
    const { secondaryAmount, amount } = order;

    if (amount && !Big(amount).eq(0)) return Big(amount).abs().toString();

    return Big(secondaryAmount).abs().toString();
  }, [order]);

  const getValue = useCallback(
    (assetId: number) => {
      const { primaryAmount, secondaryAmount } = order;
      if (assetId === order.primaryAsset) {
        return Big(primaryAmount).abs().toString();
      }

      if (assetId === order.secondaryAsset) {
        return Big(secondaryAmount).abs().toString();
      }

      return Big(0).abs().toString();
    },
    [order],
  );

  const output = useMemo(
    () => ({
      getOrderAsset,
      getTriggerPrice,
      getTriggerPriceFormatted,
      getOrderQuantity,
      getValue,
    }),
    [getOrderAsset, getTriggerPrice, getOrderQuantity, getValue],
  );

  return output;
};

export { useOrder };
