import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { Checkbox } from '@swyftx/aviary/atoms/Checkbox';
import { Input } from '@swyftx/aviary/atoms/Input';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useDebounceState } from '@swyftx/aviary/hooks/useDebounceState';
import { ArrowChevronDown, ArrowChevronUp, Information } from '@swyftx/aviary/icons/outlined';

import AssetIcon from '@global-components/AssetIcon/AssetIcon';

import { Asset, UserBalance } from '@shared/api';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import * as Popover from '@radix-ui/react-popover';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

type Props = {
  overlays: string[];
  setOverlays: (newValue: string[]) => void;
};

const MAX_OVERLAYS = 3;

const PortfolioPerformanceOverlaysMenu: React.FC<Props> = observer(({ overlays = [], setOverlays }) => {
  const [debouncedAssetSearch, assetSearch, setAssetSearch] = useDebounceState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [sortedAssets, setSortedAssets] = useState<Asset[]>([]);
  const { balances } = UserStore.useUserStore;

  const updateOverlay = useCallback(
    (assetCode: string) => {
      const newOverlays = Array.from(overlays).filter((o) => o.length > 0);
      const isChecked = newOverlays.includes(assetCode);

      if (!isChecked) {
        if (newOverlays.length === MAX_OVERLAYS) return;

        newOverlays.push(assetCode);

        // Add asset to overlays
      } else {
        const index = newOverlays.findIndex((o) => o === assetCode);
        newOverlays.splice(index, 1);
      }

      setOverlays(newOverlays);
    },
    [overlays, setOverlays],
  );

  useEffect(() => {
    if (!open) {
      const assets = assetService
        .getAssets(Object.values(balances).map((b: UserBalance) => b.assetId))
        .sort((a, b) => a.name.localeCompare(b.name));

      // Move the overlayed assets to the top
      overlays.forEach((overlay) => {
        const index = assets.findIndex((a) => a.code === overlay);
        if (index > -1) {
          assets.unshift(assets.splice(index, 1)[0]);
        }
      });

      setSortedAssets(assets);
    }
  }, [balances, open, overlays]);

  const filteredAssets = useMemo(() => {
    if (!debouncedAssetSearch.length) return sortedAssets;

    return sortedAssets.filter(
      (a) =>
        a.code.toLowerCase().includes(debouncedAssetSearch.toLowerCase()) ||
        a.name.toLowerCase().includes(debouncedAssetSearch.toLowerCase()),
    );
  }, [debouncedAssetSearch, sortedAssets]);

  const isAssetDisabled = useCallback(
    (assetCode: string) => {
      const activeOverlays = overlays.filter((o) => o.length);

      if (activeOverlays.find((o) => o === assetCode)) return false;

      return activeOverlays.length >= MAX_OVERLAYS;
    },
    [overlays],
  );

  const validOverlays = useMemo(() => overlays.filter((o) => o.length > 0), [overlays]);

  return (
    <Popover.Root open={open} onOpenChange={setOpen} modal>
      <Popover.Trigger>
        <FlexLayout alignItems='center' className='w-full' justifyContent='space-between' spacing={16}>
          <Body>Asset overlay</Body>
          <Button
            variant='outlined'
            size='md'
            trailingIcon={open ? <ArrowChevronUp /> : <ArrowChevronDown />}
            focusStyle={open}
            className={cn('rounded-[8px]', open ? 'z-modal-popover outline-color-border-accent' : '')}
          >
            {validOverlays.length > 0 ? validOverlays.join(', ') : 'Select'}
          </Button>
        </FlexLayout>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={24} alignOffset={-16} align='end'>
          <Card className='min-w-[22rem]'>
            <FlexLayout className='w-full' direction='column' spacing={0}>
              <div className='p-16 pb-8'>
                <Input
                  placeholder='Search for...'
                  className='w-full'
                  autoFocus
                  value={assetSearch}
                  onChange={(e) => setAssetSearch(e.target.value)}
                />
              </div>
              <OverlayScrollbarsComponent
                className='max-h-[200px] px-16'
                options={{ scrollbars: { visibility: 'auto', autoHide: 'leave', autoHideDelay: 400 } }}
              >
                {filteredAssets.map((asset) => (
                  <FlexLayout
                    key={asset.code}
                    className='cursor-pointer rounded-[8px] px-8 py-8 hover:bg-color-background-surface-hover'
                    alignItems='center'
                    spacing={16}
                    justifyContent='space-between'
                    onClick={() => updateOverlay(asset.code)}
                  >
                    <FlexLayout spacing={12}>
                      <Checkbox
                        className='h-20 w-20 border-2 border-color-border-main'
                        disabled={isAssetDisabled(asset.code)}
                        checked={overlays.includes(asset.code)}
                      />
                      <AssetIcon asset={asset} size={20} />
                      <Body className='max-w-[130px] truncate'>{asset.name}</Body>
                    </FlexLayout>
                    <Body color='secondary'>{asset.code}</Body>
                  </FlexLayout>
                ))}
              </OverlayScrollbarsComponent>

              <FlexLayout className='bg-color-background-surface-secondary p-12' spacing={8} alignItems='center'>
                <Information className='h-16 w-16 text-color-text-secondary' />
                <Body size='small' color='secondary'>
                  Select a maximum of 3 assets to overlay
                </Body>
              </FlexLayout>
            </FlexLayout>
          </Card>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
});

export { PortfolioPerformanceOverlaysMenu };
