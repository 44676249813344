import React, { ImgHTMLAttributes } from 'react';

type Props = ImgHTMLAttributes<SVGElement>;

const DollarFilled: React.FC<Props> = ({ className }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.25 2.75C13.25 2.05964 12.6904 1.5 12 1.5C11.3096 1.5 10.75 2.05964 10.75 2.75V4.25H9.76786C7.41079 4.25 5.5 6.16078 5.5 8.51786C5.5 10.8749 7.41079 12.7857 9.76786 12.7857H10.75L10.75 17.25H7.75C7.05964 17.25 6.5 17.8096 6.5 18.5C6.5 19.1904 7.05964 19.75 7.75 19.75H10.75V21.25C10.75 21.9404 11.3096 22.5 12 22.5C12.6904 22.5 13.25 21.9404 13.25 21.25V19.75H13.7679C16.3813 19.75 18.5 17.6313 18.5 15.0179C18.5 12.4044 16.3813 10.2857 13.7679 10.2857H13.25L13.25 6.75H16.25C16.9404 6.75 17.5 6.19036 17.5 5.5C17.5 4.80964 16.9404 4.25 16.25 4.25H13.25V2.75ZM10.75 6.75H9.76786C8.7915 6.75 8 7.5415 8 8.51786C8 9.49422 8.7915 10.2857 9.76786 10.2857H10.75L10.75 6.75ZM13.25 12.7857L13.25 17.25H13.7679C15.0006 17.25 16 16.2506 16 15.0179C16 13.7851 15.0006 12.7857 13.7679 12.7857H13.25Z'
      fill='currentColor'
    />
  </svg>
);

export { DollarFilled };
