import React, { useMemo, useState } from 'react';

import { ModalProps } from '@swyftx/react-web-design-system';

import Wizard from '@global-components/Wizard';

import { Asset } from '@shared/api/@types/markets';
import { DepositMethodEnum } from '@shared/enums';

import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

import { TransferEnum } from './TransferModal.types';
import { TransferModalDetailsStep } from './TransferModalDetailsStep';

type Props = {
  initialTransferType: TransferEnum;
  selectedAsset?: Asset | string;
  initialDepositType?: DepositMethodEnum;
  onClose: () => void;
  open: boolean;
};

// Possibly put this inside component as a ternary this depending on if we need to add another step
const containerSx: ModalProps['sx'] = {
  height: { xs: '100%', md: 'min-content' },
  width: '650px',
  '& > div > div > div': {
    // Possibly investigate a better way to remove this padding -- need it gone for the tab placement
    padding: '0 !important',
  },
};

export const TransferModal: React.FC<Props> = ({
  initialTransferType,
  initialDepositType,
  selectedAsset,
  onClose,
  open,
}) => {
  const [stepIndex] = useState<number>(0);
  const { getAssetByCode } = useMarkets();

  const asset = useMemo(() => {
    if (typeof selectedAsset === 'string') {
      return getAssetByCode(selectedAsset);
    } else {
      return selectedAsset;
    }
  }, [getAssetByCode, selectedAsset]);

  return (
    <Wizard.Container stepIndex={stepIndex} onClose={onClose} open={open} containerSx={containerSx}>
      {[
        <TransferModalDetailsStep
          key={0}
          onClose={onClose}
          initialTransferType={initialTransferType}
          initialDepositType={initialDepositType}
          selectedAsset={asset}
        />,
      ]}
    </Wizard.Container>
  );
};
