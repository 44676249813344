import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@swyftx/react-web-design-system';

import { TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { observer } from 'mobx-react-lite';

import { TradeOrderDetailsAssetsContainer } from './components/TradeOrderDetailsAssetContainer';

const TradeOrderDetailsContainer: React.FC = observer(() => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });

  return (
    <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={3}>
      <TradeOrderDetailsAssetsContainer title={t('labels.tradeFrom')} side={TradeSide.From} />
      <TradeOrderDetailsAssetsContainer title={t('labels.tradeTo')} side={TradeSide.To} />
    </Stack>
  );
});

TradeOrderDetailsContainer.displayName = 'TradeOrderDetailsContainer';

export { TradeOrderDetailsContainer };
