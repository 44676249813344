import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { UnavailableDemoMode } from '@global-components/UnavailableDemoMode/UnavailableDemoMode';

import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { AppStore, RatesStore, UIStore, UserStore } from '@shared/store';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { useSort, WalletSort } from '@hooks/useSort/useSort';
import { useUserTradeDetails } from '@hooks/useUserTradeDetails';
import { getBalanceKey, getBalanceValue } from '@utils/balance';

import { WalletContext } from '@Wallet/Wallet.context';
import {
  WalletListFilterBar,
  WalletListNoSearchResultsFound,
  WalletListNoWallets,
  WalletListRow,
  WalletListTile,
} from '@Wallet/components/WalletList/components';
import { WalletLayoutType, WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';

const WalletList: React.FC = observer(() => {
  const { getMinimumOrderAmount } = RatesStore.useRatesStore;
  const { isDemo } = AppStore.useAppStore;
  const { pageRef } = UIStore.useUIStore;
  const { userBaseCurrency, balances, stakingInfo } = UserStore.useUserStore;

  const { t } = useTranslation('wallet');
  const { isMobile } = useContentBreakpoint();

  const { sortWallets } = useSort();
  const { depositedFiat, tradedCrypto } = useUserTradeDetails();
  const { walletSort, hideZeroBalances, walletType, walletLayoutByType, setWalletLayoutByType, walletListSearchValue } =
    useContext(WalletContext);

  const [searchResultFound, setSearchResultFound] = useState(false);

  const isTileView = walletLayoutByType[walletType] === WalletLayoutType.TILES;

  /** if is mobile force list view */
  useEffect(() => {
    if (isMobile) {
      setWalletLayoutByType(WalletType.ALL_WALLETS, WalletLayoutType.LIST);
      setWalletLayoutByType(WalletType.TRADING_WALLETS, WalletLayoutType.LIST);
      setWalletLayoutByType(WalletType.EARNING_WALLETS, WalletLayoutType.LIST);
    }
  }, [isMobile]);

  // TODO: refactor to wallet store
  const wallets = useMemo(() => {
    const isStaking = walletType === WalletType.EARNING_WALLETS;
    const balanceKey = getBalanceKey(walletType);
    const walletInformation = isStaking ? { ...stakingInfo } ?? {} : balances;
    const walletKeys = Object.keys(walletInformation).map(Number);

    let filteredWalletKeys = walletKeys.filter(assetService.getAsset);

    if (hideZeroBalances && walletType !== WalletType.EARNING_WALLETS) {
      filteredWalletKeys = walletKeys.filter((wallet) => {
        const assetBalance = getBalanceValue(balances[Number(wallet)], balanceKey);
        return Big(assetBalance).gte(getMinimumOrderAmount(wallet));
      });
    } else if (walletType === WalletType.EARNING_WALLETS) {
      filteredWalletKeys = walletKeys.filter((wallet) =>
        stakingInfo !== undefined ? !stakingInfo[wallet][0].disabled : true,
      );
    }

    return sortWallets(
      filteredWalletKeys,
      walletSort.ascending || false,
      walletSort.key || WalletSort.Amount,
      balanceKey,
    );
  }, [walletSort, balances, userBaseCurrency, hideZeroBalances, walletType]);

  /** filter wallets based on user search value */
  const filteredWallets = useMemo(() => {
    if (walletListSearchValue.length < 1) {
      setSearchResultFound(true);
      return wallets;
    }

    const searchedAssets = wallets.filter((wallet) => {
      const asset = assetService.getAsset(wallet);
      if (!asset) return false;
      const includesName = asset.name.toLowerCase().includes(walletListSearchValue.toLowerCase());
      const includesCode = asset.code.toLowerCase().includes(walletListSearchValue.toLowerCase());

      return includesName || includesCode;
    });

    setSearchResultFound(searchedAssets.length > 0);
    return searchedAssets;
  }, [walletListSearchValue, wallets]);

  /** for performance only show 6 elements at a time */
  const shownWallets = useInfiniteScroll(pageRef, filteredWallets, {
    initialItemsLength: 20,
    addLengthAmount: 10,
    fromBottomTrigger: 150,
  });

  /**
   * If no wallets show tiles to deposit and trade crypto.
   * If never traded crypto show trade call to action
   * Even if no wallets staking still has wallet data
   */
  if ((wallets.length < 1 || !tradedCrypto) && walletType !== WalletType.EARNING_WALLETS) {
    return <WalletListNoWallets depositedFiat={depositedFiat} wallets={wallets} />;
  }

  if (walletType === WalletType.EARNING_WALLETS && isDemo) {
    return (
      <Card className='p-16'>
        <UnavailableDemoMode showImage subTitle={t('walletList.demoMode') as string} />
      </Card>
    );
  }

  return (
    <FlexLayout direction='column' spacing={8} className='w-full'>
      <WalletListFilterBar />

      {!searchResultFound && <WalletListNoSearchResultsFound />}

      {searchResultFound && (
        <FlexLayout spacing={8} className='h-full w-full flex-wrap' direction='row'>
          {shownWallets.map((wallet) => (
            <React.Fragment key={`wallet-item-${wallet}`}>
              {isTileView ? (
                <WalletListTile id={`wallet-item-${wallet}`} assetId={wallet} walletType={walletType} />
              ) : (
                <WalletListRow id={`wallet-item-${wallet}`} assetId={wallet} walletType={walletType} />
              )}
            </React.Fragment>
          ))}
        </FlexLayout>
      )}
    </FlexLayout>
  );
});

WalletList.displayName = 'WalletList';

export { WalletList };
