import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Checkbox, MenuItem, Typography } from '@swyftx/react-web-design-system';

import { TransactionStatusFilter } from '@shared/services';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { observer } from 'mobx-react-lite';

import { orderStatuses } from '../TransactionsFilters.types';

interface Props {
  disabled?: boolean;
}

const TransactionsFiltersStatus: React.FC<Props> = observer(({ disabled }) => {
  const { updateFilters, filters } = useContext(TransactionsContext);
  const { t } = useTranslation('orders');

  const getFilterValue = () => {
    let value = '';

    if (!filters.statuses.length) return t('status.placeholder');

    if (filters.statuses.length === orderStatuses.length) return t('status.all');

    filters.statuses.forEach((status, index) => {
      const foundStatus = orderStatuses.find((s) => s.value === status);

      if (foundStatus) {
        value += t(foundStatus.label as any);
        if (index < filters.statuses.length - 1) value += ', ';
      }
    });

    return value;
  };

  return (
    <FormControl sx={{ width: '100%', marginLeft: 0, backgroundColor: 'background.paper' }}>
      <Select<TransactionStatusFilter[]>
        sx={{ width: '100%', height: '40px' }}
        labelId='transactionsFilters.status.select.label'
        id='transactionsFilters.status.select'
        autoWidth
        disabled={disabled || false}
        multiple
        displayEmpty
        value={filters?.statuses || []}
        onChange={(e) => updateFilters({ statuses: e.target.value as TransactionStatusFilter[] })}
        renderValue={() => getFilterValue()}
      >
        {orderStatuses.map((status) => (
          <MenuItem
            id={`orderFilters.status.select.item.${status.label}`}
            key={`orderFilters.status.select.item.${status.label}`}
            value={status.value}
            dense
            sx={{
              width: '100%',
              '& .MuiFormControlLabel-root': {
                marginRight: 0,
              },
              justifyContent: 'space-between',
              justifyItems: 'center',
            }}
          >
            <Typography id={`orderFilters.status.select.item.${status.label}.label`} sx={{ marginRight: 2 }}>
              {t(status.label as any)}
            </Typography>
            <Checkbox
              id={`orderFilters.status.select.item.${status.label}.checkbox`}
              checked={filters.statuses.indexOf(status.value) > -1}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

TransactionsFiltersStatus.displayName = 'TransactionsFiltersStatus';

export { TransactionsFiltersStatus };
