import {
  AddressDetailsKeyEnum,
  api,
  AssetHistoryItemStatus,
  CreateWithdrawalParams,
  CreateWithdrawalPayload,
  DepositAddressDetails,
  TransactionType,
  WithdrawalAddressDetails,
  WithdrawalAddressDetailsOptionalKeyEnum,
  WithdrawalResponse,
} from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import logger from '@shared/logger';
import { Big } from '@shared/safe-big';
import {
  assetService,
  balanceService,
  portfolioService,
  stakingDetailService,
  WithdrawAddressMetadata,
  WithdrawalAddress,
} from '@shared/services';

import { AxiosResponse } from 'axios';

import {
  EarnFilterType,
  EarnHistoryItem,
  EarnTypeFilter,
  TransactionHistoryItem,
  TransactionOrder,
  TransactionSort,
  TransactionStatusFilter,
  TransactionTypeFilter,
} from './Wallet.service.data';

const LOG_TAG = 'WalletService';

const getTransactions = async (
  assetId: number,
  page: number,
  pageSize = 10,
  type: TransactionTypeFilter[] = [],
  status: TransactionStatusFilter[] = [],
  sortKey: TransactionSort = TransactionSort.date,
  sortDirection: TransactionOrder = TransactionOrder.DESC,
  startDate?: Date,
  endDate?: Date,
): Promise<{ tableItems: TransactionHistoryItem[]; recordCount: number }> => {
  const query = {
    page,
    limit: pageSize,
    sortKey,
    sortDirection,
    ...(type && { type }),
    ...(status && { status }),
    ...{ startDate: startDate?.getTime() },
    ...{ endDate: endDate?.getTime() },
  };

  const response = await api.endpoints.getAssetTransactionHistory({
    params: { assetId },
    query,
  });

  const { runningTotal, items, recordCount } = response.data;

  let runningTotalBig = Big(runningTotal ?? 0);

  const tableItems = [];

  for (const historyItem of items) {
    const movementAmount =
      historyItem.primaryAsset === assetId ? historyItem.primaryAmount : historyItem.secondaryAmount;
    const itemCompleted = historyItem.status === AssetHistoryItemStatus.Completed;

    if (sortDirection === TransactionOrder.ASC && itemCompleted) {
      // HACK: recalculates the running total to account for deposit fees such as Stripe Credit Cards
      // TODO: the BE needs a change to update the running total
      if (historyItem.type === TransactionType.Deposit && historyItem.feeAmount) {
        runningTotalBig = runningTotalBig.plus(movementAmount).minus(historyItem.feeAmount);
      } else {
        runningTotalBig = runningTotalBig.plus(movementAmount);
      }
    }

    tableItems.push({ ...historyItem, runningTotal: runningTotalBig, tradedAssetId: assetId });

    if (sortDirection === TransactionOrder.DESC && itemCompleted) {
      if (historyItem.type === TransactionType.Deposit && historyItem.feeAmount) {
        runningTotalBig = runningTotalBig.minus(movementAmount).plus(historyItem.feeAmount);
      } else {
        runningTotalBig = runningTotalBig.minus(movementAmount);
      }
    }
  }

  return {
    tableItems,
    recordCount,
  };
};

const convertEarnTypeFilter = (typeFilter: EarnTypeFilter[]): string | undefined => {
  const transactionTypes = [];
  for (const filter of typeFilter) {
    switch (filter) {
      case EarnTypeFilter.Reward:
        transactionTypes.push(EarnFilterType.Reward);
        break;
      case EarnTypeFilter.Deposit:
        transactionTypes.push(EarnFilterType.Add);
        break;
      case EarnTypeFilter.Withdrawal:
        transactionTypes.push(EarnFilterType.Remove);
        break;
      default:
        // not possible
        break;
    }
  }

  return transactionTypes.join(',');
};

const getEarnHistory = async (
  assetId: number,
  page: number,
  pageSize = 10,
  typeFilter: EarnTypeFilter[] = [],
  order: TransactionOrder = TransactionOrder.DESC,
  startDate?: Date,
  endDate?: Date,
): Promise<{ tableItems: EarnHistoryItem[]; earnRecordCount: number }> => {
  const types = typeFilter.length ? convertEarnTypeFilter(typeFilter) : undefined;
  const query = {
    assets: assetService.getAsset(assetId)?.code ?? '',
    page,
    limit: pageSize,
    types,
    ...{ from: startDate?.getTime() },
    ...{ to: endDate?.getTime() },
    // Todo deal with sort changes when david responds
    sortBy: 'timestamp',
    sortDirection: `${order === TransactionOrder.ASC ? 'ASC' : 'DESC'}`,
  };

  const response = await api.endpoints.getEarnHistory({ query });

  const tableItems = response.data;
  return { tableItems, earnRecordCount: Number(response.headers['x-paging-totalrecordcount']) || 0 };
};

const getDepositAddressV2 = async (assId: number, networkId: number) => {
  try {
    const results = await api.endpoints.getDepositAddressesV2({
      params: { assId, networkId },
    });

    return results.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const generateDepositAddressV2 = async (assId: number, networkId: number) => {
  try {
    const results = await api.endpoints.generateDepositAddressV2({
      params: { assId, networkId },
    });

    return results.data;
  } catch (error) {
    const err = error as SwyftxError;
    logger.error(LOG_TAG, err.errorMessage);
    throw err;
  }
};

const stakeAsset = async (amount: string, offerId: string) => {
  await api.endpoints.stakeAmount({ data: { offerId, amount } });
  Promise.all([balanceService.forceUpdate(), stakingDetailService.forceUpdate(), portfolioService.forceUpdate()]);
};

const unStakeAsset = async (amount: string, offerId: string) => {
  await api.endpoints.destakeAmount({ data: { offerId, amount } });
  Promise.all([balanceService.forceUpdate(), stakingDetailService.forceUpdate(), portfolioService.forceUpdate()]);
};

const withdrawalAddressMetadataKeyNamePair = [
  { name: 'Memo', key: WithdrawalAddressDetailsOptionalKeyEnum.Memo },
  { name: 'Destination Tag', key: WithdrawalAddressDetailsOptionalKeyEnum.DestinationTag },
  { name: 'Message', key: WithdrawalAddressDetailsOptionalKeyEnum.Message },
  { name: 'Payment ID', key: WithdrawalAddressDetailsOptionalKeyEnum.PaymentId },
  { name: 'BSB', key: AddressDetailsKeyEnum.BSB, required: true },
];

const getWithdrawAddressMetadata = (
  addressData: DepositAddressDetails | WithdrawalAddressDetails,
): WithdrawAddressMetadata => {
  const result = { name: '', data: '' };

  for (let i = 0; i < withdrawalAddressMetadataKeyNamePair.length; i += 1) {
    if (addressData[withdrawalAddressMetadataKeyNamePair[i].key as AddressDetailsKeyEnum]) {
      result.name = withdrawalAddressMetadataKeyNamePair[i].name;
      result.data = addressData[withdrawalAddressMetadataKeyNamePair[i].key as AddressDetailsKeyEnum];
      break;
    }
  }

  return result;
};

const getWithdrawAddresses = async (code: string): Promise<Array<WithdrawalAddress>> => {
  const results = await api.endpoints.getWithdrawAddresses({ params: { code } });

  const formattedAddressData: Array<WithdrawalAddress> = results.data.address.map((address) => {
    const metadata = getWithdrawAddressMetadata(address.address_details);
    return { ...address, metadata };
  });

  return formattedAddressData;
};

const removeWithdrawalAddress = async (addressId: number, code: string): Promise<void> => {
  await api.endpoints.removeWithdrawAddress({ params: { id: addressId, code } });
};

const createWithdrawal = async (
  code: string,
  data: CreateWithdrawalPayload,
): Promise<AxiosResponse<WithdrawalResponse, any>> => {
  const params: CreateWithdrawalParams = { code };

  return api.endpoints.createWithdrawal({ params, data });
};

const WalletService = {
  getTransactions,
  getDepositAddressV2,
  generateDepositAddressV2,
  stakeAsset,
  unStakeAsset,
  getWithdrawAddresses,
  removeWithdrawalAddress,
  createWithdrawal,
  getEarnHistory,
};

export default WalletService;
