import { useContext, useEffect, useMemo } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { TourGuideContext, TourGuideData } from '@swyftx/aviary/molecules/TourGuide';

import { StorageKey } from '@shared/storage';

import { useLocalStorage } from 'react-use';

const useSwyftxProTour = () => {
  const { startTour } = useContext(TourGuideContext);
  const [viewedTour, setViewedTour] = useLocalStorage<boolean>(StorageKey.SWYFTX_PRO_TOUR_VIEWED, false);

  const isXs = useTailwindBreakpoint('xs');
  const isSm = useTailwindBreakpoint('sm');

  const isSmallScreen = useMemo(() => isXs || isSm, [isSm, isXs]);

  const tourItem = useMemo(
    (): TourGuideData => ({
      name: 'Auto Invest Entry Tour',
      description: 'Shows how to access the new Auto Invest page',
      showSteps: true,
      steps: [
        {
          title: 'Set your preferred currency',
          description: 'Change your trading and display currency here.',
          spotlightElementId: 'pro-currency-selector',
          alignment: 'top',
          borderRadius: 16,
          onNext: () => {
            document.getElementById('tv-overlays-trigger')?.click();
          },
        },
        {
          title: 'New chart overlays',
          description: 'Visualise open orders and in progress orders as you are placing them.',
          spotlightElementId: 'tv-overlays',
          alignment: 'right',
          delay: 50,
          borderRadius: 16,
        },
        {
          title: 'Track market depth',
          description: 'Visualise depth in the market and trade directly from the order book.',
          spotlightElementId: 'pro-orderbook',
          alignment: 'top',
          borderRadius: 16,
        },
        {
          title: 'Set your order direction',
          description: 'Switch between buying and selling directly within the order panel.',
          spotlightElementId: 'pro-trade-direction',
          alignment: 'top',
          borderRadius: 16,
        },
        {
          title: 'Select your order type',
          description: 'Place Market, Limit and One Cancels the Other (OCO) orders here.',
          spotlightElementId: 'pro-trade-type',
          alignment: 'top',
          borderRadius: 16,
        },
      ],
      variant: 'spotlight',
      showBackdrop: true,
      tourKey: StorageKey.SWYFTX_PRO_TOUR_VIEWED,
    }),
    [],
  );

  useEffect(() => {
    if (!viewedTour && !isSmallScreen) {
      const settingsTrigger = document.querySelector('[data-spotlightelementid="pro-settings-trigger"]') as HTMLElement;

      if (settingsTrigger) {
        settingsTrigger.click();
        startTour(tourItem, () => setViewedTour(true));
      }
    }
  }, [isSmallScreen, setViewedTour, startTour, tourItem, viewedTour]);
};

export { useSwyftxProTour };
