import React, { useCallback } from 'react';

import { Image } from '@swyftx/aviary/atoms/Image';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, BodyLink, Heading } from '@swyftx/aviary/atoms/Typography';

import { ENTITY_ASSISTANCE_PHONE_CONSULT_URL } from '@shared/constants/urls';

import { useEntityOnboardingService } from '@routes/EntityOnboarding/EntityOnboarding.context';
import { useEntityOnboardingAnalytics } from '@routes/EntityOnboarding/events/useEntityOnboardingAnalytics';
import { useStepId } from '@routes/EntityOnboarding/hooks/useStepId';

import { EntityApplicationIntroductionStepItem } from './EntityApplicationIntroductionStepItem';
import { EntityOnboardingStepContainer } from '../../EntityOnboardingSteps/EntityOnboardingStepContainer';

const EntityApplicationIntroductionStep: React.FC = () => {
  const entityOnboardingService = useEntityOnboardingService();
  const { phoneConsultLinkCtaClicked } = useEntityOnboardingAnalytics();
  const { stepId } = useStepId();

  const handleOpenPhoneConsultLink = useCallback(() => {
    phoneConsultLinkCtaClicked({
      stepId,
      callToActionLocation: 'select_entity_type_page',
    });
    window.open(ENTITY_ASSISTANCE_PHONE_CONSULT_URL, '_blank', 'noopener,noreferrer');
  }, [phoneConsultLinkCtaClicked, stepId]);

  return (
    <EntityOnboardingStepContainer
      customTitle='Investing with an entity on Swyftx'
      onSubmit={(e) => {
        e.preventDefault();
        entityOnboardingService.send({ type: 'NEXT' });
      }}
    >
      <FlexLayout direction='column' spacing={32}>
        <Body color='secondary'>
          Entity accounts are treated as a sub-account attached to your existing personal account. We currently support
          Company, Self Managed Super Fund (SMSF) and Trusts.
        </Body>
        <FlexLayout direction='column' className='w-full' spacing={24}>
          <Heading size='h5'>Here&apos;s what&apos;s involved:</Heading>
          <EntityApplicationIntroductionStepItem
            icon={<Image image='entity_application' className='w-96' />}
            title='You provide entity information'
            subtitle={
              <FlexLayout direction='row' spacing={4}>
                <Body>
                  Provide your entity details and contact details of members and trustees. Don&apos;t have an existing
                  entity?{' '}
                  <BodyLink
                    className='decoration cursor-pointer text-color-text-accent underline'
                    onClick={handleOpenPhoneConsultLink}
                  >
                    Book a phone consult.
                  </BodyLink>
                </Body>
              </FlexLayout>
            }
          />
          <EntityApplicationIntroductionStepItem
            icon={<Image image='entity_documents' className='w-96' />}
            title='You upload supporting documentation'
            subtitle='Upload key legal documents such as your Trust Deeds or ASIC extracts for verification.'
          />
          <EntityApplicationIntroductionStepItem
            icon={<Image image='entity_reviewing' className='w-96' />}
            title='We review and handle the rest'
            subtitle="We'll review your application and get back to you within 1 business day."
          />
        </FlexLayout>
      </FlexLayout>
    </EntityOnboardingStepContainer>
  );
};

export { EntityApplicationIntroductionStep };
