import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Separator } from '@swyftx/aviary/atoms/Seperator/Seperator';
import { Body, Utility } from '@swyftx/aviary/atoms/Typography';
import { Filter, Loading } from '@swyftx/aviary/icons/outlined';
import { EnhancedTabs } from '@swyftx/aviary/molecules/EnhancedTabs';

import { useTriggerOrderAnnouncement } from '@global-components/Modals/EditTriggerOrdersAnnouncementModal';

import { Asset } from '@shared/api/@types/markets';
import { AssetHistoryItemStatus, SortKey } from '@shared/api/@types/portfolio';
import { TransactionOrder, TransactionStatusFilter, TransactionTypeFilter } from '@shared/services';
import { AppStore } from '@shared/store';
import { cn } from '@shared/utils/lib/ui';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsFilters } from '@routes/Transactions/components';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import AssetOrderItem from './AssetOrderItem/AssetOrderItem';
import { AssetOrdersTab } from './AssetOrders.data';

type Props = {
  asset: Asset;
  onChangeTab?: React.Dispatch<React.SetStateAction<AssetOrdersTab>>;
};

const AssetOrders: React.FC<Props> = observer(({ asset, onChangeTab }) => {
  const [tab, setTab] = useState<AssetOrdersTab>(AssetOrdersTab.Open);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { updateFilters, orders, maxPages, page, fetching, incrementPage, resetPage } = useContext(TransactionsContext);

  const openOrdersCount = useMemo(
    () => orders.filter((order) => order.status === AssetHistoryItemStatus.Pending).length,
    [orders],
  );

  // Don't trigger announcement if the asset has no open orders (or orders haven't loaded)
  const shouldShowAnnouncement = Boolean(openOrdersCount > 0 && orders.length > 0);
  useTriggerOrderAnnouncement(shouldShowAnnouncement);

  const { isDemo } = AppStore.useAppStore;

  const viewOpenOrders = useCallback(() => {
    updateFilters({
      types: [TransactionTypeFilter.BUY, TransactionTypeFilter.SELL],
      asset,
      statuses: [TransactionStatusFilter.PENDING],
    });
    resetPage();
  }, [asset, updateFilters, resetPage]);

  const viewAllOrders = useCallback(() => {
    updateFilters({
      types: [TransactionTypeFilter.BUY, TransactionTypeFilter.SELL],
      asset,
      statuses: [TransactionStatusFilter.COMPLETED, TransactionStatusFilter.FAILED],
    });
    resetPage();
  }, [asset, updateFilters, resetPage]);

  useEffect(() => {
    if (tab === AssetOrdersTab.Open) {
      viewOpenOrders();
    } else {
      viewAllOrders();
    }
  }, [viewOpenOrders, viewAllOrders, tab, isDemo]);

  useEffect(
    () => () => {
      updateFilters({
        types: [TransactionTypeFilter.BUY, TransactionTypeFilter.SELL],
        asset: undefined,
        statuses: [TransactionStatusFilter.COMPLETED, TransactionStatusFilter.FAILED],
        sort: SortKey.Date,
        order: TransactionOrder.DESC,
      });
    },
    [updateFilters],
  );

  const changeTab = (newTab: AssetOrdersTab) => {
    setTab(newTab);
    if (onChangeTab) onChangeTab(newTab);
  };

  const getTableHeader = () => (
    <div className='relative hidden @md:block'>
      <Separator />
      <FlexLayout className='mb-0 p-8  @container'>
        <FlexLayout className='w-3/12 pl-16'>
          <Utility variant='overline'>Type / Status / Date</Utility>
        </FlexLayout>
        <FlexLayout className='w-3/12 ' justifyContent='end'>
          <Utility variant='overline'>Trigger price</Utility>
        </FlexLayout>
        <FlexLayout className='w-3/12 ' justifyContent='end'>
          <Utility variant='overline'>Amount</Utility>
        </FlexLayout>
        <FlexLayout className='w-3/12 pr-16' justifyContent='end'>
          <Utility variant='overline'>Total</Utility>
        </FlexLayout>
      </FlexLayout>
      <Separator />
    </div>
  );

  const getShowAllButton = () => {
    if (page >= maxPages || fetching || (!fetching && !orders.length)) return null;

    return (
      <div>
        <FlexLayout className='mb-0 p-8 @container' alignItems='center'>
          <FlexLayout className='w-full' alignItems='center' justifyContent='center'>
            {page === maxPages && (
              <Button variant='ghost' color='primary' onClick={resetPage}>
                Show Less
              </Button>
            )}
            {page < maxPages && (
              <Button variant='ghost' color='primary' onClick={incrementPage}>
                Show More
              </Button>
            )}
          </FlexLayout>
        </FlexLayout>
      </div>
    );
  };

  return (
    <Card className='p-0'>
      <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='px-16 py-8'>
        <EnhancedTabs<AssetOrdersTab>
          tabs={[
            {
              value: AssetOrdersTab.Open,
              title: 'Open orders',
            },
            {
              value: AssetOrdersTab.History,
              title: 'History',
            },
          ]}
          value={tab}
          variant='child'
          onChange={changeTab}
        />

        <Button
          leadingIcon={<Filter className='h-20 w-20' />}
          id='filters'
          color={showFilters ? 'primary' : 'subtle'}
          disabled={isDemo}
          onClick={() => setShowFilters(!showFilters)}
        >
          Filters
        </Button>
      </FlexLayout>
      <FlexLayout
        className={cn(showFilters ? 'h-auto px-8 py-16' : 'h-0', 'w-full overflow-hidden')}
        alignItems='center'
      >
        <TransactionsFilters type={TransactionsRouteType.Orders} showAssets={false} />
      </FlexLayout>

      {tab === AssetOrdersTab.Open && (
        <div id='tab1' className='relative p-0'>
          {getTableHeader()}
          {orders &&
            orders.map((order, index) => (
              <>
                <AssetOrderItem key={order.uuid} order={order} />
                {index + 1 < orders.length && <Separator />}
              </>
            ))}
          {!orders.length && !fetching && (
            <Body className='p-16 text-center' weight='emphasis' color='secondary'>
              There are no open orders
            </Body>
          )}{' '}
          {fetching && (
            <FlexLayout alignItems='center' justifyContent='center' className='p-24'>
              <Loading className='h-24 w-24 animate-spin' />
            </FlexLayout>
          )}
          {getShowAllButton()}
        </div>
      )}
      {tab === AssetOrdersTab.History && (
        <>
          <div id='tab2' className='relative p-0'>
            {getTableHeader()}
            {orders &&
              orders.map((order, index) => (
                <>
                  <AssetOrderItem key={order.uuid} order={order} />
                  {index + 1 < orders.length && <Separator />}
                </>
              ))}
            {!orders.length && !fetching && (
              <Body className='p-16 text-center' weight='emphasis' color='secondary'>
                There are no orders
              </Body>
            )}
            {fetching && (
              <FlexLayout alignItems='center' justifyContent='center' className='p-24'>
                <Loading className='h-24 w-24 animate-spin' />
              </FlexLayout>
            )}
            {getShowAllButton()}
          </div>
        </>
      )}
    </Card>
  );
});

export { AssetOrders };
