import React, { useMemo } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { MAX_TRADE_ASSETS } from '@shared/constants';
import { UniversalTradeStore } from '@shared/store';
import { TradeAssetAction, TradeSide } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { AssetFilter } from '@hooks/Assets/useAssets';

import { observer } from 'mobx-react-lite';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { TradeAssetSelectorListItem } from './TradeAssetSelectorListItem';

type Props = {
  assets: Asset[];
  side: TradeSide;
  multiSelect: boolean;
  selectedFilter: AssetFilter;
  height: number;
};

type CombinedAssets = { fiatAssets: Asset[]; fiatAssetIds: number[]; cryptoAssets: Asset[]; cryptoAssetIds: number[] };

const assetsReducer = (acc: CombinedAssets, asset: Asset) => {
  if (asset.assetType === AssetType.Fiat) {
    return { ...acc, fiatAssets: acc.fiatAssets.concat(asset), fiatAssetIds: acc.fiatAssetIds.concat(asset.id) };
  }

  if (asset.assetType === AssetType.Crypto) {
    return {
      ...acc,
      cryptoAssets: acc.cryptoAssets.concat(asset),
      cryptoAssetIds: acc.cryptoAssetIds.concat(asset.id),
    };
  }

  return acc;
};

const initialCombinedAssets = { fiatAssets: [], fiatAssetIds: [], cryptoAssets: [], cryptoAssetIds: [] };

type ListHeaderProps = {
  index: number;
  style: React.CSSProperties;
  renderSelectAll?: boolean;
  renderDeselectAll?: boolean;
  handleSelectAll?: () => void;
  handleDeselectAll?: () => void;
};

const FiatHeaderComponent = ({ index, style }: ListHeaderProps): JSX.Element => (
  <div key={index} style={style} className='flex items-center'>
    <Body weight='emphasis' color='primary'>
      Fiat currencies
    </Body>
  </div>
);

const CryptoHeaderComponent = ({
  index,
  style,
  renderDeselectAll,
  renderSelectAll,
  handleSelectAll,
  handleDeselectAll,
}: ListHeaderProps): JSX.Element => (
  <div style={style} key={index} className='flex items-center'>
    <FlexLayout direction='row' className='w-full items-center justify-between'>
      <Body weight='emphasis' color='primary'>
        Cryptocurrencies
      </Body>
      {renderSelectAll && (
        <Button variant='ghost' onClick={handleSelectAll} size='sm'>
          Select all
        </Button>
      )}
      {renderDeselectAll && (
        <Button variant='ghost' onClick={handleDeselectAll} size='sm'>
          Deselect all
        </Button>
      )}
    </FlexLayout>
  </div>
);

const TradeAssetSelectorList: React.FC<Props> = observer(({ assets, side, multiSelect, selectedFilter, height }) => {
  const { setTradeCache, tradeCache } = UniversalTradeStore;
  const { fiatAssets, fiatAssetIds, cryptoAssets, cryptoAssetIds } = useMemo(
    () => assets.reduce<CombinedAssets>((acc, asset) => assetsReducer(acc, asset), initialCombinedAssets),
    [assets],
  );
  const typeOrderedAssets = fiatAssets.concat(cryptoAssets);
  const currentSideSelectedAssets = tradeCache[side];
  const selectedCryptoAssetIds = useMemo(
    () => currentSideSelectedAssets.filter((assetId) => cryptoAssetIds.includes(assetId)),
    [currentSideSelectedAssets, currentSideSelectedAssets.length, cryptoAssetIds],
  );

  const selectAllCryptoAssets = () => {
    setTradeCache(cryptoAssetIds, side, TradeAssetAction.Add);
  };

  const deselectAllCryptoAssets = () => {
    setTradeCache(selectedCryptoAssetIds, side, TradeAssetAction.Remove);
  };

  // We have split the assets into fiat and crypto.
  // If the count is greater than 0 for each, we render a list item containing a section header
  // (only way to have list subheadings at this stage).
  // We need to keep count of the amount of available asset items + header items.
  // These two constants assist with this.
  const fiatHeaderIndexOffset = fiatAssetIds.length ? 1 : 0;
  const cryptoHeaderIndexOffset = cryptoAssetIds.length ? 1 : 0;

  const renderRow = (props: ListChildComponentProps) => {
    if (!typeOrderedAssets.length) return null;

    const { index, style } = props || {};

    // Fiat currencies
    if (fiatAssetIds.length) {
      if (index === 0) {
        return <FiatHeaderComponent index={index} style={style} />;
      }

      if (index < fiatAssetIds.length + fiatHeaderIndexOffset) {
        const item = fiatAssets[index - fiatHeaderIndexOffset];
        if (!item) return null;

        return (
          <TradeAssetSelectorListItem
            index={index}
            key={item.code}
            side={side}
            style={style}
            selectedFilter={selectedFilter}
            assetId={item.id}
            multiSelect={multiSelect}
          />
        );
      }
    }

    // Cryptocurrencies
    if (cryptoAssetIds.length) {
      if (index < fiatAssetIds.length + fiatHeaderIndexOffset + cryptoHeaderIndexOffset) {
        const shouldRenderDeselectAllBtn =
          selectedCryptoAssetIds.length > 1 || (cryptoAssetIds.length < 2 && selectedCryptoAssetIds.length > 0);

        const shouldRenderSelectAllBtn =
          typeOrderedAssets.length <= MAX_TRADE_ASSETS && selectedCryptoAssetIds.length >= 0;

        return (
          <CryptoHeaderComponent
            index={index}
            style={style}
            renderSelectAll={shouldRenderSelectAllBtn}
            renderDeselectAll={shouldRenderDeselectAllBtn}
            handleSelectAll={selectAllCryptoAssets}
            handleDeselectAll={deselectAllCryptoAssets}
          />
        );
      }

      const cryptoItemIndex = index - fiatAssetIds.length - fiatHeaderIndexOffset - cryptoHeaderIndexOffset;
      const item = cryptoAssets[cryptoItemIndex];
      if (!item) return null;
      return (
        <TradeAssetSelectorListItem
          index={index}
          key={item.code}
          side={side}
          style={style}
          selectedFilter={selectedFilter}
          assetId={item.id}
          multiSelect={multiSelect}
        />
      );
    }

    return null;
  };

  const itemCount = typeOrderedAssets.length + fiatHeaderIndexOffset + cryptoHeaderIndexOffset;

  return (
    <FixedSizeList height={height} width='100%' itemSize={50} itemCount={itemCount} overscanCount={20}>
      {renderRow}
    </FixedSizeList>
  );
});

export { TradeAssetSelectorList };
