import React, { PropsWithChildren, useState } from 'react';

import { Tooltip } from '@swyftx/aviary/atoms/Tooltip/Tooltip';
import { ArrowLineOnlyDown, ArrowLineOnlyUp, Information } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import { FlexLayout } from '../Layout/Flex';
import { FlexLayoutProps } from '../Layout/Flex/FlexLayout.styles';

type Props = {
  className?: string;
  sortable?: boolean;
  sorting?: 'ASC' | 'DESC';
  tooltip?: string;
  frozen?: boolean;
  showFrozenDivider?: boolean;
  freezeColumns?: boolean;
  insetLeft?: boolean;
  insetRight?: boolean;
  offsetLeft?: number;
  onClick?: React.MouseEventHandler<HTMLTableHeaderCellElement> | undefined;
} & Pick<FlexLayoutProps, 'justifyContent'>;

const TableHeader: React.FC<PropsWithChildren<Props>> = ({
  className,
  sorting,
  sortable = true,
  justifyContent,
  children,
  tooltip,
  frozen,
  insetLeft,
  insetRight,
  showFrozenDivider = true,
  freezeColumns,
  offsetLeft,
  onClick,
}) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <th
      className={cn(
        `${sortable || tooltip ? 'cursor-pointer' : ''}
          ${freezeColumns ? 'bg-color-background-surface-primary' : ''}
          ${frozen ? '!sticky' : ''} whitespace-nowrap px-12
          ${insetLeft ? '!pl-16 @md:!pl-24' : ''}
          ${insetRight ? '!pr-16 @md:!pr-24' : ''}
          `,
        className,
      )}
      style={{ left: offsetLeft !== undefined && frozen ? `${offsetLeft}px` : undefined }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <FlexLayout alignItems='center' className='gap-2' justifyContent={justifyContent}>
        <div className={justifyContent === 'start' ? 'order-1' : 'order-2'}>{children}</div>
        {sortable && (
          <div className={justifyContent === 'start' ? 'order-2' : 'order-1'}>
            {!sorting && (
              <ArrowLineOnlyDown className={` h-12 w-12 text-color-text-tertiary ${hover ? 'visible' : 'invisible'}`} />
            )}
            {sorting === 'ASC' && <ArrowLineOnlyUp className='h-12 w-12 text-color-text-accent' />}
            {sorting === 'DESC' && <ArrowLineOnlyDown className='h-12 w-12 text-color-text-accent' />}
          </div>
        )}
        {tooltip && (
          <Tooltip title={tooltip}>
            <Information className='order-1 h-12 min-h-[12px] w-12 min-w-[12px] text-color-text-tertiary hover:text-color-text-accent' />
          </Tooltip>
        )}
      </FlexLayout>
      {frozen && showFrozenDivider && freezeColumns && (
        <div className='absolute right-0 top-0 h-[40px] w-[1px] bg-color-border-main' />
      )}
    </th>
  );
};

TableHeader.displayName = 'TableHeader';

export { TableHeader };
