import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { UniversalTradeStore } from '@shared/store';
import { TradeState } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useUniversalTradeUtilityStore } from '@hooks/Trade';

import { observer } from 'mobx-react-lite';

type Props = {
  state: TradeState;
  disabled?: boolean;
};

const TradeOrderDetailsContinue: React.FC<Props> = observer(({ state, disabled }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeOrderDetails' });
  const { resetTradeAssets } = useUniversalTradeUtilityStore();
  const { setTradeState } = UniversalTradeStore;

  return (
    <FlexLayout direction='row' className='full-width p-24' spacing={8}>
      <Button variant='outlined' size='lg' onClick={resetTradeAssets} className='w-[100%]'>
        {t('buttonLabels.clear')}
      </Button>
      <Button size='lg' className='w-[100%]' color='primary' onClick={() => setTradeState(state)} disabled={disabled}>
        {t('buttonLabels.continue')}
      </Button>
    </FlexLayout>
  );
});

TradeOrderDetailsContinue.displayName = 'TradeOrderDetailsContinue';

export { TradeOrderDetailsContinue };
