import React, { PropsWithChildren, useState } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Heading } from '@swyftx/aviary/atoms/Typography';
import { Cross } from '@swyftx/aviary/icons/outlined';

import { DepositMethodEnum } from '@shared/enums';
import { formatCurrency } from '@shared/utils';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useDeposit } from '@hooks/Deposit/useDeposit';

import { observer } from 'mobx-react-lite';
import { FiatDepositDetailsCard } from 'src/lib/portfolio/components/FiatDepositDetailsCard';

type Props = {
  title: string;
  reference: string;
  position?: 'center' | 'bottom';
};

const AutoInvestDepositDetailsModal: React.FC<PropsWithChildren<Props>> = observer(
  ({ position = 'center', title, reference, children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const countryAsset = useCountryAsset();

    const { bankAddressDetails, loading } = useDeposit({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      asset: countryAsset!,
      transferType: DepositMethodEnum.MONOOVA_DIRECT_CREDIT,
      fetchAddressDetails: open,
    });

    return (
      <Modal
        triggerElement={children}
        open={open}
        onOpenChange={(o) => setOpen(!!o)}
        position={position}
        className='min-h-[200px] sm:!min-w-[500px] sm:!max-w-[500px]'
      >
        <FlexLayout direction='column'>
          <FlexLayout alignItems='center' justifyContent='space-between' className='p-24'>
            <Heading size='h4'>{title}</Heading>
            <Button layout='icon' variant='outlined' leadingIcon={<Cross />} onClick={() => setOpen(false)} />
          </FlexLayout>
          <FlexLayout direction='column' className='px-16 pb-16'>
            {!bankAddressDetails && !loading && (
              <Notification
                severity='destructive'
                title='Error generating bank details'
                content='There was an error generating the deposit details for your recurring order. Please try again'
              />
            )}

            {(loading || (!loading && bankAddressDetails)) && (
              <FiatDepositDetailsCard
                accountName='Swyftx'
                accountNumber={bankAddressDetails?.address}
                bsb={bankAddressDetails?.bsb}
                referenceDescription={reference}
                minimumDepositAmount={formatCurrency(countryAsset?.min_deposit, countryAsset)}
                loading={loading}
              />
            )}
          </FlexLayout>
        </FlexLayout>
      </Modal>
    );
  },
);

export { AutoInvestDepositDetailsModal };
