import { compile } from 'path-to-regexp';

function compilePath(path: string) {
  const generator = compile(path);
  return generator;
}

/**
 * Transform parameters into path
 * @param {string} path Relative endpoint path
 * @param {object} params key-value pair parameters to transform
 * @returns Formatted path
 * @example
 * // return /users/:uuid
 * generatePath('/users/:uuid/', {uuid: '123e4567-e89b-12d3-a456-426614174000'})
 */
function generatePath(path = '/', params = {}) {
  return path === '/' ? path : compilePath(path)(params);
}

export default generatePath;
