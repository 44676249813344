import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@swyftx/react-web-design-system';

import { FormattedText } from '@global-components/Text';

import { TransactionType } from '@shared/api';
import { assetService } from '@shared/services';

import { useContentBreakpoint } from '@hooks/Grid/useContentBreakpoint';
import { useOrder } from '@hooks/Orders';

import { observer } from 'mobx-react-lite';

import { OrdersTableColumnProps } from './columns.data';

const OrdersTableAmount: React.FC<OrdersTableColumnProps> = observer(({ item, mobile, width }) => {
  const secondaryAsset = useMemo(() => assetService.getAsset(item.secondaryAsset), [item]);
  const { getOrderQuantity } = useOrder(item);
  const { t } = useTranslation('orders');
  const { bx } = useContentBreakpoint();

  const secondaryIsQuantity = useMemo(() => secondaryAsset?.id === item.limitAsset, [secondaryAsset, item.limitAsset]);
  const isDepositOrWithdraw = useMemo(
    () => item.type === TransactionType.Deposit || item.type === TransactionType.Withdrawal,
    [item],
  );

  const prefix = useMemo(() => {
    switch (item.type) {
      case TransactionType.Deposit:
        return '+';
      case TransactionType.Withdrawal:
        return '-';
      default:
        return undefined;
    }
  }, [item]);

  return (
    <Stack
      width={!mobile && width ? `${width}px` : '100%'}
      minWidth={!mobile && width ? `${width}px` : '100%'}
      maxWidth={!mobile && width ? `${width}px` : '100%'}
      flex={2}
      paddingRight={mobile ? 1 : 0}
      key={`${item.uuid}_amount`}
      justifyContent={mobile ? 'space-between' : 'flex-end'}
      alignItems='center'
      direction='row'
    >
      {mobile && (
        <Typography color='text.secondary' fontWeight={400} fontSize={14}>
          {t('ordersTable.headers.amount')}
        </Typography>
      )}
      <Stack direction={bx({ xs: 'row', md: 'row' })} alignItems={bx({ xs: 'end', md: 'center' })} spacing={1}>
        {!isDepositOrWithdraw && secondaryIsQuantity && <Typography color='text.secondary'>*</Typography>}
        <FormattedText
          prefix={prefix}
          value={getOrderQuantity()}
          currency={secondaryAsset}
          formatBalance
          typographyProps={{
            color: 'primary',
          }}
          formatOpts={{ appendCode: false, hideCode: true }}
        />
        <Typography number color='text.secondary'>
          {secondaryAsset?.code}
        </Typography>
      </Stack>
    </Stack>
  );
});

export { OrdersTableAmount };
