import { HTMLAttributes } from 'react';

import { VariantProps, cva } from 'cva';

export type NumericProps = HTMLAttributes<HTMLParagraphElement> & VariantProps<typeof numericVariants>;

export const numericVariants = cva('', {
  variants: {
    color: {
      accent: 'text-color-text-accent',
      primary: 'text-color-text-primary',
      secondary: 'text-color-text-secondary',
      success: 'text-color-text-success',
      error: 'text-color-text-error',
      warning: 'text-color-text-warning',
      white: 'text-white',
      inverse: 'text-color-text-inverse',
    },
    size: {
      xxlarge: `font-mono numeric-xxlarge line-height-40 letter-spacing-expanded`,
      xlarge: `font-mono numeric-xlarge line-height-24 letter-spacing-expanded`,
      large: `font-mono numeric-large line-height-20 letter-spacing-expanded`,
      medium: `font-mono numeric-medium line-height-20 letter-spacing-expanded`,
      small: `font-mono numeric-small line-height-16 letter-spacing-expanded`,
      xsmall: `font-mono numeric-xsmall line-height-12 letter-spacing-expanded`,
      xxsmall: `font-mono numeric-xxsmall line-height-12 letter-spacing-expanded`,
    },
    loading: {
      true: 'animate-pulse !text-color-background-surface-active line-through decoration-[100%]',
      false: '',
    },
    weight: {
      none: 'font-400',
      emphasis: 'font-500',
      bold: 'font-600',
    },
    overflow: {
      truncate: 'truncate',
      none: '',
    },
    PII: {
      true: 'PII',
      false: '',
    },
  },

  compoundVariants: [
    {
      size: 'xxlarge',
      loading: true,
      class: 'decoration-[32px]',
    },
    {
      size: 'xlarge',
      loading: true,
      class: 'decoration-[24px]',
    },
    {
      size: 'large',
      loading: true,
      class: 'decoration-[18px]',
    },
    {
      size: 'medium',
      loading: true,
      class: 'decoration-[16px]',
    },
    {
      size: 'small',
      loading: true,
      class: 'decoration-[14px]',
    },
    {
      size: 'xsmall',
      loading: true,
      class: 'decoration-[12px]',
    },
    {
      size: 'xxsmall',
      loading: true,
      class: 'decoration-[10px]',
    },
  ],
  defaultVariants: {
    color: 'primary',
    size: 'medium',
    weight: 'none',
  },
});
