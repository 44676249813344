import React from 'react';
import { useTranslation } from 'react-i18next';

import { pxToRem, Stack, Typography, TypographyProps } from '@swyftx/react-web-design-system';

import { UserStore } from '@shared/store';

const contentTypographyProps: TypographyProps = {
  fontSize: pxToRem(16),
  color: 'text.secondary',
};

export const EmailVerification: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'verification' });
  const { userProfile } = UserStore.useUserStore;
  const email = userProfile?.email;

  return (
    <Stack spacing={3} alignItems='flex-start'>
      <Typography {...contentTypographyProps}>
        {t('email.actionComplete')}
        <Typography PII {...contentTypographyProps} fontWeight={600} display='inline'>
          {email}.
        </Typography>
      </Typography>
      <Typography {...contentTypographyProps}>{t('email.checkInbox')}</Typography>
    </Stack>
  );
};
