import React from 'react';

import { Column } from '@global-components/VirtualizedTable/VirtualizedTable.types';

import { TransactionHistoryItem } from '@shared/services';

import { TransactionsRouteType } from '@routes/Transactions/types';

import {
  OrdersTableActions,
  OrdersTableAmount,
  OrdersTableAsset,
  OrdersTableExchangeRate,
  OrdersTableFee,
  OrdersTableTotal,
} from './columns';
import { OrdersTableDate } from './columns/OrdersTableDate';

const getColumns = (isXl: boolean, type?: TransactionsRouteType): Column[] => {
  const cols: Column[] = [
    {
      label: 'ordersTable.headers.date',
      hidden: false,
      accessor: 'date',
      width: 250,
      paddingLeft: 2,
      align: 'flex-start',
      flex: 2,
    },
    {
      label: 'ordersTable.headers.asset',
      hidden: false,
      accessor: 'secondaryAsset',
      noSort: true,
      width: 80,
      flex: 2,
      align: 'flex-start',
    },
  ];

  if (!type || type === TransactionsRouteType.Orders) {
    cols.push(
      {
        label: 'ordersTable.headers.amount',
        hidden: false,
        noSort: true,
        accessor: 'amount',
        flex: 2,
        align: 'flex-end',
      },
      {
        label: 'ordersTable.headers.price',
        hidden: false,
        accessor: 'rate',
        noSort: true,
        flex: 2,
        align: 'flex-end',
      },
      {
        label: 'ordersTable.headers.fee',
        hidden: !isXl,
        accessor: 'feeAmount',
        noSort: true,
        flex: 2,
        align: 'flex-end',
      },
      {
        label: 'ordersTable.headers.total',
        hidden: false,
        accessor: 'userCountryValue',
        flex: 2,
        align: 'flex-end',
      },
      {
        label: '',
        hidden: false,
        noSort: true,
        width: 80,
        flex: 1,
      },
    );
  } else {
    cols.push(
      {
        label: 'ordersTable.headers.address',
        hidden: false,
        noSort: true,
        accessor: 'rate',
        flex: 4,
        align: 'flex-end',
      },
      {
        label: 'ordersTable.headers.fee',
        hidden: false,
        noSort: true,
        accessor: 'feeAmount',
        flex: 3,
        align: 'flex-end',
      },
      {
        label: 'ordersTable.headers.amount',
        hidden: false,
        noSort: true,
        accessor: 'amount',
        flex: 3,
        align: 'flex-end',
      },
      {
        label: '',
        hidden: false,
        noSort: true,
        width: 80,
        flex: 1,
      },
    );
  }

  return cols;
};

const getRows = (item: TransactionHistoryItem) => ({
  id: `ordersTable.row.${item.uuid}`,
  row: {
    date: <OrdersTableDate item={item} />,
    asset: <OrdersTableAsset item={item} />,
    exchangeRate: <OrdersTableExchangeRate item={item} />,
    amount: <OrdersTableAmount item={item} />,
    total: <OrdersTableTotal item={item} />,
    fee: <OrdersTableFee item={item} />,
    actions: <OrdersTableActions item={item} />,
  },
});

export { getColumns, getRows };
