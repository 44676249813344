/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import { Input, TextFieldProps } from '@swyftx/react-web-design-system';

import AccountNumberValidator from '@hooks/validator/types/accountNumber.validator';
import { useValidation } from '@hooks/validator/useValidation';

type AccountNumberInputFormProps = {
  characters: {
    min: number;
    max: number;
  };
  attachForm?: (id: string, validate: () => Promise<boolean>) => void;
  setValid?: (valid: boolean) => void;
  success?: boolean;
} & TextFieldProps;

const AccountNumberInput: React.FC<AccountNumberInputFormProps> = ({
  id,
  characters,
  attachForm,
  setValid,
  onChange,
  value,
  ...props
}) => {
  const invalidChars = ['-', '+', 'e', '.'];

  const validatorProps = useValidation(
    [new AccountNumberValidator('Account number is not valid', { characters })],
    true,
    onChange,
    value,
  );

  const isValidKey = (key: string) => {
    if (invalidChars.includes(key)) return false;
    return true;
  };

  useEffect(() => {
    if (validatorProps?.success !== undefined && setValid) {
      setValid(validatorProps?.success);
    }
  }, [validatorProps.success, setValid]);

  useEffect(() => {
    if (attachForm) attachForm(id!, validatorProps.validate);
  }, [attachForm]);

  return (
    <Input
      PII
      id={id}
      type='number'
      label='Account number'
      placeholder='Enter account number'
      InputProps={{ sx: { height: '48px', fontSize: '14px' } }}
      ContainerProps={{ sx: { marginBottom: '1rem' } }}
      onKeyDown={(evt) => !isValidKey(evt.key) && evt.preventDefault()}
      sx={{
        width: '100%',
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          appearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          appearance: 'textfield',
        },
      }}
      {...props}
      {...validatorProps}
    />
  );
};

export { AccountNumberInput };
