import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { ReceiveCryptoFilled, VerifiedFilled } from '@swyftx/aviary/icons/filled';
import { Coin, DollarInCircle } from '@swyftx/aviary/icons/outlined';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';
import { useAvo } from '@hooks/Avo/useAvo';

import { WalletType } from '@Wallet/types';

import { observer } from 'mobx-react-lite';
import { useNavigateRoute } from 'src/lib/navigation/hooks';
import { NavigationURLs } from 'src/lib/navigation/types';

import { WalletListRow } from '../WalletListRow';

type NoWalletsRow = {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  buttonText: string;
  onClick: () => void;
  isCrypto?: boolean;
};

type Props = {
  depositedFiat: boolean;
  wallets: number[];
};

const LOG_TAG = 'EMPTY_WALLET_LIST';

const WalletListNoWallets: React.FC<Props> = observer(({ depositedFiat, wallets }) => {
  const { isUserVerified } = UserStore.useUserStore;
  const { navigate } = useNavigateRoute();
  const { t } = useTranslation('wallet');
  const countryAsset = useCountryAsset();
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const avo = useAvo();

  const cryptoTileData: NoWalletsRow = useMemo(() => {
    if (!isUserVerified()) {
      return {
        icon: <VerifiedFilled className='h-[30px] w-[30px]' />,
        title: t('overview.noWallets.verify.title'),
        subtitle: t('overview.noWallets.verify.subTitle'),
        buttonText: t('overview.noWallets.verify.buttonLabels.verify'),
        onClick: () => navigate(NavigationURLs.Onboarding),
        isCrypto: true,
      };
    }

    if (depositedFiat) {
      return {
        icon: <Coin className='h-30 w-30' />,
        title: t('walletList.noWallets.depositedFiat.title'),
        subtitle: t('walletList.noWallets.depositedFiat.subtitle'),
        buttonText: t('walletList.noWallets.depositedFiat.buttonText'),
        onClick: () => navigate(NavigationURLs.Assets),
        isCrypto: true,
      };
    }

    return {
      icon: <ReceiveCryptoFilled className='h-[30px] w-[30px]' />,
      title: t('walletList.noWallets.cryptoTile.title'),
      subtitle: t('walletList.noWallets.cryptoTile.subtitle'),
      buttonText: t('walletList.noWallets.cryptoTile.buttonText'),
      onClick: () => {
        avo.depositFundsTapped({
          screen: pathname,
          component: LOG_TAG,
        });
        openModal(Modals.DepositReceive);
      },
      isCrypto: true,
    };
  }, [avo, depositedFiat, isUserVerified, navigate, openModal, pathname, t]);

  const getRow = ({ icon, title, subtitle, buttonText, onClick }: NoWalletsRow) => (
    <Card className='mb-8 h-full w-full p-24'>
      <FlexLayout direction='row' className='h-auto w-full @container sm:h-[60px]' alignItems='center'>
        <FlexLayout direction='row' alignItems='center' justifyContent='space-between' spacing={16} className='w-full'>
          <FlexLayout direction='row' alignItems='center' spacing={16} className='w-full @sm:w-8/12'>
            {/* icon */}
            <div className='hidden @sm:block'>{icon}</div>

            {/* Text */}
            <FlexLayout direction='column' justifyContent='space-between'>
              <Body weight='bold' size='large'>
                {title}
              </Body>
              <Body color='secondary'>{subtitle}</Body>
            </FlexLayout>
          </FlexLayout>

          {/* Button */}
          <FlexLayout className='w-full @sm:w-4/12' direction='row' justifyContent='end' alignItems='center'>
            <Button variant='filled' onClick={onClick}>
              {buttonText}
            </Button>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </Card>
  );

  return (
    <FlexLayout direction='column' spacing={8} className='w-full'>
      <Body weight='bold' size='large'>
        {t('walletList.noWallets.title')}
      </Body>

      <FlexLayout direction='column' className='w-full'>
        {depositedFiat &&
          wallets.map((wallet) => (
            <WalletListRow
              key={wallet}
              id={`wallet-item-${wallet}`}
              assetId={wallet}
              walletType={WalletType.ALL_WALLETS}
            />
          ))}

        {countryAsset &&
          isUserVerified() &&
          !depositedFiat &&
          getRow({
            icon: <DollarInCircle className='h-30 w-30' />,
            title: `${t('walletList.noWallets.noFiat.title', { code: countryAsset.code })}`,
            subtitle: `${t('walletList.noWallets.noFiat.subtitle')}`,
            buttonText: `${t('walletList.noWallets.noFiat.buttonText')}`,
            onClick: () => {
              avo.depositFundsTapped({
                screen: pathname,
                component: LOG_TAG,
              });
              openModal(Modals.DepositReceive, { selectedAsset: countryAsset });
            },
          })}

        {/* deposit/trade crypto */}
        {getRow(cryptoTileData)}
      </FlexLayout>
    </FlexLayout>
  );
});

export { WalletListNoWallets };
