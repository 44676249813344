import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body, Heading } from '@swyftx/aviary/atoms/Typography';

import { TrendingCryptoNarrativeCategory } from './TrendingCryptoNarrativeCategory';

const TrendingCryptoNarrative: React.FC = () => {
  const { t } = useTranslation('assets');

  return (
    <FlexLayout direction='column' spacing={8} className='w-full md:w-2/3'>
      <Heading size='h5'>{t('trendingCategory.narrativeTitle')}</Heading>
      <Body color='secondary' size='xsmall'>
        {t('trendingCategory.narrativeSubTitle')}
      </Body>

      <TrendingCryptoNarrativeCategory title='Artificial Intelligence' value='11.3%' className='w-full' />
      <TrendingCryptoNarrativeCategory title='GameFi' value='10.5%' className='w-[70%]' />
      <TrendingCryptoNarrativeCategory title='Meme Coins' value='8.3%' className='  w-[63%]' />
      <TrendingCryptoNarrativeCategory title='Solana Ecosystem' value='7.2%' className=' w-[58%]' />
      <TrendingCryptoNarrativeCategory title='BRC-20' value='7.1%' className='  w-[57%]' />
      <TrendingCryptoNarrativeCategory title='Real World Assets' value='6.5%' className='w-[54%]' />

      <Body color='secondary' size='xsmall'>
        <strong> {t('trendingCategory.source')}: </strong> {t('trendingCategory.coinGeckoNarrative')}
      </Body>
    </FlexLayout>
  );
};

export { TrendingCryptoNarrative };
