import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { Button, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetType } from '@shared/api';

import { useAvo } from '@hooks/Avo/useAvo';
import { SUPPORTED_DEPOSIT_COUNTRY_CODES } from '@services/DepositService/DepositSerivce.service';

type Props = {
  asset: Asset;
};

const IMG_SIZE = '100px';
const LOG_TAG = 'TRADE_INPUT_ZERO_BALANCE';

export const TradeInputZeroBalance: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation('trade', { keyPrefix: 'tradeAssetInput.zeroBalance' });
  const isFiat = asset.assetType === AssetType.Fiat;
  const { openModal } = useModal();
  const hideButton = isFiat && !SUPPORTED_DEPOSIT_COUNTRY_CODES.includes(asset.code);
  const { pathname } = useLocation();
  const avo = useAvo();

  const img = (() => {
    if (isFiat) {
      return <img src='/assets/images/no-fiat-funds.svg' alt='no_fiat_funds' width={IMG_SIZE} height={IMG_SIZE} />;
    }
    return <img src='/assets/images/no-crypto-funds.svg' alt='no_crypto_funds' width={IMG_SIZE} height={IMG_SIZE} />;
  })();

  const handleDepositClicked = () => {
    avo.depositFundsTapped({
      screen: pathname,
      component: LOG_TAG,
    });
    openModal(Modals.DepositReceive, { selectedAsset: asset });
  };

  return (
    <Stack direction='column' spacing={1} paddingBottom={2} alignItems='center' justifyContent='center'>
      <Typography fontSize={pxToRem(16)} fontWeight={600} textAlign='center'>
        {t('title')}
      </Typography>
      <Typography fontSize={pxToRem(14)} color='text.secondary' textAlign='center'>
        {t('description')}
      </Typography>
      <Box padding={1}>{img}</Box>
      {!hideButton && (
        <Button variant='contained' fullWidth size='large' onClick={handleDepositClicked}>
          {isFiat ? t('buttonLabels.fiat') : t('buttonLabels.crypto', { code: asset.code })}
        </Button>
      )}
    </Stack>
  );
};
