import React from 'react';

import { Backdrop } from '@swyftx/aviary/atoms/Backdrop';

import { TourGuideClip } from './TourGuideClip';
import { useTourGuide } from './useTourGuide';

const TourGuide: React.FC = () => {
  const { element, showBackdrop, stopTour, targetRect, borderRadius } = useTourGuide();

  return (
    <>
      <Backdrop
        className='z-tour-backdrop'
        show={showBackdrop}
        onClick={() => stopTour(true)}
        style={{
          maskImage: 'url(#tourGuideMask)',
        }}
      />
      {showBackdrop && targetRect && <TourGuideClip rect={targetRect} borderRadius={borderRadius} />}
      {element}
    </>
  );
};

export { TourGuide };
