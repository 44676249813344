import {
  AddressBrokenUp,
  AddressSuggestion,
  AffiliateInfoResponse,
  APIKey,
  APIKeyScope,
  CreateAPIKeyArgs,
  CreateAPIKeyResponse,
  CreateAppAPIKeyArgs,
  DustLimit,
  DustUserBalanceData,
  Entity,
  GetGeneralOrderFeeResponse,
  GetOrderFeeResponse,
  Invite,
  Member,
  OnboardingPayload,
  OnboardingResponse,
  OrderDirection,
  ReferralCodeResponse,
  RevokeAPIKeyIdArgs,
  RevokeAPIKeyRefArgs,
  UpdateAffiliateArgs,
  UpdateUserCurrencyArgs,
  UpdateUserProfileArgs,
  UpdateUserSettingsArgs,
  UserBalance,
  UserStatistics,
  CreateKoinlySsoSessionArgs,
  CreateKoinlySsoSessionResponse,
  UpdatePhoneData,
  UpdatePhoneResponse,
} from '@shared/api/@types/user';
import { TimesEnum } from '@shared/enums';

import {
  GetProfileResponse,
  PasswordlessOTPParams,
  ProfileResponse,
  RequestPhoneVerificationPinResponse,
} from '../../@types/auth';
import { BecomeAffiliateArgs } from '../../@types/history';
import { EmailStatus } from '../../@types/verification';
import { Methods, Request, request } from '../rest';

const userEndpoints = {
  dustUserBalance: ({ data }: { data: DustUserBalanceData }) =>
    request({ path: '/user/balance/dust/', method: Methods.POST, data, auth: true, demoable: true }),

  checkDustLimit: (): DustLimit =>
    request({ path: '/user/balance/dustLimit/', method: Methods.GET, auth: true, demoable: true }),

  setGreenIDInfo: ({ data, headers }: { data: object; headers?: Record<string, string> }) =>
    request({ path: '/user/verification/storeGreenID/', method: Methods.POST, data, headers, auth: true }),

  setupNewGreenID: ({ data, headers }: { data: object; headers: Record<string, string> }) =>
    request({ path: '/verification/setupNewGreenID/', method: Methods.POST, data, headers, auth: true }),

  requestEmailVerification: () => request({ path: '/user/verification/email/', method: Methods.POST, auth: true }),

  verifyEmailOTP: ({ data, headers }: { data: PasswordlessOTPParams; headers?: Record<string, string> }) =>
    request({ path: '/user/verification/email/otp/', method: Methods.POST, data, headers, auth: true }),

  requestPhoneVerificationPin: (): RequestPhoneVerificationPinResponse =>
    request({ path: '/user/verification/phone/', method: Methods.GET, auth: true }),

  checkPhoneVerificationPin: ({ params, headers }: { params: { pin: string }; headers?: Record<string, string> }) =>
    request({ path: '/user/verification/phone/:pin/', method: Methods.POST, headers, params, auth: true }),

  updateUserCurrency: ({
    data,
    headers,
  }: {
    data: UpdateUserCurrencyArgs;
    headers?: Record<string, string>;
  }): ProfileResponse => request({ path: '/user/currency/', method: Methods.POST, headers, data, auth: true }),

  becomeAffiliate: ({ data }: { data: BecomeAffiliateArgs }): void =>
    request({ path: '/user/affiliations/affiliate/', method: Methods.POST, data, auth: true }),

  createAPIKey: ({
    data,
    headers,
  }: {
    data: CreateAPIKeyArgs;
    headers?: Record<string, string>;
  }): CreateAPIKeyResponse =>
    request({ path: '/user/apiKeys/create/', method: Methods.POST, headers, data, auth: true }),

  createAppAPIKey: ({ data }: { data: CreateAppAPIKeyArgs }): CreateAPIKeyResponse =>
    request({
      path: '/user/apiKeys/create/',
      method: Methods.POST,
      data,
      auth: true,
    }),

  createAPIKeyForEntity: ({
    data,
    headers,
  }: {
    data: CreateAPIKeyArgs;
    headers?: Record<string, string>;
  }): CreateAPIKeyResponse =>
    request({ path: '/user/entity/apiKey/', method: Methods.POST, headers, data, auth: true }),

  revokeAPIKey: ({ data }: { data: RevokeAPIKeyIdArgs | RevokeAPIKeyRefArgs }): string =>
    request({ path: '/user/apiKeys/revoke/', method: Methods.POST, data, auth: true }),

  revokeAllAPIKeys: () => request({ path: '/user/apiKeys/revokeAll/', method: Methods.POST, auth: true }),

  addMemberToEntity: ({
    params,
    data,
    headers,
  }: {
    params: { entityUuid: string };
    data: object;
    headers: Record<string, string>;
  }) =>
    request({
      path: '/user/entity/:entityUuid/members/add/',
      method: Methods.POST,
      headers,
      data,
      params,
      auth: true,
    }),

  createNewEntityMember: ({
    params,
    data,
  }: {
    params: { entityUuid: string };
    data: {
      type: string;
      stakeholder: boolean;
      nameFirst: string;
      nameLast: string;
      email: string;
      phone: string;
      country: string;
      scope: string;
    };
  }) => request({ path: '/user/entity/:entityUuid/members/new/', method: Methods.POST, data, params, auth: true }),

  createEntity: ({ data, headers }: { data: object; headers: Record<string, string> }) =>
    request({ path: '/user/entity/', method: Methods.POST, headers, data, auth: true }),

  updateUserProfile: ({ data, headers }: { data: UpdateUserProfileArgs; headers?: Record<string, string> }) =>
    request({ path: '/user/update/', method: Methods.PATCH, headers, data, auth: true }),

  updateAffiliate: ({ data, headers }: { data: UpdateAffiliateArgs; headers: Record<string, string> }): void =>
    request({ path: '/user/affiliations/updateAbn/', method: Methods.POST, headers, data, auth: true }),

  updateEntityMember: ({
    params,
    data,
  }: {
    params: {
      entityUuid: string;
      memberUuid: string;
    };
    data: {
      scope: string;
    };
  }) =>
    request({
      path: '/user/entity/:entityUuid/members/:memberUuid/',
      method: Methods.PATCH,
      data,
      params,
      auth: true,
    }),

  removeMemberFromEntity: ({
    params,
    headers,
  }: {
    params: {
      entityUuid: string;
      memberUuid: string;
    };
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/user/entity/:entityUuid/members/:memberUuid/',
      method: Methods.DELETE,
      headers,
      params,
      auth: true,
    }),

  getProfile: (): GetProfileResponse => request({ path: '/user/', method: Methods.GET, auth: true }),

  getUserBalance: (): UserBalance[] =>
    request({ path: '/user/balance/', method: Methods.GET, auth: true, demoable: true }),

  getProgress: ({ headers }: { headers: Record<string, string> }) =>
    request({ path: '/user/progress/', method: Methods.GET, headers, auth: true }),

  getStatistics: (): UserStatistics => request({ path: '/user/statistics/', method: Methods.GET, auth: true }),

  getNewGreenIDToken: ({ query, headers }: { query: { id: string }; headers?: Record<string, string> }) =>
    request({ path: '/user/verification/getNewGreenIDToken/', method: Methods.GET, query, headers, auth: true }),

  checkEmailVerification: (): EmailStatus =>
    request({ path: '/user/verification/email/', method: Methods.GET, auth: true, hideErrorToast: true }),

  // the old report endpoint
  downloadTaxReport: ({ query }: { query: { from: string; to: string; offset: number; type: string } }) =>
    request({ path: '/user/taxReport', method: Methods.GET, responseType: 'arraybuffer', query, auth: true }),
  // the new report endpoint
  downloadTransactionReport: ({ query }: { query: { from: string; to: string; offset: number; type: string } }) =>
    request({ path: '/user/transactionReport', method: Methods.GET, responseType: 'arraybuffer', query, auth: true }),

  downloadOrderInvoice: ({ data }: { data: { orderIds: string[] } }) =>
    request({
      path: '/user/orderInvoice',
      responseType: 'arraybuffer',
      method: Methods.POST,
      data,
      auth: true,
      hideErrorToast: true,
    }),

  getAddressSuggestion: ({
    query,
    headers,
  }: {
    query: object;
    headers?: Record<string, string>;
  }): AddressSuggestion[] =>
    request({ path: '/user/address-suggestion/', method: Methods.GET, headers, query, auth: true }),

  selectAddressSuggestion: ({
    params,
    query,
    headers,
  }: {
    params: object;
    query: object;
    headers?: Record<string, string>;
  }): AddressBrokenUp =>
    request({ path: '/user/address-formatted/:placeId/', method: Methods.GET, headers, params, query, auth: true }),

  getAffiliationInfo: (): AffiliateInfoResponse =>
    request({ path: '/user/affiliations/info', method: Methods.GET, auth: true }),

  getAPIKeys: (): APIKey[] =>
    request({
      path: '/user/apiKeys/',
      method: Methods.GET,
      auth: true,
    }),

  getAPIPermissions: ({ params }: { params?: object } = {}): APIKeyScope =>
    request({ path: '/user/apiKeys/scope/', method: Methods.GET, params, auth: true, cache: TimesEnum.HOUR }),

  getEntities: ({ headers }: { headers: Record<string, string> }): Entity[] =>
    request({ path: '/user/entity/', method: Methods.GET, headers, auth: false }),

  getMembers: ({
    params,
  }: {
    params: { entityUuid: string; deepSearch?: boolean; flatten?: boolean; includeSelf?: boolean };
  }): Member[] => request({ path: '/user/entity/:entityUuid/members/', method: Methods.GET, params, auth: true }),

  getInvites: ({ params }: { params: { entityUuid: string } }): Invite[] =>
    request({ path: '/user/entity/:entityUuid/invites/', method: Methods.GET, params, auth: true }),

  refreshEntityToken: ({ params, headers }: { params: { entityUuid: string }; headers: Record<string, string> }) =>
    request({ path: '/user/entity/:entityUuid/token/', method: Methods.GET, headers, params, auth: false }),

  getPromotions: ({ headers }: { headers: Record<string, string> }) =>
    request({ path: '/user/promotions/', method: Methods.GET, headers, auth: true }),

  updateUserSettings: ({
    data,
    headers,
    ignoreErrors,
  }: {
    data: UpdateUserSettingsArgs;
    headers?: Record<string, string>;
    ignoreErrors?: Request<any>['ignoreErrors'];
  }) =>
    request({
      path: '/user/settings/',
      method: Methods.POST,
      headers,
      data,
      auth: true,
      ignoreErrors,
      hideErrorToast: true,
    }),

  downloadAffiliateInvoice: ({ data, headers }: { data: { reference: string }; headers?: Record<string, string> }) =>
    request({
      path: '/user/affiliationInvoice',
      method: Methods.POST,
      responseType: 'arraybuffer',
      headers,
      data,
      auth: true,
    }),

  updateEntityMemberNotificationSettings: ({
    params,
    data,
    headers,
  }: {
    params: {
      entityUuid: string;
      memberUuid: string;
    };
    data: {
      enable: boolean;
    };
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/user/entity/:entityUuid/members/:memberUuid/notification/settings',
      method: Methods.PATCH,
      headers,
      params,
      data,
      auth: true,
    }),

  getUserFees: (): GetGeneralOrderFeeResponse => request({ path: '/user/fees/', method: Methods.GET, auth: true }),

  getFees: ({
    headers,
    params,
  }: {
    params: { primary: number; secondary: number; direction: OrderDirection };
    headers?: Record<string, string>;
  }): GetOrderFeeResponse =>
    request({ path: '/user/fees/:primary/:secondary/:direction/', method: Methods.GET, headers, params, auth: true }),
  getOnboardingData: ({ headers }: { headers?: Record<string, string> } = {}): OnboardingResponse =>
    request({
      path: '/user/onboarding/',
      method: Methods.GET,
      headers,
      auth: true,
    }),

  syncOnboardingData: ({
    data,
    headers,
  }: {
    data: OnboardingPayload;
    headers?: Record<string, string>;
  }): OnboardingResponse =>
    request({
      path: '/user/onboarding/',
      method: Methods.POST,
      data,
      headers,
      auth: true,
    }),

  getReferralCode: (): ReferralCodeResponse =>
    request({ path: '/user/referral/code/', method: Methods.GET, auth: true }),

  createKoinlySsoSession: ({
    data,
    headers,
  }: {
    data: CreateKoinlySsoSessionArgs;
    headers?: Record<string, string>;
  }): CreateKoinlySsoSessionResponse =>
    request({
      path: '/user/koinly/sso',
      method: Methods.POST,
      data,
      headers,
      auth: true,
    }),

  /**
   * This api handles 4 steps of the verification process
   * 1. Create a OTP request for old phone number
   * 2. Verify the OTP for old phone number
   * 3. Create a OTP request for new phone number
   * 4. Verify the OTP for new phone number
   *
   * Request body for each step should be of the following format
   * 1. {}
   * 2. { oldOTP: string }
   * 3. { oldOTP: string, newPhone: string }
   * 4. { oldOTP: string, newPhone: string, newOTP: string }
   *
   * oldOTP is optional if the green id and phone number have not been verified
   */
  updatePhone: ({ data }: { data: UpdatePhoneData }): UpdatePhoneResponse =>
    request({ path: '/user/update/phone/', method: Methods.PATCH, auth: true, data }),
};

export default userEndpoints;
