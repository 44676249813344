import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { NavigationURLs } from '../types';

type ExitIntentData = {
  title: string;
  content: string;
  stepId?: string;
  featureName?: string;
  modalName?: string;
  path?: NavigationURLs;
  show?: boolean;
};

type NavigationContextType = {
  exitIntentData?: ExitIntentData;
  hasExitIntent: boolean;
  setExitIntentData: Dispatch<SetStateAction<ExitIntentData | undefined>>;
  showExitIntent: (path: NavigationURLs) => void;
  hideExitIntent: (clearExitIntent?: boolean) => void;
};

const NavigationContext = createContext<NavigationContextType>({
  exitIntentData: undefined,
  hasExitIntent: false,
  setExitIntentData: () => undefined,
  showExitIntent: () => undefined,
  hideExitIntent: () => undefined,
});

const NavigationContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [exitIntentData, setExitIntentData] = useState<ExitIntentData>();

  const showExitIntent = useCallback(
    (path: NavigationURLs) => {
      if (!exitIntentData) return;
      setExitIntentData({ ...exitIntentData, path, show: true });
    },
    [exitIntentData],
  );

  const hideExitIntent = useCallback(
    (clearExitIntent?: boolean) => {
      if (!clearExitIntent && exitIntentData) {
        setExitIntentData({ ...exitIntentData, show: false });
      } else {
        setExitIntentData(undefined);
      }
    },
    [exitIntentData],
  );

  const hasExitIntent = useMemo(() => exitIntentData !== undefined, [exitIntentData]);

  return (
    <NavigationContext.Provider
      value={{ exitIntentData, hasExitIntent, setExitIntentData, showExitIntent, hideExitIntent }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContextProvider, NavigationContext };
