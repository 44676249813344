import React, { Dispatch, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { AssetInput } from '@global-components/Input';
import { Balance } from '@global-components/Input/AssetInput/AssetInput';
import { SendCryptoModalStep } from '@global-components/Modals/SendCrypto/SendCryptoModal';

import { Asset } from '@shared/api/@types/markets';
import { WithdrawalAddress, WithdrawalReason } from '@shared/services';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

import { observer } from 'mobx-react-lite';

import { SendCryptoAddressDropdown } from '../components/SendCryptoAddressDropdown';
import { SendCryptoReasonDropdown } from '../components/SendCryptoReasonDropdown';

const labelTypographyProps = {
  fontSize: pxToRem(14),
  fontWeight: 500,
};

const Required = () => (
  <Typography color='error.main' {...labelTypographyProps} display='inline'>
    *
  </Typography>
);

type Props = {
  asset: Asset;
  setCanContinue: (checked: boolean) => void;
  selectedAddress: WithdrawalAddress | null;
  setSelectedAddress: (address: WithdrawalAddress | null) => void;
  selectedReason: WithdrawalReason | null;
  setSelectedReason: (reason: WithdrawalReason | null) => void;
  value: string;
  setValue: (value: string) => void;
  withdrawalAddresses?: Array<WithdrawalAddress>;
  setStep: Dispatch<SendCryptoModalStep>;
};

export const SendCryptoDetails: React.FC<Props> = observer(
  ({
    withdrawalAddresses,
    setSelectedAddress,
    setSelectedReason,
    selectedAddress,
    setCanContinue,
    selectedReason,
    setValue,
    asset,
    value,
    setStep,
  }) => {
    const countryAsset = useCountryAsset();
    const { t } = useTranslation('wallet');

    useEffect(() => {
      if (!selectedAddress || !selectedReason || !value.length) {
        setCanContinue(false);
      } else {
        setCanContinue(true);
      }
    }, [selectedAddress, selectedReason, value]);

    if (!countryAsset) return null;

    return (
      <Stack spacing={1}>
        <Stack>
          <Typography {...labelTypographyProps}>
            {t('withdrawCrypto.addressDetails.selectAddress', { code: asset.code })}
            <Required />
          </Typography>
          <SendCryptoAddressDropdown
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
            addresses={withdrawalAddresses}
            setStep={setStep}
          />
        </Stack>
        <Stack>
          <Typography {...labelTypographyProps}>
            {t('withdrawCrypto.addressDetails.withdrawalAmount', { code: asset.code })}
            <Required />
          </Typography>
          <AssetInput
            onChange={(newValue) => setValue(newValue.value)}
            balanceToUse={Balance.Trading}
            baseAsset={countryAsset}
            flippable={false}
            asset={asset}
            value={value}
            slider
          />
        </Stack>
        <Stack>
          <Typography {...labelTypographyProps}>
            {t('withdrawCrypto.addressDetails.withdrawing')}
            <Required />
          </Typography>
          <SendCryptoReasonDropdown selectedReason={selectedReason} setSelectedReason={setSelectedReason} />
        </Stack>
      </Stack>
    );
  },
);
