import { DateRange } from '@swyftx/react-web-design-system';

import { Asset, SortKey } from '@shared/api';
import {
  TransactionHistoryItem,
  TransactionOrder,
  TransactionStatusFilter,
  TransactionTypeFilter,
} from '@shared/services';

import { DateTime } from 'luxon';

export enum TransactionsRouteType {
  Orders = 'orders',
  Deposits = 'deposits',
  Withdrawals = 'withdrawals',
}

export type TransactionsSortOptions = {
  key: Extract<keyof TransactionHistoryItem, string>;
  direction: 'asc' | 'desc' | 'default';
};

export type TransactionsFilterOptions = {
  searchString: string;
  statuses: TransactionStatusFilter[];
  types: TransactionTypeFilter[];
  sort?: SortKey;
  asset?: Asset;
  order?: TransactionOrder;
  dateRange: DateRange<DateTime>;
};

export enum UpdateFilterAction {
  Filter,
  Refresh,
  None,
}

export const routeToTypeMap: { [key in TransactionsRouteType]: TransactionTypeFilter[] } = {
  [TransactionsRouteType.Orders]: [TransactionTypeFilter.BUY, TransactionTypeFilter.SELL],
  [TransactionsRouteType.Deposits]: [TransactionTypeFilter.DEPOSIT],
  [TransactionsRouteType.Withdrawals]: [TransactionTypeFilter.WITHDRAWAL],
};

export type TransactionsStoreSchema = {
  page: number;
  maxPages: number;
  fetching: boolean;
  showFilters: boolean;
  orders: TransactionHistoryItem[];
  filteredOrders: TransactionHistoryItem[];
  filters: TransactionsFilterOptions;
  sort: TransactionsSortOptions;
  forceUpdateOrders: () => void;
  fetchOrders: (delayLoadingState?: boolean, force?: boolean, updateDuplicates?: boolean) => void;
  updateFilters: (filterOptions: Partial<TransactionsFilterOptions>, action?: UpdateFilterAction) => void;
  setShowFilters: (showFilters: boolean) => void;
  incrementPage: () => void;
  resetPage: () => void;
  deleteOrder: (orderUuid: string) => void;
};

export const initialValues: TransactionsStoreSchema = {
  fetching: true,
  showFilters: false,
  page: 1,
  maxPages: 2,
  orders: [],
  filteredOrders: [],
  filters: {
    searchString: '',
    statuses: [],
    types: [],
    sort: undefined,
    order: undefined,
    dateRange: [DateTime.fromObject({ year: 2018, month: 7, day: 1 }), DateTime.now().endOf('day')],
  },
  sort: {
    key: 'date',
    direction: 'desc',
  },
  forceUpdateOrders: () => {},
  fetchOrders: () => {},
  updateFilters: () => {},
  setShowFilters: () => {},
  deleteOrder: () => {},
  incrementPage: () => {},
  resetPage: () => {},
};
