import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { Skeleton, Stack } from '@swyftx/react-web-design-system';

type Props = {
  num: number;
};

const OrdersTableMobileItemSkeleton: React.FC<Props> = ({ num }) => {
  const [skeletonItems, setSkeletonItems] = useState<object[]>([]);

  useEffect(() => {
    const newSkeletonItems = [];
    for (let i = 0; i < num; i += 1) {
      newSkeletonItems.push({});
    }

    setSkeletonItems(newSkeletonItems);
  }, [num]);

  const renderSkeleton = (index: number) => (
    <Stack key={index} direction='column' spacing={1} marginBottom={3} id='ordersTable.mobile.skeleton.container'>
      {/* Trade Status */}
      <Stack
        id='ordersTable.mobile.skeleton.status'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingRight={1}
      >
        <Skeleton variant='text' width='140px' height='20px' />
        <Skeleton variant='text' width='140px' height='20px' />
      </Stack>

      {/* Asset */}
      <Stack
        id='ordersTable.mobile.skeleton.asset'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingRight={1}
      >
        <Skeleton variant='text' width='60px' height='20px' />
        <Box display='flex' alignItems='center'>
          <Skeleton variant='circular' width='24px' height='24px' sx={{ marginRight: 1 }} />
          <Skeleton variant='text' width='30px' height='20px' />
        </Box>
      </Stack>

      {/* Exchange Rate */}
      <Stack
        id='ordersTable.mobile.skeleton.exchangeRate'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingRight={1}
      >
        <Skeleton variant='text' width='100px' height='20px' />
        <Skeleton variant='text' width='140px' height='20px' />
      </Stack>

      {/* Amount */}
      <Stack
        id='ordersTable.mobile.skeleton.amount'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingRight={1}
      >
        <Skeleton variant='text' width='70px' height='20px' />
        <Skeleton variant='text' width='80px' height='20px' />
      </Stack>

      {/* Total */}
      <Stack
        id='ordersTable.mobile.skeleton.total'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingRight={1}
      >
        <Skeleton variant='text' width='60px' height='20px' />
        <Skeleton variant='text' width='100px' height='20px' />
      </Stack>

      {/* Fee */}
      <Stack
        id='ordersTable.mobile.skeleton.fee'
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingRight={1}
      >
        <Skeleton variant='text' width='40px' height='20px' />
        <Skeleton variant='text' width='80px' height='20px' />
      </Stack>
    </Stack>
  );

  if (!skeletonItems.length) return null;

  return <>{skeletonItems && skeletonItems.map((item, index) => renderSkeleton(index))}</>;
};

export { OrdersTableMobileItemSkeleton };
