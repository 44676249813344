import React, { Dispatch, useState } from 'react';

import AddCircle from '@mui/icons-material/AddCircle';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import { Chip, Menu, pxToRem, Stack, Typography } from '@swyftx/react-web-design-system';

import { SendCryptoModalStep } from '@global-components/Modals/SendCrypto/SendCryptoModal';

import { WithdrawalAddress } from '@shared/services';

import { observer } from 'mobx-react-lite';
import { useSize } from 'react-use';

type Props = {
  setSelectedAddress: (option: WithdrawalAddress | null) => void;
  selectedAddress: WithdrawalAddress | null;
  addresses?: Array<WithdrawalAddress>;
  setStep: Dispatch<SendCryptoModalStep>;
};

const AddressItem: React.FC<{ isVerified: boolean; label: string; isMobile?: boolean; addressCode?: string }> = ({
  isVerified,
  addressCode,
  isMobile,
  label,
}) => (
  <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
    <Stack direction='row' spacing={2} alignItems='center'>
      <Box sx={{ opacity: isVerified ? 1 : 0.5 }} display='flex' alignItems='center' justifyContent='center'>
        {/* Convert to inverse when new design system
         */}
        <img src='/assets/images/usb.svg' alt='address' />
      </Box>
      <Stack spacing={0.5}>
        <Typography fontSize={pxToRem(16)} fontWeight={500} color={isVerified ? 'text.primary' : 'text.disabled'}>
          {label}
        </Typography>
        {addressCode ? (
          <Typography fontSize={pxToRem(14)} color='text.secondary'>
            {addressCode}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
    {!isMobile && !isVerified && <Chip label='Confirm email' color='primary' />}
  </Stack>
);

export const SendCryptoAddressDropdown: React.FC<Props> = observer(
  ({ selectedAddress, setSelectedAddress, addresses, setStep }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [widthSize, { width }] = useSize(<Box width='100%' height={0} />);
    const theme = useTheme();

    const handleOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => setAnchorEl(e.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const borderColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600];
    const bgColor = theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[700];

    return (
      <>
        <ButtonBase component='div' onClick={handleOpen}>
          <Stack
            justifyContent='space-between'
            border='1px solid'
            borderRadius='5px'
            borderColor={borderColor}
            bgcolor={bgColor}
            height={pxToRem(40)}
            direction='row'
            width='100%'
            spacing={2}
            padding={1}
          >
            {selectedAddress ? (
              <AddressItem
                isVerified={selectedAddress.verified}
                label={selectedAddress.label}
                addressCode={selectedAddress.address_details.address}
              />
            ) : (
              <AddressItem isVerified label='Select an address' />
            )}
            <KeyboardArrowDown width={pxToRem(14)} height={pxToRem(14)} />
          </Stack>
        </ButtonBase>
        {widthSize}
        <Menu
          onClose={handleClose}
          sx={{
            '& li': {
              minWidth: width,
            },
            '& ul': {
              maxHeight: '20rem',
            },
            '& .MuiList-root': {
              paddingY: 0,
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={Boolean(anchorEl)}
        >
          {addresses?.map((address) => (
            <MenuItem onClick={() => setSelectedAddress(address)} key={address.code}>
              <AddressItem
                isVerified={address.verified}
                label={address.label}
                addressCode={address.address_details.address}
              />
            </MenuItem>
          ))}
          {/* TODO connect to add address */}
          <MenuItem>
            <Stack direction='row' alignItems='center' spacing={1} paddingY={1}>
              <AddCircle color='primary' fontSize='small' />
              <Typography
                color='primary'
                fontSize={pxToRem(16)}
                onClick={() => setStep(SendCryptoModalStep.AddAddress)}
              >
                Add a new address
              </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </>
    );
  },
);
