import { PostRecurringOrderResponse } from '@shared/api/@types/recurringOrder';
import { StorageKey } from '@shared/storage';

import { action, observable } from 'mobx';

import {
  OrderStatus,
  TradeAssetAction,
  TradeData,
  TradeSide,
  TradeState,
  TradeType,
  TradeUIData,
  TradeVariant,
  UniversalTradeStateType,
  UniversalTradeStoreSchema,
} from './@types/universalTradeTypes';
import {
  createTradeData,
  createTradeUIData,
  updateTradeDataBalances,
  updateTradeDataPercentages,
} from './universalTradeStore.utils';

const initialValues: UniversalTradeStateType = {
  maxTradeValue: '',
  tradeState: TradeState.PlaceOrder,
  tradeType: TradeType.Instantly,
  tradeVariant: TradeVariant.Buy,
  tradeFrom: [],
  tradeTo: [],
  tradeData: {},
  tradeKeys: [],
  tradeUIData: {},
  tradePercentages: {},
  tradeCache: {
    tradeFrom: [],
    tradeTo: [],
  },
  executingOrders: false,
  orderStatus: OrderStatus.Idle,
  showGlobalTrade: false,
};

const store: UniversalTradeStoreSchema = observable({
  /* observables */
  ...initialValues,

  setTradeState: action((state: TradeState) => {
    // If we are resetting we should set deposit details back
    if (state === TradeState.PlaceOrder) store.setDepositDetails(undefined);
    store.tradeState = state;
  }),
  setTradeType: action((type: TradeType) => (store.tradeType = type)),
  setTradeVariant: action((variant: TradeVariant) => (store.tradeVariant = variant)),
  setTradeAssets: action(
    (
      assets: number[],
      side: TradeSide,
      action: TradeAssetAction = TradeAssetAction.Replace,
      pairWith: number[] | undefined = undefined,
    ) => {
      const reverseSide = side === TradeSide.From ? TradeSide.To : TradeSide.From;

      switch (action) {
        case TradeAssetAction.Replace:
          store[side] = assets.filter((asset) => !store[reverseSide].includes(asset));
          break;
        case TradeAssetAction.Add:
          store[side].push(
            ...assets.filter((asset) => !store[side].includes(asset) && !store[reverseSide].includes(asset)),
          );
          break;
        case TradeAssetAction.Remove:
          assets.forEach((asset) => {
            if (store[side].includes(asset)) store[side].splice(store[side].indexOf(asset), 1);
          });
          break;
        default:
          // not possible
          break;
      }

      if (pairWith !== undefined) {
        store[reverseSide] = pairWith.filter((asset) => !store[side].includes(asset));
      }

      const tradeData = createTradeData(store);
      const tradeUIData = createTradeUIData(store);

      store.tradeData = tradeData;
      store.tradeUIData = tradeUIData;
      store.tradeKeys = Object.keys(tradeData);

      if (store.tradeTo.length > 1) {
        store.tradeUIData = updateTradeDataPercentages(store);
        store.tradeData = updateTradeDataBalances(store);
      }
    },
  ),
  setTradeCache: action((assets: number[], side: TradeSide, action: TradeAssetAction = TradeAssetAction.Replace) => {
    switch (action) {
      case TradeAssetAction.Replace:
        store.tradeCache[side] = assets;
        break;
      case TradeAssetAction.Add:
        store.tradeCache[side].push(...assets.filter((asset) => !store.tradeCache[side].includes(asset)));
        break;
      case TradeAssetAction.Remove:
        store.tradeCache[side] = store.tradeCache[side].filter((asset) => !assets.includes(asset));
        break;
      default:
        // not possible
        break;
    }
  }),

  setTradeData: action((tradeFrom: number, tradeTo: number, data: Partial<TradeData>) => {
    const key = `${tradeFrom}_${tradeTo}`;
    store.tradeData[key] = { ...store.tradeData[key], ...data };
  }),

  setTradeUIData: action((tradeFrom: number, tradeTo: number, data: Partial<TradeUIData>, updateBalances = true) => {
    const key = `${tradeFrom}_${tradeTo}`;
    store.tradeUIData[key] = { ...store.tradeUIData[key], ...data };

    // If the data includes a percentage, lets update the per centages
    if (updateBalances && data.percentage !== undefined && store.tradeTo.length > 1) {
      const tradeUIData = updateTradeDataPercentages(store, key, data);
      store.tradeUIData = tradeUIData;

      const tradeData = updateTradeDataBalances(store, key);
      store.tradeData = tradeData;
    }

    return store.tradeUIData[key];
  }),

  setCloseTo: action((closeTo?: any) => (store.closeTo = closeTo)),
  setMaxTradeValue: action((maxTradeValue: string) => {
    store.maxTradeValue = maxTradeValue;
    const tradeUIData = updateTradeDataPercentages(store);
    const tradeData = updateTradeDataBalances(store, undefined, true);
    store.tradeUIData = tradeUIData;
    store.tradeData = tradeData;
  }),
  setExecutingOrders: action((executingOrders: boolean) => {
    store.executingOrders = executingOrders;
  }),
  setShowGlobalTrade: action((showGlobalTrade: boolean) => {
    if (!showGlobalTrade && [OrderStatus.Success, OrderStatus.Error].includes(store.orderStatus)) {
      store.setOrderStatus(OrderStatus.Idle);
      if (store.tradeState === TradeState.OrderSummary) {
        store.setTradeState(TradeState.PlaceOrder);
        store.setTradeAssets([], TradeSide.From, TradeAssetAction.Replace);
        store.setTradeAssets([], TradeSide.To, TradeAssetAction.Replace);
      }
    }

    store.showGlobalTrade = showGlobalTrade;
    localStorage.setItem(StorageKey.SHOW_UNIVERSAL_TRADE_PANEL, String(showGlobalTrade));
  }),
  setOrderStatus: action((orderStatus: OrderStatus) => {
    store.orderStatus = orderStatus;
  }),
  setMultiTradeError: action((error: string) => {
    store.multiTradeError = error;
  }),
  setDepositDetails: action((depositDetails?: PostRecurringOrderResponse) => {
    store.depositDetails = depositDetails;
  }),
  cleanup: action(() => {
    store.maxTradeValue = initialValues.maxTradeValue;
    store.tradeState = initialValues.tradeState;
    store.tradeType = initialValues.tradeType;
    store.tradeFrom = initialValues.tradeFrom;
    store.tradeTo = initialValues.tradeTo;
    store.tradeData = initialValues.tradeData;
    store.tradeKeys = initialValues.tradeKeys;
    store.tradeUIData = initialValues.tradeUIData;
    store.tradePercentages = initialValues.tradePercentages;
    store.tradeCache = initialValues.tradeCache;
    store.executingOrders = initialValues.executingOrders;
    store.depositDetails = initialValues.depositDetails;
  }),
});

export default store;
