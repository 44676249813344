import React from 'react';

import { Select } from '@swyftx/aviary/atoms/Select';
import { Switch } from '@swyftx/aviary/atoms/Switch';
import { DarkMode } from '@swyftx/aviary/icons/outlined';
import { MenuItem } from '@swyftx/aviary/molecules/Menu';

import { DarkModeEnum } from '@shared/enums';

import { observer } from 'mobx-react-lite';
import { AppFeature, useIsFeatureEnabled } from 'src/config';
import { useToggleTheme } from 'src/lib/utils/hooks';

type Props = {
  renderOnlyElement?: boolean;
};

const SwyftxThemeSelector: React.FC<Props> = observer(({ renderOnlyElement }) => {
  const { darkMode, isDarkMode, setDarkMode, toggleDarkMode } = useToggleTheme();
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const midnightTheme = isFeatureEnabled(AppFeature.MidnightTheme);

  if (renderOnlyElement) {
    return midnightTheme ? (
      <div className='w-[7rem]'>
        <Select
          value={darkMode}
          items={[
            { value: DarkModeEnum.LIGHT, label: 'Light' },
            { value: DarkModeEnum.DARK, label: 'Dark' },
            { value: DarkModeEnum.MIDNIGHT, label: 'Midnight' },
          ]}
          onValueChange={(val) => setDarkMode(val as DarkModeEnum)}
        />
      </div>
    ) : (
      <Switch checked={isDarkMode} data-transition-force onCheckedChange={toggleDarkMode} />
    );
  }

  return midnightTheme ? (
    <MenuItem
      icon={<DarkMode width={24} height={24} className='text-color-text-primary' />}
      text='Theme'
      endAdornment={
        <div className='w-[7rem]'>
          <Select
            value={darkMode}
            items={[
              { value: DarkModeEnum.LIGHT, label: 'Light' },
              { value: DarkModeEnum.DARK, label: 'Dark' },
              { value: DarkModeEnum.MIDNIGHT, label: 'Midnight' },
            ]}
            onValueChange={(val) => setDarkMode(val as DarkModeEnum)}
          />
        </div>
      }
      preventCloseOnSelect
    />
  ) : (
    <MenuItem
      icon={<DarkMode width={24} height={24} className='text-color-text-primary' />}
      text='Dark mode'
      endAdornment={<Switch checked={isDarkMode} data-transition-force onCheckedChange={toggleDarkMode} />}
      action={() => toggleDarkMode(!isDarkMode)}
      preventCloseOnSelect
    />
  );
});

export { SwyftxThemeSelector };
