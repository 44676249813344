import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Notification } from '@swyftx/aviary/atoms/Notification';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { TickInCircleFilled } from '@swyftx/aviary/icons/filled';
import { TickInCircle } from '@swyftx/aviary/icons/outlined';
import { SettingsCluster } from '@swyftx/aviary/molecules/SettingsCluster';
import { SettingsItem } from '@swyftx/aviary/molecules/SettingsItem';

import entityService from '@shared/services/entityService';

import { observer } from 'mobx-react-lite';

import { useEntityVerification } from './useEntityVerification.hooks';

export const ProfileEntityVerification: React.FC = observer(() => {
  const { t } = useTranslation('profile');
  const { steps } = useEntityVerification();

  const isVerified = useMemo(() => entityService.isEntityVerified(), []);

  if (!isVerified) {
    return (
      <Notification severity='info' title={t('entities.entityVerification.notificationBox.title')}>
        <Body>{t('entities.entityVerification.notificationBox.content')}</Body>
      </Notification>
    );
  }

  return (
    <SettingsCluster
      items={steps.map(({ id, completed, title, content }) => (
        <SettingsItem
          icon={completed ? <TickInCircleFilled /> : <TickInCircle />}
          title={title}
          description={content}
          key={id}
        />
      ))}
      title={t('entities.entityVerification.section.title')}
    />
  );
});
