import React, { PropsWithChildren, useState } from 'react';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Notification } from '@swyftx/aviary/atoms/Notification';

import { RecurringOrder, RecurringOrderStatus } from '@shared/api/@types/recurringOrder';
import { RecurringOrderSource } from '@shared/enums';

import { useEditAutoInvestModal } from './useEditAutoInvestModal';
import { useEditAutoInvestment } from '../../hooks';
import { AutoInvestReviewContent } from '../../widgets/CreateAutoInvestWidget/components';
import { AutoInvestAssetAllocation } from '../AutoInvestAssetAllocation';
import { AutoInvestAmount, AutoInvestDateFrequency, AutoInvestOrderName } from '../AutoInvestPreferences';
import { AutoInvestFundWith } from '../AutoInvestPreferences/AutoInvestFundWith';

type Props = {
  autoInvestment: RecurringOrder;
  position?: 'center' | 'bottom';
};

const EditAutoInvestModal: React.FC<PropsWithChildren<Props>> = ({ autoInvestment, position = 'center', children }) => {
  const [open, setOpen] = useState<boolean>();

  const {
    orderName,
    setOrderName,
    feeAmount,
    lowestFeeAmount,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    startDate,
    setStartDate,
    frequency,
    setFrequency,
    amount,
    setAmount,
    minimumOrderAmount,
    updating,
    resetValues,
    autoInvestAssets,
    validEditOrder,
    parseTemplate,
    onLock,
    onRemove,
    onAddAsset,
    onUpdatePercentage,
    onUpdateAsset,
    updateRecurringOrder,
    showBalanceWarning,
    diffDayText,
    diffDays,
    dirty,
  } = useEditAutoInvestment({
    autoInvestment,
    setOpen,
  });

  const { footer, header, state } = useEditAutoInvestModal({
    open,
    updating,
    position,
    resetValues,
    parseTemplate,
    validEditOrder,
    dirty,
    setOpen,
    updateRecurringOrder,
  });

  return (
    <Modal
      triggerElement={children}
      open={open}
      onOpenChange={(o) => o && setOpen(o)}
      position={position}
      className='h-[94vh] sm:h-auto sm:!min-w-[500px] sm:!max-w-[500px]'
    >
      <FlexLayout direction='column' justifyContent='space-between' className='h-full'>
        <FlexLayout direction='column'>
          {header}
          {state === 'edit' && (
            <FlexLayout direction='column' spacing={24} className='px-16 sm:px-24'>
              <AutoInvestOrderName value={orderName} onChange={setOrderName} />
              <AutoInvestFundWith
                value={selectedPaymentMethod}
                onChange={setSelectedPaymentMethod}
                disabled={autoInvestment.status === RecurringOrderStatus.Paused}
              />
              {selectedPaymentMethod === RecurringOrderSource.ACCOUNT && (
                <>
                  <AutoInvestDateFrequency
                    startDate={startDate}
                    onStartDateChange={setStartDate}
                    frequency={frequency}
                    onFrequencyChange={setFrequency}
                  />
                  <AutoInvestAmount value={amount} onChange={setAmount} min={minimumOrderAmount} />
                </>
              )}
              <AutoInvestAssetAllocation
                assets={autoInvestAssets}
                onLock={onLock}
                onRemove={onRemove}
                onUpdatePercentage={onUpdatePercentage}
                onUpdateAsset={onUpdateAsset}
                onAddNewAsset={onAddAsset}
                isEdit
              />
            </FlexLayout>
          )}
          {state === 'review' && (
            <FlexLayout direction='column' className='p-24' spacing={24}>
              <AutoInvestReviewContent
                orderName={orderName}
                frequency={frequency}
                amount={amount}
                selectedAutoInvestAssets={autoInvestAssets}
                selectedPaymentMethod={selectedPaymentMethod}
                diffDays={diffDays}
                feeAmount={feeAmount}
                startDate={startDate}
                lowestFeeAmount={lowestFeeAmount}
                diffDayText={diffDayText}
                showBalanceWarning={showBalanceWarning}
                showDepositMessage={false}
              />
              {selectedPaymentMethod === 'ACCOUNT' && autoInvestment.source === RecurringOrderSource.DEPOSIT && (
                <Notification
                  severity='warning'
                  title='Additional action required'
                  content='Remember to also delete any recurring bank transfers set up with your bank in relation to this order. Future transfers received after order deletion will be credited to your account.'
                />
              )}
            </FlexLayout>
          )}
        </FlexLayout>
        {footer}
      </FlexLayout>
    </Modal>
  );
};

export { EditAutoInvestModal };
