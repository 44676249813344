/* eslint-disable camelcase */

import React from 'react';

import { CountriesEnum, EntityTypeEnum, GreenIdStatusEnum, KycStatusEnum } from '@shared/enums';

import { DashboardLayoutKeys } from 'src/lib/dashboard/types';
import { DashboardTileEnum } from 'src/shared/enums';

export interface UserProfile {
  auth?: Auth;
  profile: Profile;
}

export interface ProfileResponse {
  profile: Profile;
}

export interface GetProfileResponse {
  user: ProfileResponse;
}

export interface MFARequired {
  mfa: {
    required: true;
    token: string;
    challenge_type: string;
    oob_code?: string;
  };
}

export type OTPChallengeType = 'sms' | 'email';

export interface OTPRequired {
  otp: {
    required: true;
    challengeType: OTPChallengeType;
    reason?: string;
  };
}

export interface OTPSMSFallbackParams {
  otp: {
    email: string;
    password: string;
  };
}

export interface OTPParams {
  otp: {
    email: string;
    password: string;
    challengeType: OTPChallengeType;
    code: string;
  };
}

export interface MFAEnrolment {
  mfa_enrollment: string;
}

export interface Auth {
  access_token: string;
  scope: string;
  expires_in: number;
  token_type?: string;
  id_token?: string;
  recovery_code?: string;
}

export interface Name {
  first?: string;
  last?: string;
  entity?: string;
}

export interface Address {
  unit?: any;
  streetNum?: any;
  streetName?: any;
  streetType?: any;
  suburb?: any;
  state?: any;
  postcode?: any;
  country: CountriesEnum;
}

export interface AccountStatus {
  withdrawals: 1 | 0;
  deposits: 1 | 0;
  tradeOnBehalf: 1 | 0;
  otcInvited: 1 | 0;
}

export interface Currency {
  id: number;
  code: string;
}

export interface CountryCurrency {
  id: number;
  code: string;
  name: string;
  assetType: number;
  primary: number;
  secondary: number;
  deposit_enabled: number;
  withdraw_enabled: number;
  min_deposit: number;
  min_withdrawal: number;
  mining_fee: number;
  tradable: number;
  min_confirmations?: any;
  price_scale: number;
  minimum_order_increment?: any;
  minimum_order: number;
  minWithdrawalIncrementC: number;
  minWithdrawalIncrementE: number;
  subAssetOf?: any;
  contract?: any;
  assetDepositScale?: any;
  delisting: number;
  buyDisabled: number;
}

export interface Intercom {
  user_hash: string;
  intercomEncryptedPayload: string;
  uuid: string;
}

export interface Metadata {
  mfa_enabled: boolean;
  mfa_enrolled?: any;
  featureOptIn: string[];
  cryptoRequiresKyc1: boolean;
  passwordResetRequired: boolean;
}

export interface FavouriteAssets {
  [key: string]: boolean;
}

export type DashboardTileSize = 'compact' | 'half' | 'wide' | 'full';

export type DashboardTileMetadata = {
  size?: DashboardTileSize;
  offset?: boolean;
  webIndex?: number;
};

export type DashboardTile = {
  name: DashboardTileEnum;
  display: boolean;
  metadata?: DashboardTileMetadata;
};

export interface DashboardLayoutData {
  layoutKey?: DashboardLayoutKeys;
  metadata: { [key: string]: any };
}

export interface DashboardSettings {
  tiles?: DashboardTile[];
  layout?: DashboardLayoutData;
}

export interface UserSettings {
  favouriteAssets: FavouriteAssets;
  analyticsOptOut?: boolean;
  activeAffil: boolean;
  legacyAffil?: number;
  disableSMSRecovery?: any;
  hideHelperTooltips?: any;
  dashboardSettings?: DashboardSettings;
  profitOrLossDisplay: string;
  abn?: any;
  gstRegistered?: any;
  signupBonus: SignupBonus;
  applyForEntityAccount?: boolean;
}

export interface EntityDetails {
  entityName: string;
  ABN?: any;
  entityType: EntityTypeEnum;
}

export interface Greenid {
  id?: any;
  token?: any;
  status: GreenIdStatusEnum;
}

export interface Verification {
  email: number;
  phone: number;
  id: boolean;
  greenid: Greenid;
}

export interface Kyc {
  KYC0: KycStatusEnum;
  KYC1: KycStatusEnum;
  KYC2: KycStatusEnum;
}

export interface Profile {
  name: Name;
  email: string;
  phone?: string;
  address?: Address;
  accountStatus?: AccountStatus;
  currency: Currency;
  countryCurrency?: CountryCurrency;
  minimumOrderAmount?: number;
  banxaConsentedAt?: any;
  currentFeeTier?: string | null;
  proEnabled: boolean;
  intercom?: Intercom;
  metadata?: Metadata;
  userSettings: UserSettings;
  entityDetails?: EntityDetails;
  verification?: Verification;
  kyc?: Kyc;
}

export interface LoginParams {
  login: {
    email: string;
    password: string;
    fingerprint?: string;
  };
}

export interface ReCaptchaHeaders {
  captcha?: string;
}

export interface ResetPasswordParams {
  resetPassword: {
    email: string;
  };
  captcha?: string;
}

export interface PasswordlessOTPParams {
  phone?: string;
  email?: string;
  code?: string;
}

export const UTM_TAGS = ['utm_campaign', 'utm_source', 'utm_medium', 'campaign'] as const;

export type UTMTag = (typeof UTM_TAGS)[number];

export type SignUpMetaParams = { [key in UTMTag]?: string };

export interface SignUpParams {
  signup: {
    email: string;
    phone: string;
    password: string;
    name: {
      first: string;
      last: string;
    };
    otp: {
      email: string;
      phone: string;
    };
    country: CountriesEnum;
    referralCode?: string;
    promoRef?: string;
    applyForEntityAccount?: boolean;
  };
  meta?: SignUpMetaParams;
}

export interface ChangePasswordParams {
  changePassword: {
    oldPassword: string;
    newPassword: string;
  };
  auth?: string;
}

export interface ChangePasswordResponse {
  mfaType?: 'email' | 'sms';
  token?: string;
}

export interface CompleteResetPasswordParams {
  resetToken: string;
  newPassword: string;
}

export interface MfaParams {
  mfa: {
    token: string;
    challenge_type: string;
    otp?: string;
    binding_code?: string;
    recovery_code?: string;
    oob_code?: string;
  };
}

export interface MfaLostAuthParams {
  mfaToken: string;
}

export interface RefreshAccessTokenParams {
  apiKey: string;
}

export interface RefreshAccessTokenResponse {
  accessToken: string;
  scope: string;
}

export interface SetupMFAEnrolmentParams {
  mfaTicket: string;
}

export interface CancelMFAEnrolmentResponse {
  success: boolean;
}

export interface CompleteMFAEnrolmentParams {
  otp: string;
  mfaToken: string;
}

export interface SetupMFAEnrolmentResponse {
  secret: string;
  recoveryCode: string;
  mfaToken: string;
}

export interface MessageTile {
  title: string;
  content: string;
  icon: React.ReactNode;
}

export interface SuperheroLoginResponse {
  auth: {
    access_token: string;
    id_token: string;
    scope: string;
    expires_in: number;
    token_type: string;
  };
  linked: boolean;
}

export type RequestPhoneVerificationPinResponse = {
  msg: string;
  phoneVerified: boolean;
  success: boolean;
};

export interface SignupBonus {
  expires: number;
  type: string;
  state: 'pending' | 'expired' | 'redeemed';
  metadata: {
    asestToCreditId: number;
    assetValueId: number;
    assetValue: number;
    referralCode: string;
  };
}
