import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MoreHoriz } from '@mui/icons-material';

import { IconButton, Menu, MenuItem, Stack, Typography } from '@swyftx/react-web-design-system';

import { Modals } from '@global-components/Modals/Modals.enum';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { api, AssetHistoryItemStatus } from '@shared/api';
import { SwyftxError } from '@shared/error-handler';
import { assetService } from '@shared/services';
import { UIStore } from '@shared/store';

import { EntityPermissions, usePermissions } from '@hooks/Permissions';
import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { observer } from 'mobx-react-lite';

import { OrdersTableColumnProps } from './columns.data';

const ICON_SIZE = 14;

const OrdersTableActions: React.FC<OrdersTableColumnProps> = observer(({ item, width }) => {
  const { forceUpdateOrders } = useContext(TransactionsContext);
  const { addToastMessage } = UIStore.useUIStore;
  const { t } = useTranslation('orders', { keyPrefix: 'ordersTable' });
  const { openModal } = useModal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadUserOrder = async (orderId: string) => {
    try {
      const res = await api.endpoints.downloadOrderInvoice({
        data: {
          orderIds: [orderId],
        },
      });

      const uInt8 = new Uint8Array(res.data);
      const blob = new Blob([uInt8], { type: res.headers['content-type'] });

      const name = t('invoiceDownloadName', { orderId });

      // on browser download file use a "clicked" <a/> object
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 0);
    } catch (e) {
      const swyftxError: SwyftxError = e as SwyftxError;
      addToastMessage({ severity: 'error', message: swyftxError.errorMessage });
    }
  };

  const handleDownloadOrder = () => {
    downloadUserOrder(item.uuid);
    handleClose();
  };

  const handleCancelOrder = () => {
    openModal(Modals.OrderDetails, { orderId: item.uuid, deleteModal: true });
    handleClose();
  };

  const handleEditOrder = () => {
    openModal(Modals.EditTriggerOrders, { order: item, onSuccess: forceUpdateOrders });
    handleClose();
  };

  const handleOpenOrderDetails = () => {
    if (item.orderType) {
      openModal(Modals.OrderDetails, { orderId: item.uuid, transaction: item, onEditSuccess: forceUpdateOrders });
    } else {
      const asset = assetService.getAsset(item.secondaryAsset);
      if (asset) openModal(Modals.TransactionDetails, { transaction: item, asset });
    }

    handleClose();
  };

  const { element: cancelButton } = usePermissions({
    permissions: [EntityPermissions.ORDERS_DELETE],
    enabledElement: (
      <MenuItem key='cancel-order'>
        <Typography color='error' onClick={handleCancelOrder}>
          {t('actionLabels.cancelOrder')}
        </Typography>
      </MenuItem>
    ),
    disabledOptions: { disabled: true, tooltip: true, invalidScopeLabel: t('tooltips.noCancelPermission') },
  });

  const { element: editButton } = usePermissions({
    permissions: [EntityPermissions.ORDERS_CREATE],
    enabledElement: (
      <MenuItem key='edit-order' onClick={handleEditOrder}>
        <Typography>{t('actionLabels.editOrder')}</Typography>
      </MenuItem>
    ),
    disabledOptions: { disabled: true, tooltip: true, invalidScopeLabel: t('tooltips.noEditPermission') },
  });

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      flex={1}
      width={width ? `${width}px` : ''}
      minWidth={width ? `${width}px` : ''}
      maxWidth={width ? `${width}px` : ''}
    >
      <IconButton size='small' onClick={handleClick}>
        <MoreHoriz id={`orders-more-${item.uuid}`} width={ICON_SIZE} height={ICON_SIZE} />
      </IconButton>
      <Menu id={`orders-menu-${item.uuid}`} open={open} onClose={handleClose} anchorEl={anchorEl}>
        {[
          <MenuItem key='order-details' onClick={handleOpenOrderDetails}>
            <Typography>{t('actionLabels.transactionDetails')}</Typography>
          </MenuItem>,
          ...(item.orderType && item.status === AssetHistoryItemStatus.Completed
            ? [
                <MenuItem key='download-order' onClick={handleDownloadOrder}>
                  <Typography>{t('actionLabels.downloadOrder')}</Typography>
                </MenuItem>,
              ]
            : []),
          ...(item.orderType && item.status === AssetHistoryItemStatus.Pending ? [editButton, cancelButton] : []),
        ]}
      </Menu>
    </Stack>
  );
});

OrdersTableActions.displayName = 'OrdersTableActions';

export { OrdersTableActions };
