import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { Stack } from '@swyftx/react-web-design-system';

import { ContentDivider } from '@global-components/ContentDivider';
import { NoResults } from '@global-components/NoResults/NoResults';
import { VirtualizedListSkeleton } from '@global-components/VirtualizedTable/VirtualizedListSkeleton';
import { Column } from '@global-components/VirtualizedTable/VirtualizedTable.types';

import { Big } from '@shared/safe-big';
import { TransactionHistoryItem } from '@shared/services';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';
import { TransactionsRouteType } from '@routes/Transactions/types';

import { observer } from 'mobx-react-lite';

import {
  ORDERS_TABLE_FILTER_HEIGHT,
  ORDERS_TABLE_HEADER_HEIGHT,
  ORDERS_TABLE_TAB_HEIGHT,
} from './OrdersTable.settings';
import { OrdersTableHeader } from './OrdersTableHeader';
import { OrdersTableItemDesktop } from './OrdersTableItem.desktop';

type Props = {
  items: TransactionHistoryItem[];
  columns: Column[];
  height: number;
  width: number;
  loading?: boolean;
  type?: TransactionsRouteType;
};

const OrdersTableDesktop: React.FC<Props> = observer(({ items, height, width, columns, loading, type }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { incrementPage, fetching } = useContext(TransactionsContext);
  const { t } = useTranslation('orders');
  const [widthPerValueColumn, setWidthPerValueColumn] = useState<number>(0);

  const totalValueColumns = columns.filter((c) => !c.hidden).length - 3;

  useEffect(() => {
    setWidthPerValueColumn(Big(width).minus(250).minus(80).minus(80).minus(8).div(totalValueColumns).toNumber());
  }, [width, totalValueColumns]);

  const onScroll = useCallback(
    (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (Big(scrollHeight).minus(scrollTop).minus(clientHeight).lte(120)) {
        incrementPage();
      }
    },
    [incrementPage],
  );

  return (
    <>
      <Stack position='relative'>
        <Stack
          height={ORDERS_TABLE_HEADER_HEIGHT}
          justifyContent='flex-start'
          alignItems='center'
          direction='row'
          width='100%'
        >
          {columns.map((column) => (
            <OrdersTableHeader key={column.label} column={column} widthOverride={widthPerValueColumn} type={type} />
          ))}
        </Stack>
        <ContentDivider loading={loading} placement='bottom' />
      </Stack>
      <Box
        height={height - (ORDERS_TABLE_HEADER_HEIGHT + ORDERS_TABLE_FILTER_HEIGHT + ORDERS_TABLE_TAB_HEIGHT)}
        sx={{ overflowY: 'auto' }}
        onScroll={onScroll}
        ref={containerRef}
        id='ordersTable.items.container'
      >
        {items.map((item) => (
          <OrdersTableItemDesktop key={item.uuid} item={item} widthPerValueColumn={widthPerValueColumn} type={type} />
        ))}

        {!items.length && !loading && (
          <NoResults title={t(`empty.${type}.title` as any)} subTitle={t(`empty.${type}.subTitle` as any)} />
        )}
        {fetching && <VirtualizedListSkeleton num={8} />}
      </Box>
    </>
  );
});

OrdersTableDesktop.displayName = 'OrdersTableDesktop';

export { OrdersTableDesktop };
