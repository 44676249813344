import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Notification, Stack } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useTransferAssets } from '@hooks/Assets/useTransferAssets';

import { Deposit } from './Deposit/Deposit';
import { DepositReceiveProps } from './Deposit/Deposit.types';
import { Receive } from './Receive/Receive';
import { TransferEnum } from '../../TransferModal.types';
import { TransferModalAssetPicker } from '../../components/TransferModalAssetPicker';

export const TransferDepositReceive: React.FC<DepositReceiveProps> = ({
  initialAsset,
  initialDepositType,
  setDisabled,
  setOnAction,
  onClose,
  setHideContinue,
}) => {
  const { assets } = useTransferAssets(TransferEnum.DepositReceive);
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(initialAsset || null);
  const [minimumDepositWarning, setMinimumDepositWarning] = useState<string>();
  const { t } = useTranslation('modals', { keyPrefix: 'transferModal.deposit' });
  const { userProfile } = UserStore.useUserStore;
  const userDepositsDisabled = useCallback(() => userProfile?.accountStatus?.deposits === 0, [userProfile]);

  const isFiat = useMemo(() => assetService.isAssetFiat(selectedAsset?.id), [selectedAsset]);

  useEffect(() => {
    if (selectedAsset?.customWarningText) {
      setMinimumDepositWarning(selectedAsset.customWarningText.replace('<b>', '').replace('</b>', ''));
    } else {
      setMinimumDepositWarning('');
    }
  }, [selectedAsset]);

  if (userDepositsDisabled()) {
    return (
      <Stack spacing={3}>
        <Notification
          severity='error'
          sx={{
            '.MuiAlertTitle-root': {
              fontSize: 14,
              fontWeight: 600,
              color: 'error',
            },
          }}
          title={t('userDepositsDisabled.title')}
        >
          <Stack direction='column'>
            {t('userDepositsDisabled.message')}
            <Button color='error' className='intercom-trigger-bot-deposits-disabled' sx={{ width: '30%' }}>
              Contact support
            </Button>
          </Stack>
        </Notification>
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      <TransferModalAssetPicker
        id='deposit-receive-asset-picker'
        setSelectedAsset={setSelectedAsset}
        assets={assets}
        initialAsset={initialAsset}
        direction='deposit'
      />

      {minimumDepositWarning && (
        <Notification severity='warning' title='Minimum deposit'>
          {minimumDepositWarning}
        </Notification>
      )}

      {selectedAsset && !selectedAsset.deposit_enabled && (
        <Notification title={t('depositDisabledTitle')} severity='error'>
          {t('depositDisabled')}
        </Notification>
      )}

      {selectedAsset && !!selectedAsset.deposit_enabled && (
        <>
          {isFiat && (
            <Deposit
              asset={selectedAsset}
              initialDepositType={initialDepositType}
              setDisabled={setDisabled}
              setOnAction={setOnAction}
              onClose={onClose}
              setHideContinue={setHideContinue}
            />
          )}
          {!isFiat && <Receive asset={selectedAsset} />}
        </>
      )}
    </Stack>
  );
};
