import React, { useCallback, useState, useContext } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { Card } from '@swyftx/aviary/atoms/Card';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { List, ListItem } from '@swyftx/aviary/atoms/List';
import { Modal } from '@swyftx/aviary/atoms/Modal';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { StarFilled } from '@swyftx/aviary/icons/filled';
import { ArrowChevronDown, ArrowChevronUp, Star } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as Popover from '@radix-ui/react-popover';
import { ResolutionString } from 'public/assets/charting_library/charting_library';
import { useFullScreenElement } from 'src/lib/utils/hooks/useFullScreenElement';

import { ResolutionItem, useTradingViewResolution } from './useTradingViewResolution';
import { TradingViewContext } from '../../../context/TradingView.context';
import { useTradingViewAnalytics } from '../../../hooks';

const TradingViewWidgetHeaderResolution: React.FC = () => {
  const { primary, secondary } = useContext(TradingViewContext);
  const { items, selectedItem, setResolution, toggleFavouriteItem } = useTradingViewResolution();
  const [open, setOpen] = useState<boolean>(false);
  const isXs = useTailwindBreakpoint('xs');
  const { changedChartTimeframe } = useTradingViewAnalytics({ primary, secondary });
  const { element } = useFullScreenElement();

  const onFavouriteResolution = useCallback(
    (item: ResolutionItem, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      toggleFavouriteItem(item);
    },
    [toggleFavouriteItem],
  );

  const handleResolutionChange = useCallback(
    (value: ResolutionString) => {
      changedChartTimeframe('TradingView', value);
      setResolution(value);
    },
    [changedChartTimeframe, setResolution],
  );

  const renderItems = useCallback(
    () =>
      items.map((item) => {
        const selected = item.value === selectedItem?.value;

        return (
          <ListItem
            key={item.value}
            className={cn('px-8 py-4', selected ? 'bg-color-background-surface-selected text-color-text-selected' : '')}
            onClick={() => {
              handleResolutionChange(item.value);
              setOpen(false);
            }}
          >
            <FlexLayout alignItems='center' justifyContent='space-between' className='w-full'>
              <Body size='small' color='primary'>
                {item.text}
              </Body>
              <Button
                variant='ghost'
                layout='icon'
                leadingIcon={item.favourite ? <StarFilled className='text-color-text-warning' /> : <Star />}
                onClick={(e) => onFavouriteResolution(item, e)}
              ></Button>
            </FlexLayout>
          </ListItem>
        );
      }),
    [handleResolutionChange, items, onFavouriteResolution, selectedItem?.value],
  );

  return (
    <>
      {open && isXs && (
        <Modal
          position='bottom'
          open={open}
          onOpenChange={(o) => !o && setOpen(o || false)}
          title='Chart timeframe'
          container={element}
        >
          <List>{renderItems()}</List>
        </Modal>
      )}

      <FlexLayout spacing={8} alignItems='center'>
        <Body size='small' weight='emphasis'>
          Time
        </Body>

        {items.map((item) => {
          if (!item.favourite) return null;

          const selected = item.value === selectedItem?.value;

          return (
            <Button
              key={item.value}
              className={cn(
                'rounded-full h-24 w-24',
                selected ? 'bg-color-background-surface-selected text-color-text-selected' : '',
              )}
              size='sm'
              variant='ghost'
              onClick={() => handleResolutionChange(item.value)}
            >
              {item.text}
            </Button>
          );
        })}

        <Popover.Root open={open} onOpenChange={(o) => !o && setOpen(o)} modal>
          <Popover.Trigger asChild>
            <Button
              variant={open ? 'filled' : 'ghost'}
              size='sm'
              className={
                !selectedItem?.favourite ? 'bg-color-background-surface-selected text-color-text-selected' : ''
              }
              layout={selectedItem?.favourite ? 'icon' : 'default'}
              trailingIcon={open ? <ArrowChevronUp /> : <ArrowChevronDown />}
              onClick={() => setOpen(true)}
            >
              {selectedItem?.favourite ? '' : selectedItem?.text}
            </Button>
          </Popover.Trigger>
          {!isXs && (
            <Popover.Portal container={element}>
              <>
                <Popover.Content asChild sideOffset={16} align='start'>
                  <Card className='h-[20rem] w-[15rem] p-8 pr-8'>
                    <List>{renderItems()}</List>
                  </Card>
                </Popover.Content>
              </>
            </Popover.Portal>
          )}
        </Popover.Root>
      </FlexLayout>
    </>
  );
};

export { TradingViewWidgetHeaderResolution };
