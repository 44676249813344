import React, { useEffect, useMemo, useState } from 'react';

import { Stack, Notification } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api';
import { DepositMethodEnum } from '@shared/enums';

import { useDeposit } from '@hooks/Deposit/useDeposit';
import { useTheme } from '@hooks/useTheme';

import { DepositReceiveProps } from './Deposit.types';
import { DepositBankTransfer } from './TransferTypes/BankTransfer';
import { DepositCreditCardTransfer } from './TransferTypes/CreditCard';
import { DepositPayIdTransfer } from './TransferTypes/PayId';
import { DepositFaqAccordion } from './components/DepositFaqAccordion';
import { DepositTabs } from './components/DepositTabs';

type Props = {
  asset: Asset;
  initialDepositType?: DepositMethodEnum;
} & DepositReceiveProps;

export const Deposit: React.FC<Props> = ({
  asset,
  setDisabled,
  setOnAction,
  onClose,
  setHideContinue,
  initialDepositType,
}) => {
  const { theme } = useTheme();
  const [transferType, setTransferType] = useState(
    asset.code === 'NZD' ? DepositMethodEnum.BANXA : DepositMethodEnum.NZD_BANK_TRANSFER,
  );

  const { depositDescription } = useDeposit({ asset, transferType, fetchAddressDetails: false });

  useEffect(() => {
    if (initialDepositType) setTransferType(initialDepositType);
  }, [initialDepositType]);

  useEffect(() => {
    setDisabled(true);

    // hide continue step for these deposit methods
    if (setHideContinue) {
      if (
        transferType === DepositMethodEnum.NZD_BANK_TRANSFER ||
        transferType === DepositMethodEnum.MONOOVA_DIRECT_CREDIT ||
        transferType === DepositMethodEnum.MONOOVA_PAYID
      ) {
        setHideContinue(true);
      } else {
        setHideContinue(false);
      }
    }
  }, [setDisabled, setHideContinue, transferType]);

  const content = useMemo(() => {
    switch (transferType) {
      case DepositMethodEnum.MONOOVA_PAYID:
        return <DepositPayIdTransfer asset={asset} />;
      case DepositMethodEnum.BANXA:
        return (
          <DepositCreditCardTransfer
            asset={asset}
            setDisabled={setDisabled}
            setOnAction={setOnAction}
            onClose={onClose}
          />
        );
      case DepositMethodEnum.MONOOVA_DIRECT_CREDIT:
      case DepositMethodEnum.NZD_BANK_TRANSFER:
      default:
        return <DepositBankTransfer asset={asset} />;
    }
  }, [asset, onClose, setDisabled, setOnAction, transferType]);

  return (
    <Stack
      spacing={2}
      sx={{
        '.MuiAccordion-root::before': {
          backgroundColor: 'initial !important',
        },
      }}
    >
      {(transferType === DepositMethodEnum.MONOOVA_PAYID ||
        transferType === DepositMethodEnum.MONOOVA_DIRECT_CREDIT ||
        transferType === DepositMethodEnum.NZD_BANK_TRANSFER) && (
        <Notification title='You may experience delays from your bank' severity='warning'>
          Deposits may be subject to a temporary, 24hr hold for the purpose of conducting additional compliance checks
          by your banking institution.
        </Notification>
      )}

      <Notification title='Deposits must be in the same name as your Swyftx account.' severity='info'>
        Deposits made from a different name may be delayed.
      </Notification>

      <Stack borderRadius='8px' overflow='auto' border={`1px solid ${theme.palette.divider}`}>
        <DepositTabs transferType={transferType} setTransferType={setTransferType} asset={asset} />

        {content}
      </Stack>

      <DepositFaqAccordion depositDescription={depositDescription} />
    </Stack>
  );
};
