import React, { PropsWithChildren, useEffect, useState } from 'react';

import { Theme, ThemeProvider } from '@mui/material/styles';

import { Themes } from '@swyftx/react-web-design-system';

import { RefreshDarkTheme, RefreshLightTheme, RefreshMidnightTheme } from '@components/old/themes/refresh';

import { DarkModeEnum } from '@shared/enums';
import { ThemeStore } from '@shared/store';

import { observer } from 'mobx-react-lite';

const DynamicTheme: React.FC<PropsWithChildren<unknown>> = observer(({ children }) => {
  const { theme, darkMode } = ThemeStore.useThemeStore;

  const [refreshTheme, setRefreshTheme] = useState<Theme | undefined>(undefined);

  useEffect(() => {
    if (darkMode === DarkModeEnum.MIDNIGHT) {
      setRefreshTheme(RefreshMidnightTheme);
    } else if (darkMode === DarkModeEnum.DARK) {
      setRefreshTheme(RefreshDarkTheme);
    } else {
      setRefreshTheme(RefreshLightTheme);
    }
  }, [darkMode]);

  return <ThemeProvider theme={refreshTheme ?? Themes[darkMode][theme]}>{children}</ThemeProvider>;
});

export { DynamicTheme };
