/* eslint-disable react/no-danger */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { TourGuideContext } from './TourGuide.context';
import { getTourPosition, removeSpotlightElement } from './TourGuide.utils';
import { Tour } from '../Tour/Tour';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const useTourGuide = () => {
  const { stopTour, tour, tourStarted, curStep, setCurStep } = useContext(TourGuideContext);
  const [element, setElement] = useState<React.ReactElement | null>(null);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [targetRect, setTargetRect] = useState<DOMRect>();
  const [borderRadius, setBorderRadius] = useState<number>();

  const resetTour = useCallback(
    (end?: boolean) => {
      tour?.steps.forEach((s, index) => removeSpotlightElement(index));
      stopTour(tourStarted && end);
      setElement(null);
      setCurStep(0);
    },
    [setCurStep, stopTour, tour?.steps, tourStarted],
  );

  const onNextStep = useCallback(() => {
    if (!tour) return;

    const step = tour.steps[curStep];
    if (step.onNext) step.onNext();

    removeSpotlightElement(curStep);
    setElement(null);
    setCurStep((prev) => prev + 1);
  }, [curStep, setCurStep, tour]);

  useEffect(() => {
    const processStep = async () => {
      const step = tour?.steps[curStep];

      // If we are trying to process a step but:
      // The tour is in a not started state
      // The tour object is null
      // The curStep has returned an empty step
      // Or there hasn't been a spotLightElementId set which is required to highlight the step
      //
      // Then we should reset/stop the tour
      if (!tourStarted || !tour || !step || !step.spotlightElementId) {
        return resetTour(!step);
      }

      await delay(step.delay || 0);

      const targetElement = document.querySelector(
        `[data-spotlightelementid="${step.spotlightElementId}"]`,
      ) as HTMLElement;
      if (!targetElement) return resetTour();

      if (tour.variant === 'spotlight' && !tour.showBackdrop) {
        targetElement.focus();
      } else {
        setTargetRect(targetElement.getBoundingClientRect());
        setBorderRadius(step.borderRadius);
      }
      const tourPosition = getTourPosition(step.alignment, targetElement, tour.variant || 'tour');
      let finalTextButton = tour.steps.length > 1 ? 'Finish' : 'Ok';
      if (tour.variant === 'spotlight') finalTextButton = 'Got it';

      setElement(
        <Tour
          title={step.title}
          description={step.description}
          image={step.image}
          onNext={onNextStep}
          buttonText={curStep === tour.steps.length - 1 ? finalTextButton : 'Next'}
          position={tourPosition}
          variant={tour.variant}
          imageClassName={step.imageClassName}
          stepLength={tour?.steps.length || 0}
          showSteps={tour?.showSteps}
          stepNumber={curStep + 1}
          onClose={() => resetTour()}
        />,
      );
    };

    processStep();
  }, [curStep, onNextStep, resetTour, tour, tourStarted, windowWidth]);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        resetTour();
      }
    });

    return () => {
      tour?.steps.forEach((s, index) => removeSpotlightElement(index));

      document.removeEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          resetTour();
        }
      });

      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
    };
  }, [resetTour, tour?.steps]);

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
    };
  }, []);

  return {
    targetRect,
    element,
    showBackdrop: element !== null && (tour?.variant !== 'spotlight' || tour.showBackdrop === true),
    borderRadius,
    stopTour,
  };
};

export { useTourGuide };
