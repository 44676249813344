import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TransferEnum } from '../../../TransferModal.types';
import { TransferTabs, TransferTabsData } from '../../../components/TransferTabs';

type Props = {
  transferType: TransferEnum;
  setTransferType: (transferType: TransferEnum) => void;
};

export const TransferModalDetailsTabs: React.FC<Props> = ({ transferType, setTransferType }) => {
  const { t } = useTranslation('common', { keyPrefix: 'transferModal.detailsStep.tabLabels' });

  const tabs = useMemo<TransferTabsData>(
    () => [
      {
        onClick: () => setTransferType(TransferEnum.DepositReceive),
        selected: transferType === TransferEnum.DepositReceive,
        label: t('deposit'),
      },
      {
        onClick: () => setTransferType(TransferEnum.WithdrawSend),
        selected: transferType === TransferEnum.WithdrawSend,
        label: t('withdraw'),
      },
    ],
    [transferType],
  );

  return <TransferTabs tabs={tabs} />;
};
