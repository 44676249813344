import React, { Dispatch, SetStateAction } from 'react';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { assetService, WithdrawalAddress } from '@shared/services';
import { formatDateTime } from '@shared/utils';

interface AccountDetailsProps {
  address: WithdrawalAddress;
  setConfirmRemoveAccount: Dispatch<SetStateAction<boolean>>;
  isFiat: boolean;
}

const accountDetails = (address: WithdrawalAddress, isFiat: boolean) => {
  if (isFiat) {
    const asset = assetService.getAsset(address.code);
    const code = asset?.code || '';

    if (code === 'AUD') {
      return [
        { label: 'Account Name', value: address.address_details.nameOnAccount },
        { label: 'Account Number', value: address.address_details.address },
        { label: 'BSB', value: address.address_details.bsb },
      ];
    }
    if (code === 'NZD') {
      return [
        { label: 'Account Name', value: address.address_details.nameOnAccount },
        { label: 'Account Number', value: address.address_details.address },
      ];
    }
  }
  return [
    {
      label: 'Address Name',
      value: address.address_details.nameOnAccount,
    },
    {
      label: 'Account address',
      value: address.address_details.address,
    },
    {
      label: address.metadata?.name,
      value: address.metadata?.data,
    },
    {
      label: 'Network',
      value: address.address_details.network,
    },
    {
      label: 'Date added',
      value: formatDateTime(new Date(address.created)),
    },
    {
      label: 'Status',
      value: address.verified ? 'Verified' : 'Unverified',
    },
  ];
};

export const AccountDetails: React.FC<AccountDetailsProps> = ({ address, setConfirmRemoveAccount, isFiat }) => (
  <FlexLayout direction='column' spacing={16}>
    {accountDetails(address, isFiat).map(
      ({ label, value }) =>
        label && (
          <FlexLayout
            className='fs-mask'
            direction='row'
            justifyContent='space-between'
            key={`address-detail-line-${label}-${value}`}
          >
            <Body>{label}</Body>
            <Body>{value}</Body>
          </FlexLayout>
        ),
    )}
    <FlexLayout direction='row' className='pt-24'>
      <Button
        variant='filled'
        color='destructive'
        onClick={() => {
          setConfirmRemoveAccount(true);
        }}
      >
        {isFiat ? 'Remove Account' : 'Remove Address'}
      </Button>
    </FlexLayout>
  </FlexLayout>
);
