import { useCallback } from 'react';

import { RateSide, UserBalance } from '@shared/api';
import { Big } from '@shared/safe-big';
import { RatesStore, UserStore } from '@shared/store';

import { useCountryAsset } from '@hooks/Assets/useCountryAsset';

const usePortfolioBalance = () => {
  const { balances } = UserStore.useUserStore;
  const { convertRate } = RatesStore.useRatesStore;
  const countryAsset = useCountryAsset();

  const getBalance = useCallback(
    (assetId: number): UserBalance | undefined =>
      Object.values(balances).find((b: UserBalance) => b.assetId === assetId),
    [balances],
  );

  const getBalanceValue = useCallback(
    (assetId: number, side: RateSide = 'midPrice'): Big => {
      if (!countryAsset) return Big(0);

      const balance: UserBalance | undefined = Object.values(balances).find((b: UserBalance) => b.assetId === assetId);

      if (!balance) return Big(0);

      return convertRate(assetId, countryAsset.id, balance.availableBalance, side);
    },
    [balances, convertRate, countryAsset],
  );

  const getCountryBalance = useCallback(
    (): UserBalance | undefined => Object.values(balances).find((b: UserBalance) => b.assetId === countryAsset?.id),
    [balances, countryAsset?.id],
  );

  const getBalances = useCallback(() => balances, [balances]);

  return { getBalance, getBalanceValue, getCountryBalance, getBalances };
};

export { usePortfolioBalance };
