import React, { useMemo } from 'react';

import InputBase from '@mui/material/InputBase';
import Slider from '@mui/material/Slider';

import { LockedFilled } from '@swyftx/aviary/icons/filled';
import { Unlocked } from '@swyftx/aviary/icons/outlined';
import { Button, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { RatesStore, UniversalTradeStore } from '@shared/store';
import { TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';

import { useAssetBalance } from '@hooks/Assets/useAssetBalance';
import { useUniversalTradeAsset } from '@hooks/Trade';
import { useTheme } from '@hooks/useTheme';
import { returnClosestWithBuffer } from '@utils/number';

import { observer } from 'mobx-react-lite';

import { Balance } from '../TradeInput.data';

export const TradeSpendMarks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

type Props = {
  tradeDataKey: string;
  displayAsset: Asset;
  balanceToUse: Balance;
  maxValue?: string;
  lockable?: boolean;
  minPercentage?: number;
  maxPercentage?: number;
};

const TradeInputSlider: React.FC<Props> = observer(
  ({ tradeDataKey, displayAsset, balanceToUse, maxValue, lockable, minPercentage = 0, maxPercentage = 100 }) => {
    const {
      percentage,
      fromAsset,
      toAsset,
      limitAsset,
      error,
      clientSideError,
      setTradeUIData,
      setTradeData,
      fee,
      data,
      locked,
      othersLocked,
    } = useUniversalTradeAsset(tradeDataKey);
    const { tradeType, tradeUIData } = UniversalTradeStore;
    const { getContrastAssetColor } = useTheme();
    const { customTrigger } = data;
    const { trading, staking } = useAssetBalance(fromAsset!);
    const { convertRate } = RatesStore.useRatesStore;
    const assetBalance = useMemo(
      () => (balanceToUse === Balance.Staking ? staking : trading),
      [balanceToUse, staking, trading],
    );

    const toggleLock = () => {
      setTradeUIData({ locked: !locked });
    };

    const onChangePercentage = (e: any, value: number, interactedWith: 'sliderInput' | 'slider') => {
      if (locked || othersLocked()) return;

      const isClick = e.type === 'mousedown';

      const clickPercentage = returnClosestWithBuffer(value, 5, [0, 25, 50, 75, 100]);
      const percentageToUse = isClick ? clickPercentage : value;

      const clampedPercentage = Math.min(Math.max(percentageToUse, minPercentage), maxPercentage);

      const { percentage: calcPercentage } = setTradeUIData({
        percentage: Number(clampedPercentage.toFixed(0)),
        interactedWith,
        loading: true,
      });

      if (!limitAsset || !fromAsset || !toAsset) return;

      if (calcPercentage === 0) {
        setTradeData({
          balance: '',
          slippagePercentage: 0,
          isHighSlippage: false,
        });
        return;
      }

      // Calculate the Balance
      // We are limiting on the from asset so its just a basic balance * by percentage
      if (limitAsset.id === fromAsset.id && calcPercentage) {
        const { price_scale } = limitAsset;
        const parsedPercentage = Big(calcPercentage.toString()).div(100);
        const val = Big(maxValue || assetBalance.balance)
          .times(parsedPercentage)
          .round(price_scale, 0)
          .toFixed();

        setTradeData({
          balance: Big(val).round(price_scale, 0).toFixed(),
          slippagePercentage: 0,
          isHighSlippage: false,
        });
      } else if (limitAsset.id === toAsset.id && calcPercentage) {
        // If we are limiting on the to, we need to work out the from value and then calculate the amount that would buy in the to asset
        const { price_scale } = limitAsset;
        const parsedPercentage = Big(calcPercentage.toString()).div(100);
        const val = Big(maxValue || assetBalance.balance)
          .times(parsedPercentage)
          .times(Big(1).minus(fee?.toString() || '0.06'))
          .toString();

        const limitVal = customTrigger
          ? Big(val).div(customTrigger)
          : convertRate(fromAsset.id, toAsset.id, val, 'askPrice');

        setTradeData({
          balance: Big(limitVal).round(price_scale, 0).toString(),
          slippagePercentage: 0,
          isHighSlippage: false,
        });
      }
    };

    const sliderColor = useMemo(() => {
      if (Object.keys(tradeUIData).length === 1) return 'primary.main';

      if (locked || othersLocked()) return 'disabled';

      return getContrastAssetColor(displayAsset.code);
    }, [locked, error, clientSideError, displayAsset.code, getContrastAssetColor, othersLocked]);

    if (tradeType === TradeType.OnTrigger && (Big(assetBalance.balance).lte(0) || limitAsset?.id !== fromAsset?.id))
      return null;

    return useMemo(
      () => (
        <Stack
          color='text.secondary'
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          width='100%'
          spacing={1.5}
          marginBottom={0}
        >
          <Stack direction='row' spacing={2.5} width='100%' alignItems='center'>
            <Slider
              value={Number(percentage)}
              marks={TradeSpendMarks}
              onChange={(e, sliderValue) => onChangePercentage(e, sliderValue as number, 'slider')}
              disabled={locked || othersLocked()}
              sx={{
                zIndex: 0,
                '& .MuiSlider-thumb': {
                  height: 16,
                  width: 16,
                  zIndex: 3,
                  backgroundColor: sliderColor,
                  border: `1px solid ${sliderColor}`,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
                  },
                },
                '& .MuiSlider-track': {
                  height: 4,
                  zIndex: 2,
                  color: sliderColor,
                },
                '& .MuiSlider-rail': {
                  backgroundColor: 'grey.200',
                  opacity: undefined,
                  height: 2,
                },
                '& .MuiSlider-mark': {
                  width: '8px',
                  height: '8px',
                  zIndex: 2,
                  backgroundColor: 'background.paper',
                  opacity: 1,
                  border: '1px solid',
                  borderColor: 'text.secondary',
                  borderRadius: '4px',
                },
                '.MuiSlider-markActive': {
                  borderColor: `${sliderColor}`,
                },
              }}
            />

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
              className='bg-color-background-surface-secondary'
              sx={{ padding: 1, borderRadius: 1, width: '5rem', height: '36px' }}
            >
              <InputBase
                value={percentage?.toString()}
                type='number'
                onChange={(e) => onChangePercentage(e, Number(e.target.value), 'sliderInput')}
                className='font-mono'
                sx={{
                  MozAppearance: 'textfield',
                  color: 'text.primary',
                  textAlign: 'left',
                  width: '100%',
                  fontSize: 16,
                  paddingY: 0.5,
                  '.MuiInputBase-input::placeholder': {
                    color: 'text.primary',
                  },
                  'input::-webkit-outer-spin-button': {
                    WebkitAppearance: 'none',
                  },
                  'input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                  },
                }}
                disabled={locked || othersLocked()}
                placeholder='0.00'
                inputProps={{ sx: { fontSize: '16px', textAlign: 'left', paddingY: 0.5 } }}
              />
              <Typography
                marginLeft={0.2}
                number
                fontSize={16}
                lineHeight='normal'
                sx={{
                  color: locked || othersLocked() ? 'text.disabled' : 'text.primary',
                }}
              >
                %
              </Typography>
            </Stack>
          </Stack>
          {lockable && (
            <Button
              startIcon={locked ? <LockedFilled color='inherit' /> : <Unlocked />}
              variant='outlined'
              sx={{
                width: 36,
                height: 36,
                borderColor: (theme) =>
                  theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
              }}
              icon
              color='inherit'
              onClick={toggleLock}
            ></Button>
          )}
        </Stack>
      ),
      [clientSideError, error, lockable, locked, onChangePercentage, othersLocked, percentage, sliderColor],
    );
  },
);

export { TradeInputSlider };
