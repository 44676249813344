import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAvo } from '@hooks/Avo/useAvo';
import { useCheckBrowserPermission } from '@hooks/useCheckBrowserPermission';

const COPIED_TIMEOUT = 5000;

const useCopy = (copiedTimeout: number = COPIED_TIMEOUT) => {
  const { pathname: screen } = useLocation();
  const avo = useAvo();
  const [copied, setCopied] = useState<boolean>(false);
  const hasClipboardPermission = useCheckBrowserPermission({ name: 'clipboard-write' });

  useEffect(() => {
    if (copied) {
      window.setTimeout(() => {
        setCopied(false);
      }, copiedTimeout);
    }
  }, [copied, copiedTimeout]);

  const onCopy = async (value: string, fieldName: string, modalName?: string) => {
    if (copied) return;

    try {
      if (hasClipboardPermission !== 'granted') {
        throw new Error('Clipboard permission not granted');
      }
      await navigator.clipboard.writeText(value);
      setCopied(true);

      avo.clipboardCopyButtonClicked({
        screen,
        modalName,
        fieldName,
      });
    } catch (e) {
      setCopied(false);
    }
  };

  return {
    copied,
    onCopy,
  };
};

export { useCopy };
