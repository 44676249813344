import { HistoricalOrder } from '@shared/api/@types/portfolio';
import {
  InitiateStripeRequest,
  InitiateStripeResponse,
  GetStripeMetadataRequest,
  GetStripeMetadataResponse,
} from '@shared/api/@types/stripeService';
import env from '@shared/config';
import { TimesEnum } from '@shared/enums';

import { ApiError } from '../../@types/api';
import {
  AddWithdrawAddressParams,
  AddWithdrawAddressPayload,
  AddWithdrawAddressResponse,
  CreateBundleResult,
  CreateLinkedOrderPayload,
  CreateLinkedOrderResult,
  CreateOrderParams,
  CreateOrderResponse,
  CreateWithdrawalParams,
  CreateWithdrawalPayload,
  CryptoAddressesResponse,
  GenerateDepositAddressParams,
  GenerateDepositAddressPayload,
  GenerateDepositAddressResponse,
  GetDepositAddressesParams,
  GetDepositAddressesResponse,
  GetWithdrawAddressesParams,
  GetWithdrawAddressesResponse,
  PairExchangeRateParams,
  PairExchangeRateResponse,
  RemoveWithdrawalAddressParams,
  SwapPayload,
  SwapResponse,
  UpdateOrderParams,
  VerifyBSBParams,
  VerifyBSBResponse,
  WithdrawalLimitResponse,
  WithdrawalPermissionsParams,
  WithdrawalPermissionsResponse,
} from '../../@types/trade';
import { Methods, request } from '../rest';

const tradeEndpoints = {
  getPairExchangeRate: ({
    data,
    headers,
  }: {
    data: PairExchangeRateParams;
    headers?: Record<string, string>;
  }): PairExchangeRateResponse =>
    request({
      path: '/orders/rate/',
      method: Methods.POST,
      headers,
      data,
      auth: true,
    }),

  getExchangeRates: ({ data }: { data: PairExchangeRateParams[] }): Array<PairExchangeRateResponse | ApiError> =>
    request({
      path: '/orders/rate/multi/',
      method: Methods.POST,
      data,
      auth: true,
    }),

  getBundleExchangeRate: ({ data }: { data: PairExchangeRateParams[] }): PairExchangeRateResponse[] =>
    request({ path: '/orders/rate/bundle/', method: Methods.POST, data, auth: true }),

  createOrder: ({ data }: { data: CreateOrderParams }): CreateOrderResponse =>
    request({ path: '/orders/', method: Methods.POST, data, auth: true, demoable: true, hideErrorToast: true }),

  updateOrder: ({ data }: { data: UpdateOrderParams }): CreateOrderResponse =>
    request({ path: '/orders/', method: Methods.PUT, data, auth: true, demoable: true }),

  createBundleOrder: ({ data }: { data: CreateOrderParams[] }): CreateBundleResult[] =>
    request({ path: '/orders/bundle/', method: Methods.POST, data, auth: true, demoable: true }),

  generateDepositAddress: ({
    params,
    data,
  }: {
    params: GenerateDepositAddressParams;
    data: GenerateDepositAddressPayload;
  }): GenerateDepositAddressResponse =>
    request({
      path: '/address/deposit/:code/:variant/:amount?/',
      method: Methods.POST,
      data,
      params,
      auth: true,
    }),

  addWithdrawAddress: ({
    params,
    data,
  }: {
    params: AddWithdrawAddressParams;
    data: AddWithdrawAddressPayload;
  }): AddWithdrawAddressResponse =>
    request({
      path: '/address/withdraw/:code/',
      method: Methods.POST,
      data,
      params,
      auth: true,
    }),

  createWithdrawal: ({ params, data }: { params: CreateWithdrawalParams; data: CreateWithdrawalPayload }) =>
    request({
      path: '/funds/withdraw/:code/',
      method: Methods.POST,
      data,
      params,
      auth: true,
    }),

  resetPaperTrading: ({ headers }: { headers?: Record<string, string> } = {}) =>
    request({
      path: '/funds/reset/',
      method: Methods.POST,
      headers,
      auth: true,
      paperTrading: true,
      data: {},
    }),

  updateWithdrawAddress: ({
    params,
    data,
    headers,
  }: {
    params: object;
    data: object;
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/address/withdraw/:code/',
      method: Methods.PATCH,
      headers,
      data,
      params,
      auth: true,
    }),

  cancelOrder: ({ params, headers }: { params: { orderId: string }; headers?: Record<string, string> }) =>
    request({
      path: '/orders/:orderId/',
      method: Methods.DELETE,
      headers,
      params,
      auth: true,
      demoable: true,
    }),

  removeWithdrawAddress: ({ params }: { params: RemoveWithdrawalAddressParams }) =>
    request({
      path: '/address/withdraw/:id/',
      method: Methods.DELETE,
      params,
      auth: true,
    }),

  verifyBsbForWithdraw: ({ params }: { params: VerifyBSBParams }): VerifyBSBResponse =>
    request({
      path: '/address/withdraw/bsb-verify/:bsb/',
      method: Methods.GET,
      params,
      auth: true,
    }),

  getOrderById: ({
    headers,
    params,
  }: {
    params: { orderUuid: string };
    headers?: Record<string, string>;
  }): HistoricalOrder =>
    request({
      path: '/orders/byId/:orderUuid/',
      method: Methods.GET,
      headers,
      params,
      auth: true,
      demoable: true,
    }),

  getOrder: ({ headers, query }: { query: object; headers: Record<string, string> }) =>
    request({
      path: '/orders/',
      method: Methods.GET,
      query,
      headers,
      auth: true,
      demoable: true,
    }),

  getWithdrawalPermissions: ({ params }: { params: WithdrawalPermissionsParams }): WithdrawalPermissionsResponse =>
    request({
      path: '/funds/withdrawalPermissions/:assetCode/',
      method: Methods.GET,
      params,
      auth: true,
    }),

  getLiveRates: ({ headers, params }: { params: object; headers: Record<string, string> }) =>
    request({
      path: '/live-rates/:baseId/',
      method: Methods.GET,
      headers,
      params,
      auth: true,
    }),

  getOrders: ({ headers, params, query }: { query: object; params: object; headers: Record<string, string> }) =>
    request({
      path: '/orders/:secondaryAsset/',
      method: Methods.GET,
      headers,
      params,
      query,
      auth: true,
      demoable: true,
    }),

  getDepositAddresses: ({
    params,
  }: {
    params: GetDepositAddressesParams | { code: string };
  }): GetDepositAddressesResponse =>
    request({
      path: '/address/deposit/:code/:variant?/',
      method: Methods.GET,
      params,
      auth: true,
    }),

  // todo make this endpoint cache forever based on asset id
  getDepositAddressesV2: ({
    params,
    query = { version: 2 },
  }: {
    query?: { version: number };
    params: { assId: number; networkId: number };
  }): CryptoAddressesResponse =>
    request({
      path: '/address/deposit/:assId/:networkId',
      method: Methods.GET,
      params,
      query,
      auth: true,
      cache: TimesEnum.MINUTE,
    }),

  generateDepositAddressV2: ({
    params,
    query = { version: 2 },
  }: {
    query?: { version: number };
    params: { assId: number; networkId: number };
  }): CryptoAddressesResponse =>
    request({
      path: '/address/deposit/:assId/:networkId',
      method: Methods.POST,
      params,
      query,
      auth: true,
    }),

  checkForDeposit: ({
    headers,
    params,
  }: {
    params: { code: string; addressId: string };
    headers?: Record<string, string>;
  }) =>
    request({
      path: '/address/check/:code/:addressId/',
      method: Methods.GET,
      headers,
      params,
      auth: true,
    }),

  getWithdrawAddresses: ({ params }: { params: GetWithdrawAddressesParams }): GetWithdrawAddressesResponse =>
    request({
      path: '/address/withdraw/:code/',
      method: Methods.GET,
      params,
      auth: true,
    }),

  getWithdrawalLimit: (): WithdrawalLimitResponse =>
    request({ path: '/limits/withdrawal/', method: Methods.GET, auth: true }),

  swap: ({ data }: { data: SwapPayload }): SwapResponse =>
    request({
      path: '/swap/',
      method: Methods.POST,
      auth: true,
      demoable: true,
      data,
    }),

  swapMulti: ({ data }: { data: SwapPayload[] }): SwapResponse[] =>
    request({
      path: '/swap/multi/',
      method: Methods.POST,
      auth: true,
      demoable: true,
      data,
    }),

  generateStripePayment: ({ data }: { data: InitiateStripeRequest }): InitiateStripeResponse =>
    request({
      path: '/deposits/api/v1/deposits/stripe',
      method: Methods.POST,
      host: env.GEN2_HOST,
      auth: true,
      data,
    }),

  getStripeMetadata: ({ params }: { params: GetStripeMetadataRequest }): GetStripeMetadataResponse =>
    request({
      path: '/deposits/api/v1/deposits/stripe/metadata/:assetId/',
      method: Methods.GET,
      host: env.GEN2_HOST,
      auth: true,
      params,
    }),

  /* Linked Orders */
  createLinkedOrder: ({ data }: { data: CreateLinkedOrderPayload }): CreateLinkedOrderResult[] =>
    request({
      path: '/orders/linked/',
      method: Methods.POST,
      data,
      auth: true,
    }),
};

export default tradeEndpoints;
