import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';

import { AssetTransactionHistoryModal } from '@global-components/Modals/AssetTransactionHistoryModal';
import { Modals } from '@global-components/Modals/Modals.enum';
import { FiatWithdrawalCreateNewAccountModal } from '@global-components/Modals/WithdrawFunds/FiatWithdrawal/FiatWithdrawalCreateNewAccountModal/FiatWithdrawalCreateNewAccountModal';
import { useModal } from '@global-components/Modals/useModal.hooks';

import { Asset, AssetType } from '@shared/api/@types/markets';
import { assetService } from '@shared/services';

import { WithdrawalBlocked } from '@hooks/Withdraw/useWithdrawalPermission';
import { TransactionTypeFilter } from '@services/WalletService';

import { WalletContext } from '@Wallet/Wallet.context';
import { WithdrawalLimit } from '@Wallet/components/WithdrawalLimit';

import { SingleWalletManageAccounts } from '../../../SingleWalletManageAccounts/SingleWalletManageAccounts';
import { SingleWalletSendInfo } from '../../../SingleWalletSend/components/SingleWalletSendInfo';

type Props = {
  asset: Asset;
  withdrawDisabled: boolean;
  withdrawalBlockedStatus: WithdrawalBlocked;
};

const SingleWalletFiatWithdrawalWithdraw: React.FC<Props> = ({ asset, withdrawDisabled, withdrawalBlockedStatus }) => {
  const { t } = useTranslation('wallet');
  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState<boolean>(false);
  const [hasWithdrawAddresses, setHasWithdrawAddresses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWithdrawHistory, setShowWithdrawHistory] = useState(false);

  const { addresses, updateAddresses } = useContext(WalletContext);
  const isFiat = asset && assetService.isAssetFiat(asset.id);
  const { openModal } = useModal();

  const withdrawalsBlocked = withdrawalBlockedStatus.blocked;

  const fetchAddresses = useCallback(async () => {
    setLoading(true);
    const assetCode = asset.code ?? '';
    await updateAddresses(assetCode);
    const currentAddresses = addresses.get(assetCode) || [];

    setHasWithdrawAddresses(currentAddresses.length > 0);
    setLoading(false);
  }, [addresses, asset.code, updateAddresses]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  const handleCloseModal = () => {
    setShowCreateNewAccountModal(false);
    fetchAddresses();
  };

  const createAccountButton = () => (
    <Button className='w-full' onClick={() => setShowCreateNewAccountModal(true)}>
      Add new account
    </Button>
  );

  const manageAccountsButton = () => (
    <SingleWalletManageAccounts
      asset={asset}
      isFiat={isFiat}
      createAccountHandler={() => setShowCreateNewAccountModal(true)}
      fullWidth
      buttonSize='md'
    />
  );

  return (
    <>
      <FlexLayout direction='column' spacing={16}>
        {!withdrawDisabled && !withdrawalsBlocked && (
          <SingleWalletSendInfo assetType={AssetType.Fiat} assetCode={asset.code} />
        )}

        {!withdrawDisabled && !withdrawalsBlocked && (
          <>
            <WithdrawalLimit />
            <Button
              size='lg'
              className='w-full'
              onClick={() => openModal(Modals.WithdrawSend, { selectedAsset: asset })}
            >
              Withdraw funds
            </Button>
          </>
        )}

        <FlexLayout direction='row' alignItems='center' spacing={2}>
          <div className='h-full w-full'>
            {loading && <div className='h-full w-full animate-pulse bg-color-background-surface-secondary' />}
            {!loading && (hasWithdrawAddresses ? manageAccountsButton() : createAccountButton())}
          </div>
          <Button variant='ghost' onClick={() => setShowWithdrawHistory(true)} className='w-full'>
            {t('singleWalletFiatDeposit.selector.buttonLabels.withdrawHistory')}
          </Button>
        </FlexLayout>
      </FlexLayout>

      {/* create new account */}
      {showCreateNewAccountModal && <FiatWithdrawalCreateNewAccountModal asset={asset} onClose={handleCloseModal} />}

      {showWithdrawHistory && (
        <AssetTransactionHistoryModal
          asset={asset}
          type={TransactionTypeFilter.WITHDRAWAL}
          onClose={() => setShowWithdrawHistory(false)}
        />
      )}
    </>
  );
};

export { SingleWalletFiatWithdrawalWithdraw };
