import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Stack } from '@swyftx/react-web-design-system';

import { EmailVerificationModal } from '@global-components/Modals/SendCrypto/EmailVerificationModal';

import { Asset } from '@shared/api/@types/markets';
import { Big } from '@shared/safe-big';
import { WithdrawalAddress, WithdrawalReason, withdrawalService } from '@shared/services';
import { StorageKey } from '@shared/storage/index';
import { UIStore } from '@shared/store';

import { useWithdraw } from '@hooks/Withdraw/useWithdraw';

import { WalletContext } from '@Wallet/Wallet.context';

import { MobileVerificationModal } from './MobileVerificationModal';
import { reasonLabels } from './SendCryptoModal.const';
import { SendCryptoAddAddress, SendCryptoDetails, SendCryptoScamWarning, SendCryptoSetup } from './steps';
import { AddCryptoAddressFormData } from './steps/SendCryptoAddAddress';

export enum SendCryptoModalStep {
  Setup,
  Details,
  ScamWarning,
  AddAddress,
  MobileVerification,
  EmailVerification,
}

type Props = {
  asset: Asset;
  onClose: () => void;
  addingAddress: boolean;
  skipLanding?: boolean;
  withdrawalAddresses?: Array<WithdrawalAddress>;
};

const SendCryptoModal: React.FC<Props> = ({ asset, onClose, addingAddress, withdrawalAddresses, skipLanding }) => {
  const { t } = useTranslation('wallet');
  const { addAddress } = useContext(WalletContext);
  const { createWithdrawal } = useWithdraw();

  const hasReadLanding =
    skipLanding || Boolean(JSON.parse(localStorage.getItem(StorageKey.SEND_CRYPTO_LANDING_READ) || 'false'));
  const hasReadScamLanding = Boolean(
    JSON.parse(localStorage.getItem(StorageKey.SEND_CRYPTO_SCAM_LANDING_READ) || 'false'),
  );
  const noAddresses = !withdrawalAddresses || withdrawalAddresses.length === 0;

  const getInitialStep = () => {
    if (hasReadLanding) {
      if (hasReadScamLanding) {
        if (noAddresses || addingAddress || skipLanding) {
          return SendCryptoModalStep.AddAddress;
        }
        return SendCryptoModalStep.Details;
      }
      return SendCryptoModalStep.ScamWarning;
    }
    return SendCryptoModalStep.Setup;
  };

  const [step, setStep] = useState<SendCryptoModalStep>(getInitialStep());
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);
  const [canContinue, setCanContinue] = useState<boolean>(true);
  const [selectedAddress, setSelectedAddress] = useState<WithdrawalAddress | null>(null);
  const [selectedReason, setSelectedReason] = useState<WithdrawalReason | null>(null);
  const [addressFormData, setAddressFormData] = useState<AddCryptoAddressFormData>();
  const [value, setValue] = useState<string>('');
  const [pin, setPin] = useState<string>('');
  const { addToastMessage } = UIStore.useUIStore;

  const updateDoNotShowAgain = (checked: boolean) => {
    if (step === SendCryptoModalStep.ScamWarning) {
      localStorage.setItem(StorageKey.SEND_CRYPTO_SCAM_LANDING_READ, JSON.stringify(checked));
      setDoNotShowAgain(checked);
    }

    if (step === SendCryptoModalStep.Setup) {
      localStorage.setItem(StorageKey.SEND_CRYPTO_LANDING_READ, JSON.stringify(checked));
      setDoNotShowAgain(checked);
    }
  };

  const onAddAddressSubmit = async () => {
    if (addressFormData) {
      try {
        const { mfaType } = await addAddress({
          asset,
          formData: addressFormData,
          pin,
        });

        switch (mfaType) {
          case 'sms': {
            setStep(SendCryptoModalStep.MobileVerification);
            break;
          }
          case 'email': {
            setStep(SendCryptoModalStep.EmailVerification);
            break;
          }
          default: {
            onClose();
            break;
          }
        }
      } catch (error: any) {
        addToastMessage({ severity: 'error', message: error?.errorMessage || 'Something went wrong' });
      }
    }
  };

  const onSendCryptoSubmit = async () => {
    if (selectedAddress && selectedReason && value.length) {
      try {
        addToastMessage({ severity: 'info', message: 'Processing withdrawal' });
        const minWithdrawalIncrementE = withdrawalService.getMinWithdrawalIncrementE(selectedAddress, asset);

        await createWithdrawal(asset.code, {
          quantity: Big(value).toFixed(minWithdrawalIncrementE * -1),
          address_id: selectedAddress.id,
          reason: selectedReason.key,
          reasonText: reasonLabels[selectedReason.key],
        });
      } catch (error: any) {
        addToastMessage({ severity: 'error', message: error?.message || 'Something went wrong' });
      }
    }
  };

  const getFooterDetails = () => {
    const getActionName = () => {
      if (step === SendCryptoModalStep.Details) {
        return `Withdraw ${asset.code} to this address`;
      }
      if (step === SendCryptoModalStep.AddAddress) {
        return 'Add new address';
      }
      return 'Continue';
    };

    const stepMap: Record<SendCryptoModalStep, () => void> = {
      [SendCryptoModalStep.Setup]: () => {
        // Show scam landing if we haven't marked it as do not show, show address step if we need to add an address, show details if otherwise
        setCanContinue(false);
        if (hasReadScamLanding) {
          if (addingAddress || noAddresses) {
            setStep(SendCryptoModalStep.AddAddress);
          } else {
            setStep(SendCryptoModalStep.Details);
          }
        } else {
          setStep(SendCryptoModalStep.ScamWarning);
        }
      },
      [SendCryptoModalStep.ScamWarning]: () => {
        // Show address step if we need to add an address, show details if otherwise
        setCanContinue(false);
        if (addingAddress || noAddresses) {
          setStep(SendCryptoModalStep.AddAddress);
        } else {
          setStep(SendCryptoModalStep.Details);
        }
      },
      [SendCryptoModalStep.AddAddress]: () => {
        setCanContinue(false);
        if (addingAddress) {
          onAddAddressSubmit();
        } else {
          setCanContinue(false);
          setStep(SendCryptoModalStep.Details);
        }
      },
      [SendCryptoModalStep.Details]: () => {
        onSendCryptoSubmit();
        onClose();
      },
      [SendCryptoModalStep.MobileVerification]: () => {
        onAddAddressSubmit();
      },
      [SendCryptoModalStep.EmailVerification]: () => {
        onClose();
      },
    };

    const onAction = stepMap[step];
    const actionName = getActionName();
    return {
      actionName,
      onAction,
      disabled: !canContinue,
    };
  };

  const getTitle = () => {
    switch (step) {
      case SendCryptoModalStep.Details:
        return t('withdrawCrypto.withdrawCryptoTitle', { name: asset.name });
      case SendCryptoModalStep.ScamWarning:
        return t('withdrawCrypto.scamWarningTitle');
      case SendCryptoModalStep.AddAddress:
        return t('withdrawCrypto.addAddressTitle', { code: asset.code });
      default:
        return t('withdrawCrypto.default', { name: asset.name });
    }
  };

  const getFooterContent = () => {
    const footerDetails = getFooterDetails();
    return (
      <Stack direction='row' width='100%' height='100%' alignItems='center' justifyContent='flex-end' spacing={2}>
        <Button color='inherit' sx={{ color: 'text.secondary' }} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={footerDetails.onAction} disabled={footerDetails.disabled} variant='contained' disableElevation>
          {footerDetails.actionName}
        </Button>
      </Stack>
    );
  };

  const getContent = () => {
    switch (step) {
      case SendCryptoModalStep.Setup:
        return (
          <SendCryptoSetup asset={asset} doNotShowAgain={doNotShowAgain} setDoNotShowAgain={updateDoNotShowAgain} />
        );
      case SendCryptoModalStep.ScamWarning:
        return (
          <SendCryptoScamWarning
            setDoNotShowAgain={updateDoNotShowAgain}
            doNotShowAgain={doNotShowAgain}
            setCanContinue={setCanContinue}
          />
        );
      case SendCryptoModalStep.AddAddress:
        return (
          <SendCryptoAddAddress
            setFormData={setAddressFormData}
            setCanContinue={setCanContinue}
            canContinue={canContinue}
            asset={asset}
          />
        );
      case SendCryptoModalStep.MobileVerification:
        return (
          <MobileVerificationModal
            code={pin}
            onClose={() => onClose()}
            onSubmit={onAddAddressSubmit}
            setCode={setPin}
          />
        );
      case SendCryptoModalStep.EmailVerification:
        return <EmailVerificationModal onClose={() => onClose()} />;
      default:
        return (
          <SendCryptoDetails
            withdrawalAddresses={withdrawalAddresses}
            setStep={setStep}
            setSelectedAddress={setSelectedAddress}
            setSelectedReason={setSelectedReason}
            selectedAddress={selectedAddress}
            setCanContinue={setCanContinue}
            selectedReason={selectedReason}
            setValue={setValue}
            value={value}
            asset={asset}
          />
        );
    }
  };

  return (
    <Modal
      id='withdraw-crypto-modal'
      open
      sx={{ width: '600px', maxHeight: 'calc(100% - 2rem)', '.MuiCardContent-root': { height: '100%' } }}
      HeaderProps={{ title: getTitle(), dismissible: true, divider: true }}
      FooterProps={{ divider: true, content: getFooterContent() }}
      onClose={onClose}
    >
      <>{getContent()}</>
    </Modal>
  );
};

export { SendCryptoModal };
