import { RadioType } from '@swyftx/aviary/molecules/RadioGroup/RadioGroup';

export const EntityTypeStepContent = {
  COMPANY: {
    types: ['COMPANY'],
    title: 'Company',
    subtitle: 'For those who operate a company (Pty Ltd).',
  },
  SMSF: {
    types: ['SMSF_INDIVIDUAL', 'SMSF_CORPORATE'],
    title: 'Self Managed Super Fund (SMSF)',
    subtitle: 'For those who manage their own super fund.',
  },
  TRUST: {
    types: ['TRUST_INDIVIDUAL', 'TRUST_CORPORATE'],
    title: 'Trust',
    subtitle: 'For those with a discretionary, family or corporate trust.',
  },
};

export const yesNoRadioGroup: RadioType[] = [
  { text: 'Yes', value: 'yes' },
  { text: 'No', value: 'no' },
];
