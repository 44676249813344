import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/react-web-design-system';

import { TransactionsContext } from '@routes/Transactions/Transactions.context';

import { observer } from 'mobx-react-lite';

import { TransactionsTabType } from './TransactionsTabs.data';

type Props = {
  tab: TransactionsTabType;
  type?: string;
  onTabChange: (tab: TransactionsTabType) => void;
};

const TransactionTab: React.FC<Props> = observer(({ tab, type, onTabChange }) => {
  const { t } = useTranslation('orders');
  const { fetching } = useContext(TransactionsContext);

  const onChangeTab = useCallback(() => {
    if (tab.type === type) return;

    if (onTabChange) onTabChange(tab);
  }, [onTabChange, tab, type]);

  return (
    <Button
      id={tab.id}
      variant='text'
      size='large'
      disabled={fetching}
      sx={{
        '.MuiButton-startIcon': {
          color: tab.type === type ? 'primary.main' : 'text.secondary',
        },
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        color: tab.type === type ? 'text.primary' : 'text.secondary',
        backgroundColor: tab.type === type ? 'action.hover' : '',
        fontWeight: tab.type === type ? '500' : '400',
        fontSize: 14,
      }}
      onClick={onChangeTab}
    >
      {t(tab.label)}
    </Button>
  );
});

TransactionTab.displayName = 'TransactionTab';

export { TransactionTab };
