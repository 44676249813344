import { useCallback, useEffect, useState } from 'react';

import { WithdrawalAddress, withdrawalService } from '@shared/services';

export const useWithdrawalAddresses = (code?: string, updateAddresses?: (code: string) => any) => {
  const [withdrawalAddresses, setWithdrawalAddresses] = useState<Array<WithdrawalAddress>>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const refreshAddresses = useCallback(async () => {
    if (!code) return;

    setError(false);
    setLoading(true);
    try {
      const res = await withdrawalService.getWithdrawAddresses(code);
      if (updateAddresses) {
        await updateAddresses(code);
      }
      setWithdrawalAddresses(res);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }, [code, updateAddresses]);

  useEffect(() => {
    if (code) {
      refreshAddresses();
    }
  }, [code, refreshAddresses]);

  return { refreshAddresses, withdrawalAddresses, loading, error };
};
