import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TradeInput } from '@global-components/Input/TradeInput/TradeInput';
import { Balance, TradeInputVariant } from '@global-components/Input/TradeInput/TradeInput.data';

import { FiatIdEnum, LowLiquidityMax } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { RatesStore, UniversalTradeStore } from '@shared/store';
import { TradeData, TradeType } from '@shared/store/universalTradeStore/@types/universalTradeTypes';
import { formatCurrency } from '@shared/utils';

import { useUniversalTradeAsset, useUniversalTradeUtilityStore } from '@hooks/Trade';
import { useLowLiquidity } from '@hooks/Trade/useLowLiquidity';

import { observer } from 'mobx-react-lite';

type Props = {
  variant?: TradeInputVariant;
  tradeDataKey: string;
  assetToDisplay?: number;
  title?: boolean;
  flippable?: boolean;
  slider?: boolean;
  showBalances?: boolean;
  onRemove?: (data?: TradeData) => void;
  disabled?: boolean;
  lockable?: boolean;
  minPercentage?: number;
  maxPercentage?: number;
};

const TradeAssetInput: React.FC<Props> = observer(
  ({
    variant = 'singleTrade',
    tradeDataKey,
    title = true,
    showBalances = true,
    flippable = true,
    slider = true,
    assetToDisplay,
    disabled = false,
    lockable,
    onRemove,
    minPercentage = 0,
    maxPercentage = 100,
  }) => {
    const [value, setValue] = useState<string>('');
    const { getMaxValue } = useUniversalTradeUtilityStore();
    const { getMinimumOrderAmount, convertRate } = RatesStore.useRatesStore;
    const { t } = useTranslation('trade', { keyPrefix: 'tradeAssetInput' });
    const { tradeType } = UniversalTradeStore;

    const {
      fromAsset,
      toAsset,
      triggerFromAsset,
      customTrigger,
      limitAsset,
      balance,
      data,
      error,
      clientSideError,
      isOverLowLiquidityThreshold,
      setTradeUIData,
    } = useUniversalTradeAsset(tradeDataKey);

    const { isLowLiquidity, maximumAmount } = useLowLiquidity(fromAsset?.id, toAsset?.id);

    const displayAsset = useMemo(
      () => (assetToDisplay ? assetService.getAsset(assetToDisplay) : undefined),
      [assetToDisplay],
    );

    const showSlider = useMemo(() => fromAsset?.id === limitAsset?.id, [fromAsset, limitAsset]);

    useEffect(() => {
      setValue(balance || '');
    }, []);

    useEffect(() => {
      if (!fromAsset || !limitAsset || !toAsset || disabled || !triggerFromAsset) return;

      if (!Number.isNaN(Number(balance)) && balance) {
        const minOrderAmount = getMinimumOrderAmount(limitAsset);
        const bigQuantity = Big(balance);

        if (tradeType === TradeType.OnTrigger) {
          if (!customTrigger) {
            setTradeUIData({ clientSideError: '', isOverLowLiquidityThreshold: false });
            return;
          }

          // We need to work out a custom min Order amount based on the trigger price
          const assetToUse = customTrigger ? triggerFromAsset : fromAsset;
          const minTriggerOrderAmount = getMinimumOrderAmount(assetToUse);
          const limitingOnTriggerAsset = limitAsset.id === assetToUse.id;

          if (limitingOnTriggerAsset && bigQuantity.lt(minTriggerOrderAmount)) {
            setTradeUIData({
              clientSideError: t('labels.invalidMinOrder', {
                amount: formatCurrency(minTriggerOrderAmount, assetToUse),
                isOverLowLiquidityThreshold: false,
              }),
            });
            return;
          }

          const minLimitAmount = Big(minTriggerOrderAmount).div(Big(customTrigger));

          if (!limitingOnTriggerAsset && bigQuantity.lt(minLimitAmount)) {
            setTradeUIData({
              clientSideError: t('labels.invalidMinOrder', {
                amount: formatCurrency(minLimitAmount, limitAsset),
                isOverLowLiquidityThreshold: false,
              }),
            });
            return;
          }

          let quantityValue = bigQuantity;
          let maximumCustomAmount = maximumAmount;

          if (fromAsset.id !== FiatIdEnum.AUD) {
            const convertedTriggerPrice = convertRate(triggerFromAsset, FiatIdEnum.AUD, customTrigger);
            maximumCustomAmount = Big(LowLiquidityMax.AUD).div(convertedTriggerPrice);
          }

          if (isLowLiquidity && quantityValue.gt(maximumCustomAmount)) {
            setTradeUIData({
              clientSideError: t('labels.lowLiquidity', {
                amount: formatCurrency(maximumCustomAmount, limitAsset, { hideCode: false, appendCode: true }),
              }),
              isOverLowLiquidityThreshold: true,
            });
            return;
          }
        } else if (isLowLiquidity && bigQuantity.gt(maximumAmount)) {
          setTradeUIData({
            clientSideError: t('labels.lowLiquidity', {
              amount: formatCurrency(maximumAmount, limitAsset, { hideCode: false, appendCode: true }),
            }),
            isOverLowLiquidityThreshold: true,
          });
          return;
        } else if (bigQuantity.lt(minOrderAmount)) {
          setTradeUIData({
            clientSideError: t('labels.invalidMinOrder', { amount: formatCurrency(minOrderAmount, limitAsset) }),
            isOverLowLiquidityThreshold: false,
          });
          return;
        }
      } else if (balance) {
        setTradeUIData({
          clientSideError: t('labels.invalidOrderAmount'),
          isOverLowLiquidityThreshold: false,
        });
        return;
      }

      setTradeUIData({ clientSideError: '', isOverLowLiquidityThreshold: false });
    }, [
      balance,
      customTrigger,
      triggerFromAsset,
      fromAsset,
      limitAsset,
      toAsset,
      disabled,
      isLowLiquidity,
      maximumAmount,
    ]);

    if (!fromAsset || !toAsset) return null;

    return (
      <TradeInput
        variant={variant}
        title={title ? t('labels.title') : ''}
        tradeData={data}
        asset={fromAsset}
        error={error}
        clientSideError={clientSideError}
        isOverLowLiquidityThreshold={isOverLowLiquidityThreshold}
        baseAsset={toAsset}
        assetToDisplay={displayAsset}
        showBalances={showBalances}
        tradeType={tradeType}
        onRemove={onRemove}
        balanceToUse={Balance.Trading}
        value={value}
        flippable={flippable}
        slider={slider && showSlider}
        showEnterAmountBanner={slider && !showSlider}
        maxValue={getMaxValue()}
        disabled={disabled}
        lockable={lockable}
        minPercentage={minPercentage}
        maxPercentage={maxPercentage}
      />
    );
  },
);

export { TradeAssetInput };
