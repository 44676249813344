import React, { useCallback, useMemo } from 'react';

import Select from '@mui/material/Select';

import { Chip } from '@swyftx/aviary/atoms/Chip';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Body } from '@swyftx/aviary/atoms/Typography';
import { MenuItem, Stack, Typography } from '@swyftx/react-web-design-system';

import { Asset, Network } from '@shared/api/@types/markets';

type Props = {
  asset: Asset;
  selectedNetworkId?: number;
  onSelectNetwork: (network: Network) => void;
};

const CryptoNetworkSelector: React.FC<Props> = ({ asset, selectedNetworkId, onSelectNetwork }) => {
  const getNetwork = useCallback(
    (networkId: number) => asset.networks.find((n) => n.id === networkId),
    [asset.networks],
  );

  const filteredNetworks = useMemo(() => asset.networks.filter((n) => !n.depositDisableForce), [asset]);

  const onNetworkSelect = useCallback(
    (networkId: number) => {
      const network = getNetwork(networkId);
      if (network) onSelectNetwork(network);
    },
    [getNetwork, onSelectNetwork],
  );

  const renderValue = useCallback(
    (selected: number) => {
      const network = getNetwork(selected);

      if (!selected || !network) {
        return (
          <Typography fontSize={16} fontWeight={500} color='text.disabled'>
            Select a Network
          </Typography>
        );
      }

      return (
        <FlexLayout direction='row' alignItems='center' className='w-full' spacing={8}>
          <Body>{network.networkNameFull}</Body>
          {network.depositDisabled && <Chip variant='subtle'>Temporarily suspended</Chip>}
        </FlexLayout>
      );
    },
    [getNetwork],
  );

  return (
    <Stack direction='column' spacing={0.5}>
      <Stack direction='row' spacing={1}>
        <Typography color='text.primary' fontSize={14} fontWeight={500}>
          Select a network
        </Typography>

        <Typography color='error.main'>*</Typography>
      </Stack>
      <Select<number>
        id='crypto-network-selector-dropdown'
        sx={{
          width: '100%',
          marginBottom: 2,
          backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'grey.50' : 'grey.700'),
          '.MuiTypography-root': {
            color: 'text.secondary',
          },
        }}
        MenuProps={{
          MenuListProps: {
            sx: { paddingY: 0 },
          },
        }}
        displayEmpty
        value={selectedNetworkId || ''}
        onChange={(e) => onNetworkSelect(Number(e.target.value))}
        placeholder='Select a Network'
        renderValue={renderValue}
      >
        {filteredNetworks.map((network) => (
          <MenuItem key={network.id} value={network.depositDisabled ? undefined : network.id}>
            <FlexLayout direction='row' alignItems='center' justifyContent='space-between' className='w-full py-8'>
              <Body color={network.depositDisabled ? 'secondary' : 'primary'}>{network.networkNameFull}</Body>
              {network.depositDisabled && <Chip variant='subtle'>Temporarily suspended</Chip>}
            </FlexLayout>
          </MenuItem>
        ))}
      </Select>

      <Stack direction='row' spacing={1} width='100%' overflow='auto' paddingBottom={1}></Stack>
    </Stack>
  );
};

export { CryptoNetworkSelector };
