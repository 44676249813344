import React, { PropsWithChildren } from 'react';

import { Cross } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as Dialog from '@radix-ui/react-dialog';

import { ModalProps, modalCardVariants, modalVariants } from './Modal.styles';
import { Button } from '../Button';
import { Card, CardHeader } from '../Card';
import { FlexLayout } from '../Layout/Flex';
import { Heading } from '../Typography';

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
  open,
  triggerElement,
  title,
  showCloseButton = title !== undefined,
  onClose,
  onOpenChange,
  position = 'center',
  className,
  contentClassName,
  overlayClassName,
  container,
  children,
}) => (
  <Dialog.Root open={open} onOpenChange={onOpenChange}>
    {triggerElement && <Dialog.Trigger asChild>{triggerElement}</Dialog.Trigger>}
    <Dialog.Portal container={container}>
      <Dialog.Overlay
        className={cn('fixed left-0 top-0 z-modal-backdrop h-full w-full bg-black opacity-50', overlayClassName)}
      />
      <Dialog.Content
        className={cn(
          modalVariants({ position }),
          contentClassName,
          position === 'bottom' ? 'transition-transform data-[state=open]:animate-[slide-down-up_.3s_forwards]' : '',
        )}
      >
        <Card className={cn(modalCardVariants({ position }), className)} border={false}>
          {(title || showCloseButton) && (
            <CardHeader className={position === 'bottom' ? 'p-16 @sm:p-24 @sm:py-18' : 'p-24'}>
              <FlexLayout
                alignItems='center'
                className='w-full'
                justifyContent={showCloseButton ? (title ? 'space-between' : 'end') : 'center'}
                spacing={24}
              >
                {title && <Heading size='h4'>{title}</Heading>}
                {showCloseButton && (
                  <Dialog.Close asChild>
                    <Button
                      onClick={onClose}
                      variant='outlined'
                      layout='icon'
                      leadingIcon={<Cross />}
                      aria-label='Close'
                    />
                  </Dialog.Close>
                )}
              </FlexLayout>
            </CardHeader>
          )}
          <div className='h-full overflow-auto'>{children}</div>
        </Card>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export { Modal };
