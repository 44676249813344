import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Asset } from '@shared/api';
import { Category } from '@shared/api/@types/categories';

import { useFavouriteAssets } from '@hooks/Assets/useFavouriteAssets';

import { useCategories } from 'src/lib/categories/hooks';
import { useMarkets } from 'src/lib/markets/hooks/useMarkets';

type Props = {
  id?: number;
  name?: string;
};

const useCategory = ({ id, name }: Props = {}) => {
  const { t } = useTranslation('assets', { keyPrefix: 'singleCategoryPage' });
  const { categories } = useCategories();
  const favouriteAssetList = useFavouriteAssets();
  const { getAssetsByCategoryId } = useMarkets();

  const [category, setCategory] = useState<Category>();

  const getCategoryByName = useCallback(
    (categoryName: string) => categories.find((c) => c.name.toLowerCase() === categoryName.toLowerCase()),
    [categories],
  );

  const getCategoryById = useCallback(
    (categoryId: number) => {
      // Favourites Page
      if (categoryId === -1) {
        return {
          id: -1,
          name: t('titles.favourites'),
          description: '',
          enabled: true,
          promoted: false,
          assetIds: favouriteAssetList.map((a) => a.id),
          // eslint-disable-next-line no-restricted-syntax
          colour: '#ffb45e',
        };
      }

      return categories.find((c) => c.id === categoryId);
    },
    [categories, favouriteAssetList, t],
  );

  const getAssetCategories = useCallback(
    (asset: Asset) => {
      let assetCategories: Category[] = [];
      asset.categoryIds?.forEach((categoryId: number) => {
        const assetCategory = getCategoryById(categoryId);
        if (assetCategory) assetCategories.push(assetCategory);
      });

      return assetCategories;
    },
    [getCategoryById],
  );

  useEffect(() => {
    if (!id) return;

    setCategory(getCategoryById(id));
  }, [categories, getCategoryById, id]);

  useEffect(() => {
    if (!name) return;

    setCategory(getCategoryByName(name));
  }, [categories, getCategoryByName, name]);

  const assets = useMemo(() => (category ? getAssetsByCategoryId(category.id) : []), [category, getAssetsByCategoryId]);

  return {
    category,
    assets,
    getCategoryById,
    getCategoryByName,
    getAssetCategories,
  };
};

export { useCategory };
