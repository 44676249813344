import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowInCircleDown } from '@swyftx/aviary/icons/outlined';
import { pxToRem, Tooltip, Typography } from '@swyftx/react-web-design-system';

import { AssetPickerMenu } from '@global-components/AssetPickerMenu/AssetPickerMenu';

type Props = {
  assetCode: string;
  type?: 'all' | 'trade' | 'earn';
};

const AppHeaderAssetPicker: React.FC<Props> = ({ assetCode, type }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('wallet');

  return (
    <>
      <Tooltip title={t('overview.appHeader.title')}>
        <Typography
          onClick={(e) => setAnchorEl(e.currentTarget)}
          color='text.primary'
          fontSize={pxToRem(14)}
          fontWeight={500}
          sx={{ '&:hover': { color: 'primary.main', cursor: 'pointer' }, display: 'flex', alignItems: 'center' }}
        >
          {assetCode.toUpperCase()}
          <ArrowInCircleDown className='ml-8 h-14 w-14 text-color-text-primary' />
        </Typography>
      </Tooltip>
      <AssetPickerMenu
        type={type}
        currentAssetCode={assetCode}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{ maxHeight: '500px' }}
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: { maxWidth: '220px', minWidth: '220px' },
        }}
      />
    </>
  );
};

export { AppHeaderAssetPicker };
