import React, { useCallback, useMemo, useState } from 'react';

import { useTailwindBreakpoint } from '@swyftx/aviary/hooks/useTailwindBreakpoint';
import { ArrowChevronDown } from '@swyftx/aviary/icons/outlined';

import { cn } from '@shared/utils/lib/ui';

import * as RadixSelect from '@radix-ui/react-select';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { SelectItemData } from './Select.types';
import { SelectItem } from './SelectItem';
import { Button } from '../Button';
import { Label } from '../Label';
import { FlexLayout } from '../Layout/Flex';
import { ListItem } from '../List';
import { Modal } from '../Modal';
import { Body } from '../Typography';

type Props = {
  placeholder?: string;
  items: SelectItemData[];
  title?: string;
  titleWeight?: 'none' | 'emphasis' | 'bold';
  showInputTitle?: boolean;
  className?: string;
  triggerClassName?: string;
  portalClassName?: string;
  layoutClassName?: string;
  noSelectedLabel?: boolean;
  size?: 'medium' | 'small';
  spotlightElementId?: string;
  error?: boolean;
  disabled?: boolean;
} & RadixSelect.SelectProps;

const Select: React.FC<Props> = ({
  placeholder,
  items,
  value,
  title,
  titleWeight = 'none',
  size = 'medium',
  showInputTitle = true,
  className,
  layoutClassName,
  triggerClassName,
  portalClassName,
  noSelectedLabel,
  onValueChange,
  spotlightElementId,
  error,
  disabled,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>();
  const isXs = useTailwindBreakpoint('xs');
  const selectedItem = useMemo(() => items.find((i) => i.value === value), [value, items]);

  const onSelectItem = useCallback(
    (item: SelectItemData) => {
      if (onValueChange) onValueChange(item.value);
      setOpen(false);
    },
    [onValueChange],
  );

  return (
    <>
      {isXs && (
        <Modal
          title={title ? `Select ${title.toLocaleLowerCase()}` : undefined}
          open={open}
          onClose={() => setOpen(false)}
          triggerElement={null}
          showCloseButton
          position='bottom'
          className='z-modal-popover'
          overlayClassName='z-modal-popover-backdrop'
        >
          <FlexLayout direction='column' spacing={8} className='px-4 pb-4'>
            {items.map((item) => {
              const selected = selectedItem?.value === item.value;
              return (
                <ListItem
                  key={item.value}
                  onClick={() => onSelectItem(item)}
                  className={cn(selected ? 'bg-color-background-surface-selected text-color-text-selected' : '')}
                >
                  <FlexLayout alignItems='center' spacing={8}>
                    <div className={cn(selected ? 'text-color-text-selected' : 'text-color-text-primary')}>
                      {item.leadingIcon}
                    </div>
                    <FlexLayout direction='column' alignItems='start'>
                      <Body>{item.label}</Body>
                      {item.secondaryLabel && (
                        <Body size='small' color='secondary'>
                          {item.secondaryLabel}
                        </Body>
                      )}
                    </FlexLayout>
                  </FlexLayout>
                </ListItem>
              );
            })}
          </FlexLayout>
        </Modal>
      )}
      <FlexLayout direction='column' spacing={8} className={cn('w-full', layoutClassName)}>
        <RadixSelect.Root
          {...props}
          value={value}
          open={open}
          onOpenChange={setOpen}
          onValueChange={onValueChange}
          disabled={disabled}
        >
          {title && showInputTitle && <Label>{title}</Label>}
          <RadixSelect.Trigger
            className={cn(
              'px-12 py-8',
              open ? 'focus-area-info outline outline-color-border-accent' : '',
              size === 'medium' ? 'rounded-[8px]' : 'rounded-[16px]',
              'focus:focus-area-info flex w-full bg-color-background-surface-secondary hover:bg-color-background-neutral-hover focus:outline focus:outline-color-border-accent',
              error ? 'focus-area-error text-color-text-error outline outline-color-border-error' : '',
              disabled ? 'cursor-default text-color-text-disabled hover:bg-color-background-surface-secondary' : '',
              className,
              triggerClassName,
            )}
            disabled={disabled}
          >
            <FlexLayout
              alignItems='center'
              justifyContent='space-between'
              className='h-full w-full leading-5'
              data-spotlightelementid={spotlightElementId}
            >
              <FlexLayout
                alignItems='center'
                justifyContent='start'
                spacing={12}
                className={value ? 'text-color-text-primary' : 'text-color-text-neutral'}
              >
                {selectedItem?.leadingIcon}
                {!noSelectedLabel && (
                  <Body size={size} weight={titleWeight}>
                    {selectedItem?.label || placeholder}
                  </Body>
                )}
              </FlexLayout>
              <RadixSelect.Icon>
                <Button
                  variant='ghost'
                  layout='icon'
                  size='sm'
                  leadingIcon={<ArrowChevronDown className='h-16 w-16' />}
                  disabled={disabled}
                ></Button>
              </RadixSelect.Icon>
            </FlexLayout>
          </RadixSelect.Trigger>
          {!isXs && (
            <RadixSelect.Portal>
              <RadixSelect.Content
                style={{ width: 'var(--radix-select-trigger-width)' }}
                className={cn(
                  `rounded-xl z-modal-popover rounded-8 border 
                  border-color-border-main bg-color-background-surface-primary p-8 shadow-md`,
                  portalClassName,
                )}
                position='popper'
                sideOffset={5}
              >
                <RadixSelect.Viewport>
                  <OverlayScrollbarsComponent
                    className='flex h-full flex-col p-0'
                    options={{ scrollbars: { visibility: 'auto', autoHide: 'leave', autoHideDelay: 400 } }}
                  >
                    <FlexLayout direction='column' className='max-h-[18rem] overflow-y-auto'>
                      {items.map((item) => (
                        <SelectItem key={item.value} item={item} selected={item.value === value} />
                      ))}
                    </FlexLayout>
                  </OverlayScrollbarsComponent>
                </RadixSelect.Viewport>
              </RadixSelect.Content>
            </RadixSelect.Portal>
          )}
        </RadixSelect.Root>
      </FlexLayout>
    </>
  );
};

export { Select };
