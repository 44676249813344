import React from 'react';
import { useTranslation } from 'react-i18next';

import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Progress } from '@swyftx/aviary/atoms/Progress';
import { Body } from '@swyftx/aviary/atoms/Typography';

import { FormattedText } from '@global-components/Text';

import { Big } from '@shared/safe-big';
import { assetService } from '@shared/services';
import { UserStore } from '@shared/store';

import { useWithdrawalLimit } from '@Wallet/components/WithdrawalLimit/WithdrawalLimit.hooks';

import { useIntercom } from 'react-use-intercom';

type Props = {};

export const WithdrawalLimit: React.FC<Props> = () => {
  const { t } = useTranslation('wallet', { keyPrefix: 'withdrawalLimit' });
  const { show: showIntercom } = useIntercom();
  const { limits, loading, error } = useWithdrawalLimit();
  const { userCountryCurrency } = UserStore.useUserStore;

  const countryAsset = assetService.getAsset(userCountryCurrency);

  if (error) {
    // TODO
    return null;
  }

  const used = Big(limits?.used) || 0;
  const remaining = Big(limits?.remaining) || 0;

  const total = used.add(remaining);
  const progress = used.div(total).times(100);

  return (
    <FlexLayout
      direction='column'
      alignItems='center'
      className='w-full rounded-16 bg-color-background-surface-secondary px-16 py-32 text-center'
      spacing={16}
    >
      <Body weight='bold' color='primary'>
        {t('title')}
      </Body>
      <FlexLayout direction='row' alignItems='center' spacing={4}>
        <FormattedText
          typographyProps={{ size: 'large', weight: 'bold', color: 'primary' }}
          value={used}
          currency={countryAsset}
        />
        <Body color='secondary'>/</Body>
        <FormattedText
          typographyProps={{ size: 'large', color: 'secondary' }}
          value={total}
          formatOpts={{ appendCode: true }}
          loading={loading}
          currency={countryAsset}
        />
      </FlexLayout>
      <div className='w-3/4'>
        <Progress value={progress.toNumber()} />
      </div>
      <div>
        <Body size='small' color='secondary' className='inline'>
          Note: limit includes both fiat and crypto transactions. Increase your daily limit{' '}
        </Body>
        <Body className='inline cursor-pointer' size='small' color='primary' onClick={showIntercom}>
          here.
        </Body>
      </div>
    </FlexLayout>
  );
};
