import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@swyftx/aviary/atoms/Button';
import { FlexLayout } from '@swyftx/aviary/atoms/Layout/Flex';
import { Chat, Heart, Help, Learn, SwyftxCompact, WhatsNew } from '@swyftx/aviary/icons/outlined';
import { Menu, MenuItem } from '@swyftx/aviary/molecules/Menu';

import env from '@shared/config';
import { Links } from '@shared/constants';

import { useIntercom } from 'react-use-intercom';
import { useUsersnapApi } from 'src/context/UserSnap/UserSnap.context';
import { FeatureDiscoveryContext } from 'src/lib/feature-discovery/context/FeatureDiscovery.context';
import { useSwyftxPro } from 'src/lib/trade-pro/hooks/useSwyftxPro';

const AppHeaderHelp: React.FC = () => {
  const { t } = useTranslation('common');
  const { setShowAll: openWhatsNew } = useContext(FeatureDiscoveryContext);
  const { show: openLiveChat } = useIntercom();
  const usersnapApi = useUsersnapApi();
  const { isSwyftxPro } = useSwyftxPro();
  const feedbackID = env.USERSNAP_FEEDBACK_PROJECT_ID;
  const brandFeedbackID = env.USERSNAP_PROJECT_ID_BRAND_FEEDBACK;

  return (
    <Menu triggerContent={<Button id='header-help' variant='outlined' layout='icon' leadingIcon={<Help />} />}>
      <FlexLayout direction='column'>
        {!isSwyftxPro && (
          <MenuItem
            icon={<WhatsNew width={24} height={24} />}
            text={t('navigationMenu.whatsNew')}
            action={() => openWhatsNew(true)}
          />
        )}
        <MenuItem
          icon={<Chat width={24} height={24} />}
          text={t('navigationMenu.liveChat')}
          action={() => openLiveChat()}
        />
        <MenuItem
          icon={<Help width={24} height={24} />}
          text={t('navigationMenu.helpCentre')}
          action={() => window.open(Links.meta.support, '_blank', 'noopener,noreferrer')}
        />
        {!isSwyftxPro && (
          <MenuItem
            icon={<Learn width={24} height={24} />}
            text={t('navigationMenu.swyftxLearn')}
            action={() => window.open(Links.meta.learn, '_blank', 'noopener,noreferrer')}
          />
        )}
        <MenuItem
          icon={<Heart width={24} height={24} />}
          text={t('navigationMenu.provideFeedback')}
          action={() => usersnapApi?.show(feedbackID).then((widgetApi) => widgetApi.open({}))}
        />
        <MenuItem
          icon={<SwyftxCompact width={24} height={24} />}
          text={t('navigationMenu.provideFeedbackBrand')}
          action={() => usersnapApi?.show(brandFeedbackID).then((widgetApi) => widgetApi.open({}))}
        />
      </FlexLayout>
    </Menu>
  );
};

export { AppHeaderHelp };
