import { LoginParams } from '../..';
import { MFAEnrolment, MfaParams, MFARequired, ReCaptchaHeaders, SuperheroLoginResponse } from '../../@types/auth';
import { Methods, request } from '../rest';

const superheroEndpoints = {
  superheroLogin: ({
    data,
    headers,
  }: {
    data: LoginParams;
    headers: ReCaptchaHeaders;
  }): SuperheroLoginResponse | MFARequired | MFAEnrolment =>
    request({ path: '/superhero/login/', method: Methods.POST, data, headers, hideErrorToast: true }),

  superheroMfa: ({ data }: { data: MfaParams }): SuperheroLoginResponse =>
    request({ path: '/superhero/mfa/', method: Methods.POST, data, hideErrorToast: true }),
};

export default superheroEndpoints;
