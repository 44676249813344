import { useMemo } from 'react';

import { AssetHistoryItemStatus, HistoricalOrder, OrderType } from '@shared/api';
import { OrderStatusEnum } from '@shared/enums';
import { Big } from '@shared/safe-big';
import { assetService, TransactionHistoryItem } from '@shared/services';

import { useBaseAsset } from '@hooks/Assets/useBaseAsset';
import { useHistoricalOrder, useOrder } from '@hooks/Orders';
import { FeeEstimateOrderDetails, useEstimatedTriggerFeeIfNeeded } from '@hooks/Trade/useEstimatedTriggerFeeIfNeeded';
import TradeService from '@services/TradeService';

function isOpenTriggerOrder(order: TransactionHistoryItem) {
  return !order?.feeAmount && order.status === AssetHistoryItemStatus.Pending;
}

export const useOrderFee = (order: TransactionHistoryItem) => {
  const isTriggerFee = isOpenTriggerOrder(order);
  const { getValue } = useOrder(order);
  const baseAsset = useBaseAsset();

  const isBuy = order.orderType ? TradeService.isBuy(order.orderType) : true;

  const { feeAsset, feeAssetId } =
    useMemo(() => {
      const primaryIsLimit = order.primaryAsset === order.limitAsset;
      const primary = isTriggerFee && isBuy ? order.primaryAsset : order.secondaryAsset;
      const secondary = isTriggerFee && isBuy ? order.secondaryAsset : order.primaryAsset;

      let assetId = primaryIsLimit ? primary : secondary;

      // Deposit or Withdrawal use secondary asset as fallback
      if (!assetId && !order.orderType) {
        assetId = order.secondaryAsset;
      }

      return { feeAsset: assetService.getAsset(assetId), feeAssetId: assetId };
    }, [order.limitAsset, order.primaryAsset, order.secondaryAsset, isTriggerFee, isBuy]) || baseAsset;

  const orderDetails: FeeEstimateOrderDetails = useMemo(
    () => ({
      amount: getValue(feeAssetId),
      orderDirection: isBuy ? 'BUY' : 'SELL',
      primaryId: order.primaryAsset,
      secondaryId: order.secondaryAsset,
      pro: order.pro,
    }),
    [feeAssetId, getValue, isBuy, order],
  );

  const { feeAmount } = useEstimatedTriggerFeeIfNeeded(Big(order.feeAmount).toString(), isTriggerFee, orderDetails);

  const isFeeUncertain = order.limitAsset !== order.primaryAsset;

  return { feeAsset, feeAmount, isTriggerFee, isFeeUncertain };
};

function isOpenTriggerHistoricalOrder(order: HistoricalOrder) {
  const triggerOrderTypes = [OrderType.TriggerBuy, OrderType.TriggerSell, OrderType.StopBuy, OrderType.StopSell];
  return !order?.feeAmount && order.status === OrderStatusEnum.OPEN && triggerOrderTypes.includes(order.order_type);
}

export const useHistoricalOrderFee = (order: HistoricalOrder) => {
  const isTriggerFee = isOpenTriggerHistoricalOrder(order);
  const { getValue } = useHistoricalOrder(order);
  const baseAsset = useBaseAsset();
  const isBuy = TradeService.isBuy(order.order_type);

  const feeAsset = useMemo(
    () =>
      (isTriggerFee ? assetService.getAsset(order.primary_asset) : assetService.getAsset(order.feeAsset!)) || baseAsset,
    [order, isTriggerFee, baseAsset],
  );

  const orderDetails: FeeEstimateOrderDetails = useMemo(
    () => ({
      amount: getValue(order.primary_asset),
      orderDirection: isBuy ? 'BUY' : 'SELL',
      primaryId: order.primary_asset,
      secondaryId: order.secondary_asset,
      pro: order.pro,
    }),
    [order, getValue, isBuy],
  );

  const { feeAmount } = useEstimatedTriggerFeeIfNeeded(Big(order.feeAmount!).toString(), isTriggerFee, orderDetails);

  const isFeeUncertain = order.quantity_asset !== order.primary_asset;

  return { feeAsset, feeAmount, isTriggerFee, isFeeUncertain };
};
